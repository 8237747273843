import { Injectable, OnDestroy } from '@angular/core';
import {
  DashboardListItem,
  MainWorkbookDashboardListDomainService,
  WorkbookPinsDomainService,
  Workspace,
} from '@selfai-platform/bi-domain';
import { BiEntityPermission } from '@selfai-platform/shared';
import { ActionsForItemService, DataListItemAction } from '@selfai-platform/shell';
import { Observable, Subscription, combineLatest, map, switchMap } from 'rxjs';
import { WorkbookDashboardDataListViewService } from './workbook-dashboard-data-list-view.service';

@Injectable()
export class DashboardActionsForItemService extends ActionsForItemService<DashboardListItem> implements OnDestroy {
  private subscription = new Subscription();

  constructor(
    private readonly workbookDashboardDataListViewService: WorkbookDashboardDataListViewService,
    private readonly workbookPinsDomainService: WorkbookPinsDomainService,
    private readonly mainWorkbookDashboardListDomainService: MainWorkbookDashboardListDomainService,
  ) {
    super();

    this.subscription.add(
      workbookDashboardDataListViewService.workbookId$
        .pipe(
          switchMap((workbookId) => {
            return this.workbookPinsDomainService.loadPins(workbookId);
          }),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  override getActionsForItem(dashboard: DashboardListItem): Observable<DataListItemAction[]> {
    return combineLatest({
      workspace: this.workbookDashboardDataListViewService.workspace$,
      workbookId: this.workbookDashboardDataListViewService.workbookId$.pipe(),
      isPinned: this.workbookDashboardDataListViewService.workbookId$.pipe(
        switchMap((workbookId) => this.workbookPinsDomainService.isPinned(workbookId, dashboard.id)),
      ),
      isMain: this.workbookDashboardDataListViewService.workbookId$.pipe(
        switchMap((workbookId) =>
          this.mainWorkbookDashboardListDomainService.isMainDashboard(workbookId, dashboard.id),
        ),
      ),
    }).pipe(
      map(({ workspace, isPinned, workbookId, isMain }) => {
        const actions: DataListItemAction[] = [];

        if (this.hasPermissionForEntity(workspace, BiEntityPermission.WORKBOOK_CREATE)) {
          actions.push({
            icon: 'pi pi-clone',
            labelTranslate: 'msg.workbook-dashboard-list.table-actions.duplicate',
            action: () => this.workbookDashboardDataListViewService.duplicate(dashboard),
          });
        }

        if (this.hasPermissionForEntity(workspace, BiEntityPermission.WORKBOOK_DELETE)) {
          actions.push({
            icon: 'pi pi-trash',
            labelTranslate: 'msg.workbook-dashboard-list.table-actions.remove',
            action: (eventTarget) => this.workbookDashboardDataListViewService.remove(dashboard, eventTarget),
          });
        }

        if (this.hasPermissionForEntity(workspace, BiEntityPermission.WORKBOOK_EDIT)) {
          if (isPinned) {
            actions.push({
              labelTranslate: 'msg.workbook-pins.delete-pin',
              icon: 'pi pi-eye-slash',
              action: () => this.workbookDashboardDataListViewService.deletePinDashboard(dashboard, workbookId),
            });
          } else {
            actions.push({
              labelTranslate: 'msg.workbook-pins.pin-dashboard',
              icon: 'pi pi-eye',
              action: () => this.workbookDashboardDataListViewService.addPinDashboard(dashboard, workbookId),
            });
          }
        }

        if (this.hasPermissionForEntity(workspace, BiEntityPermission.WORKSPACE_METADATA_EDIT) && !isMain) {
          actions.push({
            icon: 'pi pi-home',
            labelTranslate: 'msg.workbook-dashboard-list.table-actions.add-to-main',
            action: () => this.workbookDashboardDataListViewService.addToMainPage(dashboard),
          });
        }

        return actions;
      }),
    );
  }

  private hasPermissionForEntity(entity: Workspace, permission: BiEntityPermission): boolean {
    return entity.permissions?.includes(permission);
  }
}
