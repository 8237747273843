import {
  ESparkClusterConfig,
  ESparkCLusterType,
  SparkClusterOverrideConfiguration,
  WorkflowRunnerAdditionalLegacyParamData,
  WorkflowRunnerAdditionalParam,
  WorkflowRunnerClusterConfiguration,
  WorkflowRunnerClusterType,
  WorkflowRunnerData,
  WorkflowRunnerLegacyData,
  WorkflowRunnerSparkClusterConfiguration,
  WorkflowRunnerSparkClusterOverrideConfigurationLegacyData,
} from '@selfai-platform/pipeline-common';
import { v4 as uuidv4 } from 'uuid';
import { isNil, omitBy } from 'lodash';

export function normalizeDataWorkflowRunner(data: WorkflowRunnerLegacyData): WorkflowRunnerData {
  const isOverride =
    data['Spark cluster configuration'] &&
    Object.keys(data['Spark cluster configuration'])?.includes('Override');

  return {
    workflowId: data['Workflow Id'] || null,
    workflowName: data['Workflow Name'] || null,
    stopFlowBeforeRun: data['Stop flow before run'] || false,
    sparkCluster: isOverride ?
      normalizeClusterConfigData((data['Spark cluster configuration'] as SparkClusterOverrideConfiguration)['Override']) :
      null,
    parameters: data['Parameters'].map(normalizeParameterData)
  }
}

export function normalizeToLegacyDataWorkflowRunner(data: WorkflowRunnerData): WorkflowRunnerLegacyData {
  return {
    'Workflow Id': data.workflowId,
    'Workflow Name': data.workflowName,
    'Stop flow before run': data.stopFlowBeforeRun,
    'Parameters': data.parameters.map(normalizeToLegacyItem),
    'Spark cluster configuration': normalizeToLegacySparkClusterConfiguration(data.sparkCluster),
  } as WorkflowRunnerLegacyData;
}

export function normalizeParameterData(parameter: WorkflowRunnerAdditionalLegacyParamData): WorkflowRunnerAdditionalParam {
  return {
    id: uuidv4(),
    name: parameter['Parameter name'],
    query: parameter['Query'],
    notEmpty: parameter['Non empty'],
    isArray: parameter['Is array'],
  }
}

export function normalizeClusterConfigData(
  data: WorkflowRunnerSparkClusterOverrideConfigurationLegacyData
): WorkflowRunnerClusterConfiguration {
  let clusterType: ESparkCLusterType;
  switch (Object.keys(data['Cluster type'])[0]) {
    case ESparkCLusterType.Local:
      clusterType = ESparkCLusterType.Local;
      break;
    case ESparkCLusterType.Standalone:
      clusterType = ESparkCLusterType.Standalone;
      break;
  }
  return {
    clusterType: clusterType,
    driverMemory: data['Driver memory'],
    executorCores: Number(data['Executor cores']),
    executorMemory: data['Executor memory'],
    uri: data['Master URI'],
    totalExecutorCores: Number(data['Total executor cores']),
    userIP: data['Driver hostname'],
    params: data['Additional application parameters'],
    numExecutors: Number(data['Total executor cores']),
  } as WorkflowRunnerClusterConfiguration;
}

function normalizeToLegacySparkClusterConfiguration(
  clusterConfig: WorkflowRunnerClusterConfiguration | null
): WorkflowRunnerSparkClusterConfiguration['Spark cluster configuration'] {
  let clusterType: WorkflowRunnerClusterType['Cluster type'];

  switch (clusterConfig?.clusterType) {
    case ESparkCLusterType.Local:
      clusterType = {
        'Local': {}
      }
      break;
    default:
      clusterType = {
        'Standalone': {}
      }
      break;
  }

  if (clusterConfig) {
    return {
      [ESparkClusterConfig.Override]: {
        'Additional application parameters': clusterConfig.params,
        'Driver hostname': clusterConfig.userIP,
        'Cluster type': clusterType,
        'Master URI': clusterConfig.uri,
        'Driver memory': clusterConfig.driverMemory,
        'Executor memory': clusterConfig.executorMemory,
        'Executor cores': clusterConfig.executorCores.toString(),
        'Number of executors': clusterConfig.numExecutors.toString(),
        'Total executor cores': clusterConfig.totalExecutorCores.toString(),
      }
    }
  } else {
    return {
      [ESparkClusterConfig.Default]: {}
    }
  }
}

function normalizeToLegacyItem(parameterItem: WorkflowRunnerAdditionalParam): WorkflowRunnerAdditionalLegacyParamData {
  return {
    'Parameter name': parameterItem.name,
    'Query': parameterItem.query,
    'Non empty': parameterItem.notEmpty,
    'Is array': parameterItem.isArray,
  } as WorkflowRunnerAdditionalLegacyParamData;
}
