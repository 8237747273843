import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  WorkbookDomainService,
  WorkbookListDomainService,
  WorkbookListItem,
  WorkspaceDomainService,
} from '@selfai-platform/bi-domain';
import { PageParams, UrlPageParamsService } from '@selfai-platform/shared';
import {
  DataListViewItem,
  DataListViewLazyComponentService,
  convertMessageToHtml,
  KNOWLEDGE_DESIGNER_ROOT_ROUTE,
  SelectedItemsService,
  TableColumn,
} from '@selfai-platform/shell';
import { ConfirmationService } from 'primeng/api';
import { Observable, filter, map, shareReplay, switchMap, take, tap } from 'rxjs';

@Injectable()
export class WorkbookDataListViewService extends DataListViewLazyComponentService<WorkbookListItem> {
  workspaceId$: Observable<string> = this.route.paramMap.pipe(
    filter((params) => params.has('workspaceId')),
    map((params) => params.get('workspaceId')),
  );

  workspace$ = this.workspaceId$.pipe(
    tap((workspaceId) => this.workspaceDomainService.loadWorkspace(workspaceId)),
    switchMap((workspaceId) => this.workspaceDomainService.getWorkspace(workspaceId)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  constructor(
    private readonly route: ActivatedRoute,
    private readonly workspaceDomainService: WorkspaceDomainService,
    private readonly workbookDomainService: WorkbookDomainService,
    private readonly workbookListDomainService: WorkbookListDomainService,
    private readonly translate: TranslateService,
    private readonly confirmationService: ConfirmationService,
    urlPageParamsService: UrlPageParamsService,
    selectedItemsService: SelectedItemsService<WorkbookListItem>,
  ) {
    super(urlPageParamsService, selectedItemsService);

    this.subscription.add(
      this.workspaceId$
        .pipe(switchMap((workspaceId) => this.workspaceDomainService.loadWorkspace(workspaceId)))
        .subscribe(),
    );
  }

  override loadData(pageParams: PageParams): Observable<{ items: WorkbookListItem[]; totalItems: number }> {
    return this.workspaceId$.pipe(
      switchMap((workspaceId) =>
        this.workbookListDomainService
          .loadWorkbookList(workspaceId, pageParams)
          .pipe(map((state) => ({ items: state.workbooks, totalItems: state.page.totalElements }))),
      ),
    );
  }

  override getData(): Observable<WorkbookListItem[]> {
    return this.workspaceId$.pipe(
      switchMap((workspaceId) =>
        this.workbookListDomainService
          .getWorkbookList(workspaceId)
          .pipe(
            map((state) =>
              state
                ? state.workbooks
                    .map((workbook) => this.addIcon(workbook))
                    .map((workbook) => this.addRouterLink(workbook))
                : [],
            ),
          ),
      ),
    );
  }

  override isLoading(): Observable<boolean> {
    return this.workbookListDomainService.getWorkbookListLoading();
  }

  override isLoaded(): Observable<boolean> {
    return this.workbookListDomainService.getWorkbookListSuccess();
  }

  override hasError(): Observable<boolean> {
    return this.workbookListDomainService.getWorkbookListError().pipe(map(Boolean));
  }

  export(workbook: WorkbookListItem): void {
    this.exportToFile(workbook);
  }

  duplicate(workbook: WorkbookListItem): void {
    this.subscription.add(
      this.workbookDomainService
        .copyWorkbook(workbook.id)
        .pipe(
          take(1),
          tap(() => this.triggerReloadData()),
        )
        .subscribe(),
    );
  }

  remove(workbook: WorkbookListItem, eventTarget?: EventTarget): void {
    this.confirmationService.confirm({
      target: eventTarget,
      message: convertMessageToHtml(this.translate.instant('msg.workbook-list.delete.confirmation')),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.workbookDomainService
          .removeToTrash(workbook.id)
          .pipe(
            take(1),
            tap(() => this.triggerReloadData()),
          )
          .subscribe();
      },
    });
  }

  getColumns(): TableColumn<WorkbookListItem>[] {
    return [
      {
        labelTranslate: 'msg.workbook-list.table-header.name',
        fieldCode: 'name',
        fieldType: 'text',
        classStyle: 'no-breaks short-text',
        width: 'calc(100% - 45rem)',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'msg.workbook-list.table-header.author',
        fieldCode: 'author',
        fieldType: 'text',
        classStyle: 'no-breaks short-text',
        width: '9rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'msg.workbook-list.table-header.updated',
        fieldCode: 'modified',
        fieldType: 'date',
        classStyle: 'no-breaks short-text',
        width: '10rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'msg.workbook-list.table-header.created',
        fieldCode: 'create',
        fieldType: 'date',
        classStyle: 'no-breaks short-text',
        width: '10rem',
        resizable: true,
        sortable: true,
      },
    ];
  }

  private addIcon(workbook: WorkbookListItem): WorkbookListItem {
    return {
      ...workbook,
      icon: 'pi pi-book text-purple-400',
    };
  }

  private addRouterLink(workbook: WorkbookListItem): WorkbookListItem & Pick<DataListViewItem, 'routerLinkToItem'> {
    return {
      ...workbook,
      routerLinkToItem: {
        routerLink: ['/', KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'workbook', workbook.id],
      },
    };
  }

  private exportToFile(book: WorkbookListItem): void {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}${month}${day}`;
    const fileName = `${book.name}_${formattedDate}.json`;

    this.subscription.add(this.workbookDomainService.exportToFile(book.id, fileName).pipe(take(1)).subscribe());
  }
}
