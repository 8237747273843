import { Pipe, PipeTransform } from '@angular/core';

import moment from 'moment';

@Pipe({
    name: 'momentmodified',
    standalone: false
})
export class MomentmodifiedPipe implements PipeTransform {
  transform(date: Date, type: string): any {
    const now = moment();

    if (type === 'isNew') {
      const diffDate = moment(date);

      if (now.diff(diffDate, 'days') < 1) {
        return moment(date).format('HH:mm:ss');
      } else {
        return moment(date).format('DD.MM.YYYY');
      }
    }

    return date;
  }
}
