import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  FavoriteListItem,
  FavoriteListStore,
  WorkspaceDomainService,
  WorkspaceListDomainService,
  WorkspaceListItem,
} from '@selfai-platform/bi-domain';
import { PageParams, UrlPageParamsService } from '@selfai-platform/shared';
import {
  DataListViewItem,
  DataListViewLazyComponentService,
  convertMessageToHtml,
  KNOWLEDGE_DESIGNER_ROOT_ROUTE,
  SelectedItemsService,
  TableColumn,
} from '@selfai-platform/shell';
import { ConfirmationService } from 'primeng/api';
import { Observable, filter, map, switchMap, take, tap } from 'rxjs';

@Injectable()
export class WorkspaceDataListViewService extends DataListViewLazyComponentService<WorkspaceListItem> {
  workspaceId$: Observable<string> = this.route.paramMap.pipe(
    filter((params) => params.has('workspaceId')),
    map((params) => params.get('workspaceId')),
  );

  workspace$ = this.workspaceId$.pipe(
    switchMap((workspaceId) => this.workspaceDomainService.getWorkspace(workspaceId)),
  );

  constructor(
    private readonly route: ActivatedRoute,
    private readonly workspaceDomainService: WorkspaceDomainService,
    private readonly workspaceListDomainService: WorkspaceListDomainService,
    private readonly favoriteListStore: FavoriteListStore,
    private readonly translate: TranslateService,
    private readonly confirmationService: ConfirmationService,
    urlPageParamsService: UrlPageParamsService,
    selectedItemsService: SelectedItemsService<WorkspaceListItem>,
  ) {
    super(urlPageParamsService, selectedItemsService);

    this.subscription.add(
      this.workspaceId$
        .pipe(switchMap((workspaceId) => this.workspaceDomainService.loadWorkspace(workspaceId)))
        .subscribe(),
    );
  }

  override loadData(pageParams: PageParams): Observable<{ items: WorkspaceListItem[]; totalItems: number }> {
    return this.workspaceListDomainService
      .loadAllWorkspaceList(pageParams)
      .pipe(map((state) => ({ items: state.workspaces, totalItems: state.page.totalElements })));
  }

  override getData(): Observable<WorkspaceListItem[]> {
    return this.workspaceListDomainService.getWorkspaceList().pipe(
      filter(Boolean),
      switchMap((workspaces) => {
        return this.favoriteListStore.entities$.pipe(
          map((favorites) => ({
            workspaces,
            favorites,
          })),
        );
      }),
      map(({ workspaces, favorites }) =>
        workspaces
          .map((workspace) => this.addIcon(workspace))
          .map((workspace) => this.addRouterLink(workspace))
          .map((workspace) => this.addLabels(workspace, favorites)),
      ),
    );
  }

  override isLoading(): Observable<boolean> {
    return this.workspaceListDomainService.getWorkspaceListLoading();
  }

  override isLoaded(): Observable<boolean> {
    return this.workspaceListDomainService.getWorkspaceListLoaded();
  }

  override hasError(): Observable<boolean> {
    return this.workspaceListDomainService.getWorkspaceListError().pipe(map(Boolean));
  }

  remove(workspace: WorkspaceListItem): void {
    this.confirmationService.confirm({
      message: convertMessageToHtml(this.translate.instant('msg.workspace-list.delete.confirmation')),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.workspaceDomainService
          .removeToTrash(workspace.id)
          .pipe(
            take(1),
            tap(() => this.triggerReloadData()),
          )
          .subscribe();
      },
    });
  }

  getColumns(): TableColumn<WorkspaceListItem>[] {
    return [
      {
        labelTranslate: 'msg.workspace-list.table-header.name',
        fieldCode: 'name',
        fieldType: 'text',
        width: 'calc(100% - 52.357rem)',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'msg.workspace-list.table-header.author',
        fieldCode: 'createdBy',
        fieldType: 'text',
        classStyle: 'no-breaks short-text',
        width: '12rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'msg.workspace-list.table-header.updated',
        fieldCode: 'modifiedTime',
        fieldType: 'date',
        classStyle: 'no-breaks short-text',
        width: '9rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'msg.workspace-list.table-header.created',
        fieldCode: 'createdTime',
        fieldType: 'date',
        classStyle: 'no-breaks short-text',
        width: '9rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'msg.workspace-list.table-header.count-workbooks',
        fieldCode: 'countWorkbooks',
        fieldType: 'text',
        classStyle: 'no-breaks short-text',
        width: '9rem',
        resizable: true,
        sortable: true,
      },
    ];
  }

  private addIcon(workbook: WorkspaceListItem): WorkspaceListItem {
    return {
      ...workbook,
      icon: 'pi pi-briefcase text-orange-700',
    };
  }

  private addLabels(
    workspace: WorkspaceListItem,
    favorites: FavoriteListItem[],
  ): WorkspaceListItem & Pick<DataListViewItem, 'labels'> {
    const isFavorite = favorites.some((favorite) => favorite.id === workspace.id);
    if (workspace.publicType === 'PRIVATE') {
      return {
        ...workspace,
        labels: [
          {
            icon: 'pi pi-user text-gray-800',
            tooltipTranslate: 'msg.space.btn.workspace.personal',
          },
        ],
      };
    }

    return workspace;
  }

  private addRouterLink(workbook: WorkspaceListItem): WorkspaceListItem & Pick<DataListViewItem, 'routerLinkToItem'> {
    return {
      ...workbook,
      routerLinkToItem: {
        routerLink: ['/', KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'workspace', workbook.id],
      },
    };
  }
}
