import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  WorkspaceDomainService,
  WorkspaceListBreadcrumbService,
  WorkspaceListDomainService,
  WorkspaceListItem,
} from '@selfai-platform/bi-domain';
import {
  BiPermission,
  DestroyService,
  LocalStorageKey,
  PREFIX_SETTINGS_LOCAL_STORAGE_KEY,
  PermissionService,
  providePageParams,
} from '@selfai-platform/shared';
import {
  BreadcrumbsMenuService,
  GroupAction,
  KNOWLEDGE_DESIGNER_ROOT_ROUTE,
  SelectedItemsService,
  TableColumn,
  ToolbarAction,
  UI_SETTINGS_VIEW_PAGE_DEFAULT_SIZE,
  convertMessageToHtml,
  provideDataListView,
} from '@selfai-platform/shell';
import { ConfirmationService } from 'primeng/api';
import { Observable, mergeMap, of, take, takeUntil, tap } from 'rxjs';
import { WorkspaceActionsForItemService } from './workspace-actions-for-item.service';
import { WorkspaceDataListViewService } from './workspace-data-list-view.service';

@Component({
    selector: 'selfai-platform-workspace-list',
    templateUrl: './workspace-list.component.html',
    styleUrls: ['./workspace-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        WorkspaceListBreadcrumbService,
        DestroyService,
        ...provideDataListView(WorkspaceDataListViewService, WorkspaceActionsForItemService),
        ...providePageParams({
            pageSize: UI_SETTINGS_VIEW_PAGE_DEFAULT_SIZE,
            pageNumber: 1,
            sortField: 'modifiedTime',
            sortOrder: 'desc',
        }),
        {
            provide: PREFIX_SETTINGS_LOCAL_STORAGE_KEY,
            useValue: LocalStorageKey.WORKSPACE_LIST_PREFIX_SETTINGS,
        },
    ],
    standalone: false
})
export class WorkspaceListComponent implements OnInit {
  actions$: Observable<ToolbarAction[]>;
  groupActions$: Observable<GroupAction[]>;
  columns: TableColumn<WorkspaceListItem>[] = this.workspaceDataListViewService.getColumns();

  pluralKeys: string[] = [
    'msg.space.ui.set-permission-schema.workbook',
    'msg.space.ui.set-permission-schema.workbooks',
    'msg.space.ui.set-permission-schema.workbooks-second',
  ];

  constructor(
    private readonly workspaceDataListViewService: WorkspaceDataListViewService,
    private readonly workspaceListBreadcrumbService: WorkspaceListBreadcrumbService,
    private readonly workspaceDomainService: WorkspaceDomainService,
    private readonly workspaceListDomainService: WorkspaceListDomainService,
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    private readonly confirmationService: ConfirmationService,
    private readonly selectedItemsService: SelectedItemsService<WorkspaceListItem>,
    private readonly translate: TranslateService,
    private readonly permissionService: PermissionService,
    private readonly router: Router,
    private readonly destroy$: DestroyService,
  ) {}

  ngOnInit(): void {
    this.actions$ = this.getActions();

    this.groupActions$ = this.getGroupActions();

    this.workspaceListBreadcrumbService
      .getBreadcrumbsMenu()
      .pipe(takeUntil(this.destroy$))
      .subscribe((items) => {
        this.breadcrumbsMenuService.setBreadcrumbsMenu(items);
      });
  }

  saveName(editingWorkspace: WorkspaceListItem): void {
    this.workspaceListDomainService.updateOneInList(editingWorkspace.id, { name: editingWorkspace.name });
  }

  toggleFavorite({ itemId, isFavorite }: { itemId: string; isFavorite: boolean }): void {
    if (isFavorite) {
      this.workspaceListDomainService.addToFavorite(itemId).pipe(take(1), takeUntil(this.destroy$)).subscribe();
    } else {
      this.workspaceListDomainService.deleteFromFavorite(itemId).pipe(take(1), takeUntil(this.destroy$)).subscribe();
    }
  }

  private getActions(): Observable<ToolbarAction[]> {
    const actions: ToolbarAction[] = [];

    if (this.hasPermission(BiPermission.KD_WORKSPACE_CREATE)) {
      actions.push({
        icon: 'pi pi-plus-circle',
        labelTranslate: 'msg.workspaces.addWorkspace',
        buttonClass: 'p-button-success',
        action: () => this.router.navigate(['/', KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'workspace', 'create']),
      });
    }

    return of(actions);
  }

  private getGroupActions(): Observable<GroupAction[]> {
    const actions: GroupAction[] = [];

    if (this.hasPermission(BiPermission.KD_WORKSPACE_DELETE_ALL)) {
      actions.push({
        icon: 'pi pi-trash',
        buttonClass: 'p-button-danger',
        labelTranslate: 'msg.workspace-list.table-actions.remove-selected',
        action: (eventTarget) => this.removeSelected(eventTarget),
      });
    }

    return of(actions);
  }

  private removeSelected(eventTarget: EventTarget): void {
    this.confirmationService.confirm({
      target: eventTarget,
      message: convertMessageToHtml(this.translate.instant('msg.workspace-list.delete-selected.confirmation')),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.selectedItemsService
          .getSelectedItemIds()
          .pipe(
            mergeMap((selectedItemIds) =>
              selectedItemIds.map((itemId) => this.workspaceDomainService.removeToTrash(itemId)),
            ),
            take(1),
            tap(() => this.workspaceDataListViewService.triggerReloadData()),
            takeUntil(this.destroy$),
          )
          .subscribe();
      },
    });
  }

  private hasPermission(permission: BiPermission): boolean {
    return this.permissionService.hasBiPermission(permission);
  }
}
