import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

import * as _ from 'lodash';

import {
  BaseOption,
  ChartType,
  Pivot,
  SeriesType,
  ShelveFieldType,
  ShelveType,
  SymbolType,
  createPivotTableInfo,
} from '@selfai-platform/bi-domain';
import { ComposeOption, WordCloudSeriesOption } from 'echarts';
import { ColorOptionConverter } from '../../converters';
import { provideBaseChartServices } from '../../services';
import { BaseChart } from '../base-chart';

import 'echarts-wordcloud';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'wordcloud-chart',
    template: '<div class="chartCanvas" style="width: 100%; height: 100%; display: block;"></div>',
    styleUrls: ['./chart-host.component.scss'],
    providers: [...provideBaseChartServices()],
    standalone: false
})
export class WordCloudChartComponent extends BaseChart implements OnInit, OnDestroy, AfterViewInit {
  public override isValid(pivot: Pivot): boolean {
    return (
      this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.DIMENSION) > 0 &&
      this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.TIMESTAMP) == 0 &&
      this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.MEASURE) +
        this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.CALCULATED) ==
        1
    );
  }

  protected override initOption(): BaseOption {
    return {
      type: ChartType.WORDCLOUD,
      series: [],
    };
  }

  protected override convertSeriesData(): ComposeOption<WordCloudSeriesOption> {
    this.chartOption.series = [
      {
        type: SeriesType.WORDCLOUD,
        width: '90%',
        height: '90%',
        shape: SymbolType.CIRCLE,
        sizeRange: [12, 60],
        rotationRange: [-90, 90],
        rotationStep: 45,
        layoutAnimation: false,
        textStyle: {
          color: function () {
            return (
              'rgb(' +
              [Math.round(Math.random() * 160), Math.round(Math.random() * 160), Math.round(Math.random() * 160)].join(
                ',',
              ) +
              ')'
            );
          },
        },
        data: this.data.columns[0].value.map((item: any) => {
          item.selected = false;

          return item;
        }),
      },
    ];

    return this.chartOption as ComposeOption<WordCloudSeriesOption>;
  }

  protected override additionalSeries(): BaseOption {
    this.chartOption = this.convertColor();

    return this.chartOption;
  }

  protected override setPivotInfo(): void {
    const aggs = this.data.columns[0].value.map((dataObj: any) => {
      return dataObj.name;
    });

    this.pivotInfo = createPivotTableInfo([], [], aggs);
  }

  protected override selection(): void {
    this.addChartSelectEventListener();
  }

  protected override convertTooltip(): BaseOption {
    return this.chartOption;
  }

  protected override selectSeriesData(seriesData: any) {
    delete seriesData.textStyle.color;
    seriesData.selected = true;
  }

  protected override unselectSeriesData(seriesData: any) {
    seriesData.textStyle.color = '#aaaaaa';
    seriesData.selected = false;
  }

  protected override clearSelectSeriesData(seriesData: any) {
    delete seriesData.textStyle.color;
    seriesData.selected = false;
  }

  private convertColor(): BaseOption {
    const cloneFieldInfo = _.cloneDeep(this.fieldInfo);

    this.fieldInfo.aggs = this.pivot.aggregations
      .filter((agg) => {
        return _.eq(agg.type, ShelveFieldType.DIMENSION) || _.eq(agg.type, ShelveFieldType.TIMESTAMP);
      })
      .map((agg) => {
        return !_.isEmpty(agg.alias) ? agg.alias : agg.name;
      });

    this.chartOption = ColorOptionConverter.convertColor(
      this.chartOption,
      this.uiOption,
      this.fieldOriginInfo,
      this.fieldInfo,
      this.pivotInfo,
      this.drawByType || undefined,
    );

    this.fieldInfo = cloneFieldInfo;

    return this.chartOption;
  }
}
