import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TreeNode } from '@selfai-platform/pipeline-common';
import { LoadingService, LoadingState } from '@selfai-platform/shared';
import { DialogService } from '@selfai-platform/shell';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { JsonSchemaTreeService } from '../../services/json-schema-tree.service';
import { LOADING_JSON_SCHEMA } from '../../tokens/loading-json-schema.token';

@Component({
    selector: 'selfai-platform-json-schema-tree-view',
    templateUrl: './json-schema-tree-view.component.html',
    styleUrls: ['./json-schema-tree-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [JsonSchemaTreeService],
    standalone: false
})
export class JsonSchemaTreeViewComponent implements OnInit {
  formControl = new UntypedFormControl();
  node$?: Observable<TreeNode>;
  loadingState$!: Observable<LoadingState>;

  constructor(
    private readonly jsonSchemeService: JsonSchemaTreeService,
    private readonly dialogService: DialogService<TreeNode>,
    @Inject(LOADING_JSON_SCHEMA) private readonly loadingJsonSchema: LoadingService,
  ) {}

  ngOnInit(): void {
    this.loadingState$ = this.loadingJsonSchema.getState();
    this.jsonSchemeService.loadJsonSchema().subscribe();

    this.node$ = this.jsonSchemeService.getRootNode().pipe(filter(Boolean));
  }

  onClickItem(item: TreeNode): void {
    this.dialogService.close(item);
  }
}
