import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BiPermission, KdConfigService, PermissionService } from '@selfai-platform/shared';
import { MenuService } from '../../menu';
import { map } from 'rxjs';
import { PATH_RECYCLEBIN_ADMIN, PATH_RECYCLEBIN_USER } from '../../navigation';

@Component({
    selector: 'selfai-platform-recycle-menu',
    templateUrl: './recycle-menu.component.html',
    styleUrls: ['./recycle-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RecycleMenuComponent {
  menuMode$ = this.menuService.menuMode$;

  PATH_RECYCLEBIN_ADMIN = PATH_RECYCLEBIN_ADMIN;
  PATH_RECYCLEBIN_USER = PATH_RECYCLEBIN_USER;

  hasAccessToAdminRecycleBin$ = this.permissionService.currentUserBiPermissionsObs$.pipe(
    map((perms) => perms.some((p) => p === BiPermission.KD_RECYCLEBIN_ADMIN)),
  );

  constructor(
    private permissionService: PermissionService,
    private menuService: MenuService,
    private kdConfigService: KdConfigService,
  ) {}

  get hasAccessToUserRecycleBin() {
    return this.kdConfigService.getConfig().enabled;
  }
}
