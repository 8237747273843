import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';

import { cloneDeep, isNil } from 'lodash';
import { OverlayOnShowEvent } from 'primeng/api';
import { takeUntil } from 'rxjs';

import {
  Candidate,
  Dashboard,
  InclusionFilter,
  InclusionSelectorType,
  createCandidate,
} from '@selfai-platform/bi-domain';
import { DestroyService } from '@selfai-platform/shared';

import { AbstractComponent } from '../../../../common/component/abstract.component';
import { DatasourceService } from '../../../../datasource/service/datasource.service';

@Component({
    selector: 'selfai-bi-filter-select',
    templateUrl: './filter-select.component.html',
    styleUrls: ['./filter-select.component.scss'],
    providers: [DestroyService],
    standalone: false
})
export class FilterSelectComponent extends AbstractComponent implements OnInit {
  private static readonly AllSelectedMessageTranslateKey = 'msg.comm.ui.list.all';
  private static readonly UnSelectedMessageTranslateKey = 'msg.comm.ui.no.filtering';

  @ViewChild('ddpOffSet', { static: true })
  private ddpOffSet: ElementRef;

  @Input()
  set inputCandidateList(array: Candidate[]) {
    this.candidateList = array;
  }

  @Input()
  disabled = false;

  @Input()
  selectedItems: Candidate[];

  @Input()
  isShowSelectListForOutSide = true;

  @Input()
  isShowAll = true;

  @Input()
  viewKey: string;

  @Input()
  isUpSelect = false;

  @Input()
  viewType = 'widget';

  @Input()
  isMockup = false;

  @Input()
  filter: InclusionFilter;

  @Input()
  dashboard: Dashboard;

  @Output() afterSelected = new EventEmitter();

  @Output() afterCheckAll = new EventEmitter();

  @Output() changeDisplayOptions = new EventEmitter<boolean>();

  @Output() loadPage = new EventEmitter<number>();

  candidateList: Candidate[];

  searchText = '';

  defaultIndex = -1;

  pageNum = 0;

  pageCandidateList: Candidate[] = [];
  currentPage = 1;
  lastPage = 1;
  pageSize = 10;
  totalCount = 0;

  viewItems: string[];
  overlayVisible = false;
  attachElement: HTMLElement;

  isStringArray = false;
  isShowSelectList = false;

  allSelectedMessage = this.translateService.instant(FilterSelectComponent.AllSelectedMessageTranslateKey);
  unselectedMessage = this.translateService.instant(FilterSelectComponent.UnSelectedMessageTranslateKey);
  noSelected = false;

  get isSelectedAll() {
    return (
      this.selectedItems && this.selectedItems.length > 0 && this.selectedItems.length === this.candidateList.length
    );
  }

  constructor(
    protected elementRef: ElementRef,
    protected injector: Injector,
    protected datasourceService: DatasourceService,
    private readonly $destroy: DestroyService,
  ) {
    super(elementRef, injector);
    this.attachElement = document.getElementsByClassName('bi-incapsulation')[0] as HTMLElement;
  }

  ngOnInit() {
    super.ngOnInit();

    if (isNil(this.selectedItems)) {
      this.selectedItems = [];
    }

    if (this.candidateList !== null && this.candidateList.length > 0) {
      if (typeof this.candidateList[0] === 'string') {
        this.isStringArray = true;
      }
    }

    if (this.isSelectorTypeSingle()) {
      if (this.defaultIndex > -1) {
        this.selectedItems.push(this.candidateList[this.defaultIndex]);
      }
    }

    this.updateView();
    this.setCandidatePage(1, true);

    this.setTranslation();
  }

  private setTranslation() {
    this.translateService
      .getStreamOnTranslationChange([
        FilterSelectComponent.AllSelectedMessageTranslateKey,
        FilterSelectComponent.UnSelectedMessageTranslateKey,
      ])
      .pipe(takeUntil(this.$destroy))
      .subscribe((data) => {
        this.allSelectedMessage = data[FilterSelectComponent.AllSelectedMessageTranslateKey];
        this.unselectedMessage = data[FilterSelectComponent.UnSelectedMessageTranslateKey];
      });
  }

  clearSearch() {
    this.searchText = '';
    this.setCandidatePage(1, true);
  }

  onSearchKeyUp() {
    this.setViewListPosition();
    this.setCandidatePage(1, true);
  }

  onSearchClick($event: Event) {
    $event.stopPropagation();
  }

  selectAllItem(): void {
    if (this.isMockup) {
      this.alertPrimeService.info(this.translateService.instant('msg.board.alert.not-select-editmode'));
      return;
    }

    this.selectedItems = [];

    if (this.isSelectorTypeSingle()) {
      this.afterCheckAll.emit('all');
      this.isShowSelectList = false;
      this.changeDisplayOptions.emit(this.isShowSelectList);
    }
  }

  selectCandidateItem(item: Candidate): void {
    if (this.isMockup) {
      this.alertPrimeService.info(this.translateService.instant('msg.board.alert.not-select-editmode'));
      return;
    }

    if (this.isSelectorTypeSingle()) {
      this.selectedItems = [];
      this.selectedItems.push(item);

      this.afterSelected.emit(item);

      this.isShowSelectList = false;
      this.changeDisplayOptions.emit(this.isShowSelectList);
    }

    if (this.isSelectorTypeMulti()) {
      if (this.isSelectedItem(item)) {
        const idx = this.selectedItems.findIndex((arrItem) => arrItem.name === item.name);
        this.selectedItems.splice(idx, 1);
      } else {
        this.selectedItems.push(item);
      }
      this.afterSelected.emit(this.selectedItems);
    }

    this.updateView();
  }

  onClickHost(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      if (this.isSelectorTypeMulti()) {
        if (this.isShowSelectList && this.candidateList && this.candidateList.length) {
          this.afterSelected.emit(this.selectedItems);
        }
      }

      this.isShowSelectList = false;
      this.changeDisplayOptions.emit(this.isShowSelectList);
    }
  }

  toggleSelectList(event?: MouseEvent): void {
    if (event) {
      event.stopImmediatePropagation();
      event.preventDefault();
    }

    this._setViewListPosition();
    this.isShowSelectList = !this.isShowSelectList;

    if (this.isSelectorTypeMulti()) {
      if (!this.isShowSelectList) {
        this.afterSelected.emit(this.selectedItems);
      }
    }

    this.changeDisplayOptions.emit(this.isShowSelectList);
  }

  setViewListPosition(): void {
    this._setViewListPosition();
  }

  reset(valueList: Candidate[]): void {
    if (valueList == null || valueList.length === 0) {
      this.selectedItems = [];
    } else {
      if (this.isSelectorTypeSingle()) {
        this.selectedItems.push(valueList[0]);
      } else if (this.isSelectorTypeMulti()) {
        this.selectedItems = valueList;
      }
    }

    this.changeDetect.detectChanges();
  }

  closeList(): void {
    this.isShowSelectList = false;

    if (this.isSelectorTypeMulti()) {
      this.afterSelected.emit(this.selectedItems);
    }

    this.changeDisplayOptions.emit(this.isShowSelectList);

    this.changeDetect.detectChanges();
  }

  onScroll(): void {
    this.loadPage.emit(this.pageNum++);
  }

  candidateFromSearchText() {
    this.pageLoaderService.show();
    this.datasourceService
      .getCandidateForFilter(this.filter, this.dashboard, [], null, null, this.searchText)
      .then((resultCandidates) => {
        if (resultCandidates && resultCandidates.length > 0) {
          resultCandidates.forEach((resultCandidate) => {
            if (this.existCandidate(resultCandidate.field) === false) {
              const candidate = createCandidate({
                name: resultCandidate.field,
                count: resultCandidate.count,
                isTemporary: true,
              });
              this.candidateList.push(candidate);
            }
          });

          this.setCandidatePage(1, true);
        }

        this.isShowSelectList = true;
        this.safelyDetectChanges();

        this.pageLoaderService.hide();
      })
      .catch((error) => {
        this.commonExceptionHandler(error);
      });
  }

  setCandidatePage(page: number, isInitial: boolean = false): void {
    if (isInitial) {
      this.pageCandidateList = [];
      this.currentPage = 1;
      this.lastPage = 1;
      this.totalCount = 0;
    }

    if (page <= 0 || this.lastPage < page) {
      return;
    }

    this.currentPage = page;
    let start = 0;
    let end = 0;

    if (this.candidateList && 0 < this.candidateList.length) {
      let pagedList: Candidate[] = cloneDeep(this.candidateList);

      if (this.filter.showSelectedItem) {
        pagedList = pagedList.filter((item) => {
          return -1 < this.selectedItems.findIndex((val) => val.name === item.name);
        });
      }

      if ('' !== this.searchText) {
        pagedList = pagedList.filter((item) => {
          return item.name ? -1 < item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) : false;
        });
      }

      this.totalCount = pagedList.length;

      this.lastPage =
        this.totalCount % this.pageSize === 0
          ? this.totalCount / this.pageSize
          : Math.floor(this.totalCount / this.pageSize) + 1;
      1 > this.lastPage && (this.lastPage = 1);

      start = page * this.pageSize - this.pageSize;
      end = page * this.pageSize;
      if (end > this.totalCount) {
        end = this.totalCount;
      }

      this.pageCandidateList = pagedList.slice(start, end);
    }
  }

  deselectCandidate(): void {
    this.selectedItems = [];
    this.filter.showSelectedItem = false;
    this.setCandidatePage(1, true);
    this.selectAllItem();
    this.updateView();
  }

  updateView(items?: Candidate[]): void {
    this.viewItems = this.calculateViewText(items || this.selectedItems);
  }

  private calculateViewText(selectedItems: Candidate[]): string[] {
    this.noSelected = false;
    if (selectedItems == null || selectedItems.length === 0) {
      this.noSelected = true;
      return [this.unselectedMessage];
    }
    if (selectedItems.length === this.candidateList.length) {
      return [this.allSelectedMessage];
    }
    return (this.viewItems = selectedItems.map((item) => item.name));
  }

  checkAll(): void {
    if (this.isMockup) {
      this.alertPrimeService.info(this.translateService.instant('msg.board.alert.not-select-editmode'));
      return;
    }

    if (this.isSelectedAll) {
      this.selectedItems = [];
    } else {
      this.selectedItems = [];
      this.candidateList.forEach((item) => this.selectedItems.push(item));
    }

    this.updateView();
  }

  isSelectedItem(targetItem: Candidate): boolean {
    return this.selectedItems && this.selectedItems.some((item) => item.name === targetItem.name);
  }

  isSelectorTypeSingle(): boolean {
    return this.filter.selector === InclusionSelectorType.SINGLE_COMBO;
  }

  isSelectorTypeMulti(): boolean {
    return this.filter.selector === InclusionSelectorType.MULTI_COMBO;
  }

  onPopupOpen($event: OverlayOnShowEvent): void {
    if (this.viewType === 'widget') {
      const $ddpOffSetEl = $(this.ddpOffSet.nativeElement);
      const $dropboxWidth = $ddpOffSetEl.width();
      $($event.overlay)
        .find('.ddp-wrap-popup2')
        .css({
          width: $dropboxWidth + 35,
        });
    }
  }

  private _setViewListPosition(): void {
    this.overlayVisible = true;
  }

  private existCandidate(name: string): boolean {
    const filteredCandidates = this.candidateList.filter((candidate) => candidate.name === name);
    return filteredCandidates !== null && filteredCandidates.length > 0;
  }
}
