import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ChartPdfDownloadApiToDomainService } from './char-pdf-download-api-to-domain.service';

@Injectable({ providedIn: 'root' })
export class ChartPdfDownloadDomainService {
  constructor(private readonly chartPdfDownloadApiToDomainService: ChartPdfDownloadApiToDomainService) {}

  downloadChartPdf(fileName = 'chart.pdf', widgetId: string): Observable<void> {
    return this.chartPdfDownloadApiToDomainService.downloadChartPdf(fileName, widgetId);
  }

  downloadDashboardPdf(fileName = 'dashboard.pdf', dashboardId: string): Observable<void> {
    return this.chartPdfDownloadApiToDomainService.downloadDashboardPdf(fileName, dashboardId);
  }
}
