import { ChangeDetectionStrategy, Component, OnInit, Optional, ViewChild } from '@angular/core';

import { ConfirmationService } from 'primeng/api';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import {
  Observable,
  ReplaySubject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  skip,
  take,
  takeUntil,
} from 'rxjs';

import { Book, Dashboard, DashboardList, Datasource } from '@selfai-platform/bi-domain';
import { DestroyService, PageParams, PageParamsAdapter, UrlPageParamsLegacyService } from '@selfai-platform/shared';
import { BI_ROOT_ROUTE, getUiSettingsDefaultPageSizes, provideDialogService } from '@selfai-platform/shell';
import { DashboardListComponentService } from './dashboard-list-component.service';

type SelectWorkbookFn = (workbook: { id: string }) => void;

@Component({
    selector: 'selfai-platform-bi-dashboard-list',
    templateUrl: './dashboard-list.component.html',
    styleUrls: ['./dashboard-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ConfirmationService,
        DestroyService,
        {
            provide: PageParamsAdapter,
            useClass: UrlPageParamsLegacyService,
        },
        DashboardListComponentService,
        provideDialogService(),
    ],
    standalone: false
})
export class DashboardListComponent implements OnInit {
  dashboardList$: Observable<Dashboard[]>;
  loading$: Observable<boolean>;
  pageParams: PageParams;
  querySearch$ = new ReplaySubject<string>(1);

  pageSizes = getUiSettingsDefaultPageSizes();

  textButton = '';

  onSelectWorkbook: SelectWorkbookFn | null = null;
  visibleWorkBookDialogSelector = false;

  @ViewChild('dt', { read: Table }) dataTable: Table;

  get totalRecords(): number {
    return this.pageParams.totalItems;
  }

  get first(): number {
    return this.pageParams.pageSize * (this.pageParams.pageNumber - 1);
  }

  get sortField(): string {
    return this.pageParams.sortField || 'modifiedTime';
  }

  get sortOrder(): number {
    return this.pageParams.sortOrder === 'asc' ? 1 : -1;
  }

  private dataSourceSpoilerOpened = new Set<string>();

  constructor(
    protected readonly dashboardListComponentService: DashboardListComponentService,
    private readonly confirmationService: ConfirmationService,
    private readonly destroy$: DestroyService,
    @Optional() private readonly pageParamsAdapter?: PageParamsAdapter,
  ) {}

  ngOnInit(): void {
    this.dashboardList$ = this.getDashboardList().pipe(map(({ dashboards }) => dashboards));
    this.loading$ = this.dashboardListComponentService.getDashboardListState().pipe(map(({ loading }) => loading));

    this.syncPageParams();
    this.initSearch();
  }

  getDashboardList(): Observable<DashboardList> {
    return this.dashboardListComponentService.getDashboardListState().pipe(
      map(({ value }) => value),
      filter(Boolean),
    );
  }

  loadDashboardList(event: TableLazyLoadEvent): void {
    this.querySearch$.pipe(take(1)).subscribe((query) => {
      this.setPageParams({
        pageNumber: this.calcPageNumber(event.first, event.rows),
        pageSize: event.rows,
        sortField: event.sortField as string,
        sortOrder: event.sortOrder > 0 ? 'asc' : 'desc',
        query,
      });

      this.dashboardListComponentService.loadDashboardList();
    });
  }

  remove(event: Event, item: Dashboard): void {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `Are you sure to proceed?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.dashboardListComponentService.removeDashboard(item.id, item.workBook.id);
      },
    });
  }

  onSearch(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.querySearch$.next(target.value);
  }

  getDashboardRouterLink(item: Dashboard): string[] {
    return [BI_ROOT_ROUTE, 'workbook', item.workBook.id, `?dashboardId=${item.id}`];
  }

  getWorkbookRouterLink(item: Book): string[] {
    return [BI_ROOT_ROUTE, 'workbook', item.id];
  }

  getDataSourceRouterLink(item: Datasource): string[] {
    return [BI_ROOT_ROUTE, 'management', 'storage', 'datasource', item.id];
  }

  isDataSourceSpoilerOpened(dashboardId: string): boolean {
    return this.dataSourceSpoilerOpened.has(dashboardId);
  }

  openDataSourceSpoiler(dashboardId: string): void {
    this.dataSourceSpoilerOpened.add(dashboardId);
  }

  closeDataSourceSpoiler(dashboardId: string): void {
    this.dataSourceSpoilerOpened.delete(dashboardId);
  }

  protected setPageParams(pageParams: Partial<PageParams>): void {
    this.pageParamsAdapter?.setPageParams(pageParams);
  }

  protected syncPageParams(): void {
    this.pageParamsAdapter
      ?.getPageParams()
      .pipe(filter((pageParams) => JSON.stringify(pageParams) !== JSON.stringify(this.pageParams)))
      .subscribe((pageParams) => {
        this.pageParams = pageParams;
        this.querySearch$.next(pageParams.query || '');
      });
  }

  onPageChange(pageParams: PageParams) {
    this.pageParams.pageSize = pageParams.pageSize;
    this.pageParams.pageSize = pageParams.pageNumber;
    this.setPageParams({ pageSize: pageParams.pageSize, pageNumber: pageParams.pageNumber });
    this.dashboardListComponentService.loadDashboardList();
  }

  protected initSearch(): void {
    this.querySearch$
      .pipe(distinctUntilChanged(), skip(1), debounceTime(500), takeUntil(this.destroy$))
      .subscribe((query) => {
        this.setPageParams({ query });
        this.dashboardListComponentService.loadDashboardList();
      });
  }

  protected calcPageNumber(first: number, rows: number): number {
    return Math.round(first / rows) + 1;
  }

  private openDashboardDialog(textButton: string): Observable<{ id: string }> {
    this.textButton = textButton;

    return new Observable((observer) => {
      this.registerOnSelectWorkbook((workbook: { id: string }) => {
        this.visibleWorkBookDialogSelector = false;
        this.onSelectWorkbook = null;

        observer.next(workbook);
        observer.complete();
      });

      this.visibleWorkBookDialogSelector = true;
    });
  }

  private registerOnSelectWorkbook(fn: SelectWorkbookFn): void {
    this.onSelectWorkbook = fn;
  }
}
