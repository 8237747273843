import { ChangeDetectorRef, Component, ElementRef, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import * as echarts from 'echarts/core';
import * as _ from 'lodash';

import {
  AuthenticationType,
  BoardDataSource,
  ConnectionType,
  DIRECTION,
  Dashboard,
  DataSourceSummary,
  Dataconnection,
  Datasource,
  DatasourceField as Field,
  FieldFormat,
  FieldFormatType,
  FieldRole,
  Filter,
  ImplementorType,
  JoinMapping,
  LogicalType,
  MetadataColumn,
  QueryParam,
  Stats,
  TimeRangeFilter,
  createQueryParam,
  createSort,
  createStats,
  createTimeRangeFilter,
} from '@selfai-platform/bi-domain';

import { SlickGridHeader } from '../../../common/component/grid/grid.header';
import { isSameDataSource } from '../../../dashboard';
import { DashboardUtil } from '../../../dashboard/util/dashboard.util';
import { StorageService } from '../../../data-storage/service/storage.service';
import { TimezoneService } from '../../../data-storage/service/timezone.service';
import { DataconnectionService } from '../../../dataconnection/service/dataconnection.service';
import { DatasourceService } from '../../../datasource/service/datasource.service';
import { CommonUtil } from '../../util/common.util';
import { StringUtil } from '../../util/string.util';
import { PeriodData } from '../../value/period.data.value';
import { AbstractPopupComponent } from '../abstract-popup.component';
import { DataDownloadComponent, PreviewResult } from '../data-download/data.download.component';
import { GridComponent } from '../grid/grid.component';
import { header } from '../grid/grid.header';
import { GridOption } from '../grid/grid.option';

enum FieldRoleType {
  ALL = 'ALL',
  DIMENSION = 'DIMENSION',
  MEASURE = 'MEASURE',
}

@Component({
    selector: 'data-preview',
    templateUrl: './data.preview.component.html',
    standalone: false
})
export class DataPreviewComponent extends AbstractPopupComponent implements OnInit, OnDestroy {
  public changeDetect: ChangeDetectorRef;

  @ViewChild('main', { static: true })
  private gridComponent: GridComponent;

  @ViewChild('histogram')
  private histogram: ElementRef;

  @ViewChild(DataDownloadComponent, { static: true })
  private _dataDownComp: DataDownloadComponent;

  private gridData: any[];

  private barOption: any;
  private scatterOption: any;

  private _zIndex: string;

  private _filters: Filter[] = [];

  private _queryParams = createQueryParam();

  private _isGridDataDown = true;

  @Input()
  public source: Dashboard | Datasource;

  @Input()
  public singleTab = false;

  @Input()
  public field: any;

  @Input()
  public initial: Datasource;

  public isDashboard = false;

  public srchText: string;

  public isShowDataGrid = true;
  public isShowInfoLayer = false;
  public isJoin = false;

  public fieldRoleType = FieldRoleType;
  public selectedFieldRole: FieldRoleType = this.fieldRoleType.ALL;

  public isShowTypeComboOpts = false;
  public logicalTypes: any[];
  public selectedLogicalType: any;

  public columns: any[] = [];
  public colTypes: { type: string; cnt: number }[] = [];
  public roles: any = {};

  public datasources: Datasource[] = [];
  public mainDatasource: Datasource;
  public joinMappings: JoinMapping[] = [];
  public joinDataSources: Datasource[] = [];

  public rowNum = 100;

  public selectedField: any;

  public selectedSource: Datasource;

  public covarianceData: any = {};

  public statsData: any = {};

  public selectedColumnData: any[] = [];

  public connType: string;

  public mainDsSummary: DataSourceSummary;

  public commonUtil = CommonUtil;

  public timestampField: Field;

  public downloadPreview: PreviewResult;

  public isExistDerivedField: boolean;

  constructor(
    private datasourceService: DatasourceService,
    private connectionService: DataconnectionService,
    private timezoneService: TimezoneService,
    private storageService: StorageService,

    protected elementRef: ElementRef,
    protected injector: Injector,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();

    const $popup = $('.ddp-wrap-tab-popup');
    this._zIndex = $popup.css('z-index');
    $popup.css('z-index', '9999');

    this.initView();

    if (this.source['configuration']) {
      this.isDashboard = true;
      const dashboardInfo: Dashboard = <Dashboard>this.source;

      this.datasources = _.cloneDeep(dashboardInfo.dataSources);
    } else {
      this.isDashboard = false;
      this.datasources.push(<Datasource>_.cloneDeep(this.source));
    }

    this.datasources.forEach((source) => {
      source.fields.forEach((field, index, object) => {
        this._setMetaDataField(field, source);

        if (DashboardUtil.isCurrentDateTime(field)) {
          object.splice(index, 1);
        }
      });
    });

    this.selectDataSource(
      this.initial ? this.datasources.find((item) => item.id === this.initial.id) : this.datasources[0],
    );
  }

  public ngOnDestroy() {
    super.ngOnDestroy();

    $('.ddp-wrap-tab-popup').css('z-index', this._zIndex);
  }

  private getFieldStats(selectedField, engineName) {
    return new Promise<any>((resolve, reject) => {
      const params = {
        dataSource: {
          name: engineName,
          type: 'default',
        },
        fields: [selectedField],
        userFields: [],
      };

      params.fields = params.fields.map((field) => {
        return {
          name: field.name,
          type: field.role.toLowerCase(),
        };
      });

      this.datasourceService
        .getFieldStats(params)
        .then((result) => {
          if (!_.isNil(result[0])) {
            for (const property in result[0]) {
              if (!this.statsData.hasOwnProperty(property)) {
                this.statsData[property] = result[0][property];
              }
            }
          }
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private queryData(source: Datasource): Promise<any> {
    return new Promise<any>((res, rej) => {
      const params = createQueryParam();
      params.limits.limit = this.rowNum < 1 ? 100 : this.rowNum;

      const dsInfo = _.cloneDeep(<Datasource>source);
      params.dataSource.name = dsInfo.engineName;
      params.dataSource.engineName = dsInfo.engineName;
      if (dsInfo.connType) {
        params.dataSource.connType = dsInfo.connType.toString();
      } else {
        if (-1 < dsInfo.engineName.indexOf('temporary_ingestion_')) {
          params.dataSource.connType = 'LINK';
          params.dataSource.temporary = true;
        } else {
          params.dataSource.connType = 'ENGINE';
        }
      }
      params.dataSource.type = 'default';

      params.filters = this._filters && 0 < this._filters.length ? this._filters : [];

      this.pageLoaderService.show();
      this.datasourceService
        .getDatasourceQuery(params)
        .then((gridData) => {
          params.limits.limit = 10000000;
          this._queryParams = _.cloneDeep(params);

          params.metaQuery = true;
          this.datasourceService
            .getDatasourceQuery(params)
            .then((metaData) => {
              this.downloadPreview = new PreviewResult(metaData.estimatedSize, metaData.totalCount);
              this.rowNum > this.downloadPreview.count && (this.rowNum = this.downloadPreview.count);
            })
            .catch((err) => {});
          res(gridData);
          this.pageLoaderService.hide();
        })
        .catch((err) => {
          console.error(err);
          rej(err);
          this.pageLoaderService.hide();
        });
    });
  }

  private _getFilteredFieldList(field: Field[]): Field[] {
    return field.filter(
      (field) =>
        (this.selectedFieldRole === FieldRoleType.ALL
          ? true
          : FieldRoleType.DIMENSION === this.selectedFieldRole && FieldRole.TIMESTAMP === field.role
          ? field
          : this.selectedFieldRole.toString() === field.role.toString()) &&
        (this.selectedLogicalType.value === 'all' ? true : this.selectedLogicalType.value === field.logicalType),
    );
  }

  private _getGridHeader(fields: Field[]): header[] {
    const derivedFieldList = fields ? fields.filter((field) => field.derived) : [];

    if (derivedFieldList.length > 0) {
      const defaultStyle = 'line-height:30px;';
      const nullStyle = 'color:#b6b9c1;';
      const noPreviewGuideMessage: string = this.translateService.instant('msg.dp.ui.no.preview');

      return fields.map((field: Field) => {
        const headerName: string = field.headerKey || field.name;
        return new SlickGridHeader()
          .Id(headerName)
          .Name(this._getGridHeaderName(field, headerName))
          .Field(headerName)
          .Behavior('select')
          .Selectable(false)
          .CssClass('cell-selection')
          .Width(10 * headerName.length + 20)
          .MinWidth(100)
          .CannotTriggerInsert(true)
          .Resizable(true)
          .Unselectable(true)
          .Sortable(true)
          .Formatter((row, cell, value) => {
            if (
              field.derived &&
              (field.logicalType === LogicalType.STRING || this.mainDatasource.connType === ConnectionType.LINK)
            ) {
              return '<div  style="' + defaultStyle + nullStyle + '">' + noPreviewGuideMessage + '</div>';
            } else {
              return value;
            }
          })
          .build();
      });
    } else {
      return fields.map((field: Field) => {
        const headerName: string = field.headerKey || field.name;
        return new SlickGridHeader()
          .Id(headerName)
          .Name(this._getGridHeaderName(field, headerName))
          .Field(headerName)
          .Behavior('select')
          .Selectable(false)
          .CssClass('cell-selection')
          .Width(10 * headerName.length + 20)
          .MinWidth(100)
          .CannotTriggerInsert(true)
          .Resizable(true)
          .Unselectable(true)
          .Sortable(true)
          .build();
      });
    }
  }

  private updateGrid(data?: any, fields?: Field[]) {
    const headers: header[] = this._getGridHeader(this._getFilteredFieldList(fields || this.columns));
    let rows: any[] = data || this.gridData;

    if (rows && 0 < headers.length) {
      if (rows.length > 0 && !rows[0].hasOwnProperty('id')) {
        rows = rows.map((row: any, idx: number) => {
          Object.keys(row).forEach((key) => {
            row[key.substr(key.indexOf('.') + 1, key.length)] = row[key];
          });
          row.id = idx;
          return row;
        });
      }

      this.rowNum = rows.length;

      this.changeDetect.detectChanges();

      this.isEnableSecondHeader()
        ? this.gridComponent.create(
            headers,
            rows,
            new GridOption()
              .SyncColumnCellResize(true)
              .MultiColumnSort(true)
              .RowHeight(32)
              .ShowHeaderRow(true)
              .HeaderRowHeight(32)
              .ExplicitInitialization(true)
              .build(),
          )
        : this.gridComponent.create(
            headers,
            rows,
            new GridOption().SyncColumnCellResize(true).MultiColumnSort(true).RowHeight(32).build(),
          );

      this.gridComponent.search(this.srchText);

      this.isEnableSecondHeader() && this.gridComponent.grid.init();
    } else {
      this.gridComponent.destroy();
    }
  }

  private _getGridHeaderName(field: Field, headerName: string): string {
    return field.logicalType === LogicalType.TIMESTAMP &&
      (this.timezoneService.isEnableTimezoneInDateFormat(field.format) ||
        (field.format && field.format.type === FieldFormatType.UNIX_TIME))
      ? `<span  style="padding-left:20px;">
        <selfai-bi-shared-field-icon [type]="${field.logicalType.toString()}" [view]="'FIELD'"></selfai-bi-shared-field-icon>
        ${headerName}<div class="slick-column-det" title="${this._getTimezoneLabel(
          field.format,
        )}">${this._getTimezoneLabel(field.format)}</div></span>`
      : `<span style="padding-left:20px;">
      <selfai-bi-shared-field-icon [type]="${field.logicalType}" [view]="'FIELD'"></selfai-bi-shared-field-icon>${headerName}</span>`;
  }

  private _getQueryDataInLinked(source): void {
    this.pageLoaderService.show();

    const connection: Dataconnection = source.connection || source.ingestion.connection;
    const params: any = source.ingestion && connection ? this._getConnectionParams(source.ingestion, connection) : {};

    if (!this._isGridDataDown) {
      const downloadParams = this._getDashboardQueryParam(source, <Dashboard>this.source);
      downloadParams.limits.limit = 10000000;
      this._queryParams = _.cloneDeep(downloadParams);
    }

    params.filters = this._filters && 0 < this._filters.length ? this._filters : [];

    this.connectionService
      .getTableDetailWitoutId(
        params,
        connection.implementor === ImplementorType.HIVE,
        this.rowNum < 1 ? 100 : this.rowNum,
      )
      .then((result: { data: any; fields: Field[]; totalRows: number }) => {
        this.gridData = result.data;

        this.rowNum !== result.data.length && (this.rowNum = result.data.length);
        this.updateGrid(this.gridData, this.columns);

        this.pageLoaderService.hide();
      })
      .catch((error) => this.commonExceptionHandler(error));
  }

  private _getDashboardQueryParam(dataSource: Datasource, board: Dashboard, params?: QueryParam): QueryParam {
    params || (params = createQueryParam());
    if (this.timestampField) {
      const sortInfo = createSort({
        field: this.timestampField.name,
        direction: DIRECTION.DESC,
      });
      params.limits.sort.push(sortInfo);
    }

    let boardDs: BoardDataSource = board.configuration.dataSource;
    if ('multi' === boardDs.type) {
      boardDs = boardDs.dataSources.find((item) => isSameDataSource(item, dataSource));
    }

    params.dataSource = _.cloneDeep(boardDs);
    params.dataSource.name = boardDs.engineName;
    const joins = boardDs.joins;
    if (joins && joins.length > 0) {
      this.isJoin = true;
      this.joinMappings = joins;
      params.dataSource.type = 'mapping';
      params.dataSource['joins'] = joins;
    }
    return params;
  }

  private _getConnectionParams(ingestion: any, connection: Dataconnection) {
    const connectionType = this.storageService.findConnectionType(connection.implementor);
    const params = {
      connection: {
        implementor: connection.implementor,
        authenticationType: connection.authenticationType || AuthenticationType.MANUAL,
      },
      database: ingestion.database,
      type: ingestion.dataType,
      query: ingestion.query,
    };

    if (StringUtil.isEmpty(connection.url)) {
      params.connection['hostname'] = connection.hostname;
      params.connection['port'] = connection.port;
      if (this.storageService.isRequireCatalog(connectionType)) {
        params.connection['catalog'] = connection.catalog;
      } else if (this.storageService.isRequireDatabase(connectionType)) {
        params.connection['database'] = connection.database;
      } else if (this.storageService.isRequireSid(connectionType)) {
        params.connection['sid'] = connection.sid;
      }
    } else {
      params.connection['url'] = connection.url;
    }

    if (connection.authenticationType !== AuthenticationType.USERINFO) {
      params.connection['username'] =
        connection.authenticationType === AuthenticationType.DIALOG
          ? ingestion.connectionUsername
          : connection.username;
      params.connection['password'] =
        connection.authenticationType === AuthenticationType.DIALOG
          ? ingestion.connectionPassword
          : connection.password;
    }
    return params;
  }

  private _getTimezoneLabel(format: FieldFormat): string {
    if (format.type === FieldFormatType.UNIX_TIME) {
      return 'Unix time';
    } else {
      return this.timezoneService.getConvertedTimezoneUTCLabel(this.timezoneService.getTimezoneObject(format).utc);
    }
  }

  private initView() {
    this.logicalTypes = [
      {
        label: this.translateService.instant('msg.comm.ui.list.all'),
        value: 'all',
      },
      {
        label: this.translateService.instant('msg.storage.ui.list.string'),
        value: 'STRING',
      },
      {
        label: this.translateService.instant('msg.storage.ui.list.boolean'),
        value: 'BOOLEAN',
      },
      {
        label: this.translateService.instant('msg.storage.ui.list.integer'),
        value: 'INTEGER',
      },
      {
        label: this.translateService.instant('msg.storage.ui.list.double'),
        value: 'DOUBLE',
      },
      {
        label: this.translateService.instant('msg.storage.ui.list.date'),
        value: 'TIMESTAMP',
      },
      {
        label: this.translateService.instant('msg.storage.ui.list.array'),
        value: 'ARRAY',
      },
      {
        label: this.translateService.instant('msg.storage.ui.list.lnt'),
        value: 'LNT',
      },
      {
        label: this.translateService.instant('msg.storage.ui.list.lng'),
        value: 'LNG',
      },
      {
        label: this.translateService.instant('msg.storage.ui.list.geo.point'),
        value: 'GEO_POINT',
        derived: true,
      },
      {
        label: this.translateService.instant('msg.storage.ui.list.geo.polygon'),
        value: 'GEO_POLYGON',
        derived: true,
      },
      {
        label: this.translateService.instant('msg.storage.ui.list.geo.line'),
        value: 'GEO_LINE',
        derived: true,
      },
    ];
    this.selectedLogicalType = this.logicalTypes[0];

    this.barOption = {
      backgroundColor: '#ffffff',
      color: ['#c1cef1'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
        },
      },
      grid: {
        left: 0,
        top: 5,
        right: 5,
        bottom: 0,
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          splitNumber: 3,
          splitLine: { show: false },
        },
      ],
      series: [
        {
          type: 'bar',
          barWidth: '70%',
          itemStyle: {
            normal: { color: '#c1cef1' },
            emphasis: { color: 'var(--color-primary)' },
          },
          data: [],
        },
      ],
    };

    this.scatterOption = {
      backgroundColor: '#ffffff',
      grid: {
        left: 8,
        right: 8,
        top: 8,
        bottom: 8,
        containLabel: true,
      },
      title: {
        x: 'center',
        text: '',
      },
      tooltip: {
        trigger: 'item',
        axisPointer: {
          show: true,
          type: 'cross',
        },
      },
      xAxis: {
        name: '',
        type: 'value',
        nameLocation: 'middle',
        axisLabel: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        scale: true,
        axisLabel: {
          show: false,
        },
      },
      series: [],
    };
  }

  private getStats(): Stats {
    if (this.selectedField.role !== 'TIMESTAMP' && this.statsData.hasOwnProperty(this.selectedField.name)) {
      return this.statsData[this.selectedField.name];
    } else if (this.selectedField.role === 'TIMESTAMP' && this.statsData.hasOwnProperty('__time')) {
      return this.statsData['__time'];
    }
    return createStats();
  }

  private get getFrequentItems() {
    return this.getStats().frequentItems;
  }

  private get getTimeSegments() {
    return this.getStats().segments;
  }

  private getQuartile(type: string) {
    let result;

    const stats = this.getStats();
    if (stats.hasOwnProperty('iqr')) {
      const length = stats.iqr.length;
      switch (type) {
        case 'LOWER':
          result = stats.iqr[0];
          break;
        case 'UPPER':
          result = stats.iqr[length - 1];
          break;
      }
    }
    return result;
  }

  private getStatsMaxAndMin(type: string) {
    let result;

    const stats = this.getStats();

    if (this.selectedField.role === 'TIMESTAMP' && stats.hasOwnProperty('segments')) {
      const length = this.getTimeSegments.length;
      switch (type) {
        case 'MIN':
          result = this.getArrayUsedSeparator(this.getTimeSegments[0].interval, /\//)[0];
          break;
        case 'MAX':
          result = this.getArrayUsedSeparator(this.getTimeSegments[length - 1].interval, /\//)[1];
          break;
      }
    } else if (this.selectedField.role !== 'TIMESTAMP') {
      switch (type) {
        case 'MIN':
          result = stats.min;
          break;
        case 'MAX':
          result = stats.max;
          break;
      }
    }
    return result;
  }

  private getArrayUsedSeparator(value: string, separator: any) {
    return value.split(separator);
  }

  private getPercentage(value: number) {
    const total = this.getRowCount();
    const result = Math.floor((value / total) * 10000) / 100;
    return isNaN(result) ? 0 : result;
  }

  private getRowCount(): number {
    const stats = this.getStats();
    if (this.selectedField.role === 'TIMESTAMP' && stats.hasOwnProperty('segments')) {
      return this.getTimeSegments
        .map((item) => {
          return item.rows === undefined ? 0 : item.rows;
        })
        .reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        });
    } else {
      return stats.count === undefined ? 0 : stats.count;
    }
  }

  private getMinMaxValue(array: any[]) {
    const min = Math.min.apply(
      null,
      array.map((item) => {
        return item.value;
      }),
    );
    const max = Math.max.apply(
      null,
      array.map((item) => {
        return item.value;
      }),
    );

    return { minValue: min, maxValue: max };
  }

  private getHistogramChart(roleType: string) {
    this.changeDetect.detectChanges();

    const barChart = echarts.init(this.histogram.nativeElement);

    barChart.setOption(this.getBarOption(roleType));
    barChart.resize();
  }

  private getBarOption(roleType: string) {
    const barOption = _.cloneDeep(this.barOption);

    const stats = this.getStats();

    if (roleType === 'DIMENSION' && !_.isNil(stats.frequentItems)) {
      barOption.xAxis[0].data = stats.frequentItems.map((item) => {
        return item.value;
      });
      barOption.series[0].data = stats.frequentItems.map((item) => {
        return item.count;
      });
    } else if (roleType === 'TIMESTAMP' && !_.isNil(stats.segments)) {
      barOption.xAxis[0].data = stats.segments.map((item) => {
        return item.interval.split('/')[0];
      });
      barOption.series[0].data = stats.segments.map((item) => {
        return item.rows;
      });
    } else {
      const count = stats.count;
      let pmf = stats.pmf;
      if (!_.isNil(pmf)) {
        pmf = this.getPmfList(pmf);

        barOption.series[0].data = pmf.map((item) => {
          return item * count;
        });

        barOption.xAxis[0].data = pmf.map((item, index) => {
          return index + 1;
        });
      }
    }

    return barOption;
  }

  private getScatterOption(series: any) {
    const scatterOption = _.cloneDeep(this.scatterOption);
    scatterOption.series = series;
    return scatterOption;
  }

  private getPmfList(pmf: any[]) {
    const pmfLength = pmf.length;

    if (pmfLength <= 10) {
      return pmf;
    } else if (pmfLength === 11) {
      pmf.shift();
      return pmf;
    } else {
      pmf.shift();
      pmf.pop();
      return pmf;
    }
  }

  private _setMetaDataField(field: Field, source: Datasource): void {
    if (this.isExistMetaData(source)) {
      const fieldMetaData: MetadataColumn = _.find(source.uiMetaData.columns, {
        physicalName: field.name,
      });
      if (!_.isUndefined(fieldMetaData)) {
        field['codeTable'] = fieldMetaData.codeTable;

        field['dictionary'] = fieldMetaData.dictionary;
      }
    }
  }

  public getTimezoneLabelInColumn(format: FieldFormat): string {
    if (format.type === FieldFormatType.UNIX_TIME) {
      return 'Unix time';
    } else {
      return this.timezoneService.getTimezoneObject(format).label;
    }
  }

  public onChangeDate(data: PeriodData) {
    if ('ALL' === data.type) {
      this._filters = [];
    } else {
      const timestampField: Field = this.columns.filter((item) => item.role === 'TIMESTAMP')[0];
      const timeRange: TimeRangeFilter = createTimeRangeFilter(timestampField);
      timeRange.intervals = [
        data.startDateStr.replace(/T/gi, ' ') + ':00' + '/' + data.endDateStr.replace(/T/gi, ' ') + ':00',
      ];
      this._filters = [timeRange];
    }

    if (this.connType === 'LINK' && 'ALL' === data.type) {
      this._getQueryDataInLinked(this.mainDatasource);
    } else {
      this.queryData(this.mainDatasource)
        .then((data) => {
          this.gridData = data;
          this.updateGrid(data, this.columns);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  public selectDataSource(dataSource: Datasource) {
    this.mainDatasource = dataSource;
    this.rowNum = 100;
    this.isExistDerivedField = dataSource.fields.some((field) => field.derived);

    this.selectedField = null;
    this.timestampField = null;
    this.columns = [];
    this.colTypes = [];
    this.roles = {};

    this.joinMappings = [];
    this.joinDataSources = [];

    this.safelyDetectChanges();

    if (this.isDashboard) {
      this.mainDsSummary = this.mainDatasource && this.mainDatasource.summary ? this.mainDatasource.summary : undefined;
      this.columns = this.mainDatasource.fields;
      if (this.mainDsSummary) {
        this.downloadPreview = new PreviewResult(this.mainDsSummary.size, this.mainDsSummary.count);
        if (this.rowNum > this.mainDsSummary.count) {
          this.rowNum = this.mainDsSummary.count;
        }
      }

      const tempColType: any = {};
      this.columns.forEach((item: Field) => {
        if (tempColType[item.logicalType]) {
          tempColType[item.logicalType] += 1;
        } else {
          tempColType[item.logicalType] = 1;
        }
        if (this.roles[item.role]) {
          this.roles[item.role] += 1;
        } else {
          this.roles[item.role] = 1;
        }
      });
      Object.keys(tempColType).forEach((key) => this.colTypes.push({ type: key, cnt: tempColType[key] }));

      const boardDs: BoardDataSource = (<Dashboard>this.source).configuration.dataSource;
      if ('multi' === boardDs.type) {
        const masterBoardDs: BoardDataSource = boardDs.dataSources.find((item) => isSameDataSource(item, dataSource));
        this.joinDataSources = [dataSource];
        if (masterBoardDs.joins && 0 < masterBoardDs.joins.length) {
          this.joinDataSources = this.joinDataSources.concat(
            (<Dashboard>this.source).dataSources.filter((item) => {
              return masterBoardDs.joins.some((joinItem: JoinMapping) => {
                return (joinItem.join && joinItem.join.id === item.id) || joinItem.id === item.id;
              });
            }),
          );
        }
      } else {
        this.joinDataSources = this.datasources;
      }
    } else {
      this.joinDataSources = this.datasources;
      this.columns = this.mainDatasource.fields;
    }

    this.timestampField = this.columns.find((item) => item.role === 'TIMESTAMP');

    this.connType = this.mainDatasource.hasOwnProperty('connType') ? this.mainDatasource.connType.toString() : 'ENGINE';

    const field = this.singleTab ? this.field : this.columns[0];
    this.isShowDataGrid = !this.singleTab;

    if (this.connType === 'LINK') {
      this._getQueryDataInLinked(this.mainDatasource);
    } else {
      this.queryData(this.mainDatasource)
        .then((data) => {
          if (data && 0 < data.length) {
            this.gridData = data;

            if (!this.singleTab) {
              this.updateGrid(data, this.columns);
            } else {
              const data = [];
              this.gridData.forEach((item) => {
                if (item.hasOwnProperty(field.name) && item[field.name]) {
                  data.push(item[field.name]);
                }
              });
              this.selectedColumnData = data;

              this.onSelectedField(field, this.mainDatasource);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  public getValueList() {
    let list = this.getFrequentItems;
    if (list && list.length > 10) {
      list = list
        .sort((a, b) => {
          return a.count > b.count ? -1 : a.count < b.count ? 1 : 0;
        })
        .slice(0, 9);
      list.push({ value: '기타' });
    }
    return list;
  }

  public isEnabledHistogram(): boolean {
    return (
      this.selectedField && (this.selectedField.logicalType === 'TIMESTAMP' || this.selectedField.role === 'MEASURE')
    );
  }

  public isEnabledValueList(): boolean {
    return (
      this.selectedField && !(this.selectedField.logicalType === 'TIMESTAMP' || this.selectedField.role === 'MEASURE')
    );
  }

  public createMetaDataHeader(args: any): void {
    $(
      '<div class="slick-data">' + (_.find(this.columns, { name: args.column.id }).logicalName || '') + '</div>',
    ).appendTo(args.node);
  }

  public isLinkedTypeSource(source: Datasource): boolean {
    return source && source.connType ? source.connType.toString() === 'LINK' : true;
  }

  public isEnableFiltering(column: any): boolean {
    return column.filtering;
  }

  public isGeoType(column: any): boolean {
    return column.logicalType.indexOf('GEO_') !== -1;
  }

  public isDerivedColumn(column: Field): boolean {
    return column.derived;
  }

  public getColumnTypeLabel(type: string, typeList: any): string {
    return typeList[_.findIndex(typeList, (item) => item['value'] === type)].label;
  }

  public getLabel(labelName: string): any {
    let result;

    const stats = this.getStats();

    switch (labelName) {
      case 'count':
        result = this.getRowCount();
        break;
      case 'valid':
        {
          const valid = this.getRowCount() - (stats.missing === undefined ? 0 : stats.missing);
          result = valid + ` (${this.getPercentage(valid)}%)`;
        }

        break;
      case 'unique':
        {
          const cardinality = stats.cardinality || stats['cardinality(estimated)'];
          const unique = cardinality === undefined ? 0 : cardinality;
          result = unique + ` (${this.getPercentage(unique)}%)`;
        }
        break;
      case 'outliers':
        {
          const outliers = stats.outliers === undefined ? 0 : stats.outliers;
          result = outliers + ` (${this.getPercentage(outliers)}%)`;
        }

        break;
      case 'missing':
        {
          const missing = stats.missing === undefined ? 0 : stats.missing;
          result = missing + ` (${this.getPercentage(missing)}%)`;
        }
        break;
      case 'min':
        {
          const min = this.getStatsMaxAndMin('MIN');
          result = min === undefined ? 0 : min;
        }
        break;
      case 'lower':
        {
          const lower = this.getQuartile('LOWER');
          result = lower === undefined ? 0 : lower;
        }
        break;
      case 'median':
        result = stats.median === undefined ? 0 : stats.median;
        break;
      case 'upper':
        {
          const upper = this.getQuartile('UPPER');
          result = upper === undefined ? 0 : upper;
        }
        break;
      case 'max':
        {
          const max = this.getStatsMaxAndMin('MAX');
          result = max === undefined ? 0 : max;
        }

        break;
      case 'average':
        result = stats.mean === undefined ? 0 : stats.mean;
        break;
      case 'standard':
        result = stats.stddev === undefined ? 0 : stats.stddev;
        break;
      case 'skewness':
        result = stats.skewness === undefined ? 0 : stats.skewness;
        break;
    }
    return result;
  }

  public changeRowNum(event: KeyboardEvent) {
    if (13 === event.keyCode) {
      this.rowNum = event.target['value'];
      if (this.downloadPreview && this.rowNum > this.downloadPreview.count) {
        this.rowNum = this.downloadPreview.count;
      }

      if (this.mainDatasource.connType === ConnectionType.LINK) {
        this._getQueryDataInLinked(this.mainDatasource);
      } else {
        this.queryData(this.mainDatasource)
          .then((data) => {
            this.gridData = data;
            this.updateGrid(data, this.columns);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }

  public resetFilter() {
    this.clearSrchText();
    this.selectedFieldRole = FieldRoleType.ALL;
    this.selectedLogicalType = this.logicalTypes[0];
    if (this.isShowDataGrid) {
      this.updateGrid();
    } else {
      console.info('column detail');
    }
  }

  public searchData(keyword: string) {
    this.srchText = keyword;

    this.updateGrid(this.gridData, this.columns);
  }

  public clearSrchText() {
    this.srchText = '';
  }

  public changeFieldRole(type: FieldRoleType) {
    this.selectedFieldRole = type;
    if (this.isShowDataGrid) {
      this.updateGrid();
    } else {
      console.info('column detail');
    }
  }

  public downloadData(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (this.connType === 'LINK' && this._isGridDataDown) {
      this._dataDownComp.openDataDown(event, this.columns, this.gridData, this.downloadPreview);
    } else {
      this._dataDownComp.openDataDown(event, this.columns, null, this.downloadPreview, this._queryParams);
    }
  }

  public onChangeLogicalType(event: MouseEvent, type) {
    event.stopPropagation();
    event.preventDefault();
    this.isShowTypeComboOpts = false;
    this.selectedLogicalType = type;
    if (this.isShowDataGrid) {
      this.updateGrid();
    } else {
      console.info('change logical type');
    }
  }

  public onChangeTab(showGridFl) {
    if (showGridFl === this.isShowDataGrid) {
      return;
    }

    this.isShowDataGrid = showGridFl;

    if (!this.isShowDataGrid && !this.selectedField) {
      this.onSelectedField(this.columns[0], this.mainDatasource);
    }

    if (this.isShowDataGrid) {
      this.updateGrid(this.gridData, this.columns);
    }
  }

  public onSelectedField(field, source) {
    this.selectedField = field;

    this.selectedSource = source;

    this.changeDetect.detectChanges();

    const engineName = source.engineName;

    this._setMetaDataField(field, source);

    if (!this.isLinkedTypeSource(source) && !this.isGeoType(field)) {
      if (
        (this.selectedField.role === 'TIMESTAMP' && !this.statsData.hasOwnProperty('__time')) ||
        (this.selectedField.role !== 'TIMESTAMP' && !this.statsData.hasOwnProperty(field.name))
      ) {
        this.pageLoaderService.show();

        this.getFieldStats(field, engineName)
          .then((stats) => {
            this.pageLoaderService.hide();

            this.getHistogramChart(this.selectedField.role);
          })
          .catch((error) => {
            this.alertPrimeService.error(error.message);

            this.pageLoaderService.hide();
          });
      } else {
        this.getHistogramChart(this.selectedField.role);
      }
    }
  }

  public getColumnList(fields) {
    let result = fields;

    if (this.selectedFieldRole !== FieldRoleType.ALL) {
      result = result.filter((field) => {
        if (
          this.selectedFieldRole === FieldRoleType.DIMENSION &&
          (field.role === this.selectedFieldRole.toString() || field.role === 'TIMESTAMP')
        ) {
          return field;
        } else if (field.role === this.selectedFieldRole.toString()) {
          return field;
        }
      });
    }

    if (this.selectedLogicalType.value !== 'all') {
      result = result.filter((field) => {
        if (field.logicalType === this.selectedLogicalType.value) {
          return field;
        }
      });
    }

    if (this.srchText) {
      result = result.filter((field) => {
        if (field.name.toLowerCase().includes(this.srchText.toLowerCase().trim())) {
          return field;
        }
      });
    }

    return result;
  }

  public isExistMetaData(source: Datasource): boolean {
    return source.hasOwnProperty('uiMetaData');
  }

  public isEnableSecondHeader(): boolean {
    return this.joinDataSources.some((source) => this.isExistMetaData(source));
  }

  public isExistTimestampField(): boolean {
    return this.joinDataSources.some((source) =>
      source.fields.some(
        (field) =>
          field.logicalType === LogicalType.TIMESTAMP &&
          (this.timezoneService.isEnableTimezoneInDateFormat(field.format) ||
            (field.format && field.format.type === FieldFormatType.UNIX_TIME)),
      ),
    );
  }

  public isEnableTimezone(field: Field): boolean {
    return (
      field.format &&
      (field.format.type === FieldFormatType.UNIX_TIME ||
        this.timezoneService.isEnableTimezoneInDateFormat(field.format))
    );
  }

  public getRoleStr(): string {
    let roleStr = '';
    if (this.roles['TIMESTAMP']) {
      roleStr += `${this.roles['TIMESTAMP']} Timestamps`;
    }
    if (this.roles['DIMENSION']) {
      '' !== roleStr && (roleStr += ' / ');
      roleStr += `${this.roles['DIMENSION']} Dimensions`;
    }
    if (this.roles['MEASURE']) {
      '' !== roleStr && (roleStr += ' / ');
      roleStr += `${this.roles['MEASURE']} Measures`;
    }
    return roleStr;
  }

  public getDatasourceCnt(): number {
    const dsInfo: Dashboard = <Dashboard>this.source;
    return dsInfo.dataSources.length;
  }

  public getDatasourceNames(): string {
    const dsInfo: Dashboard = <Dashboard>this.source;
    if (dsInfo.dataSources.length > 0) {
      let names = this.mainDatasource.name;
      if (dsInfo.dataSources.length > 1) {
        names += ` and ${dsInfo.dataSources.length - 1} more`;
      }
      return names;
    }
    return '';
  }
}
