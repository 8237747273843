import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DestroyService, PresetsPermissionAction } from '@selfai-platform/shared';
import { BreadcrumbsMenuItem, BreadcrumbsMenuService } from '@selfai-platform/shell';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { combineLatest, take } from 'rxjs';
import { getPresetsPath, getPresetsPathCreatePath, getPresetsPathEditPath } from '../../functions';
import { WorkflowPresets } from '../../interfaces';
import { WorkflowPresetsPermissionService } from '../../services';
import { ClusterConfigurationFormComponent } from '../cluster-configuration-form';

@Component({
    selector: 'selfai-platform-workflow-presets',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        InputTextModule,
        InputTextareaModule,
        InputNumberModule,
        DropdownModule,
        ToastModule,
        CardModule,
        ToolbarModule,
        ButtonModule,
        FloatLabelModule,
        CheckboxModule,
        ClusterConfigurationFormComponent,
    ],
    templateUrl: './workflow-presets.component.html',
    styleUrl: './workflow-presets.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DestroyService]
})
export class WorkflowPresetsComponent implements OnInit {
  public id: string = this.activatedRoute?.snapshot?.params['id'];
  public canUpdate: boolean = false;
  public editMode = !!this.id;

  public breadcrumbItems: BreadcrumbsMenuItem[] = [
    {
      label: this.translate.instant('shell.menu.presets'),
      routerLink: getPresetsPath(),
    },
  ];

  @Input() workflowPresets: WorkflowPresets[];
  @Input() shortForm = false;
  @Input() currentWorkflowPreset!: WorkflowPresets;

  @Output() backEvent = new EventEmitter<void>();
  @Output() submitEvent = new EventEmitter<WorkflowPresets>();

  constructor(
    private readonly destroyRef: DestroyRef,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    private readonly permissionService: WorkflowPresetsPermissionService,
    private readonly router: Router,
  ) {}

  public ngOnInit(): void {
    if (this.editMode) {
      combineLatest([
        this.permissionService.checkPermission(PresetsPermissionAction.Get),
        this.permissionService.checkPermission(PresetsPermissionAction.Update),
      ])
        .pipe(take(1))
        .subscribe(([canView, canUpdate]) => {
          if (!canView) {
            this.router.navigate(['access-denied']).then();
          }
          this.canUpdate = canUpdate;
        });
      if (this.breadcrumbItems.length < 2) {
        this.translate
          .stream('shell.bc.presets.label.edit')
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((label) => {
            this.breadcrumbItems?.push({
              label: `${label} ${this.currentWorkflowPreset?.name}`,
              routerLink: getPresetsPathEditPath(this.currentWorkflowPreset?.id.toString() || ''),
            });
          });
      }
    } else {
      this.translate
        .stream('shell.bc.presets.label.create')
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((label) => {
          this.breadcrumbItems?.push({
            label,
            routerLink: getPresetsPathCreatePath(),
          });
        });
    }
    this.breadcrumbsMenuService.setBreadcrumbsMenu(this.breadcrumbItems);
  }

  public onBackEvent(): void {
    this.backEvent.emit();
  }

  public onSubmit(formValue: WorkflowPresets): void {
    this.changeDetectorRef.detectChanges();
    this.submitEvent.emit({ ...formValue, id: this.id });
  }
}
