<ng-container *ngIf="{ data: data$ | async } as obs">
  <p-table
    #table
    styleClass="p-datatable-sm"
    paginatorStyleClass="prime-table-custom__paginator"
    [value]="obs.data.datasources"
    [showLoader]="false"
    [loading]="loading"
    [first]="pageParams.pageSize * (pageParams.pageNumber - 1)"
    [paginator]="true"
    [rows]="pageParams.pageSize"
    [scrollable]="true"
    scrollHeight="450px"
    (onFilter)="onFilter($event)"
  >
    <ng-template pTemplate="loadingbody">
      <tr tabindex="-1" *ngFor="let e of [].constructor(12)">
        <td style="height: 34px" *ngFor="let item of tableConfig" [style.width]="item.width">
          <p-skeleton></p-skeleton>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td [colSpan]="5">{{ 'msg.board.filter.ui.search-all.nodata' | translate }}</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="caption">
      <div class="flex justify-content-between">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>

          <input
            style="width: 300px"
            pAutoFocus
            [autofocus]="true"
            pInputText
            type="text"
            (input)="onSearch(table, $event.target)"
            placeholder="{{ 'msg.workbook-list.search.input-placeholder' | translate }}"
          />
        </span>

        <p-dropdown
          [options]="pageSizes"
          [ngModel]="pageParams.pageSize"
          (onChange)="onPageChange({ pageSize: $event.value, pageNumber: 1, totalItems: pageParams.totalItems })"
        ></p-dropdown>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let item of tableConfig" [pSortableColumn]="item.key" [style.width]="item.width">
          <div class="flex align-items-center">
            {{ item.label }}

            <p-sortIcon [field]="item.key"></p-sortIcon>

            <p-columnFilter
              *ngIf="item.key === 'connType'"
              field="connType"
              matchMode="in"
              display="menu"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect
                  [ngModel]="value"
                  [options]="typeFilter"
                  [placeholder]="'msg.comm.ui.list.all' | translate"
                  (onChange)="filter($event.value)"
                >
                  <ng-template let-option pTemplate="item">
                    <div class="inline-block vertical-align-middle">
                      <span class="ml-1 mt-1">{{ option.label }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>

            <p-columnFilter
              *ngIf="item.key === 'published'"
              type="boolean"
              field="published"
              display="menu"
            ></p-columnFilter>
          </div>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-item>
      <tr>
        <td class="short-text">{{ item.name }}</td>
        <td class="short-text">
          {{
            item?.connType === 'ENGINE' ? ('msg.comm.ui.ds.engine' | translate) : ('msg.comm.ui.ds.link' | translate)
          }}
        </td>
        <td class="short-text">{{ item.summary?.size | fileSize }}</td>
        <td class="short-text">{{ item.modifiedTime | date: 'dd.MM.yyyy' }}</td>
        <td class="short-text">{{ item.published ? ('msg.dp.ui.yes' | translate) : ('msg.dp.ui.no' | translate) }}</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer">
      <tr>
        <td class="p-0" [colSpan]="5">
          <selfai-platform-data-list-view-pagination
            *ngIf="pageParams.totalItems"
            [pageNumber]="pageParams.pageNumber"
            [pageSize]="pageParams.pageSize"
            [totalRecords]="pageParams.totalItems"
            (pageChange)="onPageChange($event)"
          ></selfai-platform-data-list-view-pagination>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>
