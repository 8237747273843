import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

import { Datasource } from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../common/component/abstract.component';
import { EventBroadcaster } from '../../common/event/event.broadcaster';

@Component({
    selector: 'dashboard-datasource-combo',
    templateUrl: './dashboard-datasource-combo.component.html',
    standalone: false
})
export class DashboardDatasourceComboComponent extends AbstractComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('dataSources') private _inputDataSources: Datasource[];
  @Input('initialValue') private _initValue: Datasource;
  @Output('selectOption') private _selectOptionEvent: EventEmitter<Datasource> = new EventEmitter();
  @Output('showInfo') private _showInfoEvent: EventEmitter<Datasource> = new EventEmitter();

  public isShowDataSourceOpts = false;
  public dataSources: Datasource[] = [];
  public selectedDataSource: Datasource;

  public searchText = '';

  @Input('enableInfo') public isEnableInfo = false;
  @Input('enableEditAssociationJoin') public isEnableEditAssociationJoin = false;

  constructor(protected broadCaster: EventBroadcaster, protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnChanges(changes: SimpleChanges) {
    const dataSourceChanges: SimpleChange = changes._inputDataSources;
    const initValChanges: SimpleChange = changes._initValue;
    if (dataSourceChanges && dataSourceChanges.currentValue) {
      this.dataSources = dataSourceChanges.currentValue;
    }
    if (initValChanges && initValChanges.firstChange) {
      this.selectedDataSource = initValChanges.currentValue;
    }
  }

  public ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public toggleDataSourceList($event) {
    const $targetElm = $($event.target);

    if (!$targetElm.hasClass('ddp-form-search') && 0 === $targetElm.closest('.ddp-form-search').length) {
      this.isShowDataSourceOpts = !this.isShowDataSourceOpts;
    }
  }

  public selectDataSource(dataSource: Datasource) {
    if (dataSource.valid) {
      this.isShowDataSourceOpts = false;
      this.selectedDataSource = dataSource;
      this._selectOptionEvent.emit(dataSource);
      this.safelyDetectChanges();
    }
  }

  public triggerShowInfo() {
    this._showInfoEvent.emit(this.selectedDataSource);
  }

  public updateBoardDataSource() {
    this.broadCaster.broadcast('UPDATE_BOARD_UPDATE_DATASOURCE');
  }
}
