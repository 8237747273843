import { Injectable } from '@angular/core';
import { PageParams, downloadJson } from '@selfai-platform/shared';
import { Observable, combineLatest, filter, map, take } from 'rxjs';
import { DashboardApiToDomainService } from '../../dashboard';
import { RecycleBinApiToDomainService } from '../../recycle-bin';
import { Workbook, WorkbookAdd, WorkbookDetailProjections, WorkbookImport } from '../models';
import { WorkbookStore } from '../stores';
import { WorkbookApiToDomainService } from './workbook-domain-api.service';
import { WorkbookPinsDomainService } from './workbook-pins-domain.service';

@Injectable({ providedIn: 'root' })
export class WorkbookDomainService {
  constructor(
    private readonly workbookApiToDomainService: WorkbookApiToDomainService,
    private readonly workbookPinsDomainService: WorkbookPinsDomainService,
    private readonly dashboardApiToDomainService: DashboardApiToDomainService,
    private readonly recycleBinApiToDomainService: RecycleBinApiToDomainService,
    private readonly workbookStore: WorkbookStore,
  ) {}

  updateWorkbook(workbookId: string, workbook: Partial<Workbook>): void {
    this.workbookApiToDomainService.updateWorkbook(workbookId, workbook).pipe(take(1)).subscribe();
  }

  addWorkbook(workspaceId: string, workbook: WorkbookAdd): Observable<Workbook> {
    return this.workbookApiToDomainService.addWorkbook(workspaceId, workbook);
  }

  loadWorkbook(workbookId: string): Observable<WorkbookDetailProjections> {
    return this.workbookStore.getByKey(workbookId);
  }

  getWorkbook(workbookId: string): Observable<WorkbookDetailProjections> {
    return this.workbookStore.entityMap$.pipe(
      map((workbooks) => workbooks[workbookId]),
      filter(Boolean),
    );
  }

  copyWorkbook(workbookId: string): Observable<Workbook> {
    return this.workbookApiToDomainService.copyWorkbook(workbookId);
  }

  exportToFile(workbookId: string, fileName: string): Observable<void> {
    const page: PageParams = { pageNumber: 1, pageSize: 9999 };

    return combineLatest({
      workBook: this.loadWorkbook(workbookId),
      dashBoards: this.dashboardApiToDomainService
        .loadDashboardList(workbookId, page)
        .pipe(map(({ dashboards }) => dashboards)),
      pins: this.workbookPinsDomainService.loadPins(workbookId).pipe(map((pins) => pins.map((pin) => pin.dashboardId))),
    }).pipe(
      map(({ workBook, dashBoards, pins }) => {
        return { ...workBook, dashBoards, pins };
      }),
      map((workBook: WorkbookImport) => {
        downloadJson(workBook, fileName);
      }),
    );
  }

  removeToTrash(id: string): Observable<unknown> {
    return this.recycleBinApiToDomainService.recycleBinWorkbook(id);
  }
}
