import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UserProfile, WorkspaceApiToDomainService } from '@selfai-platform/bi-domain';
import { BiEntityPermission, DestroyService } from '@selfai-platform/shared';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BehaviorSubject, debounceTime, map, Observable, of, shareReplay, switchMap, takeUntil } from 'rxjs';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';

@Component({
    selector: 'selfai-platform-workspaces-roles-manager-access',
    templateUrl: './workspaces-roles-manager-access.component.html',
    styleUrls: ['./workspaces-roles-manager-access.component.scss', '../workspaces-roles-manager-styles.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ConfirmationService, MessageService, DestroyService],
    standalone: false
})
export class WorkspacesRolesManagerAccessComponent implements OnInit, OnChanges {
  @Input() role: { name: string; id: number; permissions: BiEntityPermission[] };
  @Input() users: string[];
  @Input() edit: boolean;
  @Input() loadedMembers$: BehaviorSubject<any[]>;

  @Output() members = new EventEmitter<any[]>();
  @Output() remove = new EventEmitter<string>();
  @Output() changePermissions = new EventEmitter();

  expanded$ = new BehaviorSubject<boolean>(true);
  public filteredUsers: Observable<UserProfile[]>;

  form = new FormGroup({
    id: new FormControl(null),
    name: new FormControl(null),
    permissions: new FormGroup({
      [BiEntityPermission.WORKBOOK_VIEW]: new FormControl(false),
      [BiEntityPermission.WORKBOOK_CREATE]: new FormControl(false),
      [BiEntityPermission.WORKBOOK_EDIT]: new FormControl(false),
      [BiEntityPermission.WORKBOOK_DELETE]: new FormControl(false),
      [BiEntityPermission.DASHBOARD_VIEW]: new FormControl(false),
      [BiEntityPermission.DASHBOARD_CREATE]: new FormControl(false),
      [BiEntityPermission.DASHBOARD_EDIT]: new FormControl(false),
      [BiEntityPermission.DASHBOARD_DELETE]: new FormControl(false),
      [BiEntityPermission.WORKSPACE_MANAGE_ROLES]: new FormControl(false),
      [BiEntityPermission.WORKSPACE_METADATA_EDIT]: new FormControl(false),
      [BiEntityPermission.WORKSPACE_MANAGE_ACCESS]: new FormControl(false),
      [BiEntityPermission.WORKSPACE_DATASOURCES_VIEW]: new FormControl(false),
    }),
    members: new FormControl([]),
  });

  public filterUsers(event: AutoCompleteCompleteEvent): void {
    this.filteredUsers = of(event.query).pipe(
      debounceTime(300),
      switchMap((filter: string) => this.workspaceService.getUsers({ nameContains: filter })),
      map((usersWrapper) => usersWrapper._embedded?.users || []),
      map((users) => {
        const selectedMembers = this.form.get('members').value;

        return users
          .filter((user: any) => !selectedMembers.find((member) => member.username === user.username))
          .map((user: any) => {
            if (!user.fullName) {
              user.fullName = user.username;
            }

            return user;
          });
      }),
      shareReplay(1),
    );
  }

  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly workspaceService: WorkspaceApiToDomainService,
    private readonly destroy$: DestroyService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['edit']) {
      this.setDisabledPermissions();
    }
  }

  ngOnInit(): void {
    this.form.patchValue(
      {
        id: this.role.id,
        name: this.role.name,
        permissions: this.role.permissions.reduce<{ [key: string]: boolean }>((acc, permission: BiEntityPermission) => {
          acc[permission] = true;

          return acc;
        }, {}),
      },
      { emitEvent: false },
    );

    this.loadedMembers$.pipe(takeUntil(this.destroy$)).subscribe((members) => {
      this.form
        .get('members')
        .patchValue(
          members
            .filter((member) => member.role === this.role.name)
            .map(({ memberId, memberName }) =>
              ({ username: memberId, fullName: memberName.length ? memberName : memberId })
            ),
        );

      this.members.emit([
        this.role.name,
        members.filter((member) => member.role === this.role.name).map((member) => member.memberId),
      ]);
    });

    this.form
      .get('members')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((membersValue: any[]) => {
        this.members.emit([this.role.name, membersValue.map((v) => v.username)]);
      });

    this.form
      .get('permissions')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((permissions) => {
        const { id, name } = this.form.getRawValue();
        const keys = Object.keys(permissions) as BiEntityPermission[];
        const role = {
          id,
          name,
          permissions: keys.filter((key) => permissions[key]),
        };

        this.changePermissions.emit(role);
      });

    if (!this.edit) {
      this.form.get('permissions').disable({ emitEvent: false });
    }
  }

  setDisabledPermissions() {
    this.edit ? this.form.get('permissions').enable() : this.form.get('permissions').disable();
  }

  toggleExpanded() {
    this.expanded$.next(!this.expanded$.value);
  }

  removeRole() {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `${this.translateService.instant('msg.workspacesEdit.deleteRoleConfirm')}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const { name } = this.role;
        this.remove.emit(name);
      },
    });
  }
}
