import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ChartPdfDownloadApiService } from '@selfai-platform/bi-api';

@Injectable({ providedIn: 'root' })
export class ChartPdfDownloadApiToDomainService {
  constructor(private readonly chartPdfDownloadApiService: ChartPdfDownloadApiService) {}

  downloadChartPdf(fileName = 'chart.pdf', widgetId: string): Observable<void> {
    return this.chartPdfDownloadApiService.downloadChartPdf(fileName, widgetId);
  }

  downloadDashboardPdf(fileName = 'dashboard.pdf', dashboardId: string): Observable<void> {
    return this.chartPdfDownloadApiService.downloadDashboardPdf(fileName, dashboardId);
  }
}
