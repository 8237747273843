import { Pipe, PipeTransform, Injectable } from '@angular/core';
@Pipe({
    name: 'search',
    pure: false,
    standalone: false
})
@Injectable()
export class FilterSearchPipe implements PipeTransform {
  transform(items: any, term: string, objectFieldName: string): any {
    if (!term || !items) return items;
    return FilterSearchPipe.filter(items, term, objectFieldName);
  }

  static filter(
    items: Array<{ [key: string]: any }>,
    term: string,
    objectFieldName: string,
  ): Array<{ [key: string]: any }> {
    const toCompare = term.toLowerCase();
    if (objectFieldName === null || objectFieldName === undefined) {
      return items.filter(function (item: any) {
        for (const property in item) {
          if (item[property] === null || item[property] == undefined) {
            continue;
          }
          if (item[property].toString().toLowerCase().includes(toCompare)) {
            return true;
          }
        }
        return false;
      });
    }
    return items.filter(function (item: any) {
      return item[objectFieldName].toString().toLowerCase().includes(toCompare);
    });
  }
}
