import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { ConfirmationService } from 'primeng/api';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  skip,
  take,
  takeUntil,
} from 'rxjs';

import { Book, Chart, ChartList, ChartListService, Dashboard, Datasource } from '@selfai-platform/bi-domain';
import { AlertService, DestroyService, PageParams, UrlPageParamsLegacyService } from '@selfai-platform/shared';
import { BI_ROOT_ROUTE, DialogService, getUiSettingsDefaultPageSizes, provideDialogService } from '@selfai-platform/shell';
import { TableLazyLoadEvent } from 'primeng/table';
import { DialogEntityIdSelector, DialogEntityViewSelector } from './dialog-entity-selector.model';

type SelectDashboardFn = (dashboard: { id: string }) => void;

// TODO: should use TableComponent from @selfai-platform/shared
@Component({
    selector: 'selfai-platform-bi-ui-chart-list',
    templateUrl: './chart-list.component.html',
    styleUrls: ['./chart-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ConfirmationService,
        provideDialogService(),
        DestroyService,
        ChartListService,
        UrlPageParamsLegacyService,
    ],
    standalone: false
})
export class ChartListComponent implements OnInit {
  chartList$: Observable<Chart[]>;
  loading$ = new BehaviorSubject<boolean>(true);
  visibleDashboardDialogSelector = false;
  textButton = '';
  dataSourceId: string;
  pageParams: PageParams;
  querySearch$ = new ReplaySubject<string>(1);

  onSelectDashboard: SelectDashboardFn | null = null;

  pageSizes = getUiSettingsDefaultPageSizes();

  get totalRecords(): number {
    return this.pageParams.totalItems;
  }

  constructor(
    private readonly chartListService: ChartListService,
    private readonly confirmationService: ConfirmationService,
    private readonly urlPageParamsService: UrlPageParamsLegacyService,
    private readonly destroy$: DestroyService,
    private readonly alertService: AlertService,
    public readonly dialogService: DialogService<DialogEntityIdSelector, DialogEntityViewSelector>,
  ) {}

  ngOnInit(): void {
    this.chartList$ = this.getChartList().pipe(map(({ charts }) => charts));
    this.urlPageParamsService
      .getPageParams()
      .pipe(filter((pageParams) => JSON.stringify(pageParams) !== JSON.stringify(this.pageParams)))
      .subscribe((pageParams) => {
        this.pageParams = pageParams;
        this.querySearch$.next(pageParams.query || '');
      });

    this.chartListService
      .getChartListState()
      .pipe(map(({ loading }) => loading))
      .subscribe((loading) => {
        this.loading$.next(loading);
      });

    this.querySearch$
      .pipe(distinctUntilChanged(), skip(1), debounceTime(500), takeUntil(this.destroy$))
      .subscribe((query) => {
        this.urlPageParamsService.setPageParams({ query });
        this.chartListService.loadChartList();
      });
  }

  getChartList(): Observable<ChartList> {
    return this.chartListService.getChartListState().pipe(
      map(({ value }) => value),
      filter(Boolean),
    );
  }

  loadChartList(event: TableLazyLoadEvent): void {
    this.querySearch$.pipe(take(1)).subscribe((query) => {
      this.urlPageParamsService.setPageParams({
        pageNumber: this.calcPageNumber(event.first, event.rows),
        pageSize: event.rows,
        sortField: event.sortField as string,
        sortOrder: event.sortOrder > 0 ? 'asc' : 'desc',
        query,
      });

      this.chartListService.loadChartList();
    });
  }

  onPageChange(pageParams: PageParams) {
    this.pageParams.pageSize = pageParams.pageSize;
    this.pageParams.pageNumber = pageParams.pageNumber;
    this.urlPageParamsService.setPageParams({ pageSize: pageParams.pageSize, pageNumber: pageParams.pageNumber });
    this.chartListService.loadChartList();
  }

  moveTo(item: Chart): void {
    const datasourceId = item.dataSources?.[0].id;
    if (!datasourceId) {
      this.alertService.warn('Can not move chart without datasource');

      return;
    }
    this.openDashboardDialog('Move', datasourceId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ id }) => {
        this.loading$.next(true);

        this.chartListService.moveChart(item.id, id, datasourceId).subscribe({
          next: () => {
            this.chartListService.loadChartList();
            this.loading$.next(false);
          },
          error: (err) => {
            this.loading$.next(false);
            this.alertService.error('Error moving chart');
            console.error(err);
          },
        });
      });
  }

  copyTo(item: Chart): void {
    const datasourceId = item.dataSources?.[0].id;
    if (!datasourceId) {
      this.alertService.warn('Can not copy chart without datasource');

      return;
    }
    this.openDashboardDialog('Duplicate', datasourceId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ id }) => {
        this.loading$.next(true);
        this.chartListService.copyToChart(item.id, id, datasourceId).subscribe({
          next: () => {
            this.chartListService.loadChartList();
            this.loading$.next(false);
          },
          error: (err) => {
            this.loading$.next(false);
            this.alertService.error('Error copying chart');
            console.error(err);
          },
        });
      });
  }

  remove(event: Event, item: Chart): void {
    const datasourceId = item.dataSources?.[0].id;
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `Are you sure to proceed?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loading$.next(true);
        this.chartListService.removeChart(item.id, datasourceId).subscribe(() => {
          this.chartListService.loadChartList();
        });
      },
    });
  }

  getChartRouterLink(item: Chart): string[] {
    return ['../', 'preview', 'widget', item.id];
  }

  getDashboardRouterLink(item: Dashboard): string[] {
    return [BI_ROOT_ROUTE, 'workbook', item.workBook.id, `?dashboardId=${item.id}`];
  }

  getWorkbookRouterLink(item: Book): string[] {
    return [BI_ROOT_ROUTE, 'workbook', item.id];
  }

  getDataSourceRouterLink(item: Datasource): string[] {
    return [BI_ROOT_ROUTE, 'management', 'storage', 'datasource', item.id];
  }

  onSearch(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.querySearch$.next(target.value);
  }

  private openDashboardDialog(textButton: string, dataSourceId: string): Observable<{ id: string }> {
    this.textButton = textButton;
    this.dataSourceId = dataSourceId;

    return new Observable((observer) => {
      this.registerOnSelectDashboard((dashboard: { id: string }) => {
        this.visibleDashboardDialogSelector = false;
        this.onSelectDashboard = null;

        observer.next(dashboard);
        observer.complete();
      });

      this.visibleDashboardDialogSelector = true;
    });
  }

  private registerOnSelectDashboard(fn: SelectDashboardFn): void {
    this.onSelectDashboard = fn;
  }

  private calcPageNumber(first: number, rows: number): number {
    return Math.round(first / rows) + 1;
  }
}
