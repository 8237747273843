import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CodeEditorConfig, CodeLanguage } from '@selfai-platform/pipeline-common';
import { Observable } from 'rxjs';
import { registerFields, resetLanguage } from '../../languages';

@Component({
    selector: 'selfai-platform-code-editor',
    templateUrl: './code-editor.component.html',
    styleUrls: ['./code-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CodeEditorComponent),
            multi: true,
        },
    ],
    standalone: false
})
export class CodeEditorComponent implements ControlValueAccessor, OnDestroy {
  @Input() canMaximized = true;

  @Input() set language(value: CodeLanguage) {
    this.editorOptions = { ...this.editorOptions, language: value };
  }

  @Input() set fields(value: string[] | null) {
    if (value) {
      registerFields(value);
    }
  }

  _value = '';

  get value() {
    return this._value;
  }

  @Input() set value(value: string) {
    this._value = value;
    this.onChange(this._value);
  }

  disabled = true;
  maximized = false;

  onChange(_: string): void {};

  editorOptions: CodeEditorConfig = {
    theme: 'sparksql',
    language: 'sql',
    automaticLayout: true,
    minimap: { enabled: false },
    wordWrap: 'on',
  };

  fieldsFromExecutionReport$: Observable<string[]>;

  private onTouched!: () => void;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    resetLanguage();
  }

  maximazeEditor(): void {
    this.maximized = true;
  }

  toggleWordWrap(): void {
    const wordWrap = this.editorOptions.wordWrap === 'on' ? 'off' : 'on';
    this.editorOptions = { ...this.editorOptions, wordWrap };
  }

  writeValue(value: string): void {
    this.value = value;
    this.cdr.detectChanges();
  }

  registerOnChange(fn: (_: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
