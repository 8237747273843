import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { omit } from 'lodash';
import { Observable, Subscription, catchError, map, of, startWith, take } from 'rxjs';
import { S3ConnectionParams } from '../models';
import { DataconnectionDomainService } from './dataconnection-domain.service';

type CustomParametersFormGroup = FormGroup<{
  key: FormControl<string>;
  value: FormControl<string>;
}>;

@Injectable()
export class S3ConnectionParamsComponentService implements OnDestroy {
  subscription = new Subscription();
  form = new FormGroup({
    connection: new FormGroup({
      serviceEndpoint: new FormControl('', [Validators.required]),
      signingRegion: new FormControl('', [Validators.required]),
      accessKey: new FormControl('', [Validators.required]),
      secretKey: new FormControl('', [Validators.required]),
      bucketName: new FormControl('', [Validators.required]),
      objectKey: new FormControl('', [Validators.required]),
    }),
    connectionValid: new FormControl(true, [], [this.validatorConnection.bind(this)]),
    customParameters: new FormArray<CustomParametersFormGroup>([]),
  });

  get connectionValidControl(): FormControl<boolean> {
    return this.form.controls.connectionValid;
  }

  constructor(
    private readonly dataconnectionDomainService: DataconnectionDomainService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.form.reset();
  }

  getParams(): S3ConnectionParams | undefined {
    if (!this.form?.value) {
      return undefined;
    }

    // connectionValid is a service field, not a connection param
    const formValues = omit(this.form.getRawValue(), 'connectionValid');

    const params = {
      ...formValues.connection,
      customParameters: formValues.customParameters.reduce((acc, { key, value }) => {
        if (key) {
          acc[key] = value;
        }

        return acc;
      }, {} as Record<string, string>),
    };

    return params;
  }

  initFormValues({ customParameters, ...params }: S3ConnectionParams): void {
    const customParametersForm = Object.entries(customParameters || {}).map(([key, value]) => {
      return new FormGroup({
        key: new FormControl(key),
        value: new FormControl(value),
      });
    });

    this.form.controls.customParameters = new FormArray(customParametersForm);
    this.resetConnectionControlStatus();
  }

  addCustomParameter(): void {
    this.form.controls.customParameters.push(
      new FormGroup({
        key: new FormControl(''),
        value: new FormControl(''),
      }),
    );
  }

  removeCustomParameter(index: number): void {
    this.form.controls.customParameters.removeAt(index);
  }

  isValidConnection(): Observable<boolean> {
    return this.form.statusChanges.pipe(
      map((status) => status === 'VALID' && this.form.untouched),
      startWith(this.form.valid && this.form.untouched),
    );
  }

  validateConnection(): void {
    this.form.markAllAsTouched();
    this.form.controls.connectionValid.updateValueAndValidity();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private validatorConnection(_control: AbstractControl): Observable<ValidationErrors | null> {
    const params = this.getParams();
    if (!params) {
      return of({
        connectionInvalid: this.translateService.instant('s3-connection-params.form.fields.connection-invalid'),
      });
    }

    return this.dataconnectionDomainService.checkConnectionS3(params).pipe(
      take(1),
      map((result) => {
        const isValid = result?.connected;

        return isValid ? null : { connectionInvalid: result.message };
      }),
      catchError(() => {
        return of({
          connectionInvalid: this.translateService.instant('s3-connection-params.form.fields.connection-invalid'),
        });
      }),
    );
  }

  private resetConnectionControlStatus(): void {
    setTimeout(() => {
      this.form.controls.connectionValid.setErrors({'invalid': true});
      this.form.markAsTouched();
    })
  }
}
