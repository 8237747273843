import { Injectable, Optional } from '@angular/core';

import { Observable, filter, from, map, mergeMap, of, take, tap } from 'rxjs';

import { Filter, Loader, LoaderUtil, PageParamsAdapter } from '@selfai-platform/shared';

import { DashboardApiModel, DashboardApiService, DashboardListQueryParams } from '@selfai-platform/bi-api';
import { DashboardList, DashboardListItem, WorkbookPinsDomainService } from '@selfai-platform/bi-domain';

const defaultPageParams: DashboardListQueryParams = {
  page: 0,
  size: 20,
  sort: 'modifiedTime,desc',
  projection: 'forFullListView',
};

@Injectable({ providedIn: 'root' })
export class DashboardListComponentService extends LoaderUtil<DashboardList> {
  constructor(
    private readonly workbookPinsDomainService: WorkbookPinsDomainService,
    private readonly dashboardApiService: DashboardApiService,
    @Optional() private readonly pageParamsAdapter?: PageParamsAdapter,
  ) {
    super(undefined);
  }

  //TODO Fix types

  loadDashboardList(): void {
    this.getQueryPageParams().subscribe((queryPageParams) => {
      this.loadFromSource(
        this.dashboardApiService.getList(queryPageParams).pipe(
          tap(({ page }) => {
            this.pageParamsAdapter?.setPageParams({
              pageNumber: page.number + 1,
              pageSize: page.size,
              totalItems: page.totalElements,
            });
          }),
          // TODO: move mapping to DashboardApiToDomainService
          map((response) => ({
            ...response,
            dashboards: response._embedded.dashboards.map(this.normalizeDashboard.bind(this)),
          })),
        ),
      );
    });
  }

  getDashboardListState(): Observable<Loader<DashboardList | undefined>> {
    return this.asObservable().pipe(
      filter(Boolean),
    );
  }

  removeDashboard(id: string, workbookId: string): void {
    from(this.workbookPinsDomainService.deleteDashboardFromPins(workbookId, id))
      .pipe(
        mergeMap(() => {
          return this.dashboardApiService.deleteDashboard(id);
        }),
      )
      .subscribe(() => {
        this.loadDashboardList();
      });
  }

  private normalizeDashboard(dashboardApi: DashboardApiModel): DashboardListItem {
    return {
      ...dashboardApi,
      createdTime: new Date(dashboardApi.createdTime),
      modifiedTime: new Date(dashboardApi.modifiedTime),
      create: dashboardApi.createdTime,
      modified: dashboardApi.modifiedTime,
      author: dashboardApi.createdBy?.fullName || dashboardApi.createdBy?.username,
    } as any;
  }

  private getQueryPageParams(): Observable<DashboardListQueryParams> {
    if (this.pageParamsAdapter) {
      return this.pageParamsAdapter.getPageParams().pipe(
        take(1),
        map((pageParams) => {
          return this.removeUndefinedQueryParams({
            ...defaultPageParams,
            page: pageParams.pageNumber - 1,
            size: pageParams.pageSize,
            containsText: pageParams.query,
            sort: pageParams.sortField && `${pageParams.sortField},${pageParams.sortOrder}`,
            ...this.mapFilters(pageParams.filters),
          });
        }),
      );
    }

    return of(defaultPageParams);
  }

  private mapFilters(filters?: Filter[]): Record<string, string> {
    const result: Record<string, string> = {};

    filters?.forEach((filter) => {
      result[filter.fieldName] = filter.value as string;
    });

    return result;
  }

  private removeUndefinedQueryParams<T extends object>(obj: T): Partial<T> {
    const result: Partial<T> = {};

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== undefined) {
        result[key] = obj[key];
      }
    }

    return result;
  }
}
