import { Injectable } from '@angular/core';
import { EntityOp } from '@ngrx/data';
import { FavoriteApiService } from '@selfai-platform/bi-api';
import { PageParams } from '@selfai-platform/shared';
import { Observable, catchError, finalize, map, tap, throwError } from 'rxjs';
import { FavoriteList, FavoriteListItem } from '../models';
import { FavoriteListStore } from '../stores';

@Injectable({ providedIn: 'root' })
export class FavoriteListDomainService {
  constructor(private favoriteListStore: FavoriteListStore, private favoriteApiService: FavoriteApiService) {}

  getFavoriteList(): Observable<FavoriteListItem[]> {
    return this.favoriteListStore.entities$;
  }

  loadFavoriteList(pageParams: PageParams): Observable<FavoriteList> {
    const apiParams = {
      size: pageParams.pageSize,
      page: pageParams.pageNumber - 1,
      sort: pageParams.sortField ? `${pageParams.sortField},${pageParams.sortOrder}` : undefined,
      search: pageParams.query?.trim(),
    };

    this.favoriteListStore.setLoading(true);

    return this.favoriteApiService.loadFavoriteList(apiParams).pipe(
      map((res) => ({
        page: res.page,
        favorites: (res?._embedded?.favorites || []).map((favorite) => ({
          ...favorite,
          name: favorite.targetName,
        })),
      })),
      tap(({ favorites }) => this.favoriteListStore.addAllToCache(favorites)),
      catchError((err: unknown) => {
        this.favoriteListStore.createAndDispatch(EntityOp.QUERY_ALL_ERROR, err);

        return throwError(() => err);
      }),
      finalize(() => this.favoriteListStore.setLoading(false)),
    );
  }

  getFavoriteListLoading(): Observable<boolean> {
    return this.favoriteListStore.loading$;
  }

  getFavoriteListError(): Observable<unknown> {
    return this.favoriteListStore.errors$;
  }

  getFavoriteListLoaded(): Observable<boolean> {
    return this.favoriteListStore.loaded$;
  }

  removeFromFavorite(id: string): Observable<void> {
    return this.favoriteListStore.delete(id).pipe(map(() => void 0));
  }
}
