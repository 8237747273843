import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { UpdateStr } from '@ngrx/entity/src/models';
import { Observable, map } from 'rxjs';
import { WORKSPACE_ENTITY_NAME } from '../constants';
import { WorkspaceState } from '../models/workspace.model';
import { WorkspaceApiToDomainService } from './workspace-domain-api.service';

@Injectable({ providedIn: 'root' })
export class WorkspaceDataService extends DefaultDataService<WorkspaceState> {
  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private readonly workspaceApiToDomainService: WorkspaceApiToDomainService,
  ) {
    super(WORKSPACE_ENTITY_NAME, http, httpUrlGenerator);
  }

  override getById(workspaceId: string): Observable<WorkspaceState> {
    return this.workspaceApiToDomainService
      .getPublicWorkspaceForListWithPermission(workspaceId)
      .pipe(map((workspace) => ({ ...workspace, workspaceId })));
  }

  override update(update: UpdateStr<WorkspaceState>): Observable<WorkspaceState> {
    return this.workspaceApiToDomainService
      .updateWorkspace(update.id, update.changes)
      .pipe(map((workspace) => ({ ...workspace, workspaceId: update.id })));
  }
}
