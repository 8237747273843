import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { CodeLanguage, CubeEditResult, CubeWorkflowData } from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { DialogService } from '@selfai-platform/shell';
import { Observable } from 'rxjs';
import { DialogHelperService } from '../../../../dialog';
import { WorkflowReportDomainService } from '../../../../wokflow-list';
import { CubeDialogManagementService } from '../../../services/cube-dialog-management.service';
import { OperationDetailService } from '../../../services/operations/operation-detail.service';
import { SaveConfirmationService } from '../../../services/save-confirmation.service';
import { AbstractCubeDialogFormComponent } from '../../abstract-cube-dialog-form.component';
import { DialogHeaderService } from '../../dialog-header/dialog-header.service';

@Component({
    selector: 'selfai-platform-code-editor-transformation',
    templateUrl: './code-editor-transformation.component.html',
    styleUrls: ['./code-editor-transformation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DestroyService, DialogHelperService, SaveConfirmationService, DialogHeaderService],
    standalone: false
})
export class CodeEditorTransformationComponent extends AbstractCubeDialogFormComponent implements OnInit {
  codeText!: string;
  language!: CodeLanguage;
  modalHeader!: string;
  fieldName!: string;
  operationLoading = true;

  override initialItem: Record<string, string> = {};

  fieldsFromExecutionReport$: Observable<string[]> = this.workflowReportDomainService.getFieldsFromExecutionReport(
    this.dialogService.data.selectedNode.id,
  );

  constructor(
    protected override readonly dialogService: DialogService<CubeEditResult, CubeWorkflowData<Record<string, unknown>>>,
    private readonly operationDetailService: OperationDetailService,
    injector: Injector,
    private readonly dialogHeaderService: DialogHeaderService,
    private readonly cubeDialogManagementService: CubeDialogManagementService,
    private readonly workflowReportDomainService: WorkflowReportDomainService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.dialogService.data?.selectedNode.parameters) {
      const cubeParameters = this.dialogService.data.selectedNode.parameters.serialize();

      const { operationId } = this.dialogService.data.selectedNode;
      this.operationDetailService.getWithLoading(operationId).subscribe({
        next: ({ parameters, name }) => {
          const codeEditorParameter = parameters.find(({ type }) => type === 'codeSnippet');
          if (codeEditorParameter && codeEditorParameter.language) {
            this.language = codeEditorParameter.language.name;
            this.modalHeader = name;
            this.fieldName = codeEditorParameter.name;

            if (!cubeParameters?.[this.fieldName]) {
              this.codeText = codeEditorParameter.default as string;
            } else {
              this.codeText = cubeParameters[this.fieldName] as string;
            }
            this.cdr.markForCheck();
          }
        },
        complete: () => {
          this.markFormAsInitialized();

          this.operationLoading = false;
        },
      });
    }
    this.dialogHeaderService.initCustomHeaderComponent(
      this.nodeId,
      this.modalHeader,
      this.closeDialog.bind(this),
      this.onClickSave.bind(this),
    );
    setTimeout(() => {
      this.cubeDialogManagementService.setFocus(this.nodeId);
    });
  }

  get dataForWorkflow(): Record<string, string> {
    return { [this.fieldName]: this.codeText };
  }
}
