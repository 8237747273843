import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PAGE_PARAMS_DEFAULT_PAGE_SIZE, SearchQueryService, mapPaginationBiToPrime } from '@selfai-platform/shared';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { SelectButtonModule } from 'primeng/selectbutton';
import { Observable, combineLatest, map, of } from 'rxjs';
import { slotSelectors } from '../../constants';
import { DataListViewItem, GroupAction, LayoutType, TableColumn, ToolbarAction } from '../../models';
import { MenuItemsNormalizerService } from '../../normalizers';
import { DataListViewBaseService, LayoutTypeService, SelectedItemsService } from '../../services';

const allLayoutOptions: Array<{
  label: string;
  value: LayoutType;
}> = [
  { label: 'pi pi-bars', value: 'table' },
  { label: 'pi pi-th-large', value: 'grid' },
];

@Component({
    selector: 'selfai-platform-data-list-view-toolbar',
    imports: [
        CommonModule,
        FormsModule,
        ButtonModule,
        InputTextModule,
        DropdownModule,
        SelectButtonModule,
        MenuModule,
        TranslateModule,
    ],
    templateUrl: './data-list-view-toolbar.component.html',
    styleUrls: ['./data-list-view-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataListViewToolbarComponent<T extends object & DataListViewItem> implements OnChanges {
  @Input() layoutTypes: Array<LayoutType> = ['table', 'grid'];
  @Input() orderByOptions: Array<{ label: string; value: keyof T | string }> = [];
  /**
   * Actions for selected items
   */
  @Input() groupActions: GroupAction[] = [];
  @Input() actions: ToolbarAction[] = [];
  @Input() pageSizes: number[] = [PAGE_PARAMS_DEFAULT_PAGE_SIZE, 24, 40, 120, 500];
  @Input() columns: TableColumn<T>[] = [];

  selectedLayoutType$ = this.layoutTypeService.selectedLayoutType$;
  selectedItems$: Observable<unknown[]> = this.selectedItemsService.getSelectedItems();
  pageParams$ = this.dataListViewBaseService.pageParams$;
  querySearch$ = this.searchQueryService.querySearch$;
  moreActions$: Observable<MenuItem[]>;

  sortBy$ = combineLatest({
    orderAsc: this.translate.stream('msg.toolbar.order-direction-asc'),
    orderDesc: this.translate.stream('msg.toolbar.order-direction-desc'),
  }).pipe(
    map((translations) => [
      { label: translations.orderAsc, value: 'asc' },
      { label: translations.orderDesc, value: 'desc' },
    ]),
  );

  componentData$ = combineLatest({
    selectedLayoutType: this.selectedLayoutType$,
    pageParams: this.pageParams$,
  });

  slotSelectors = slotSelectors;

  get calculatedOrderByOptions$(): Observable<{ label: string; value: keyof T | string }[]> {
    return this.getCalculatedOrderByOptions();
  }

  get layoutOptions(): { value: LayoutType; label: string }[] {
    return allLayoutOptions.filter((option) => this.layoutTypes.includes(option.value));
  }

  get toolbarActions(): ToolbarAction[] | undefined {
    return this.actions?.filter((action) => !action.hideInMore);
  }

  constructor(
    private readonly layoutTypeService: LayoutTypeService,
    private readonly translate: TranslateService,
    private readonly dataListViewBaseService: DataListViewBaseService<DataListViewItem>,
    private readonly selectedItemsService: SelectedItemsService<DataListViewItem>,
    private readonly searchQueryService: SearchQueryService,
    private readonly menuItemsNormalizerService: MenuItemsNormalizerService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['actions'].currentValue) {
      this.moreActions$ = this.getMoreActions(changes['actions'].currentValue);
    }
  }

  onChangeViewType(layoutType: 'table' | 'grid'): void {
    this.layoutTypeService.changeLayoutType(layoutType);
  }

  onChangeSortField(sortField: string): void {
    this.dataListViewBaseService.onSort({ sortField });
  }

  onChangeSortOrder(sortOrder: 'asc' | 'desc'): void {
    this.dataListViewBaseService.onSort({ sortOrder });
  }

  onSearch(event: Event): void {
    const { value } = event.target as HTMLInputElement;
    this.searchQueryService.setQuerySearch(value);
  }

  onPageChange(event: { pageSize: number; pageNumber: number }): void {
    this.dataListViewBaseService.onPageChange(mapPaginationBiToPrime(event));
  }

  private getCalculatedOrderByOptions(): Observable<{ label: string; value: keyof T | string }[]> {
    if (this.orderByOptions.length > 0) {
      return of(this.orderByOptions);
    }

    const sortableColumns = this.columns.filter((column) => column.sortable);
    const labelTranslates = sortableColumns.map((column) => column.labelTranslate);

    if (labelTranslates.length === 0) {
      return of([]);
    }

    return this.translate
      .stream(labelTranslates)
      .pipe(
        map((labels) =>
          sortableColumns.map((column) => ({ label: labels[column.labelTranslate], value: column.fieldCode })),
        ),
      );
  }

  private getMoreActions(actions: ToolbarAction[] | undefined): Observable<MenuItem[]> {
    const moreActions = actions?.filter((action) => action.hideInMore);

    if (moreActions?.length) {
      return this.menuItemsNormalizerService.mapToolbarActionsToMenu(moreActions);
    }

    return of([]);
  }
}
