import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AvaliableUiCharts, CHART_TYPE_LIST } from '@selfai-platform/bi-domain';

import { IPageChart } from './page-section-chart.component.interfaces';
import { CHARTS } from './page-section-chart.component.values';

@Component({
    selector: 'selfai-platform-page-section-chart',
    templateUrl: './page-section-chart.component.html',
    styleUrls: ['./page-section-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PageSectionChartComponent {
  @Input() recommendCharts: any;
  @Input() selectChart: any;

  @Output() selectChartChange = new EventEmitter<AvaliableUiCharts>();

  #showInfoChart$ = new BehaviorSubject<AvaliableUiCharts>(null);

  isShowChartInfo$ = this.#showInfoChart$.pipe(map((chartType) => chartType ?? this.selectChart));

  charts: IPageChart[];

  constructor(@Inject(CHART_TYPE_LIST) readonly chartList: AvaliableUiCharts[]) {
    this.charts = CHARTS.filter((chart) => chartList.includes(chart.name));
  }

  clearChartInfo(): void {
    this.#showInfoChart$.next(null);
  }

  selectChartInfo(chartType: AvaliableUiCharts): void {
    this.#showInfoChart$.next(chartType);
  }
}
