import { Injectable } from '@angular/core';
import { DashboardDomainService, FavoriteListDomainService, FavoriteListItem } from '@selfai-platform/bi-domain';
import { PageParams, UrlPageParamsService } from '@selfai-platform/shared';
import {
  BI_ROOT_ROUTE,
  DataListViewItem,
  DataListViewLazyComponentService,
  KNOWLEDGE_DESIGNER_ROOT_ROUTE,
  SelectedItemsService,
  TableColumn,
} from '@selfai-platform/shell';
import { Observable, catchError, filter, forkJoin, map, mergeMap, of, take, tap } from 'rxjs';

@Injectable()
export class FavoriteDataListViewService extends DataListViewLazyComponentService<FavoriteListItem> {
  constructor(
    private readonly dashboardDomainService: DashboardDomainService,
    private readonly favoriteListDomainService: FavoriteListDomainService,
    urlPageParamsService: UrlPageParamsService,
    selectedItemsService: SelectedItemsService<FavoriteListItem>,
  ) {
    super(urlPageParamsService, selectedItemsService);
  }

  override loadData(pageParams: PageParams): Observable<{ items: FavoriteListItem[]; totalItems: number }> {
    return this.favoriteListDomainService
      .loadFavoriteList(pageParams)
      .pipe(map((state) => ({ items: state.favorites, totalItems: state.page.totalElements })));
  }

  override getData(): Observable<FavoriteListItem[]> {
    return this.favoriteListDomainService.getFavoriteList().pipe(
      filter(Boolean),
      mergeMap((favorites) => {
        if (!favorites.length) {
          return of([]);
        }

        return of(favorites.map((favoriteItem) => this.addRouterLink(favoriteItem)));
      }),
    );
  }

  override isLoading(): Observable<boolean> {
    return this.favoriteListDomainService.getFavoriteListLoading();
  }

  override isLoaded(): Observable<boolean> {
    return this.favoriteListDomainService.getFavoriteListLoaded();
  }

  override hasError(): Observable<boolean> {
    return this.favoriteListDomainService.getFavoriteListError().pipe(map(Boolean));
  }

  remove(favoriteItem: FavoriteListItem): void {
    this.favoriteListDomainService
      .removeFromFavorite(favoriteItem.id)
      .pipe(
        take(1),
        tap(() => this.triggerReloadData()),
      )
      .subscribe();
  }

  getColumns(): TableColumn<FavoriteListItem>[] {
    return [
      {
        labelTranslate: 'msg.favorite-list.table-header.name',
        fieldCode: 'targetName',
        fieldType: 'text',
        width: 'calc(100% - 52.357rem)',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'msg.favorite-list.table-header.domain',
        fieldCode: 'domain',
        fieldType: 'custom',
        width: '12rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'msg.favorite-list.table-header.created-by',
        fieldCode: 'targetModifiedBy',
        fieldType: 'text',
        classStyle: 'no-breaks',
        width: '10rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'msg.favorite-list.table-header.created-time',
        fieldCode: 'createdTime',
        fieldType: 'date',
        classStyle: 'no-breaks',
        width: '9rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'msg.favorite-list.table-header.modified-time',
        fieldCode: 'targetModifiedTime',
        fieldType: 'date',
        classStyle: 'no-breaks',
        width: '9rem',
        resizable: true,
        sortable: true,
      },
    ];
  }

  private addRouterLink(
    favoriteItem: FavoriteListItem,
  ): FavoriteListItem & Pick<DataListViewItem, 'routerLinkToItem'> {

    const routerLinkToItem: FavoriteListItem['routerLinkToItem'] = this.mapRouterLinkToItem(favoriteItem);

    return {
      ...favoriteItem,
      routerLinkToItem,
    };
  }

  private mapRouterLinkToItem(favoriteItem: FavoriteListItem): FavoriteListItem['routerLinkToItem'] {
    switch (favoriteItem.domain) {
      case 'DASHBOARD':
        return {
          routerLink: ['/', BI_ROOT_ROUTE, 'workbook', favoriteItem.workbookId],
          queryParams: { dashboardId: favoriteItem.targetId },
        };
      case 'WORKSPACE':
        return {
          routerLink: ['/', KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'workspace', favoriteItem.targetId],
        };
      case 'WORKBOOK':
        return {
          routerLink: ['/', KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'workbook', favoriteItem.targetId],
        };

      default:
        return {
          routerLink: [],
        };
    }
  }
}
