import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkspacesRolesManagerComponent } from './workspaces-roles-manager.component';
import { RouterModule } from '@angular/router';
import { WorkspacesRolesManagerSettingComponent } from './workspaces-roles-manager-setting/workspaces-roles-manager-setting.component';
import { WorkspacesRolesManagerAccessComponent } from './workspaces-roles-manager-access/workspaces-roles-manager-access.component';
import { WorkspacesRolesManagerBottomComponent } from './workspaces-roles-manager-bottom/workspaces-roles-manager-bottom.component';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ChipModule } from 'primeng/chip';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { WorkspacesAddRoleComponent } from './workspaces-add-role/workspaces-add-role.component';
import { CheckboxModule } from 'primeng/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { WorkspaceListToolbarComponentModule } from '../../../workspace/components/workspace-list/workspace-list-toolbar/workspace-list-toolbar.module';
import { WorkspaceRolesMangerToolbarComponentModule } from './workspaces-roles-manager-toolbar/workspaces-roles-manager-toolbar.module';
import { TableModule } from 'primeng/table';
import { AutoCompleteModule } from 'primeng/autocomplete';

@NgModule({
  declarations: [
    WorkspacesRolesManagerComponent,
    WorkspacesRolesManagerSettingComponent,
    WorkspacesRolesManagerAccessComponent,
    WorkspacesRolesManagerBottomComponent,
    WorkspacesAddRoleComponent,
  ],
  imports: [
    AutoCompleteModule,
    CommonModule,
    ButtonModule,
    InputTextModule,
    DropdownModule,
    TooltipModule,
    InputTextareaModule,
    ChipModule,
    InputSwitchModule,
    FormsModule,
    ReactiveFormsModule,
    MultiSelectModule,
    ConfirmPopupModule,
    ToastModule,
    DialogModule,
    CheckboxModule,
    TranslateModule,
    TableModule,
    WorkspaceListToolbarComponentModule,
    WorkspaceRolesMangerToolbarComponentModule,
    RouterModule.forChild([
      {
        path: '',
        component: WorkspacesRolesManagerComponent,
      },
    ]),
  ],
})
export class WorkspacesRolesManagerModule {}
