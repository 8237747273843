import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DestroyService } from '@selfai-platform/shared';
import { BreadcrumbsMenu, BreadcrumbsMenuItem, KNOWLEDGE_DESIGNER_ROOT_ROUTE } from '@selfai-platform/shell';
import { Observable, combineLatest, filter, map, switchMap, take, takeUntil } from 'rxjs';
import { WorkspaceListDomainService } from '../../workspace';
import { GUEST_WORKSPACE_ID, MY_WORKSPACE_ID } from '../../workspace/constants';
import { PublicType } from '../../workspace/enums';
import { Workspace } from '../../workspace/models';
import { WorkspaceDomainService } from '../../workspace/services/workspace-domain.service';

// Bread crumbs menu for workbook list
@Injectable()
export class WorkbookListBreadcrumbService implements BreadcrumbsMenu {
  constructor(
    private readonly workspaceDomainService: WorkspaceDomainService,
    private readonly workspaceListDomainService: WorkspaceListDomainService,
    private readonly translate: TranslateService,
    private readonly destroy$: DestroyService,
  ) {}

  getBreadcrumbsMenu(workspaceId: string): Observable<BreadcrumbsMenuItem[]> {
    return this.workspaceDomainService
      .getWorkspace(workspaceId)
      .pipe(filter(Boolean), take(1), takeUntil(this.destroy$))
      .pipe(
        switchMap(() => {
          return combineLatest({
            workspace: this.workspaceDomainService.getWorkspace(workspaceId),
            publicWorkspaceName: this.translate.stream('msg.workspace-list.toolbar.public-workspace'),
            guestWorkspaceName: this.translate.stream('msg.workspace-list.toolbar.guest-workspace'),
            myWorkspaceName: this.translate.stream('msg.workspace-list.toolbar.my-workspace'),
            knowledgeDesigner: this.translate.stream('shell.menu.knowledge-designer'),
          });
        }),

        map(({ workspace, ...translate }) => {
          const currentWorkspaceType = this.getWorkspaceType(workspace);
          let currentWorkspaceTypeItem: BreadcrumbsMenuItem;

          switch (currentWorkspaceType) {
            case 'guest':
              currentWorkspaceTypeItem = {
                label: translate.guestWorkspaceName,
                routerLink: ['/', KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'workspace', GUEST_WORKSPACE_ID],
              };
              break;
            case 'my':
              currentWorkspaceTypeItem = {
                label: translate.myWorkspaceName,
                routerLink: ['/', KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'workspace', MY_WORKSPACE_ID],
              };
              break;
            case 'public':
              currentWorkspaceTypeItem = {
                label: translate.publicWorkspaceName,
                routerLink: ['/', KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'workspaces'],
              };
              break;
            default:
            case 'private':
              currentWorkspaceTypeItem = {
                label: workspace.name,
                routerLink: ['/', KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'workspace', workspace.id],
                description: workspace.description,
              };
              break;
          }

          const workspaceListItems: BreadcrumbsMenuItem[] = [
            { label: translate.knowledgeDesigner, routerLink: ['/', KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'workspaces'] },
            currentWorkspaceTypeItem,
          ];

          if (currentWorkspaceType === 'public') {
            workspaceListItems.push({
              label: workspace.name,
              icon: 'pi pi-briefcase',
              routerLink: ['/', KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'workspace', workspace.id],
              description: workspace.description,
            });
          }

          return workspaceListItems;
        }),
      );
  }

  private getWorkspaceType(workspace: Workspace): 'public' | 'my' | 'guest' | 'private' {
    if (workspace.id === GUEST_WORKSPACE_ID) {
      return 'guest';
    }

    if (workspace.id === MY_WORKSPACE_ID) {
      return 'my';
    }

    if (workspace.publicType === PublicType.SHARED) {
      return 'public';
    }

    return 'private';
  }
}
