import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { formatToRussianFormat, formatToShortIso } from '@selfai-platform/shared';
import { ConstantValueFieldComponent } from '../constant-value-field/constant-value-field.component';

@Component({
    selector: 'selfai-platform-date-value-field',
    templateUrl: './date-value-field.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateValueFieldComponent),
            multi: true,
        },
    ],
    standalone: false
})
export class DateValueFieldComponent extends ConstantValueFieldComponent {
  get shownDate(): string {
    if (this.value) {
      const date = new Date(this.value);

      // date format dd.mm.yyyy
      return formatToRussianFormat(date);
    }

    return '';
  }

  override inputOnChange(value: Date | null): void {
    if (value) {
      this.updateValue(formatToShortIso(value));
    }
  }
}
