import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CubeError } from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';

@Component({
    selector: 'selfai-platform-error-dialog',
    templateUrl: './error-dialog.component.html',
    styleUrls: ['./error-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ErrorDialogComponent {
  get error(): CubeError {
    return this.dialogService.data as CubeError;
  }

  constructor(private readonly dialogService: DialogService<undefined, CubeError>) {}
}
