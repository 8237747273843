import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NodeUsingColumn } from '@selfai-platform/pipeline-common';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { WorkflowEditorFacadeService } from '../../../../workflow-editor/services/workflow-editor-facade.service';

@Component({
    selector: 'selfai-platform-used-in-panel',
    templateUrl: './used-in-panel.component.html',
    styleUrls: ['./used-in-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, ButtonModule, TooltipModule, OverlayPanelModule]
})
export class UsedInPanelComponent {
  @Input()
  usedInNodes: NodeUsingColumn[] | undefined = undefined;

  constructor(private readonly workflowEditorService: WorkflowEditorFacadeService) {}

  navigateToNode(node: NodeUsingColumn): void {
    this.workflowEditorService.openNodeModalDialogUnselectedNode(node.nodeId);
  }
}
