import {
  Component,
  ElementRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import _ from 'lodash';
import { isNil } from 'lodash/fp';
import { takeUntil } from 'rxjs';
import pixelWidth from 'string-pixel-width';

import {
  BoardDataSource,
  ConnectionType,
  convertDsToMetaDs,
  createBoardConfiguration,
  createBoardSource,
  createDashboard,
  createDatasourceField,
  createQueryParam,
  Dashboard,
  Datasource,
  DataSourceSummary,
  DatasourceField as Field,
  Filter,
  InclusionFilter,
  JoinMapping,
  JoinMappingDataSource,
  DatasourceStatus as Status,
} from '@selfai-platform/bi-domain';
import { DestroyService, isNullOrUndefined } from '@selfai-platform/shared';
import { DialogService, provideDialogService } from '@selfai-platform/shell';

import { AbstractComponent } from '../../../common/component/abstract.component';
import { GridComponent } from '../../../common/component/grid/grid.component';
import { header, SlickGridHeader } from '../../../common/component/grid/grid.header';
import { GridOption } from '../../../common/component/grid/grid.option';
import { EventBroadcaster } from '../../../common/event/event.broadcaster';
import { CommonUtil } from '../../../common/util/common.util';
import { DatasourceService } from '../../../datasource/service/datasource.service';
import { FilterUtil } from '../../util/filter.util';

import { CreateBoardPopJoinComponent } from './create-board-pop-join.component';
import { AddJoinDataSource, EditJointDataSource } from './join-data-source.model';

declare let Split;

@Component({
    selector: 'create-board-ds-info',
    templateUrl: './create-board-ds-info.component.html',
    styleUrls: ['./create-board-ds-info.component.scss'],
    providers: [DestroyService, provideDialogService()],
    standalone: false
})
export class CreateBoardDsInfoComponent extends AbstractComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('main', { static: true })
  private gridComponent: GridComponent;

  private _allDataSources: Datasource[];
  private _candidateDataSources: Datasource[];

  private _split: any;

  @Input()
  workspaceId: string;

  @Input()
  dataSource: BoardDataSource;

  tab = Tab;
  selectedTab: Tab = Tab.PREVIEW;

  joinMappings: JoinMapping[] = [];
  joinRenameStarted: Record<string, boolean> = {};

  essentialFilters: Filter[] = [];

  headerCnt = 0;
  rowNum = 1000;
  colTypes: { type: string; cnt: number }[] = [];
  dataSourceSummary: DataSourceSummary;

  isLinkedDataSource = false;
  isEmptyMainGrid = false;
  isShowColTypeLayer = false;
  isEnableJoin = false;

  commonUtil = CommonUtil;

  constructor(
    protected elementRef: ElementRef,
    protected injector: Injector,
    protected broadCaster: EventBroadcaster,
    private datasourceService: DatasourceService,
    private readonly dialogService: DialogService<JoinMappingDataSource, AddJoinDataSource | EditJointDataSource>,
    private readonly destroy$: DestroyService,
  ) {
    super(elementRef, injector);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.dataSource.joins && this.dataSource.joins.length > 0) {
      this.joinMappings = this.dataSource.joins;
    }

    this.subscriptions.push(
      this.broadCaster.on('CREATE_BOARD_SELECT_DS').subscribe((data: { dataSource: BoardDataSource }) => {
        this.changeDataSource(data.dataSource);
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const dsChanges: SimpleChange = changes.dataSource;
    if (dsChanges.firstChange) {
      this.changeDataSource(dsChanges.currentValue);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this._split = Split(['.sys-create-board-top-panel', '.sys-create-board-bottom-panel'], {
        direction: 'vertical',
      });
    }, 500);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._split.destroy();
    this._split = undefined;
  }

  getTypeClass(logicalType: string): string {
    if ('STRING' === logicalType || 'TEXT' === logicalType) {
      return 'ddp-icon-dimension-ab';
    } else if ('LNG' === logicalType || 'LNT' === logicalType) {
      return 'ddp-icon-dimension-local';
    } else if ('TIMESTAMP' === logicalType) {
      return 'ddp-icon-dimension-calen';
    } else if ('BOOLEAN' === logicalType) {
      return 'ddp-icon-dimension-tf';
    } else if ('DOUBLE' === logicalType) {
      return 'ddp-icon-measure-float';
    } else if ('INTEGER' === logicalType) {
      return 'ddp-icon-measure-sharp';
    }
  }

  changeDataSource(dataSource: BoardDataSource) {
    if (dataSource) {
      isNullOrUndefined(dataSource.type) && (dataSource.type = 'default');

      if (ConnectionType.LINK.toString() === dataSource.connType) {
        this.isLinkedDataSource = true;

        const pseudoDashboard: Dashboard = createDashboard();
        pseudoDashboard.configuration = createBoardConfiguration();
        pseudoDashboard.configuration.dataSource = dataSource;
        pseudoDashboard.configuration.fields = dataSource.uiFields;

        this.essentialFilters = isNullOrUndefined(dataSource.uiFilters) ? [] : _.cloneDeep(dataSource.uiFilters);
        this.essentialFilters = FilterUtil.getPanelContentsList(
          this.essentialFilters,
          pseudoDashboard,
          (filter: InclusionFilter) => {
            const valueList: string[] = filter.valueList;
            if (valueList && 0 < valueList.length) {
              filter['panelContents'] = valueList.join(' , ');
            } else {
              filter['panelContents'] = '(' + this.translateService.instant('msg.comm.ui.list.all') + ')';
            }
          },
        );
      }

      this.dataSource = dataSource;
      this.joinMappings = dataSource.joins;
      this._getCandidateDatasource();
      this._loadGridData();

      this.safelyDetectChanges();
    }
  }

  changeTab(tabName: Tab) {
    this.selectedTab = tabName;
    this.safelyDetectChanges();
    Tab.PREVIEW === tabName && this._loadGridData();
  }

  deleteDataSource() {
    this.broadCaster.broadcast('CREATE_BOARD_REMOVE_DS', {
      dataSourceId: this.dataSource.id,
    });
  }

  closeDsInfo() {
    this.broadCaster.broadcast('CREATE_BOARD_CLOSE_DS');
  }

  setGridRow(inputRowNum: number) {
    if (Number(inputRowNum) !== this.rowNum) {
      this.rowNum = Number(inputRowNum);

      this._loadGridData();
    }
  }

  showJoinPopup(join?: JoinMapping, targetDsId?: string) {
    if (isNil(join)) {
      this.showAddJoinDialog(this.dataSource);
    } else {
      if (isNil(targetDsId)) {
        const targetDs: Datasource = this._allDataSources.find((ds) => ds.id === join.id);
        const metads: BoardDataSource = createBoardSource();
        metads.id = targetDs.id;
        metads.name = targetDs.name;
        metads.engineName = targetDs.engineName;
        metads.connType = targetDs.connType.toString();
        metads.uiFields = targetDs.fields;
        this.showAddJoinDialog(metads, join);
      } else {
        const leftDsSrc = this._allDataSources.find((ds) => ds.id === targetDsId);
        const leftDs = convertDsToMetaDs(leftDsSrc);
        const rightDs = convertDsToMetaDs(this._allDataSources.find((ds) => ds.id === join.id));
        this.showEditJoinDialog(leftDs, rightDs, join, targetDsId);
      }
    }
  }

  changeJoin(joinMappingDataSource: JoinMappingDataSource) {
    const joinMappings: JoinMapping[] = joinMappingDataSource.joinMappings;
    const candidateDataSources: Datasource[] = joinMappingDataSource.candidateDataSources;
    this.joinMappings = joinMappings;
    this.dataSource.joins = joinMappings;
    this.broadCaster.broadcast('CREATE_BOARD_UPDATE_DS', {
      dataSource: this.dataSource,
      candidateDataSources: candidateDataSources,
    });
  }

  showRenameJoinForm(id: string): void {
    this.joinRenameStarted[id] = true;
  }

  hideRenameJoinForm(id: string): void {
    this.joinRenameStarted[id] = false;
  }

  saveRenameJoin(join: JoinMapping): void {
    if (this.validateJoinAlias(join)) {
      this.hideRenameJoinForm(join.id);
    }
  }

  removeJoin(joins, idx) {
    joins.splice(idx, 1);
  }

  showEssentialFilerPopup() {
    this.broadCaster.broadcast('CREATE_BOARD_RE_INGESTION', {
      dataSource: this.dataSource,
    });
  }

  validateJoinAlias(join: JoinMapping): boolean {
    return (
      join.joinAlias.trim().length > 0 &&
      !this.joinMappings.some(({ id, joinAlias }) => join.id !== id && join.joinAlias.trim() === joinAlias.trim())
    );
  }

  private showAddJoinDialog(leftDataSource: BoardDataSource, join?: JoinMapping): void {
    this.dialogService
      .showDialog(CreateBoardPopJoinComponent, {
        showHeader: false,
        width: '70%',
        data: {
          dataSource: this.dataSource,
          candidateDataSources: this._candidateDataSources,
          joinMappings: this.joinMappings,
          leftDataSource,
          join,
        },
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((joinMappingDataSource?: JoinMappingDataSource) => {
        if (joinMappingDataSource) {
          this.changeJoin(joinMappingDataSource);
        }
      });
  }

  private showEditJoinDialog(
    leftDataSource: BoardDataSource,
    rightDataSource: BoardDataSource,
    join: JoinMapping,
    targetDsId: string,
  ): void {
    this.dialogService
      .showDialog(CreateBoardPopJoinComponent, {
        showHeader: false,
        width: '70%',
        data: {
          dataSource: this.dataSource,
          candidateDataSources: this._candidateDataSources,
          joinMappings: this.joinMappings,
          leftDataSource,
          rightDataSource,
          targetDsId,
          join,
        },
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((joinMappingDataSource?: JoinMappingDataSource) => {
        if (joinMappingDataSource) {
          this.changeJoin(joinMappingDataSource);
        }
      });
  }

  private _loadGridData() {
    this.gridComponent && this.gridComponent.destroy();

    this._queryData(this.joinMappings, this.dataSource.temporary)
      .then((data) => {
        this.headerCnt = data[1].length;
        if (0 < data[0].length && 0 < data[1].length) {
          this.isEmptyMainGrid = false;

          this.rowNum > data[0].length && (this.rowNum = data[0].length);

          this._updateGrid(data[0], data[1]);
        } else {
          this.rowNum = 0;
          this.isEmptyMainGrid = true;
        }
        this.pageLoaderService.hide();
        this.changeDetect.detectChanges();
      })
      .catch((err) => this.commonExceptionHandler(err));
  }

  private _updateGrid(data: any, fields: Field[]) {
    const headers: header[] = fields.map((field: Field) => {
      const headerWidth: number = Math.floor(pixelWidth(field.name, { size: 12 })) + 62;
      return new SlickGridHeader()
        .Id(field.name)
        .Name(field.name)
        .Field(field.name)
        .Behavior('select')
        .Selectable(false)
        .CssClass('cell-selection')
        .Width(headerWidth)
        .CannotTriggerInsert(true)
        .Resizable(true)
        .Unselectable(true)
        .Sortable(true)
        .build();
    });

    let rows: any[] = data;

    if (data.length > 0 && !data[0].hasOwnProperty('id')) {
      rows = rows.map((row: any, idx: number) => {
        row.id = idx;
        return row;
      });
    }

    this.gridComponent.destroy();

    if (0 < headers.length) {
      this.gridComponent.create(
        headers,
        rows,
        new GridOption().SyncColumnCellResize(true).MultiColumnSort(true).RowHeight(32).build(),
      );

      if (0 === rows.length) {
        this.gridComponent.invalidateAllRows();
        this.gridComponent.elementRef.nativeElement.querySelector('.grid-canvas').innerHTML =
          '<div class="ddp-data-empty"><span class="ddp-data-contents">' +
          this.translateService.instant('msg.space.ui.no.data') +
          '</span></div>';
      }
    }
  }

  private _queryData(
    joins: JoinMapping[],
    isTemporary: boolean = false,
    loading: boolean = true,
  ): Promise<[any, Field[]]> {
    return new Promise<any>((res, rej) => {
      const params = createQueryParam();
      params.limits.limit = this.rowNum;

      params.dataSource = createBoardSource();
      params.dataSource.type = 'default';
      params.dataSource.name = this.dataSource.engineName;
      params.dataSource.temporary = this.dataSource.temporary;

      if (joins && joins.length > 0) {
        params.dataSource.type = 'mapping';
        params.dataSource['joins'] = joins;
      }

      loading && this.pageLoaderService.show();
      this.datasourceService
        .getDatasourceQuery(params)
        .then((data) => {
          let fieldList: Field[] = [];
          if (data && 0 < data.length) {
            fieldList = Object.keys(data[0]).map((keyItem) => {
              const tempInfo = createDatasourceField({
                name: keyItem,
              });
              return tempInfo;
            });
          }
          res([data, fieldList]);
          this.pageLoaderService.hide();
        })
        .catch((err) => {
          rej(err);
          this.pageLoaderService.hide();
        });
    });
  }

  private _getCandidateDatasource() {
    const params = {
      size: 100000,
      page: this.page.page,
      status: Status.ENABLED,
    };
    this.datasourceService
      .getDatasources(this.workspaceId, params, 'forDetailView')
      .then((data) => {
        this._allDataSources = data['_embedded'].datasources;
        const candidateDataSources = this._allDataSources.filter((ds) => {
          if (ds.id === this.dataSource.id) {
            ds.summary && (this.dataSourceSummary = ds.summary);

            if (ds.fields) {
              const fieldMap = ds.fields.reduce((accumulator, field: Field) => {
                if (accumulator[field.type]) {
                  accumulator[field.type] = accumulator[field.type] + 1;
                } else {
                  accumulator[field.type] = 1;
                }
                return accumulator;
              }, {});
              this.colTypes = Object.keys(fieldMap).map((key) => {
                return { type: key, cnt: fieldMap[key] };
              });
            }
            return false;
          } else {
            return true;
          }
        });
        this.isEnableJoin = candidateDataSources && 0 < candidateDataSources.length;
        this._candidateDataSources = candidateDataSources;
        this.safelyDetectChanges();
      })
      .catch((error) => {
        console.error(error);
        this.alertPrimeService.error(this.translateService.instant('msg.board.alert.fail.load.datasource'));
      });
  }
}

enum Tab {
  PREVIEW = 'PREVIEW',
  JOIN = 'JOIN',
  FILTER = 'FILTER',
}
