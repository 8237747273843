import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import * as _ from 'lodash';
import { eq, isUndefined } from 'lodash';
import { EMPTY, Observable, merge } from 'rxjs';
import { debounceTime, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { AxisOptionConverter, FormatOptionConverter, OptionGenerator } from '@selfai-platform/bi-chart-engine';
import {
  AxisLabelType,
  AxisType,
  ChartAxisGridType,
  Format,
  UIChartAxis,
  UIChartAxisLabelValue,
  UIFormatCurrencyType,
  UIFormatType,
  UIOption,
} from '@selfai-platform/bi-domain';
import { DestroyService } from '@selfai-platform/shared';

import { YAxisOptionsService } from '../service/y-axis-options.service';

import { FormatOptionComponent } from './format-option.component';

import UI = OptionGenerator.UI;

@Component({
    selector: 'axis-value-option',
    templateUrl: './axis-value-option.component.html',
    providers: [DestroyService],
    standalone: false
})
export class AxisValueOptionComponent extends FormatOptionComponent implements OnInit {
  AxisType = AxisType;

  AxisOptionConverter: any = AxisOptionConverter;
  lineThickList: Object[] = [];
  lineTypeList: Object[] = [];
  axisTemp: UIChartAxis;

  @Output()
  changeValueAxis: EventEmitter<any> = new EventEmitter();

  @Output()
  changeBaseline: EventEmitter<any> = new EventEmitter();

  axis: UIChartAxis;

  @Input('axis')
  set setAxis(axis: UIChartAxis) {
    this.axis = axis;
    axis.baseline = !isUndefined(axis.baseline) && !isNaN(<number>axis.baseline) ? axis.baseline : undefined;
    this.axisTemp = _.cloneDeep(axis);
    if (this.axisTemp.grid) {
      const isZero: boolean = this.axisTemp.grid.min == 0 && this.axisTemp.grid.max == 0;
      this.axisTemp.grid.min =
        isZero || this.axisTemp.grid.autoScaled || (!_.isUndefined(axis.baseline) && axis.baseline != 0)
          ? null
          : this.axisTemp.grid.min;
      this.axisTemp.grid.max =
        isZero || this.axisTemp.grid.autoScaled || (!_.isUndefined(axis.baseline) && axis.baseline != 0)
          ? null
          : this.axisTemp.grid.max;
    }
  }

  @Input('uiOption')
  set setUiOption(uiOption: UIOption) {
    this.uiOption = uiOption;
  }

  form: FormGroup<{
    type: FormControl<string | undefined>;
    logBase: FormControl<number | undefined>;
  }>;

  constructor(
    protected elementRef: ElementRef,
    protected injector: Injector,
    protected yAxisOptionsService: YAxisOptionsService,
    protected readonly destroy$: DestroyService,
  ) {
    super(elementRef, injector);

    this.form = new FormGroup({
      type: new FormControl(this.axis?.type as string),
      logBase: new FormControl(this.axis?.logBase, [Validators.min(1)]),
    });
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.yAxisOptionsService.setAxisConfig(this.axis);

    merge(this.handleFormChange(), this.supportLegacyDataDelivery(), this.initForm())
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  showAxisLabel(axisLabelType: any, show: boolean): void {
    if (_.eq(this.axis.mode, axisLabelType)) {
      this.axis.showLabel = show;
    }

    this.changeValueAxis.emit(this.axis);
  }

  showLabel() {
    if (!(<UIChartAxisLabelValue>this.axis.label).format) {
      (<UIChartAxisLabelValue>this.axis.label).useDefault = false;
      (<UIChartAxisLabelValue>this.axis.label).format = UI.Format.custom(
        true,
        null,
        String(UIFormatType.NUMBER),
        String(UIFormatCurrencyType.KRW),
        2,
        true,
      );
    } else {
      (<UIChartAxisLabelValue>this.axis.label).useDefault = true;
      delete (<UIChartAxisLabelValue>this.axis.label).format;
    }

    this.changeValueAxis.emit(this.axis);
  }

  onChange(target: Object): void {
    (<UIChartAxisLabelValue>this.axis.label).format = target as Format;
    this.changeValueAxis.emit(this.axis);
  }

  changeGrid(): void {
    if (_.isUndefined(this.axis.grid)) {
      this.axis.grid = {
        type: ChartAxisGridType.NUMERIC,
        autoScaled: false,
        min: 0,
        max: 0,
      };
      this.axisTemp.grid = _.cloneDeep(this.axis.grid);
      this.axisTemp.grid.min = null;
      this.axisTemp.grid.max = null;
    } else {
      delete this.axis.grid;
    }
    this.changeBaseline.emit(this.axis);
  }

  changeAutoScale(): void {
    this.axis.grid.autoScaled = !this.axis.grid.autoScaled;
    this.axis.grid.min = 0;
    this.axis.grid.max = 0;
    this.axisTemp.grid.min = null;
    this.axisTemp.grid.max = null;
    this.changeBaseline.emit(this.axis);
  }

  changeBaseLine(): void {
    const tempBaseline = _.isEmpty(this.axisTemp.baseline)
      ? 0
      : FormatOptionConverter.getNumberValue(this.axisTemp.baseline);

    if (isNaN(tempBaseline)) {
      const originBaseline = _.isUndefined(this.axis.baseline)
        ? undefined
        : FormatOptionConverter.getDecimalValue(
            _.cloneDeep(this.axis.baseline),
            this.uiOption.valueFormat.decimal,
            this.uiOption.valueFormat.useThousandsSep,
          );

      this.axisTemp.baseline = _.isUndefined(this.axis.baseline)
        ? FormatOptionConverter.getNumberValue(0)
        : originBaseline;
      return;
    }

    this.axisTemp.baseline = FormatOptionConverter.getDecimalValue(
      this.axisTemp.baseline,
      this.uiOption.valueFormat.decimal,
      this.uiOption.valueFormat.useThousandsSep,
    );

    delete this.axis.grid;
    this.axis.baseline = tempBaseline;
    this.changeBaseline.emit(this.axis);
  }

  showBaseLine(): void {
    if (undefined === this.axisTemp.baseline) {
      const originBaseline =
        undefined !== this.axis.baseline
          ? FormatOptionConverter.getNumberValue(_.cloneDeep(this.axis.baseline))
          : undefined;

      this.axisTemp.baseline = _.isUndefined(this.axis.baseline)
        ? FormatOptionConverter.getDecimalValue(
            0,
            this.uiOption.valueFormat.decimal,
            this.uiOption.valueFormat.useThousandsSep,
          )
        : originBaseline;
    } else {
      delete this.axisTemp.baseline;
      this.axis.baseline = undefined;
      this.changeBaseline.emit(this.axis);
    }
  }

  changeMin(): void {
    if (FormatOptionConverter.getNumberValue(this.axisTemp.grid.min) === Number(this.axis.grid.min)) {
      return;
    }

    const min = FormatOptionConverter.getNumberValueForMinMaxAxis(this.axisTemp.grid.min);

    if (isNaN(min) || min === null || min === undefined) {
      this.axisTemp.grid.min = this.axis.grid.min;
      return;
    }

    const originAxisMin = FormatOptionConverter.getNumberValueForMinMaxAxis(this.axis.grid.min);

    let max = FormatOptionConverter.getNumberValueForMinMaxAxis(this.axis.grid.max);

    if (max == 0) {
      const dataMax = AxisOptionConverter.axisMinMax[eq(this.axis.mode, AxisLabelType.ROW) ? 'xAxis' : 'yAxis'].max;
      max = FormatOptionConverter.getNumberValue(dataMax.toFixed(2));
      this.axis.grid.max = max;
    }

    if (FormatOptionConverter.getNumberValueForMinMaxAxis(min) >= max) {
      this.alertPrimeService.info(this.translateService.instant('msg.page.yaxis.grid.min.alert'));
      this.axisTemp.grid.min = this.axis.grid.min != 0 ? FormatOptionConverter.getDecimalValue(
        FormatOptionConverter.getNumberValueForMinMaxAxis(originAxisMin),
        this.uiOption.valueFormat.decimal,
        this.uiOption.valueFormat.useThousandsSep,
      ) : null;
      return;
    }

    this.axis.grid.min = FormatOptionConverter.getNumberValueForMinMaxAxis(this.axisTemp.grid.min);
    if (this.axisTemp.grid.autoScaled || this.axis.grid.autoScaled) {
      this.axisTemp.grid.autoScaled = false;
      this.axisTemp.grid.max = null;
      this.axis.grid.autoScaled = false;
      this.axis.grid.max = 0;
    }
    this.changeBaseline.emit(this.axis);

    if (_.eq(min, '')) {
      this.axisTemp.grid.min = null;
    } else {
      this.axisTemp.grid.min = FormatOptionConverter.getDecimalValue(
        FormatOptionConverter.getNumberValueForMinMaxAxis(this.axisTemp.grid.min),
        this.uiOption.valueFormat.decimal,
        this.uiOption.valueFormat.useThousandsSep,
      );
    }
  }

  changeMax(): void {
    const gridMaxTemp = FormatOptionConverter.getNumberValue(this.axisTemp.grid.max);

    if (gridMaxTemp === FormatOptionConverter.getNumberValue(this.axis.grid.max)) {
      return;
    }

    if (!(gridMaxTemp ?? false)) {
      this.axisTemp.grid.max = this.axis.grid.max;
      return;
    }

    const isDefaultValue = gridMaxTemp === 0;
    this.axisTemp.grid.max = isDefaultValue ? 0 : this.axisTemp.grid.max;

    const originAxisMax = FormatOptionConverter.getNumberValueForMinMaxAxis(this.axis.grid.max);

    const min = FormatOptionConverter.getNumberValueForMinMaxAxis(this.axis.grid.min);
    let max = FormatOptionConverter.getNumberValueForMinMaxAxis(this.axisTemp.grid.max) ?? 0;

    if (isDefaultValue) {
      const dataMax = AxisOptionConverter.axisMinMax[_.eq(this.axis.mode, AxisLabelType.ROW) ? 'xAxis' : 'yAxis'].max;
      max = FormatOptionConverter.getNumberValueForMinMaxAxis(dataMax.toFixed(2));
      this.axis.grid.max = max;
    }

    if ((max <= min && max != 0 && min != 0) || max == 0) {
      this.alertPrimeService.info(this.translateService.instant('msg.page.yaxis.grid.max.alert'));
      this.axisTemp.grid.max = FormatOptionConverter.getDecimalValue(
        FormatOptionConverter.getNumberValueForMinMaxAxis(originAxisMax),
        this.uiOption.valueFormat.decimal,
        this.uiOption.valueFormat.useThousandsSep,
      );
      return;
    }

    if (FormatOptionConverter.getNumberValueForMinMaxAxis(this.axisTemp.grid.max) != 0)
      this.axis.grid.max = FormatOptionConverter.getNumberValueForMinMaxAxis(this.axisTemp.grid.max);

    if (this.axisTemp.grid.autoScaled || this.axis.grid.autoScaled) {
      this.axisTemp.grid.autoScaled = false;
      this.axisTemp.grid.min = null;
      this.axis.grid.autoScaled = false;
      this.axis.grid.min = 0;
    }
    this.changeBaseline.emit(this.axis);

    if (_.eq(gridMaxTemp, '')) {
      this.axisTemp.grid.max = null;
    } else {
      this.axisTemp.grid.max = FormatOptionConverter.getDecimalValue(
        FormatOptionConverter.getNumberValueForMinMaxAxis(this.axisTemp.grid.max),
        this.uiOption.valueFormat.decimal,
        this.uiOption.valueFormat.useThousandsSep,
      );
    }
  }

  changeGridLineColor(): void {}

  changeLineThick(): void {}

  changeLineType(): void {}

  isOverMinMax(): boolean {
    return this.isOverMin() || this.isOverMax();
  }

  isOverMin(): boolean {
    if (this.axisTemp.grid && !this.axisTemp.grid.autoScaled) {
      const inputMin = FormatOptionConverter.getNumberValue(this.axisTemp.grid.min);

      const dataMin = AxisOptionConverter.axisMinMax[_.eq(this.axis.mode, AxisLabelType.ROW) ? 'xAxis' : 'yAxis'].min;

      if (!isNaN(inputMin) && inputMin < dataMin) {
        return true;
      }
    }

    return false;
  }

  isOverMax(): boolean {
    if (this.axisTemp.grid && !this.axisTemp.grid.autoScaled) {
      const inputMax = FormatOptionConverter.getNumberValue(this.axisTemp.grid.max);

      const dataMax = AxisOptionConverter.axisMinMax[_.eq(this.axis.mode, AxisLabelType.ROW) ? 'xAxis' : 'yAxis'].max;

      if (!isNaN(inputMax) && inputMax > dataMax) {
        return true;
      }
    }

    return false;
  }

  getDecimalRoundNumber(value: number): string {
    return FormatOptionConverter.getDecimalValue(
      Number(value),
      this.uiOption.valueFormat.decimal,
      this.uiOption.valueFormat.useThousandsSep,
    );
  }

  private initForm(): Observable<void> {
    return this.yAxisOptionsService.getAxisConfig().pipe(
      take(1),
      tap((config) => {
        this.form.controls.type.setValue(config.type, { emitEvent: false });
        this.form.controls.logBase.setValue(config.logBase, { emitEvent: false });
      }),
      switchMap(() => EMPTY),
    );
  }

  private handleFormChange(): Observable<void> {
    return this.form.valueChanges.pipe(
      tap((config: Partial<UIChartAxis>) => {
        if (!config.type) {
          config.type = this.axis.label.type;
        }

        this.yAxisOptionsService.setAxisConfig(config);
      }),
      switchMap(() => EMPTY),
    );
  }

  private supportLegacyDataDelivery(): Observable<void> {
    return this.yAxisOptionsService.getAxisConfig().pipe(
      debounceTime(100),
      tap((config) => {
        this.changeValueAxis.emit({ ...this.axis, ...config });
      }),
      switchMap(() => EMPTY),
    );
  }
}
