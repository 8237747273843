import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Injector,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TreeNode } from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { provideDialogService } from '@selfai-platform/shell';
import { takeUntil } from 'rxjs';
import { AggregatorCamelTreeService } from '../../../aggregator-camel/services/aggregator-camel-tree.service';
import { JsonPathService } from '../../services';

@Component({
    selector: 'selfai-platform-json-path-field',
    templateUrl: './json-path-field.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => JsonPathFieldComponent),
            multi: true,
        },
        DestroyService,
        ...provideDialogService(AggregatorCamelTreeService),
    ],
    standalone: false
})
export class JsonPathFieldComponent implements ControlValueAccessor, AfterViewInit {
  value = '';
  disabled = false;
  invalid = false;

  @Input() label?: string;

  onChange!: (_: string) => void;
  ngControl!: NgControl;
  private onTouched!: () => void;

  constructor(
    private readonly aggregatorCamelService: AggregatorCamelTreeService,
    private readonly destroy$: DestroyService,
    private readonly jsonPathService: JsonPathService,
    private readonly cdr: ChangeDetectorRef,
    private readonly injector: Injector,
  ) {}

  ngAfterViewInit(): void {
    this.ngControl = this.injector.get(NgControl);
    this.ngControl.statusChanges?.pipe(takeUntil(this.destroy$)).subscribe((status) => {
      this.invalid = status === 'INVALID';
      this.cdr.markForCheck();
    });
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: (_: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  showJsonTreeSchemaView(): void {
    this.aggregatorCamelService
      .showJsonTreeSchemaView()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (item?: TreeNode) => {
          if (item) {
            this.updateValue(this.jsonPathService.getJsonPath(item.path));
          }
        },
      });
  }

  private updateValue(value: string): void {
    this.value = value;
    this.onChange(value);
    this.onTouched();
    this.cdr.markForCheck();
  }
}
