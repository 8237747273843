import { Injectable } from '@angular/core';
import { EntityOp } from '@ngrx/data';
import { DashboardApiService } from '@selfai-platform/bi-api';
import { PageParams } from '@selfai-platform/shared';
import { Observable, catchError, combineLatest, finalize, map, take, tap, throwError } from 'rxjs';
import { Dashboard, DashboardList, normalizeUpdateDashboardBody } from '../../dashboard';
import { FavoriteDomainService } from '../../favorite';
import { WorkbookDashboardListState } from '../models';
import { normalizeDashboardListApiModelToDashboardList, normalizePageParamsToQuery } from '../normalizers';
import { WorkbookDashboardListStore } from '../stores';

@Injectable({ providedIn: 'root' })
export class WorkbookDashboardListDomainService {
  loading$ = this.workbookDashboardListStore.loading$;
  errors$ = this.workbookDashboardListStore.errors$;
  loaded$ = this.workbookDashboardListStore.loaded$;

  constructor(
    private readonly favoriteDomainService: FavoriteDomainService,
    private readonly workbookDashboardListStore: WorkbookDashboardListStore,
    private readonly dashboardApiService: DashboardApiService,
  ) {}

  loadDashboardList(workbookId: string, pageParams?: PageParams): Observable<WorkbookDashboardListState> {
    this.workbookDashboardListStore.setLoaded(false);
    this.workbookDashboardListStore.setLoading(true);

    return this.dashboardApiService
      .getDashboardListByWorkbookId(workbookId, normalizePageParamsToQuery(pageParams))
      .pipe(
        map(normalizeDashboardListApiModelToDashboardList),
        map((dashboardList) => ({ ...dashboardList, workbookId })),
        tap((dashboardListState) => {
          this.workbookDashboardListStore.setLoaded(true);
          this.workbookDashboardListStore.upsertOneInCache(dashboardListState);
        }),
        catchError((err: unknown) => {
          this.workbookDashboardListStore.setLoaded(false);
          this.workbookDashboardListStore.createAndDispatch(EntityOp.QUERY_LOAD_ERROR);

          return throwError(() => err);
        }),
        finalize(() => {
          this.workbookDashboardListStore.setLoading(false);
        }),
      );
  }

  getDashboardList(workbookId: string): Observable<DashboardList> {
    return this.workbookDashboardListStore.entityMap$.pipe(map((entityMap) => entityMap[workbookId]));
  }

  updateOne(
    workbookId: string,
    dashboardId: string,
    dashboard: Partial<Dashboard>,
  ): Observable<void> {
    return combineLatest({
      updatedDashboard: this.dashboardApiService.updateDashboard(dashboardId, normalizeUpdateDashboardBody(dashboard)),
      dashboardList: this.getDashboardList(workbookId).pipe(
        take(1),
        tap((dashboardList) => {
          const needUpdatingDashboardIndex = dashboardList.dashboards.findIndex((d) => d.id === dashboardId);
          if (needUpdatingDashboardIndex !== -1) {
            const updatedDashboard = { ...dashboardList.dashboards[needUpdatingDashboardIndex], ...dashboard };
            const updatedDashboards = [
              ...dashboardList.dashboards.slice(0, needUpdatingDashboardIndex),
              updatedDashboard,
              ...dashboardList.dashboards.slice(needUpdatingDashboardIndex + 1),
            ];

            this.workbookDashboardListStore.updateOneInCache({ workbookId, dashboards: updatedDashboards });
          }
        }),
      ),
    }).pipe(map(() => void 0));
  }

  updateOneInCache(workbookId: string, dashboardId: string, dashboard: Partial<Dashboard>): void {
    this.getDashboardList(workbookId)
      .pipe(
        take(1),
        tap((dashboardList) => {
          const needUpdatingDashboardIndex = dashboardList.dashboards.findIndex((d) => d.id === dashboardId);
          if (needUpdatingDashboardIndex !== -1) {
            const updatedDashboard = { ...dashboardList.dashboards[needUpdatingDashboardIndex], ...dashboard };
            const updatedDashboards = [
              ...dashboardList.dashboards.slice(0, needUpdatingDashboardIndex),
              updatedDashboard,
              ...dashboardList.dashboards.slice(needUpdatingDashboardIndex + 1),
            ];

            this.workbookDashboardListStore.updateOneInCache({ workbookId, dashboards: updatedDashboards });
          }
        }),
      )
      .subscribe();
  }

  addToFavorite(workbookId: string, dashboardId: string): Observable<void> {
    return this.favoriteDomainService
      .addToFavorite('dashboards', dashboardId)
      .pipe(map(() => this.updateOneInCache(workbookId, dashboardId, { favorite: true })));
  }

  removeFromFavorite(workbookId: string, dashboardId: string): Observable<void> {
    return this.favoriteDomainService
      .removeFromFavorite('dashboards', dashboardId)
      .pipe(map(() => this.updateOneInCache(workbookId, dashboardId, { favorite: false })));
  }
}
