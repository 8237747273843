import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { Observable, Subject, filter, map, switchMap, takeUntil, tap } from 'rxjs';

import {
  BoardDataSource,
  BoardWidgetOptions,
  ChartSelectInfo,
  Dashboard,
  DashboardDomainService,
  Datasource,
  Filter,
  LayoutWidgetInfo,
  PageWidget,
  QueryParamsFilter,
  WIDGET_CONTEXT_ID,
  Widget,
  WidgetDomainService,
} from '@selfai-platform/bi-domain';

import { DashboardFiltersService } from '@selfai-platform/bi-chart-engine';
import { ConfigService, DestroyService } from '@selfai-platform/shared';
import { cloneDeep } from 'lodash';
import { EventBroadcaster } from '../common/event/event.broadcaster';
import { DashboardModule } from '../dashboard';
import { DashboardUtil } from '../dashboard/util/dashboard.util';
import { WidgetEmeddedApiConfigService } from './widget-embedded-api-config.service';
import { WidgetEmbeddedInputs } from './widget-embedded.model';
import { WidgetNoneIdentityProviderService } from './widget-none-identity-provider.service';

@Component({
    selector: 'selfai-bi-widget-embedded',
    templateUrl: './widget-embedded.component.html',
    styleUrls: ['./widget-embedded.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, TranslateModule, DashboardModule],
    providers: [DestroyService]
})
export class WidgetEmbeddedComponent implements WidgetEmbeddedInputs, OnChanges, OnInit, OnDestroy {
  @Input({ required: true }) widgetId: string;
  @Input() apiUrl: string;
  @Input() authorizationToken: string;
  @Input() lang?: 'en' | 'ru' = 'ru';
  @Input() filters?: QueryParamsFilter[];

  @Output() filtersChange = new EventEmitter<Filter[]>();
  @Output() selectionsChange = new EventEmitter<ChartSelectInfo>();

  widget$: Observable<PageWidget>;

  get globalFilters(): Filter[] | null {
    return this.filters?.map((filter) => this.normalizeFilter(filter)) || null;
  }

  private dashboard?: Dashboard;
  private resizeObserver: ResizeObserver;
  private prevFiltersChangeDestroy$ = new Subject<void>();

  constructor(
    private readonly widgetService: WidgetDomainService,
    private readonly dashboardDomainService: DashboardDomainService,
    @Inject(WIDGET_CONTEXT_ID) private readonly contextId: string,
    private readonly configService: ConfigService,
    private readonly widgetNoneIdentityProviderService: WidgetNoneIdentityProviderService,
    private readonly widgetEmeddedApiConfigService: WidgetEmeddedApiConfigService,
    private readonly dashboardFiltersService: DashboardFiltersService,
    private readonly translate: TranslateService,
    private readonly destroy$: DestroyService,
    private broadCaster: EventBroadcaster,
    private readonly elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    this.initializeResizeObserver();

    this.broadCaster
      .on('CHART_SELECTION_FILTER')
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ select }: { select: ChartSelectInfo }) => {
        this.selectionsChange.emit(select);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.apiUrl?.currentValue) {
      this.widgetEmeddedApiConfigService.setApiUrl(changes.apiUrl.currentValue);
      this.configService.updateConfig({
        knowledgeDesigner: {
          apiUrl: changes.apiUrl.currentValue,
        },
      });
    }

    if (changes.lang?.currentValue) {
      this.translate.use(changes.lang.currentValue);
    }

    if (changes.authorizationToken?.currentValue) {
      this.widgetNoneIdentityProviderService.setToken(changes.authorizationToken.currentValue);
    }

    if (changes.filters?.currentValue && this.dashboard) {
      this.setFilters(changes.filters.currentValue);
    }

    if (changes.widgetId?.currentValue) {
      this.widget$ = this.widgetService.loadWidget(changes.widgetId.currentValue, this.contextId).pipe(
        switchMap((widget) =>
          this.dashboardDomainService.loadDashboardByWidgetId(widget.id, this.contextId).pipe(
            tap((dashboard) => (this.dashboard = dashboard)),
            tap((dashboard) => {
              if (this.filters) {
                this.setFilters(this.filters);
              }
              this.initializeFiltersListener(dashboard.id);
            }),
            map((dashboard) => {
              const widgetDataSource: Datasource = DashboardUtil.getDataSourceFromBoardDataSource(
                dashboard,
                (widget as PageWidget).configuration.dataSource as BoardDataSource,
              );
              return {
                ...(widget as PageWidget),
                dashBoardId: dashboard.id,
                dataSources: [widgetDataSource],
              };
            }),
          ),
        ),
        map((widget) => cloneDeep(widget)),
      );
    }
  }

  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  getWidgetOptions(widget: Widget): Observable<BoardWidgetOptions> {
    return this.dashboardDomainService.getBoardWidgetOptionsByWidgetId(widget.id);
  }

  getWidgetInfo(widget: Widget): Observable<LayoutWidgetInfo> {
    return this.dashboardDomainService.getLayoutWidgetInfoByWidgetId(widget.id);
  }

  private setFilters(filters: QueryParamsFilter[]) {
    this.dashboardFiltersService.setDashboardFilters({
      dashboardId: this.dashboard.id,
      filters: filters.map((filter) => this.normalizeFilter(filter)),
      widgetId: this.widgetId,
    });
  }

  private normalizeFilter(inputFilter: QueryParamsFilter): Filter {
    return {
      field: inputFilter.fieldCode,
      valueList: Array.isArray(inputFilter.values) ? inputFilter.values : [inputFilter.values],
      selector: 'MULTI_LIST',
      type: 'include',
      dataSource: inputFilter.dataSource,
    };
  }

  private initializeResizeObserver(): void {
    this.resizeObserver = new ResizeObserver(() => {
      this.broadCaster.broadcast('RESIZE_WIDGET');
    });

    this.resizeObserver.observe(this.elementRef.nativeElement);
  }

  private initializeFiltersListener(dashboardId: string): void {
    // if we have previous subscription, we should destroy it
    this.prevFiltersChangeDestroy$.next();

    this.dashboardFiltersService
      .getDashboardFilters(dashboardId)
      .pipe(filter(Boolean), takeUntil(this.prevFiltersChangeDestroy$), takeUntil(this.destroy$))
      .subscribe(({ filters }) => {
        this.filtersChange.emit(filters);
      });
  }
}
