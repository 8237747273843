import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CodeEditorComponentModule } from '../../../code-editor';
import { DataSourcesCategoriesEnum } from '../../enums/data-sources-categories.enum';
import { DataSourcesAdditionsComponent } from '../data-source-additions/data-sources-additions.component';
import { DataSourceUniqueNameComponent, DataSourceVisibilityComponent } from '../data-source-form-inputs';
import { DataSourcesBasic } from '../data-sources-basic';

@Component({
    selector: 'selfai-platform-data-sources-db',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ButtonModule,
        CheckboxModule,
        InputTextModule,
        RadioButtonModule,
        PasswordModule,
        DataSourcesAdditionsComponent,
        DataSourceUniqueNameComponent,
        DataSourceVisibilityComponent,
        CodeEditorComponentModule,
    ],
    templateUrl: './data-sources-db.component.html',
    styleUrls: ['./data-sources-db.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataSourcesDbComponent extends DataSourcesBasic implements OnInit {
  dataSourcesCategoriesEnum = DataSourcesCategoriesEnum;
  paramsForm = this.fb.group({
    driver: ['', Validators.required],
    url: ['', Validators.required],
    sslEnabled: [false],
    selectedCategory: [this.dataSourcesCategoriesEnum.TABLE],
    categoryLabel: [''],
    query: [''],
    table: [''],
  });

  categories = [
    { label: 'Table name', id: this.dataSourcesCategoriesEnum.TABLE },
    { label: 'Custom SQL query', id: this.dataSourcesCategoriesEnum.QUERY },
  ];

  categoryLabel = 'Table name';

  public toggleCategoryControl(categoryId: DataSourcesCategoriesEnum): void {
    switch (categoryId) {
      case DataSourcesCategoriesEnum.QUERY:
        this.paramsForm.removeControl('table');
        this.paramsForm.addControl('query', new FormControl(''));
        break;
      case DataSourcesCategoriesEnum.TABLE:
        this.paramsForm.removeControl('query');
        this.paramsForm.addControl('table', new FormControl(''));
        break;
    }
  }

  constructor() {
    super('jdbc');
  }
}
