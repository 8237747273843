import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DestroyService, LocalStorageKey, PREFIX_SETTINGS_LOCAL_STORAGE_KEY } from '@selfai-platform/shared';
import {
  BreadcrumbsMenuItem,
  BreadcrumbsMenuService,
  KE_ROOT_ROUTE,
  KE_USER_FUNCTIONS_PATH,
} from '@selfai-platform/shell';
import { map, Observable, takeUntil } from 'rxjs';
import { UserFunctionsListComponent } from '../../components';
import { FunctionTag } from '../../models';
import { UserFunctionsTagsStore } from '../../store';

@Component({
    selector: 'selfai-platform-user-functions-container',
    imports: [CommonModule, UserFunctionsListComponent],
    templateUrl: 'user-functions-container.component.html',
    styleUrls: ['./user-functions-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: PREFIX_SETTINGS_LOCAL_STORAGE_KEY,
            useValue: LocalStorageKey.USER_FUNCTIONS_LIST_PREFIX_SETTINGS,
        },
    ]
})
export class UserFunctionsContainerComponent implements OnInit {
  public tags$: Observable<FunctionTag[]> = this.tagsStore.entities$ as Observable<FunctionTag[]>;

  public breadcrumbItems$: Observable<BreadcrumbsMenuItem[]> = this.translate.stream('shell.menu.user-functions').pipe(
    map((label) => [
      {
        label,
        routerLink: ['/', KE_ROOT_ROUTE, KE_USER_FUNCTIONS_PATH],
      },
    ]),
  );

  constructor(
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    private readonly translate: TranslateService,
    private readonly destroy$: DestroyService,
    private readonly tagsStore: UserFunctionsTagsStore,
  ) {}

  public ngOnInit(): void {
    this.tagsStore.getAll();

    this.breadcrumbItems$.pipe(takeUntil(this.destroy$)).subscribe((breadcrumbItems) => {
      this.breadcrumbsMenuService.setBreadcrumbsMenu(breadcrumbItems);
    });
  }
}
