import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { FormFieldComponentModule } from '../../../../form-field/form-field.module';
import { DataSourceSelectorComponent } from '../../data-source-selector';
import { DialogHeaderComponentModule } from '../../dialog-header';
import { WriteDataFrameComponent } from '../write-data-frame';

@Component({
    selector: 'selfai-platform-write-data-frame-transit',
    templateUrl: './write-data-frame-transit.component.html',
    styleUrl: './write-data-frame-transit.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        FormFieldComponentModule,
        DataSourceSelectorComponent,
        CheckboxModule,
        ReactiveFormsModule,
        TranslateModule,
        MessageModule,
        DialogHeaderComponentModule,
        DropdownModule,
    ]
})
export class WriteDataFrameTransitComponent extends WriteDataFrameComponent {}
