import { Injectable } from '@angular/core';
import { Observable, filter, map, tap } from 'rxjs';
import { RecycleBinApiToDomainService } from '../../recycle-bin';
import { Workspace } from '../models';
import { WorkspaceStore } from '../stores';

@Injectable({ providedIn: 'root' })
export class WorkspaceDomainService {
  constructor(
    private readonly recycleBinApiToDomainService: RecycleBinApiToDomainService,
    private readonly workspaceStore: WorkspaceStore,
  ) {}

  loadWorkspace(workspaceId: string): Observable<Workspace> {
    return this.workspaceStore.getByKey(workspaceId);
  }

  getWorkspace(workspaceId: string): Observable<Workspace> {
    return this.workspaceStore.entityMap$.pipe(
      map((entityMap) => entityMap?.[workspaceId]),
      filter(Boolean),
    );
  }

  getWorkspaceLoading(): Observable<boolean> {
    return this.workspaceStore.loading$;
  }

  getWorkspaceError(): Observable<unknown> {
    return this.workspaceStore.errors$;
  }

  updateWorkspace(workspaceId: string, workspace: Partial<Workspace>): Observable<Workspace> {
    return this.getWorkspace(workspaceId).pipe(
      map((currentWorkspace) => ({ ...currentWorkspace, ...workspace, id: workspaceId })),
      tap((updatedWorkspace) => {
        this.workspaceStore.update(updatedWorkspace);
      }),
    );
  }

  removeToTrash(id: string): Observable<unknown> {
    return this.recycleBinApiToDomainService.recycleBinWorkspace(id);
  }

  /**
   * @deprecated use loadWorkspace instead
   */
  loadWorkspaceWithPermission(id: string): Observable<Workspace> {
    return this.workspaceStore.getByKey(id);
  }
}
