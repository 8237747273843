import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { DestroyService, ExecuteWithPipe } from '@selfai-platform/shared';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { SkeletonModule } from 'primeng/skeleton';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { map, mergeMap, Observable } from 'rxjs';
import { THUMBNAIL_IMAGE_DEFAULT } from '../../constants';
import { DataListItemAction, DataListViewItem } from '../../models';
import { DataListViewPaginationComponent } from '../data-list-view-pagination';
import { DataListViewTableComponent } from '../data-list-view-table';

@Component({
    selector: 'selfai-platform-data-list-view-grid',
    templateUrl: './data-list-view-grid.component.html',
    styleUrls: ['./data-list-view-grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        FormsModule,
        TableModule,
        InputTextModule,
        ButtonModule,
        DataViewModule,
        TooltipModule,
        CheckboxModule,
        MenuModule,
        SkeletonModule,
        RouterModule,
        TranslateModule,
        ConfirmPopupModule,
        DropdownModule,
        DataListViewPaginationComponent,
        BlockUIModule,
        SkeletonModule,
        ExecuteWithPipe,
    ],
    providers: [DestroyService, ConfirmationService, DatePipe]
})
export class DataListViewGridComponent<T extends object & DataListViewItem> extends DataListViewTableComponent<T> {
  thumbnailImgDefault = THUMBNAIL_IMAGE_DEFAULT;
  selectedItemIds$ = this.selectedItemsService.getSelectedItemIds();

  getItemsMenu = (item: T): Observable<MenuItem[]> => {
    return this.getActionsForItem(item).pipe(
      mergeMap((actions) => this.getLabelsTranslation(actions).pipe(map((labels) => ({ actions, labels })))),
      map(({ actions, labels }) =>
        actions.map((action) => this.mapActionsToMenuItems({ ...action, label: labels[action.labelTranslate] }, item)),
      ),
    );
  };

  private getLabelsTranslation(actions: DataListItemAction[]): Observable<Record<string, string>> {
    return this.translate.stream(actions.map((action) => action.labelTranslate));
  }

  private mapActionsToMenuItems<T extends object & DataListViewItem>(
    action: DataListItemAction & { label: string },
    item: T,
  ): MenuItem {
    return {
      label: action.label,
      icon: action.icon,
      disabled: action.disabled,
      command: (event) => action.action(event.originalEvent.target),
    };
  }
}
