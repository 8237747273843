import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';

import { AbstractComponent } from '../abstract.component';

@Component({
    selector: 'component-paging-select',
    templateUrl: './paging-select.component.html',
    host: {
        '(document:click)': 'onClickHost($event)',
    },
    standalone: false
})
export class PagingSelectComponent extends AbstractComponent implements OnInit {
  public array: any[];

  @Input('array')
  set setArray(array: any) {
    this.array = array;

    this.selectedItem = null;

    this.setSelectedItem();
  }

  @Input() public viewKey: string;

  @Input() public isUpSelect = false;

  public defaultIndex = -1;

  @Input('defaultIndex')
  set setDefaultIndex(index: number) {
    this.defaultIndex = index;

    if (this.array && this.array.hasOwnProperty('length') && this.array.length > 0) {
      if (this.defaultIndex > -1) {
        this.selectedItem = this.array[this.defaultIndex];
      } else {
        this.selectedItem = null;
      }
    }
  }

  @Input() public usePlaceholder = false;

  @Input() public unselectedMessage = 'Please select';

  @Input() public disabled = false;

  @Input() public isOptionToLeft = false;

  @Output() public onSelected = new EventEmitter();

  @Output() public onLoadPage = new EventEmitter<number>();

  public selectedItem: any;

  public isShowOptions = false;

  public pageNum = 0;

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public selectItem(item: any) {
    this.selectedItem = item;

    this.onSelected.emit(item);
  }

  public getSelectedItem() {
    return this.selectedItem;
  }

  public onClickHost(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isShowOptions = false;
    }
  }

  public setDisable() {
    this.disabled = true;
  }

  public setEnable() {
    this.disabled = false;
  }

  public onOffShowOptions() {
    this.isShowOptions = !this.isShowOptions;
    if (this.isShowOptions) {
      this.pageNum = 0;
      this.onLoadPage.emit(this.pageNum);
    }
  }

  public onScroll() {
    this.onLoadPage.emit(this.pageNum++);
  }

  private setSelectedItem() {
    if (
      !this.selectedItem &&
      this.usePlaceholder === false &&
      this.array &&
      this.array.hasOwnProperty('length') &&
      this.array.length > 0
    ) {
      this.selectedItem = this.array[0];
      if (this.defaultIndex > -1) {
        this.selectedItem = this.array[this.defaultIndex];
      }
    }
  }
}
