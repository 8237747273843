import { Component, ElementRef, Injector } from '@angular/core';

import { BaseOptionComponent } from './base-option.component';
@Component({
    selector: 'legend-option',
    templateUrl: './legend-option.component.html',
    standalone: false
})
export class LegendOptionComponent extends BaseOptionComponent {
  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }
}
