import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BehaviorSubject, catchError, debounceTime, forkJoin, startWith, switchMap, takeUntil } from 'rxjs';

import { Recycle } from '@selfai-platform/bi-api';
import { RecycleBinApiToDomainService } from '@selfai-platform/bi-domain';
import { DestroyService } from '@selfai-platform/shared';
import {
  BreadcrumbsMenuService,
  KNOWLEDGE_DESIGNER_ROOT_ROUTE,
  PATH_RECYCLEBIN_ADMIN,
  convertMessageToHtml,
  getUiSettingsDefaultPageSizes,
} from '@selfai-platform/shell';

interface IPage {
  number: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

@Component({
    selector: 'selfai-platform-bi-ui-recycle',
    templateUrl: './recycle-bin.component.html',
    styleUrls: ['./recycle-bin.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class RecycleBinComponent implements OnInit, OnDestroy {
  selectedElement: Recycle[] = [];

  form = new FormGroup({
    search: new FormControl(''),
    page: new FormControl(0),
    size: new FormControl(12),
  });

  recycledBin$ = new BehaviorSubject<Recycle[]>([]);

  page$ = new BehaviorSubject<IPage>({
    number: 0,
    size: 0,
    totalElements: 0,
    totalPages: 0,
  });

  tableConfig: { key: string; label: string; width: string }[] = [
    { key: 'name', label: this.translateService.instant('recycle.ui.name'), width: 'calc(100% - 55rem)' },
    { key: 'domain', label: this.translateService.instant('recycle.ui.type'), width: '13rem' },
    { key: 'createdTime', label: this.translateService.instant('recycle.ui.created'), width: '10rem' },
    { key: 'createdBy', label: this.translateService.instant('recycle.ui.author'), width: '9rem' },
    { key: 'modifiedTime', label: this.translateService.instant('recycle.ui.removed'), width: '10rem' },
    { key: 'modifiedBy', label: this.translateService.instant('recycle.ui.removed.by'), width: '9rem' },
  ];

  loading = true;

  pageSizes = getUiSettingsDefaultPageSizes();

  get isAdmin(): boolean {
    // TODO: bad practice use this condition
    return this.router.url.includes(PATH_RECYCLEBIN_ADMIN);
  }

  constructor(
    private readonly recycleBinService: RecycleBinApiToDomainService,
    private readonly confirmationService: ConfirmationService,
    private readonly messageService: MessageService,
    private readonly translateService: TranslateService,
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    private readonly router: Router,
    private readonly destroy$: DestroyService,
  ) {}

  ngOnInit(): void {
    this.breadcrumbsMenuService.setBreadcrumbsMenu(this.getBreadcrumbs(this.isAdmin));
    this.loadData();
    this.watchChangeSize();
  }

  ngOnDestroy(): void {
    this.breadcrumbsMenuService.clearBreadcrumbsMenu();
  }

  onPageChange(page: number): void {
    this.loading = true;

    this.form.patchValue({ page });
  }

  removePermanently(): void {
    this.confirmationService.confirm({
      message: convertMessageToHtml(this.translateService.instant('msg.alert.delete.confirmation')),
      header: this.translateService.instant('msg.alert.delete.title'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const observables = this.selectedElement.map((element) => this.recycleBinService.removePermanently(element.id));
        forkJoin(observables)
          .pipe(
            catchError((e) => {
              this.messageService.add({
                severity: 'error',
                summary: this.translateService.instant('msg.alert.delete.title'),
                detail: e.error?.message,
              });

              return [];
            }),
            takeUntil(this.destroy$),
          )
          .subscribe(() => {
            this.updateRecycleBin();
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('msg.alert.delete.title'),
              detail: this.translateService.instant('msg.alert.delete.success'),
            });
          });
      },
    });
  }

  recover(): void {
    this.confirmationService.confirm({
      message: this.translateService.instant('msg.alert.restore.confirmation'),
      header: this.translateService.instant('msg.alert.restore.title'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const observables = this.selectedElement.map((element) => this.recycleBinService.recover(element.id));
        forkJoin(observables)
          .pipe(
            catchError((e) => {
              this.messageService.add({
                severity: 'error',
                summary: this.translateService.instant('msg.alert.restore.title'),
                detail: e.error?.message,
              });

              return [];
            }),
            takeUntil(this.destroy$),
          )
          .subscribe(() => {
            this.updateRecycleBin();
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant('msg.alert.restore.title'),
              detail: this.translateService.instant('msg.alert.restore.success'),
            });
          });
      },
    });
  }

  private watchChangeSize(): void {
    this.form
      .get('size')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.form.patchValue({ page: 0 });
      });
  }

  private loadData(): void {
    this.loading = true;

    this.form.valueChanges
      .pipe(
        startWith(this.form.value),
        debounceTime(300),
        switchMap(() => {
          const formRaw = this.form.getRawValue();

          return this.isAdmin ? this.recycleBinService.getAll(formRaw) : this.recycleBinService.forCurrentUser(formRaw);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((recycleBin) => {
        const { _embedded: embedded, page } = recycleBin;
        this.recycledBin$.next(embedded?.recycleBinItems ?? []);
        this.page$.next(page);
        this.loading = false;
      });
  }

  private updateRecycleBin(): void {
    const selectedElement = this.selectedElement;
    const recycledBin = this.recycledBin$.getValue().filter((element) => {
      return !selectedElement.some((selected) => selected.id === element.id);
    });
    this.recycledBin$.next(recycledBin);
    this.selectedElement = [];
  }

  private getBreadcrumbs(isAdmin: boolean) {
    if (isAdmin) {
      return [
        {
          label: this.translateService.instant('recycle.ui.breadcrumbs.admin-title'),
          routerLink: ['/', KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'recyclebin-admin'],
        },
      ];
    }

    return [
      {
        label: this.translateService.instant('recycle.ui.breadcrumbs.title'),
        routerLink: ['/', KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'recyclebin-user'],
      },
    ];
  }
}
