import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogService } from '@selfai-platform/shell';

@Component({
    selector: 'selfai-platform-add-workbook-dialog',
    templateUrl: './add-workbook-dialog.component.html',
    styleUrls: ['./add-workbook-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AddWorkbookDialogComponent {
  form = new FormGroup<{
    name: FormControl<string>;
  }>({
    name: new FormControl('', [Validators.required, Validators.maxLength(255)]),
  });

  constructor(
    private readonly dialogService: DialogService<{
      wasSaved: boolean;
      name?: string;
    }>,
  ) {}

  close() {
    this.dialogService.close({
      wasSaved: false,
    });
  }

  save() {
    this.dialogService.close({
      wasSaved: true,
      name: this.form.value.name.trim(),
    });
  }
}
