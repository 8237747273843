<form class="cluster-configuration-form pt-4" [formGroup]="form">
  <div class="p-float-label mb-5 flex align-items-center">
    <textarea rows="1" class="w-full" pInputTextarea [autoResize]="true" formControlName="params"></textarea>
    <i
      [pTooltip]="
        'workflow.component.cluster-configuration-form.control.additional-application-parameters.tooltip' | translate
      "
      class="ml-2 pi pi-info-circle"
    ></i>
    <label class="cluster-params-label">
      {{ 'workflow.component.cluster-configuration-form.control.additional-application-parameters' | translate }}
    </label>
  </div>

  <div *ngIf="!isShort" class="p-float-label mb-5 flex align-items-center">
    <input class="w-full" pInputText formControlName="name" />
    <label>{{ 'workflow.component.cluster-configuration-form.control.preset-name' | translate }}</label>
  </div>

  <div class="p-float-label mb-5 flex align-items-center">
    <input class="w-full" pInputText formControlName="userIP" />
    <i
      [pTooltip]="'workflow.component.cluster-configuration-form.control.driver-hostname.tooltip' | translate"
      class="ml-2 pi pi-info-circle"
    ></i>
    <label>{{ 'workflow.component.cluster-configuration-form.control.driver-hostname' | translate }}</label>
  </div>

  <div class="mb-5 flex flex-row flex-nowrap gap-5 align-items-baseline">
    <div class="flex align-items-center w-full p-float-label">
      <p-dropdown
        class="w-full"
        optionLabel="name"
        optionValue="value"
        [options]="clusterType"
        formControlName="clusterType"
        inputId="float-label-cluster"
      ></p-dropdown>
      <label for="float-label-cluster">
        {{ 'workflow.component.cluster-configuration-form.control.cluster-type' | translate }}
      </label>
    </div>
    <div *ngIf="!isShort">
      <p-checkbox formControlName="isDefault" value="isDefault" inputId="isDefault" [binary]="true" />
      <label class="ml-3" for="isDefault">
        {{ 'workflow.component.cluster-configuration-form.control.default' | translate }}
      </label>
    </div>
    <div *ngIf="!isShort">
      <p-checkbox formControlName="isEditable" value="isEditable" inputId="isEditable" [binary]="true" />
      <label class="ml-3" for="isEditable">
        {{ 'workflow.component.cluster-configuration-form.control.editable' | translate }}
      </label>
    </div>
  </div>

  <div class="p-float-label mb-5 flex align-items-center">
    <input class="w-full" pInputText formControlName="uri" />
    <i
      [pTooltip]="'workflow.component.cluster-configuration-form.control.uri.tooltip' | translate"
      class="ml-2 pi pi-info-circle"
    ></i>
    <label>{{ 'workflow.component.cluster-configuration-form.control.uri' | translate }}</label>
  </div>

  <div class="p-float-label mb-5 flex align-items-center">
    <input class="w-full" pInputText formControlName="driverMemory" />
    <i
      [pTooltip]="'workflow.component.cluster-configuration-form.control.driver-memory.tooltip' | translate"
      class="ml-2 pi pi-info-circle"
    ></i>
    <label>{{ 'workflow.component.cluster-configuration-form.control.driver-memory' | translate }}</label>
  </div>

  <div class="p-float-label mb-5 flex align-items-center">
    <input class="w-full" pInputText formControlName="executorMemory" />
    <i
      [pTooltip]="'workflow.component.cluster-configuration-form.control.executor-memory.tooltip' | translate"
      class="ml-2 pi pi-info-circle"
    ></i>
    <label>{{ 'workflow.component.cluster-configuration-form.control.executor-memory' | translate }}</label>
  </div>

  <div class="p-float-label mb-5 flex align-items-center">
    <input class="w-full" pInputText type="number" formControlName="executorCores" />
    <i
      [pTooltip]="'workflow.component.cluster-configuration-form.control.executor-cores.tooltip' | translate"
      class="ml-2 pi pi-info-circle"
    ></i>
    <label>{{ 'workflow.component.cluster-configuration-form.control.executor-cores' | translate }}</label>
  </div>

  <div class="p-float-label mb-5 flex align-items-center">
    <input class="w-full" pInputText type="number" formControlName="numExecutors" />
    <i
      [pTooltip]="'workflow.component.cluster-configuration-form.control.number-of-executors.tooltip' | translate"
      class="ml-2 pi pi-info-circle"
    ></i>
    <label>{{ 'workflow.component.cluster-configuration-form.control.number-of-executors' | translate }}</label>
  </div>

  <div class="p-float-label mb-5 flex align-items-center">
    <input class="w-full" pInputText type="number" formControlName="totalExecutorCores" [readonly]="true" />
    <i
      [pTooltip]="'workflow.component.cluster-configuration-form.control.total-executor-cores.tooltip' | translate"
      class="ml-2 pi pi-info-circle"
    ></i>
    <label>{{ 'workflow.component.cluster-configuration-form.control.total-executor-cores' | translate }}</label>
  </div>

  <div *ngIf="!isShort" class="p-float-label mb-5 flex align-items-center">
    <input class="w-full" pInputText formControlName="hadoopUser" />
    <label>{{ 'workflow.component.cluster-configuration-form.control.hadoop-user' | translate }}</label>
  </div>
  <div *ngIf="form.get('clusterType').value && !isClusterTypeLocal" class="flex align-items-center w-full p-float-label mb-4">
    <p-dropdown
      id="float-label-bus-type"
      class="w-full"
      optionLabel="name"
      optionValue="value"
      [options]="busType"
      formControlName="busType"
      inputId="float-label-bus-type"
    ></p-dropdown>
    <label for="float-label-bus-type">
      {{ 'workflow.component.cluster-configuration-form.control.bus-type' | translate }}
    </label>
  </div>

  <div *ngIf="form.get('clusterType').value && !isClusterTypeLocal" class="mb-5 flex align-items-center">
    <div class="mr-4">
      <p-checkbox
        class="mr-2"
        formControlName="isInteractive"
        value="isInteractive"
        id="isInteractive"
        [binary]="true"
      />
      <label for="isInteractive">
        {{ 'workflow.component.cluster-configuration-form.control.is-interactive' | translate }}
      </label>
    </div>

    <div *ngIf="form.get('clusterType').value && !isClusterTypeLocal">
      <p-checkbox
        class="mr-2"
        formControlName="isStandalone"
        value="isStandalone"
        id="isStandalone"
        [binary]="true"
      />
      <label for="isStandalone">
        {{ 'workflow.component.cluster-configuration-form.control.is-standalone' | translate }}
      </label>
    </div>
  </div>

  <div *ngIf="form.get('clusterType').value && !isClusterTypeLocal" class="flex align-items-center w-full p-float-label mb-5">
    <p-dropdown
      id="float-label-job-type"
      class="w-full"
      optionLabel="name"
      optionValue="value"
      [options]="jobType"
      formControlName="jobType"
      inputId="float-label-job-type"
    ></p-dropdown>
    <label for="float-label-job-type">
      {{ 'workflow.component.cluster-configuration-form.control.job-type' | translate }}
    </label>
  </div>

  <div *ngIf="form.get('clusterType').value && !isClusterTypeLocal" class="p-float-label mb-5 flex align-items-center">
    <input class="w-full" pInputText formControlName="uriApi" />
    <label>{{ 'workflow.component.cluster-configuration-form.control.uri-api' | translate }}</label>
  </div>

  <div *ngIf="form.get('clusterType').value && !isClusterTypeLocal" class="p-float-label mb-5 flex align-items-center">
    <input class="w-full" pInputText formControlName="workflowDir" />
    <label>{{ 'workflow.component.cluster-configuration-form.control.workflow-dir' | translate }}</label>
  </div>
</form>

<p-toolbar aria-label="Actions">
  <ng-template pTemplate="start">
    <p-button
      class="mr-2"
      label="{{ 'workflow.component.cluster-configuration-form.button.cancel' | translate }}"
      [outlined]="true"
      severity="secondary"
      (click)="back()"
    ></p-button>
    <p-button
      [disabled]="disabled"
      label="{{ 'workflow.component.cluster-configuration-form.button.save' | translate }}"
      (click)="submit()"
    ></p-button>
  </ng-template>
</p-toolbar>
