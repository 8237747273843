import { Component, ElementRef, Injector, Input } from '@angular/core';

import { AbstractComponent } from '../../../../common/component/abstract.component';

@Component({
    selector: 'analysis-cluster',
    templateUrl: './analysis-cluster.component.html',
    standalone: false
})
export class AnalysisClusterComponent extends AbstractComponent {
  @Input()
  public dataSubLayerKey = '';

  public sectionList = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

  public clusterList = [];

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public clickDataSubPanel(dataSubLayerKey: string, event?: Event) {
    event.stopPropagation();

    if (this.dataSubLayerKey === dataSubLayerKey) {
      this.dataSubLayerKey = '';
    } else {
      this.dataSubLayerKey = dataSubLayerKey;
    }
  }

  public onChangeSection(section: any) {
    const sectionInt = parseInt(section);

    this.clusterList = [];

    for (let num = 0; num < sectionInt; num++) {
      this.clusterList.push(num);
    }
  }
}
