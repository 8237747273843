import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, take } from 'rxjs';
import { KeDataSourcesLibraryStore } from '../../store/data-sources-library.store';
import {
  DataSourcesLibraryDataFrameListComponent,
} from '../../components/data-sources-library-data-frame-list/data-sources-library-data-frame-list.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { PipelineConfigService } from '@selfai-platform/shared';
import { DataSourcesApiService } from '../../services/data-sources-api.service';

@Component({
    selector: 'selfai-platform-data-sources-library-files-list-container',
    imports: [CommonModule, DataSourcesLibraryDataFrameListComponent],
    templateUrl: './data-sources-library-files-list-container.component.html',
    styleUrl: './data-sources-library-files-list-container.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataSourcesLibraryFilesListContainerComponent implements AfterViewInit {
  loading$: Observable<boolean>;
  libraries: Observable<any>;
  config = this.pipelineConfigService.getConfig();
  apiUrl = `${this.config.hosts.api}`;

  constructor(
    public keDataSourcesLibraryStore: KeDataSourcesLibraryStore,
    private dynamicDialogRef: DynamicDialogRef,
    private pipelineConfigService: PipelineConfigService,
    private readonly datasourcesApiService: DataSourcesApiService
  ) {
    this.libraries = keDataSourcesLibraryStore.entities$;
    this.loading$ = keDataSourcesLibraryStore.loading$;
  }

  ngAfterViewInit(): void {
    this.keDataSourcesLibraryStore.getAll();
  }

  onSelectFile(name: string): void {
    this.dynamicDialogRef.close(name);
  }

  onFileDownload(name: string): void {
    this.datasourcesApiService.getFileFromApi(name).pipe(take(1)).subscribe((response) => {
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(new Blob([response.body], { type: response.body.type }));
      downloadLink.download = name;
      downloadLink.click();
    })
  }

  onFileUpload(data: { files: File[] }): void {
    this.keDataSourcesLibraryStore.add(data.files as any);
  }

  onDelete(name: string): void {
    this.keDataSourcesLibraryStore.delete(name);
  }
}
