import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  GUEST_WORKSPACE_ID,
  ImportWorkbookMappedDatasource,
  WorkbookDomainService,
  WorkbookImport,
  WorkbookImportDomainService,
  WorkbookListBreadcrumbService,
  WorkbookListDomainService,
  WorkbookListItem,
  Workspace,
  WorkspaceDomainService,
} from '@selfai-platform/bi-domain';
import { AlertService, BiEntityPermission, BiPermission, PermissionService } from '@selfai-platform/shared';
import {
  BI_ROOT_ROUTE,
  BreadcrumbsMenuService,
  DialogService,
  convertMessageToHtml,
  GroupAction,
  KNOWLEDGE_DESIGNER_ROOT_ROUTE,
  SelectedItemsService,
  ToolbarAction,
} from '@selfai-platform/shell';
import { ConfirmationService } from 'primeng/api';
import {
  EMPTY,
  Observable,
  Subscription,
  catchError,
  forkJoin,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import {
  WorkspaceEditNameDialogComponent,
  WorkspaceEditNameDialogInputData,
  WorkspaceEditNameDialogResult,
} from '../../../workspace';
import { AddWorkbookDialogComponent, AddWorkbookDialogResult } from '../add-workbook-dialog';
import { DatasourceListDialogComponent } from '../datasource-list-dialog';
import { WorkbookImportFormComponent } from '../workbook-import-form';
import { WorkbookDataListViewService } from './workbook-data-list-view.service';

@Injectable()
export class WorkbookListComponentService implements OnDestroy {
  private workspaceId$ = this.workbookDataListViewService.workspaceId$;
  private workspace$ = this.workbookDataListViewService.workspace$;

  private subscription = new Subscription();

  constructor(
    private readonly workbookDataListViewService: WorkbookDataListViewService,
    private readonly workbookDomainService: WorkbookDomainService,
    private readonly workbookListDomainService: WorkbookListDomainService,
    private readonly workspaceDomainService: WorkspaceDomainService,
    private readonly workbookListBreadcrumbService: WorkbookListBreadcrumbService,
    private readonly workbookImportDomainService: WorkbookImportDomainService,
    private readonly alertService: AlertService,
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    private readonly translate: TranslateService,
    private readonly permissionService: PermissionService,
    private readonly confirmationService: ConfirmationService,
    private readonly selectedItemsService: SelectedItemsService<WorkbookListItem>,
    private readonly addWorkbookdialogService: DialogService<AddWorkbookDialogResult>,
    private readonly dataSourceDialogService: DialogService<undefined, Workspace>,
    private readonly editNameDialogService: DialogService<
      WorkspaceEditNameDialogResult,
      WorkspaceEditNameDialogInputData
    >,
    private readonly importWorkbookDialogService: DialogService<{
      workBook: WorkbookImport;
      mappedDatasources: { [oldDatasourceId: string]: ImportWorkbookMappedDatasource };
    }>,
    private readonly router: Router,
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggleFavorite({ itemId, isFavorite }: { itemId: string; isFavorite: boolean }): void {
    this.subscription.add(
      this.workspaceId$
        .pipe(
          switchMap((workspaceId) => {
            if (isFavorite) {
              return this.workbookListDomainService.addToFavorite({ workspaceId, workbookId: itemId });
            } else {
              return this.workbookListDomainService.deleteFromFavorite({ workspaceId, workbookId: itemId });
            }
          }),
          take(1),
        )
        .subscribe(),
    );
  }

  getActions(): Observable<ToolbarAction[]> {
    return this.workspace$.pipe(
      map((workspace) => {
        const actions: ToolbarAction[] = [];

        if (this.hasPermissionForEntity(workspace, BiEntityPermission.WORKBOOK_CREATE)) {
          actions.push({
            icon: 'pi pi-plus-circle',
            labelTranslate: 'msg.workbook-toolbar.menu.add-workbook',
            buttonClass: 'p-button-success',
            action: () => this.createWorkbook(workspace),
          });
        }

        if (this.hasPermission(BiPermission.KD_WORKSPACE_EDIT_ALL)) {
          if (workspace.id !== GUEST_WORKSPACE_ID) {
            actions.push({
              labelTranslate: 'msg.workbook-toolbar.menu.edit-name',
              icon: 'pi pi-pencil',
              hideInMore: true,
              action: () => this.showEditNameDialog(workspace),
            });
          }
        }

        if (this.hasPermission(BiPermission.KD_WORKSPACE_EDIT_ALL) && workspace.id !== GUEST_WORKSPACE_ID) {
          actions.push({
            labelTranslate: 'msg.workbook-toolbar.menu.manage-permissions',
            icon: 'pi pi-user-edit',
            hideInMore: true,
            action: () => this.goToEditAccessPage(workspace),
          });
        }

        if (this.hasPermissionForEntity(workspace, BiEntityPermission.WORKBOOK_CREATE)) {
          actions.push({
            labelTranslate: 'msg.workbook-toolbar.menu.import-workbook',
            icon: 'pi pi-download',
            hideInMore: true,
            action: () => this.importWorkbookToWorkspace(),
          });
        }

        if (this.hasPermissionForEntity(workspace, BiEntityPermission.WORKSPACE_DATASOURCES_VIEW)) {
          actions.push({
            labelTranslate: 'msg.workbook-toolbar.menu.datasource-list',
            icon: 'pi pi-database',
            hideInMore: true,
            action: () => this.showDataSource(workspace),
          });
        }

        return actions;
      }),
    );
  }

  getGroupActions(): Observable<GroupAction[]> {
    return this.workspace$.pipe(
      map((workspace) => {
        const actions: GroupAction[] = [];

        if (this.hasPermissionForEntity(workspace, BiEntityPermission.WORKBOOK_CREATE)) {
          actions.push({
            icon: 'pi pi-clone',
            labelTranslate: 'msg.workbook-list.table-actions.duplicate-selected',
            action: () => this.duplicateSelected(),
          });
        }

        if (this.hasPermissionForEntity(workspace, BiEntityPermission.WORKBOOK_DELETE)) {
          actions.push({
            icon: 'pi pi-trash',
            buttonClass: 'p-button-danger',
            labelTranslate: 'msg.workbook-list.table-actions.remove-selected',
            action: (eventTarget) => this.removeSelected(eventTarget),
          });
        }

        return actions;
      }),
    );
  }

  private hasPermission(permission: BiPermission): boolean {
    return this.permissionService.hasBiPermission(permission);
  }

  private hasPermissionForEntity(entity: Workspace, permission: BiEntityPermission): boolean {
    return entity.permissions?.includes(permission);
  }

  private createWorkbook(workspace: Workspace): void {
    this.showAddWorkbookDialog(workspace);
  }

  private showAddWorkbookDialog(workspace: Workspace): void {
    this.subscription.add(
      this.addWorkbookdialogService
        .showDialog(AddWorkbookDialogComponent, {
          width: '500px',
          header: this.translate.instant('msg.add-workbook-dialog.header'),
        })
        .pipe(
          switchMap((result) => {
            if (result.wasSaved) {
              return this.workbookDomainService.addWorkbook(workspace.id, { name: result.name }).pipe(
                tap((newWorkbook) => {
                  // TODO: for compability with legacy form of creating workbook
                  if (this.hasPermissionForEntity(workspace, BiEntityPermission.DASHBOARD_CREATE)) {
                    sessionStorage.setItem('AFTER_CREATE_WORKBOOK', 'CREATE_DASHBOARD');
                  }

                  this.router.navigate(['/', BI_ROOT_ROUTE, 'workbook', newWorkbook.id]);
                }),
              );
            }

            return EMPTY;
          }),
        )
        .subscribe(),
    );
  }

  private duplicateSelected(): void {
    this.subscription.add(
      this.selectedItemsService
        .getSelectedItemIds()
        .pipe(
          mergeMap((selectedItemIds) =>
            selectedItemIds.map((workbookId) => this.workbookDomainService.copyWorkbook(workbookId)),
          ),
        )
        .pipe(tap(() => this.workbookDataListViewService.triggerReloadData()))
        .subscribe(),
    );
  }

  private removeSelected(eventTarget: EventTarget): void {
    this.confirmationService.confirm({
      target: eventTarget,
      message: convertMessageToHtml(this.translate.instant('msg.workbook-dashboard-list.delete-selected.confirmation')),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.subscription.add(
          this.selectedItemsService
            .getSelectedItemIds()
            .pipe(
              mergeMap((selectedItemIds) =>
                forkJoin(
                  selectedItemIds.map((workbookId) =>
                    this.workbookDomainService.removeToTrash(workbookId).pipe(catchError(() => of(null))),
                  ),
                ).pipe(),
              ),
              take(1),
              tap(() => this.workbookDataListViewService.triggerReloadData()),
            )
            .subscribe(() => {
              this.selectedItemsService.resetSelectedItems();
            }),
        );
      },
    });
  }

  private importWorkbookToWorkspace(): void {
    this.showImportWorkbookDialog();
  }

  private showImportWorkbookDialog(): void {
    this.subscription.add(
      this.importWorkbookDialogService
        .showDialog(WorkbookImportFormComponent, {
          header: this.translate.instant('msg.space.import.workbook'),
          width: '50%',
        })
        .subscribe((data) => {
          if (data?.workBook) {
            this.workbookImportDomainService
              .createWorkbookFromImportData(data)
              .pipe(
                catchError((e) => {
                  const errorMessage = this.translate.instant('msg.space.import.workbook.error');
                  this.alertService.error(errorMessage);

                  return throwError(() => e);
                }),
              )
              .subscribe({
                next: (workbookId?: string) => {
                  if (workbookId) {
                    this.router.navigate(['/', BI_ROOT_ROUTE, 'workbook', workbookId]);
                  }
                },
              });
          }
        }),
    );
  }

  private showDataSource(targetWorkspace: Workspace): void {
    this.showDataSourceDialog(targetWorkspace);
  }

  private showDataSourceDialog(workspace: Workspace): void {
    this.subscription.add(
      this.dataSourceDialogService
        .showDialog(DatasourceListDialogComponent, {
          data: workspace,
          width: '90%',
          position: 'top',
          header: `${this.translate.instant('msg.workbook-toolbar.menu.datasource-workspace')} ${workspace.name}`,
        })
        .subscribe(),
    );
  }

  private showEditNameDialog(workspace: Workspace): void {
    this.subscription.add(
      this.editNameDialogService
        .showDialog(WorkspaceEditNameDialogComponent, {
          data: workspace,
          header: this.translate.instant('msg.workspace-edit-name-dialog.header'),
          width: '500px',
          styleClass: 'workspace-edit-name-dialog',
        })
        .pipe(
          switchMap((result) => {
            if (result.wasSaved && result.newData) {
              return this.workspaceDomainService
                .updateWorkspace(workspace.id, {
                  name: result.newData.name,
                  description: result.newData.description,
                })
                .pipe(take(1));
            }

            return EMPTY;
          }),
        )
        .subscribe(),
    );
  }

  private goToEditAccessPage(workspace: Workspace): void {
    this.router.navigate(['/', KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'workspace', workspace.id, 'manage-roles']);
  }
}
