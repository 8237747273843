import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CodeLanguage, WorkflowInfo } from '@selfai-platform/pipeline-common';
import {
  BreadcrumbsMenuItem,
  BreadcrumbsMenuService,
  KE_ROOT_ROUTE,
  KE_USER_FUNCTIONS_PATH,
} from '@selfai-platform/shell';
import { TreeNode } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { Button } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TreeSelectModule } from 'primeng/treeselect';
import { combineLatest } from 'rxjs';
import { CodeEditorComponentModule } from '../../../code-editor';
import { UserFunctionsTypesList } from '../../lists';
import { FunctionScope, FunctionTag, Namespace, UserFunctionsListItem } from '../../models';
import { UserFunctionsPermissionService } from '../../services';
import { UserFunctionsControls } from '../../static';

@Component({
    selector: 'selfai-platform-user-function',
    templateUrl: './user-function.component.html',
    styleUrls: ['./user-function.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CodeEditorComponentModule,
        PaginatorModule,
        ReactiveFormsModule,
        Button,
        InputTextModule,
        PanelModule,
        RadioButtonModule,
        NgIf,
        MultiSelectModule,
        CheckboxModule,
        InputTextareaModule,
        ChipsModule,
        AutoCompleteModule,
        TreeSelectModule,
        TranslateModule,
    ]
})
export class UserFunctionComponent implements OnInit, OnChanges {
  public functionTypes = UserFunctionsTypesList;
  public id: string = this.activatedRoute?.snapshot?.params['id'];
  public editMode = !!this.id;
  public userFunctionForm: FormGroup;
  public isGlobal: boolean;
  public scopeList: TreeNode[] = [];
  public selectedTags: FunctionTag[] = [];
  public breadcrumbItems: BreadcrumbsMenuItem[] = [
    {
      label: this.translate.instant('shell.menu.user-functions'),
      routerLink: ['/', KE_ROOT_ROUTE, KE_USER_FUNCTIONS_PATH],
    },
  ];
  public canUpdate: boolean = true;

  @Input() userFunctions: UserFunctionsListItem[];
  @Input() currentUserFunction: UserFunctionsListItem;
  @Input() workflows: WorkflowInfo[];
  @Input() namespaces: Namespace[];
  @Input() tags: FunctionTag[];

  @Output() backEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() submitEvent: EventEmitter<UserFunctionsListItem> = new EventEmitter<UserFunctionsListItem>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    private readonly userFunctionsPermissionsService: UserFunctionsPermissionService,
    private readonly router: Router,
  ) {}

  protected readonly codeLanguage = CodeLanguage;

  public createForm(): void {
    this.userFunctionForm = this.fb.group({
      name: new FormControl({ value: '', disabled: true }),
      body: new FormControl('', [Validators.required]),
      scopes: new FormControl(),
      functionType: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      tags: new FormControl(),
    });
  }

  public addTag(event: Event): void {
    if (!(<HTMLInputElement>event.target).value.length) {
      return;
    }
    const isExist = this.tags.find((tag) => tag.name === (<HTMLInputElement>event.target).value);
    if (!isExist) {
      this.tags.push({ name: (<HTMLInputElement>event.target).value });
    } else {
      return;
    }
    let values: { name: string }[];
    if (this.userFunctionForm.controls['tags'].value) {
      values = [...this.userFunctionForm.controls['tags'].value];
    } else {
      values = [];
    }
    values.push({ name: (<HTMLInputElement>event.target).value });
    this.userFunctionForm.controls['tags'].patchValue(values);
  }

  public complete(event: any): void {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.tags as any[]).length; i++) {
      let tag = (this.tags as any[])[i];
      if (tag.name.toLowerCase().includes(query.toLowerCase())) {
        filtered.push(tag);
      }
    }

    this.selectedTags = filtered;
  }

  public onBackEvent(): void {
    this.backEvent.emit();
  }

  public onSubmitEvent(): void {
    const preparedData = {
      ...this.userFunctionForm.value,
      scopes: this.userFunctionForm
        .get('scopes')
        .value?.filter(({ data }: { data: FunctionScope }) => !!data)
        .map((scope: { data: FunctionScope }) => scope.data),
    };
    if (this.editMode) {
      preparedData.id = this.id;
    }
    this.submitEvent.emit(preparedData);
  }

  public ngOnInit(): void {
    if (!this.editMode) {
      this.breadcrumbItems?.push({
        label: `${this.translate.instant('shell.bc.user-functions.label.create')}`,
        routerLink: ['/', KE_ROOT_ROUTE, KE_USER_FUNCTIONS_PATH, 'create'],
      });
      this.breadcrumbsMenuService.setBreadcrumbsMenu(this.breadcrumbItems);
      this.createForm();
      this.changeDetectorRef.detectChanges();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['userFunctions']?.previousValue?.length && changes['userFunctions']?.currentValue.length) {
      this.currentUserFunction = this.userFunctions.find((fn) => {
        return fn.id === this.id;
      });
      this.userFunctionForm.patchValue(this.currentUserFunction);
      this.changeDetectorRef.detectChanges();
    }

    if (changes['userFunctions']?.currentValue?.length && this.editMode && !this.currentUserFunction) {
      this.currentUserFunction = this.userFunctions.find((fn) => {
        return fn.id === this.id;
      });
      this.breadcrumbItems?.push({
        label: `${this.translate.instant('shell.bc.user-functions.label.edit')} ${this.currentUserFunction?.name}`,
        routerLink: ['/', KE_ROOT_ROUTE, KE_USER_FUNCTIONS_PATH, 'edit', this.currentUserFunction?.id],
      });
      this.breadcrumbsMenuService.setBreadcrumbsMenu(this.breadcrumbItems);
      this.createForm();
      combineLatest([
        this.userFunctionsPermissionsService.canUpdateUserFunction(this.currentUserFunction?.ownerId as string),
        this.userFunctionsPermissionsService.canViewUserFunction(this.currentUserFunction?.ownerId as string),
      ]).subscribe(([canUpdate, canView]) => {
        if (!canView) {
          this.router.navigate(['access-denied']).then();
        }
        this.canUpdate = canUpdate;
        if (!canUpdate) {
          this.userFunctionForm.disable();
        }
      });
      this.userFunctionForm.patchValue(this.currentUserFunction);
      this.changeDetectorRef.detectChanges();
    }

    if (this.workflows && this.namespaces) {
      this.initScopeList(this.workflows, this.namespaces);
    }

    if (this.workflows.length && this.namespaces.length && this.currentUserFunction?.scopes.length) {
      this.setScopesControlValue();
    }
  }

  private setScopesControlValue(): void {
    this.isGlobal = !!this.currentUserFunction.scopes.find((scope) => scope.scopeType === 'global');

    if (this.isGlobal) {
      this.userFunctionForm.patchValue({ scopes: [] });
      return;
    }

    this.userFunctionForm.patchValue({
      scopes: UserFunctionsControls.mapScopesValuesToControl(this.currentUserFunction.scopes, this.scopeList),
    });
  }

  private initScopeList(workflows: WorkflowInfo[], namespaces: Namespace[]): void {
    this.scopeList = UserFunctionsControls.createScopeList(workflows, namespaces);
  }
}
