import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { WorkflowRunnerClusterConfiguration } from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { Button } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { combineLatest, startWith, takeUntil, tap } from 'rxjs';
import { WorkflowPresets } from '../../interfaces';

export enum EClusterType {
  STANDALONE = 'standalone',
  LOCAL = 'local',
  YARN = 'yarn',
  EXTERNAL_API = 'external-api',
}

export enum EBusType {
  MQ = 'mq',
  None = 'none',
}

export enum EJobType {
  Client = 'client',
  Cluster = 'cluster',
}

@Component({
    selector: 'selfai-platform-cluster-configuration-form',
    templateUrl: './cluster-configuration-form.component.html',
    styleUrls: ['./cluster-configuration-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CheckboxModule,
        DropdownModule,
        FloatLabelModule,
        InputTextModule,
        InputTextareaModule,
        NgIf,
        ReactiveFormsModule,
        Button,
        ToolbarModule,
        TooltipModule,
        TranslateModule,
    ]
})
export class ClusterConfigurationFormComponent implements OnInit, OnChanges {
  public clusterType = [
    { name: 'Standalone', value: EClusterType.STANDALONE },
    { name: 'Local', value: EClusterType.LOCAL },
    { name: 'Yarn', value: EClusterType.YARN },
    { name: 'External API', value: EClusterType.EXTERNAL_API }
  ];

  public busType = [
    { name: 'MQ', value: EBusType.MQ },
    { name: 'None', value: EBusType.None },
  ];

  public jobType = [
    { name: 'Client', value: EJobType.Client },
    { name: 'Cluster', value: EJobType.Cluster },
  ]

  public get isClusterTypeLocal(): boolean {
    return this.form.get('clusterType').value === EClusterType.LOCAL;
  }

  @Input() clusterConfig: WorkflowPresets | WorkflowRunnerClusterConfiguration;
  @Input() isShort = false;
  @Input() disabled = false;

  @Output() submitForm: EventEmitter<WorkflowPresets> = new EventEmitter<WorkflowPresets>();
  @Output() backFromForm: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly destroy$: DestroyService,
    private readonly cdr: ChangeDetectorRef,
    private readonly destroyRef: DestroyRef,
  ) {}

  public form: FormGroup = this.fb.group({
    params: [''],
    name: ['', [Validators.required]],
    userIP: [''],
    clusterType: [null, [Validators.required]],
    uri: ['', [Validators.required]],
    isDefault: [false],
    isEditable: [true],
    driverMemory: ['', [Validators.required]],
    executorMemory: ['', [Validators.required]],
    executorCores: [1, [Validators.required]],
    numExecutors: [1, [Validators.required]],
    totalExecutorCores: [{ value: 1 }, Validators.required],
    hadoopUser: [''],
    busType: [EBusType.MQ],
    isInteractive: [true],
    jobType: [EJobType.Client],
    isStandalone: [false],
    uriApi: [''],
    workflowDir: [''],
  });

  public ngOnInit(): void {
    this.form
      .get('executorCores')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.setTotalExecutorCores();
      });

    this.form
      .get('numExecutors')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.setTotalExecutorCores();
      });
    if (this.disabled) {
      this.form.disable();
    }
    this.handleRecalculateTotalExecutorCores();
  }

  public setTotalExecutorCores(): void {
    this.form
      .get('totalExecutorCores')
      .setValue((this.form.get('executorCores').value || 1) * (this.form.get('numExecutors').value || 1));
  }

  public submit(): void {
    this.form.markAllAsTouched();
    this.submitForm.emit(this.form.getRawValue());
  }

  public back(): void {
    this.backFromForm.emit();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['clusterConfig']?.currentValue) {
      this.form.patchValue(changes['clusterConfig'].currentValue);
      this.cdr.detectChanges();
    }
  }

  private handleRecalculateTotalExecutorCores(): void {
    combineLatest({
      executorCores: this.form.get('executorCores').valueChanges.pipe(startWith(this.form.get('executorCores').value)),
      numExecutors: this.form.get('numExecutors').valueChanges.pipe(startWith(this.form.get('numExecutors').value)),
    })
      .pipe(
        tap(({ executorCores, numExecutors }) => {
          this.form.get('totalExecutorCores').setValue((executorCores || 1) * (numExecutors || 1));
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
