import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { take } from 'rxjs';
import { MainDashboardService } from './main-dashboard.service';

@Injectable({ providedIn: 'root' })
export class DashboardActionsService {
  constructor(
    private readonly mainDashboardService: MainDashboardService,
    private readonly messageService: MessageService,
    private readonly translate: TranslateService,
  ) {}

  addToMainDashboard(dashboardId: string): void {
    this.mainDashboardService
      .setMainDashboard(dashboardId)
      .pipe(take(1))
      .subscribe(() => {
        this.messageService.add({
          severity: 'success',
          summary: this.translate.instant('toast-message.summary.success'),
          detail: this.translate.instant('toast-message.summary.dashboard-added'),
        });
      });
  }
}
