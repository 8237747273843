import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { FAVORITE_LIST_ENTITY_NAME } from '../constants';
import { FavoriteListItem } from '../models';
import { FavoriteListDataService } from '../services';

@Injectable({ providedIn: 'root' })
export class FavoriteListStore extends EntityCollectionServiceBase<FavoriteListItem> {
  constructor(
    entityDataService: EntityDataService,
    dataService: FavoriteListDataService,
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(FAVORITE_LIST_ENTITY_NAME, dataService);
    super(FAVORITE_LIST_ENTITY_NAME, serviceElementsFactory);
  }
}
