import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';

import { AbstractComponent } from '../abstract.component';

@Component({
    selector: 'component-multi-select',
    templateUrl: './multi-select.component.html',
    host: {
        '(document:click)': 'onClickHost($event)',
    },
    standalone: false
})
export class MultiSelectComponent extends AbstractComponent implements OnInit {
  public viewText: string;

  public array: any[];

  @Input('array')
  set setArray(array: any) {
    this.array = array;

    if (this.array != null && this.array.length > 0) {
      if (typeof this.array[0] === 'string') {
        this.isStringArray = true;
      }
    }
  }

  @Input() public selectedArray: any[];

  @Input() public viewKey: string;

  @Input() public checkKey: string;

  @Input() public isUpSelect = false;

  @Input() public usePlaceholder = false;

  @Input() public unselectedMessage = 'Please select';

  @Input() public disabled = false;

  @Input() public isOptionToLeft = false;

  @Output() public onSelected = new EventEmitter();

  @Output() public beforeShowSelectedList = new EventEmitter();

  public selectedItems: any;

  public isShowSelectList = false;

  @Input()
  public isShowSelectListForOutSide = true;

  public isStringArray = false;

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    if (this.array != null && this.array.length > 0) {
      if (typeof this.array[0] === 'string') {
        this.isStringArray = true;
      }
    }

    if (this.selectedArray == null) {
      this.selectedArray = [];
    }

    if (this.usePlaceholder === false && this.array.hasOwnProperty('length') && this.array.length > 0) {
      this.selectedArray = this.array[0];

      this.viewText = typeof this.array[0] === 'string' ? this.array[0] : this.array[0][this.viewKey];
    } else {
      this.viewText = this.unselectedMessage;
    }

    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public selected(item: any, $event: any) {
    if ($event.currentTarget.checked) {
      this.selectedArray.push(item);
    } else {
      const idx = this.selectedArray.indexOf(item);
      this.selectedArray.splice(idx, 1);
    }

    if (this.selectedArray == null || this.selectedArray.length === 0) {
      this.viewText = this.unselectedMessage;
    } else {
      if (typeof item === 'string') {
        this.viewText = this.selectedArray.join(',');
      } else {
        this.viewText = this.selectedArray
          .map((item) => {
            return item[this.viewKey];
          })
          .join(',');
      }
    }

    this.onSelected.emit(this.selectedArray);
  }

  public getSelectedArray() {
    return this.selectedArray;
  }

  public onClickHost(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isShowSelectList = false;
    }
  }

  public setDisable() {
    this.disabled = true;
  }

  public setEnable() {
    this.disabled = false;
  }

  public toggleSelectList() {
    this.isShowSelectList = !this.isShowSelectList;
    this.beforeShowSelectedList.emit(this.isShowSelectList);
  }
}
