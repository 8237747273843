import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { filter, take } from 'rxjs';
import { PageIsReadyService } from './page-is-ready.service';

@Component({
    selector: 'bi-shared-page-is-ready',
    imports: [CommonModule],
    templateUrl: './page-is-ready.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageIsReadyComponent {
  pageIsReady$ = this.pageIsReadyService.pageIsReady$.pipe(filter(Boolean), take(1));
  stateIsLoading$ = this.pageIsReadyService.stateIsLoading$;

  constructor(private readonly pageIsReadyService: PageIsReadyService) {}
}
