<p-table
  #dt
  [value]="workbookList$ | async"
  [lazy]="true"
  [loading]="loading$ | async"
  [paginator]="true"
  [(rows)]="pageSize"
  (onLazyLoad)="loadWorkbookList($event)"
  [rowsPerPageOptions]="[10, 20, 30]"
  [totalRecords]="totalRecords"
  selectionMode="single"
  [(selection)]="selectedWorkbook"
  [scrollable]="true"
  scrollHeight="50vh"
>
  <ng-template pTemplate="loadingbody">
    <tr tabindex="-1">
      <td *ngFor="let _ of [].constructor(4)">
        <p-skeleton [ngStyle]="{ width: 100 / 4 + '%' }"></p-skeleton>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="caption">
    <div class="flex justify-content-between">
      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          pAutoFocus
          [autofocus]="true"
          pInputText
          type="text"
          (input)="dt.filterGlobal($event, 'contains')"
          placeholder="Search"
        />
      </span>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="name">
        <p-sortIcon field="name"></p-sortIcon>
        Name
      </th>
      <th pSortableColumn="createdTime">
        <p-sortIcon field="createdTime"></p-sortIcon>
        Created Time
      </th>
      <th pSortableColumn="modifiedTime">
        <p-sortIcon field="modifiedTime"></p-sortIcon>
        Modified Time
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-item>
    <tr [pSelectableRow]="item" class="cursor-pointer" (dblclick)="onSelectWorkbook(item)">
      <td>{{ item.name }}</td>
      <td>{{ item.createdTime | date: 'dd.MM.yyyy, hh:mm' }}</td>
      <td>{{ item.modifiedTime | date: 'dd.MM.yyyy, hh:mm' }}</td>
    </tr>
  </ng-template>

  <ng-template pTemplate="paginatorleft">
    <p-button
      *ngIf="selectedWorkbook"
      pTooltip="Select workbook"
      (click)="onSelectWorkbook(selectedWorkbook)"
      type="button"
      styleClass="p-button"
      [label]="textButton || 'Choose'"
    ></p-button>
  </ng-template>
</p-table>
