import { Component, ElementRef, Injector, OnInit, OnDestroy, ViewChild } from '@angular/core';

import * as _ from 'lodash';

import { Dashboard, TimeFilter, DatasourceField, TimeUnit, ByTimeUnit, CustomField } from '@selfai-platform/bi-domain';

import { FilterUtil } from '../../util/filter.util';
import { AbstractFilterPopupComponent } from '../abstract-filter-popup.component';

import { TimeListFilterComponent } from './time-list-filter.component';
import { TimeRangeFilterComponent } from './time-range-filter.component';
import { TimeRelativeFilterComponent } from './time-relative-filter.component';

@Component({
    selector: 'app-config-filter-time',
    templateUrl: './configure-filters-time.component.html',
    standalone: false
})
export class ConfigureFiltersTimeComponent extends AbstractFilterPopupComponent implements OnInit, OnDestroy {
  @ViewChild(TimeListFilterComponent)
  private _candidateComp: TimeListFilterComponent;

  @ViewChild(TimeRelativeFilterComponent)
  private _relativeComp: TimeRelativeFilterComponent;

  @ViewChild(TimeRangeFilterComponent)
  private _rangeComp: TimeRangeFilterComponent;

  public isShow = false;

  public targetFilter: TimeFilter;
  public targetField: DatasourceField | CustomField;
  public dashboard: Dashboard;

  public isShowGranularitySelect = true;
  public isContinuousByAll = false;
  public isDiscontinuousFilter = false;
  public isAllType = false;
  public isRelativeType = false;
  public isRangeType = false;
  public isListType = false;

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public showComponent(
    board: Dashboard,
    targetFilter: TimeFilter,
    targetField?: DatasourceField | CustomField,
    isShowGranularitySelect: boolean = true,
  ) {
    this.isShowGranularitySelect = isShowGranularitySelect;
    this.dashboard = _.cloneDeep(board);
    this.targetField = _.cloneDeep(targetField);
    this.targetFilter = _.cloneDeep(targetFilter);
    this.targetFilter.timeUnit || (this.targetFilter.timeUnit = TimeUnit.NONE);

    this.isShow = true;
    this._setStatus();
    this.safelyDetectChanges();
  }

  public getData(): TimeFilter {
    let returnData: TimeFilter;
    switch (this.targetFilter.type) {
      case 'time_list':
        returnData = this._candidateComp.getData();
        break;
      case 'time_relative':
        returnData = this._relativeComp.getData();
        break;
      case 'time_range':
        returnData = this._rangeComp.getData();
        break;
      default:
        returnData = this.targetFilter;
        break;
    }
    return _.cloneDeep(returnData);
  }

  public setTimeRangeFilter() {
    this.targetFilter = FilterUtil.getTimeRangeFilter(
      this.targetFilter.clzField,
      this.targetFilter.timeUnit,
      this.targetFilter.ui.importanceType,
      this.dashboard.dataSources.find((ds) => ds.engineName === this.targetFilter.dataSource),
    );
    this._setStatus();
  }

  public setTimeRelativeFilter() {
    this.targetFilter = FilterUtil.getTimeRelativeFilter(
      this.targetFilter.clzField,
      this.targetFilter.timeUnit,
      this.targetFilter.ui.importanceType,
    );
    this._setStatus();
  }

  public setTimeListFilter() {
    this.targetFilter = FilterUtil.getTimeListFilter(
      this.targetFilter.clzField,
      this.targetFilter.discontinuous,
      this.targetFilter.timeUnit,
      this.targetFilter.byTimeUnit,
      this.targetFilter.ui.importanceType,
    );
    this._setStatus();
  }

  public changeTimeUnit(data: { discontinuous: boolean; unit?: TimeUnit; byUnit?: ByTimeUnit }) {
    let currFilter: TimeFilter = _.cloneDeep(this.targetFilter);
    if (TimeUnit.NONE !== data.unit) {
      currFilter = FilterUtil.getTimeListFilter(
        currFilter.clzField,
        data.discontinuous,
        data.unit,
        data.byUnit,
        currFilter.ui.importanceType,
      );
    } else {
      currFilter = FilterUtil.getTimeRangeFilter(
        currFilter.clzField,
        TimeUnit.NONE,
        currFilter.ui.importanceType,
        this.dashboard.dataSources.find((ds) => ds.engineName === this.targetFilter.dataSource),
      );
    }
    this.targetFilter = currFilter;
    this._setStatus();
  }

  private _setStatus() {
    this.isContinuousByAll = FilterUtil.isContinuousByAll(this.targetFilter);
    this.isDiscontinuousFilter = FilterUtil.isDiscontinuousTimeFilter(this.targetFilter);
    this.isAllType = FilterUtil.isTimeAllFilter(this.targetFilter);
    this.isRelativeType = FilterUtil.isTimeRelativeFilter(this.targetFilter);
    this.isRangeType = FilterUtil.isTimeRangeFilter(this.targetFilter);
    this.isListType = FilterUtil.isTimeListFilter(this.targetFilter);
  }
}
