import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { mapPaginationPrimeToBi } from '@selfai-platform/shared';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';

@Component({
    selector: 'selfai-platform-data-list-view-pagination',
    templateUrl: './data-list-view-pagination.component.html',
    styleUrls: ['./data-list-view-pagination.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, PaginatorModule, TranslateModule, ButtonModule]
})
export class DataListViewPaginationComponent {
  @Input() pageSize: number;
  @Input() pageNumber: number;
  @Input() totalRecords: number;

  // start from 1
  get first(): number {
    const pageNumber = this.pageNumber > 0 ? this.pageNumber : 1;

    return this.pageSize * (pageNumber - 1) + 1;
  }

  get last(): number {
    return Math.min(this.first + this.pageSize - 1, this.totalRecords);
  }

  @Output() pageChange = new EventEmitter<{ pageSize: number; pageNumber: number }>();

  onPageChange(event: { rows?: number; first?: number }): void {
    this.pageChange.emit(mapPaginationPrimeToBi(event));
  }
}
