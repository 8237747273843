/* eslint-disable max-lines */
import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import {
  EMPTY,
  Observable,
  OperatorFunction,
  ReplaySubject,
  catchError,
  concatMap,
  from,
  map,
  mergeMap,
  of,
  retry,
  switchMap,
  take,
  throwError,
  toArray,
} from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { AlertService } from '@selfai-platform/shared';
import { omit } from 'lodash';
import {
  BoardConfiguration,
  BoardDataSource,
  Dashboard,
  DashboardDomainService,
  convertDsToMetaDs,
  setDataSourceAndRelations,
} from '../../dashboard';
import { Datasource } from '../../datasource';
import { DashboardPageRelation, FilterWidget, PageWidget, Widget, WidgetDomainService } from '../../widget';
import { CurrentWorkspaceDomainService } from '../../workspace/services/current-workspace-domain.service';
import { DashboardField, Filter, ImportWorkbookMappedDatasource, JoinMapping, PivotField } from '../configuration';
import { Book, WorkbookImport } from '../models';
import { WorkbookDomainService } from './workbook-domain.service';
import { WorkbookPinsDomainService } from './workbook-pins-domain.service';

@Injectable()
export class WorkbookImportDomainService {
  private importStatus$ = new ReplaySubject<string>();
  private mappedDatasources: { [datasourceId: string]: Datasource & { oldEngineName: string } };

  constructor(
    private readonly currentWorkspaceDomainService: CurrentWorkspaceDomainService,
    private readonly workbookDomainService: WorkbookDomainService,
    private readonly workbookPinsDomainService: WorkbookPinsDomainService,
    private readonly dashboardDomainService: DashboardDomainService,
    private readonly translate: TranslateService,
    private readonly widgetDomainService: WidgetDomainService,
    private readonly alertService: AlertService,
  ) {}

  getImportStatus(): Observable<string> {
    return this.importStatus$.asObservable();
  }

  createWorkbookFromImportData(importData: {
    workBook: WorkbookImport;
    mappedDatasources: { [oldDatasourceId: string]: ImportWorkbookMappedDatasource };
  }): Observable<string> {
    this.mappedDatasources = importData.mappedDatasources;
    const workbookImportData = this.prepareDatasourceForImport(importData.workBook);

    return this.createWorkbook(workbookImportData).pipe(
      concatMap(({ id: workbookId }) => {
        const mapNewDashboard = new Map<string, Dashboard>();

        return from(workbookImportData.dashBoards).pipe(
          concatMap((dashBoardImportData) => {
            this.importStatus$.next(
              this.translate.instant('msg.workbook.import.status.create-dashboard', {
                dashboardName: dashBoardImportData.name,
              }),
            );

            return this.createDashboard(workbookId, dashBoardImportData).pipe(
              concatMap(({ dashBoard, oldDashboardId }) => {
                mapNewDashboard.set(oldDashboardId, dashBoard);

                return this.createWidgets(dashBoard, dashBoardImportData).pipe(
                  mergeMap((widgets: { widget: Widget; oldWidgetId: string }[]) => {
                    return this.updateDashboardConfiguration({
                      newDashboard: dashBoard,
                      dashBoardImportData,
                      widgets,
                    }).pipe(this.catchAndShowError('update-dashboard-config', dashBoardImportData.name));
                  }),
                  this.catchAndShowError('create-widgets', dashBoardImportData.name),
                );
              }),
              this.catchAndShowError('create-dashboard', dashBoardImportData.name),
            );
          }),
          toArray(),
          mergeMap(() => {
            this.importStatus$.next(
              this.translate.instant('msg.workbook.import.status.add-workbook-pins', {
                workbookName: workbookImportData.name,
              }),
            );

            return this.addPins(workbookId, workbookImportData, mapNewDashboard).pipe(
              this.catchAndShowError('add-workbook-pins', workbookImportData.name),
            );
          }),
          map(() => {
            this.importStatus$.next(undefined);
            this.importStatus$.complete();

            return workbookId;
          }),
        );
      }),
    );
  }

  private prepareDatasourceForImport(workBook: WorkbookImport): WorkbookImport {
    return {
      ...workBook,
      dashBoards: workBook.dashBoards
        .filter((dashBoard) => {
          if (dashBoard.configuration.dataSource.type !== 'multi') {
            return dashBoard.dataSources.length > 0 && this.getDatasourceByOldId(dashBoard.configuration.dataSource.id);
          }

          return (
            dashBoard.dataSources.length > 0 &&
            dashBoard.configuration.dataSource.dataSources.every((ds) => this.getDatasourceByOldId(ds.id))
          );
        })
        .map((dashBoard) => {
          return {
            ...omit(dashBoard),
            configuration: {
              ...dashBoard.configuration,
              dataSource: {
                ...dashBoard.configuration.dataSource,
                ...this.mapDatasourceConfiguration(dashBoard.configuration.dataSource),
              },
              userDefinedFields: dashBoard.configuration.userDefinedFields?.map((field) => ({
                ...field,
                dataSource: this.getDatasourceByEngineName(field.dataSource).engineName,
              })),
              filters: dashBoard.configuration.filters?.map((filter) => ({
                ...filter,
                dataSource: this.getDatasourceByEngineName(filter.dataSource)?.engineName,
              })),
            },
            dataSources: this.mapDatasources(dashBoard.dataSources),
            widgets: dashBoard.widgets.map((widget) => {
              if (this.isPageWidget(widget)) {
                return {
                  ...widget,
                  dataSourcesIds: widget.dataSourcesIds.map((id) => this.getDatasourceByOldId(id).id),
                  configuration: {
                    ...widget.configuration,
                    dataSource: this.mapDatasourceConfiguration(widget.configuration.dataSource),
                    fields: widget.configuration.fields?.map((field) => ({
                      ...field,
                      dataSource: this.getDatasourceByEngineName(field.dataSource).engineName,
                    })),
                    pivot: {
                      columns: widget.configuration.pivot.columns.map(this.mapPivotFieldDatasource.bind(this)),
                      rows: widget.configuration.pivot.rows.map(this.mapPivotFieldDatasource.bind(this)),
                      aggregations: widget.configuration.pivot.aggregations.map(
                        this.mapPivotFieldDatasource.bind(this),
                      ),
                    },
                  },
                };
              }

              return widget;
            }),
          };
        }),
      dataSource: this.mapDatasources(workBook.dataSource),
    };
  }

  private catchAndShowError(proccessName: string, entityName: string): OperatorFunction<unknown, unknown> {
    return catchError((error) => {
      this.alertService.error(this.translate.instant(`msg.workbook.import.error.${proccessName}`, { entityName }));

      return throwError(() => error);
    });
  }

  private mapDatasources(dataSources: Datasource[]): Datasource[] {
    return dataSources.map((dataSource) => {
      return this.getDatasourceByOldId(dataSource.id);
    });
  }

  private getDatasourceByEngineName(engineName: string): Datasource | undefined {
    const newDatasource = Object.values(this.mappedDatasources).find(
      (dataSource) => dataSource.oldEngineName === engineName,
    );

    return newDatasource ? omit(newDatasource, ['oldEngineName']) : undefined;
  }

  private getDatasourceByOldId(id: string): Datasource | undefined {
    const newDatasource = this.mappedDatasources[id];

    return newDatasource ? omit(newDatasource, ['oldEngineName']) : undefined;
  }

  private mapPivotFieldDatasource(pivotField: PivotField): DashboardField {
    if (!pivotField.field?.dsId) {
      return pivotField as DashboardField;
    }

    const datasource = this.getDatasourceByOldId(pivotField.field.dsId);
    const fieldDatasource = datasource.fields.find((field) => field.name === pivotField.field.name);

    return {
      ...pivotField,
      field: {
        ...pivotField.field,
        id: fieldDatasource?.id || pivotField.field.id,
        dsId: datasource.id,
        dataSource: datasource.engineName || datasource.name,
      },
    } as DashboardField;
  }

  private createWorkbook(workbookImportData: WorkbookImport): Observable<Book> {
    return this.currentWorkspaceDomainService.getCurrentWorkspace().pipe(
      take(1),
      switchMap(({ id }) => {
        return this.workbookDomainService.addWorkbook(id, {
          name: workbookImportData.name,
          description: workbookImportData.description,
          folderId: workbookImportData.folderId,
        });
      }),
    );
  }

  private createDashboard(
    workbookId: string,
    dashBoardImportData: Dashboard,
  ): Observable<{ dashBoard: Dashboard; oldDashboardId: string }> {
    const boardDataSources = dashBoardImportData.dataSources.map(convertDsToMetaDs);

    return this.dashboardDomainService
      .addDashboard(workbookId, omit(setDataSourceAndRelations(dashBoardImportData, boardDataSources), ['widgets']))
      .pipe(
        map((dashBoard) => ({ dashBoard, oldDashboardId: dashBoardImportData.id })),
        retry({ count: 3, resetOnSuccess: true, delay: 1000 }),
      );
  }

  private createWidgets(
    newDashboard: Dashboard,
    dashBoardImportData: Dashboard,
  ): Observable<{ widget: Widget; oldWidgetId: string }[]> {
    return from(dashBoardImportData.widgets).pipe(
      mergeMap((widget) => {
        this.importStatus$.next(
          this.translate.instant('msg.workbook.import.status.create-widgets', {
            dashboardName: dashBoardImportData.name,
          }),
        );
        const cleanedWidget = omit(widget, ['id']) as Widget | FilterWidget | PageWidget;
        if (this.isFilterWidget(cleanedWidget)) {
          cleanedWidget.configuration.filter = this.normalizeFilterDatasource(
            cleanedWidget.configuration.filter,
            newDashboard.configuration.dataSource,
          );
        }
        if (this.isPageWidget(cleanedWidget)) {
          if (cleanedWidget.configuration.pivot) {
            cleanedWidget.configuration.pivot = {
              aggregations: cleanedWidget.configuration.pivot.aggregations.map((field) =>
                this.injectNewDashboardIdToPivotField(field, newDashboard.id),
              ),
              rows: cleanedWidget.configuration.pivot.rows.map((field) =>
                this.injectNewDashboardIdToPivotField(field, newDashboard.id),
              ),
              columns: cleanedWidget.configuration.pivot.columns.map((field) =>
                this.injectNewDashboardIdToPivotField(field, newDashboard.id),
              ),
            };
          }

          if (cleanedWidget.configuration.filters?.length) {
            cleanedWidget.configuration.filters = cleanedWidget.configuration.filters.map((filter) =>
              this.normalizeFilterDatasource(filter, newDashboard.configuration.dataSource),
            );
          }
        }

        return this.widgetDomainService.addWidget(cleanedWidget, newDashboard.id).pipe(
          map((newWidget) => ({ widget: newWidget, oldWidgetId: widget.id })),
          retry({ count: 3, resetOnSuccess: true, delay: 1000 }),
          catchError((e) => {
            console.error(e);

            return EMPTY;
          }),
        );
      }),
      toArray(),
    );
  }

  private isPageWidget(cleanedWidget: Widget | FilterWidget | PageWidget): cleanedWidget is PageWidget {
    return cleanedWidget.configuration.type === 'page';
  }

  private isFilterWidget(widget: Widget | FilterWidget | PageWidget): widget is FilterWidget {
    return widget.configuration.type === 'filter';
  }

  private updateDashboardConfiguration({
    newDashboard,
    dashBoardImportData,
    widgets,
  }: {
    newDashboard: Dashboard;
    dashBoardImportData: Dashboard;
    widgets: { widget: Widget; oldWidgetId: string }[];
  }): Observable<void> {
    if (widgets?.length > 0) {
      const mapToNewWidgetRefs = new Map<string, string>();
      widgets.forEach(({ widget, oldWidgetId }) => {
        mapToNewWidgetRefs.set(oldWidgetId, widget.id);
      });

      this.importStatus$.next(
        this.translate.instant('msg.workbook.import.status.update-dashboard-config', {
          dashboardName: dashBoardImportData.name,
        }),
      );

      return this.dashboardDomainService
        .updateDashboard({
          id: newDashboard.id,
          configuration: this.mapOldConfigurationToNew(
            dashBoardImportData.configuration,
            mapToNewWidgetRefs,
            newDashboard,
          ),
        })
        .pipe(map(() => void 0));
    }

    return of(void 0);
  }

  private addPins(
    workbookId: string,
    workbookImportData: WorkbookImport,
    mapNewDashboard: Map<string, Dashboard>,
  ): Observable<void> {
    const pinsDashboard = (workbookImportData.pins || []).map((id) => mapNewDashboard.get(id)).filter(Boolean);

    return this.workbookPinsDomainService.addAllPins(
      workbookId,
      pinsDashboard.map((dashboard) => ({ name: dashboard.name, dashboardId: dashboard.id })),
    );
  }

  private mapOldConfigurationToNew(
    importedConfiguration: BoardConfiguration,
    mapToNewWidgetRefs: Map<string, string>,
    newDashboard: Dashboard,
  ): BoardConfiguration {
    const mappedConfigurationWidgets = (importedConfiguration.widgets || [])
      .map((widget) => ({
        ...widget,
        id: uuidv4(),
        ref: mapToNewWidgetRefs.get(widget.ref),
        isInLayout: true,
      }))
      .filter(({ ref }) => Boolean(ref));

    const mappedConfigurationRelations = (importedConfiguration.relations || []).map((relation) =>
      this.replaceOldIdsImportedRelation(relation, mapToNewWidgetRefs),
    );

    const mappedConfigurationContent = importedConfiguration.content?.map((content) => {
      return {
        ...content,
        content: content.content.map((contentItem) => ({
          ...contentItem,
          content: contentItem.content.map((c) => {
            return {
              ...c,
              id: mapToNewWidgetRefs.get(c.id),
              title: c.title === c.id ? mapToNewWidgetRefs.get(c.id) : c.title,
              componentState: { ...c.componentState, id: mapToNewWidgetRefs.get(c.id) },
            };
          }),
        })),
      };
    });

    const mappedConfigurationFilters = importedConfiguration.filters?.map((filter) =>
      this.normalizeFilterDatasource(filter, newDashboard.configuration.dataSource),
    );

    return {
      ...importedConfiguration,
      dataSource: {
        ...importedConfiguration.dataSource,
        ...newDashboard.configuration.dataSource,
      },
      filters: mappedConfigurationFilters,
      content: mappedConfigurationContent,
      relations: mappedConfigurationRelations,
      widgets: mappedConfigurationWidgets,
    };
  }

  private replaceOldIdsImportedRelation(
    relation: DashboardPageRelation,
    mapToNewWidgetRefs: Map<string, string>,
  ): DashboardPageRelation {
    return {
      ref: mapToNewWidgetRefs.get(relation.ref),
      pageRef: mapToNewWidgetRefs.get(relation.pageRef),
      children: relation.children?.map((r) => this.replaceOldIdsImportedRelation(r, mapToNewWidgetRefs)),
    };
  }

  private normalizeFilterDatasource(filter: Filter, dataSource: BoardDataSource): Filter {
    return {
      ...filter,
      dataSource: dataSource.engineName || dataSource.name,
    };
  }

  private injectNewDashboardIdToPivotField(pivotField: PivotField, boardId: string): PivotField {
    return {
      ...pivotField,
      field: {
        ...pivotField.field,
        boardId,
      },
    };
  }

  private mapDatasourceConfiguration(dataSource: BoardDataSource): BoardDataSource {
    if (dataSource.type !== 'multi') {
      const newDataSource = this.getDatasourceByOldId(dataSource.id);

      return {
        ...dataSource,
        engineName: newDataSource.engineName,
        name: newDataSource.name,
        id: newDataSource.id,
      };
    }

    return {
      ...dataSource,
      dataSources: dataSource.dataSources.map((ds) => ({
        ...ds,
        engineName: this.getDatasourceByOldId(ds.id).engineName,
        name: this.getDatasourceByOldId(ds.id).name,
        id: this.getDatasourceByOldId(ds.id).id,
      })),
      associations: dataSource.associations?.map((association) => ({
        ...association,
        source: this.getDatasourceByEngineName(association.source)?.engineName,
        target: this.getDatasourceByEngineName(association.target)?.engineName,
      })),
      joins: dataSource.joins?.map((join) => this.mapJoinMapping(join)),
    };
  }

  private mapJoinMapping(joinMapping: JoinMapping): JoinMapping {
    return {
      ...joinMapping,
      engineName: this.getDatasourceByEngineName(joinMapping.engineName)?.engineName,
      join: joinMapping.join ? this.mapJoinMapping(joinMapping.join) : undefined,
    };
  }
}
