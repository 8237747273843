<p-confirmPopup></p-confirmPopup>

<selfai-platform-data-list-view
  [layoutTypes]="['table']"
  [actions]="actions$ | async"
  [groupActions]="groupActions$ | async"
  [columns]="columns"
  [dataIsLazy]="true"
>
  <ng-template selfaiPlatformDataListViewTemplateName="fieldValue.domain" let-item>
    <div class="label label-{{ item.domain.toLowerCase() }}">
      <i class="{{ iconsByType[item.domain] }}"></i>
      {{ 'msg.favorite-list.type.' + item.domain.toLowerCase() | translate }}
    </div>
  </ng-template>
</selfai-platform-data-list-view>
