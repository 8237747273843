import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WorkflowCreationParams, WorkflowInfo } from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';

@Component({
    selector: 'selfai-platform-workflow-add-dialog',
    templateUrl: './workflow-add-dialog.component.html',
    styleUrls: ['./workflow-add-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WorkflowAddDialogComponent {
  form = new FormGroup({
    name: new FormControl('', Validators.required),
    description: new FormControl(''),
    isPublic: new FormControl(false),
  });

  constructor(protected readonly dialogService: DialogService<WorkflowCreationParams, WorkflowInfo>) {}

  submit(): void {
    this.dialogService.close({
      name: this.form.value.name as string,
      description: this.form.value.description || '',
    });
  }

  closeDialog(): void {
    this.dialogService.close();
  }
}
