import { Injectable } from '@angular/core';
import { EntityOp, MergeStrategy } from '@ngrx/data';
import { PageParams } from '@selfai-platform/shared';
import { Observable, catchError, filter, finalize, tap, throwError } from 'rxjs';
import { FavoriteDomainService } from '../../favorite';
import { WorkspaceList, WorkspaceListItem } from '../models';
import { WorkspaceListStore } from '../stores';
import { WorkspaceApiToDomainService } from './workspace-domain-api.service';

@Injectable({ providedIn: 'root' })
export class WorkspaceListDomainService {
  constructor(
    private readonly workspaceApiToDomainService: WorkspaceApiToDomainService,
    private readonly workspaceListStore: WorkspaceListStore,
    private readonly favoriteDomainService: FavoriteDomainService,
  ) {}

  loadAllWorkspaceList(pageParams: PageParams): Observable<WorkspaceList> {
    this.workspaceListStore.setLoading(true);

    return this.workspaceApiToDomainService.getAllWorkspaceList(pageParams).pipe(
      tap(({ workspaces }) => {
        this.workspaceListStore.setLoaded(true);
        this.workspaceListStore.addAllToCache(workspaces);
      }),
      catchError((err: unknown) => {
        this.workspaceListStore.setLoaded(false);
        this.workspaceListStore.createAndDispatch(EntityOp.QUERY_ALL_ERROR, err);

        return throwError(() => err);
      }),
      finalize(() => {
        this.workspaceListStore.setLoading(false);
      }),
    );
  }

  getWorkspaceList(): Observable<WorkspaceListItem[]> {
    return this.workspaceListStore.entities$.pipe(filter(Boolean));
  }

  getWorkspaceListLoading(): Observable<boolean> {
    return this.workspaceListStore.loading$;
  }

  getWorkspaceListError(): Observable<unknown> {
    return this.workspaceListStore.errors$;
  }

  getWorkspaceListLoaded(): Observable<boolean> {
    return this.workspaceListStore.loaded$;
  }

  updateOneInList(workspaceId: string, workspace: Partial<WorkspaceListItem>): void {
    this.workspaceListStore.update(
      { ...workspace, id: workspaceId },
      { isOptimistic: true, mergeStrategy: MergeStrategy.PreserveChanges },
    );
  }

  addToFavorite(id: string): Observable<void> {
    return this.favoriteDomainService.addToFavorite('workspaces', id).pipe(
      tap(() => {
        this.workspaceListStore.updateOneInCache({ id, favorite: true });
      }),
    );
  }

  deleteFromFavorite(id: string): Observable<void> {
    return this.favoriteDomainService.removeFromFavorite('workspaces', id).pipe(
      tap(() => {
        this.workspaceListStore.updateOneInCache({ id, favorite: false });
      }),
    );
  }
}
