import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

import { PageResult } from '../../../domain/common/page';
import { AbstractComponent } from '../abstract.component';

@Component({
    selector: 'component-pagination',
    templateUrl: './pagination.component.html',
    standalone: false
})
export class PaginationComponent extends AbstractComponent implements OnInit, OnChanges, OnDestroy {
  private _navigationSize = 10;

  public range: number[] = [];

  public pageSizes = [20, 40, 60, 80, 100];
  public isOpenOpts = false;

  @Input()
  public info: PageResult;

  @Output()
  public changePageData: EventEmitter<{ page: number; size: number }> = new EventEmitter();

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnChanges(changes: SimpleChanges) {
    const infoChanges: SimpleChange = changes.info;
    if (infoChanges && infoChanges.currentValue) {
      const startPage = Math.floor(this.info.number / this._navigationSize) * this._navigationSize;
      this._setRange(startPage);
    }
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public changePage(page: number) {
    if (this.info.number !== page) {
      this.changePageData.emit({ page: page, size: this.info.size });
    }
  }

  public changePageSize(size: number) {
    if (this.info.size !== size) {
      this.info.number = 0;
      this.changePageData.emit({ page: this.info.number, size: size });
    }
  }

  public prevPagination() {
    const startPage = this.range[0] - this._navigationSize;
    this._setRange(startPage);
  }

  public nextPagination() {
    let startPage = this.range[0] + this._navigationSize;
    this.info.totalPages <= startPage && (startPage = this.range[0]);
    this._setRange(startPage);
  }

  public openChangePageComboBox() {
    if (this.info.totalElements <= this.info.size) {
      return;
    }

    this.isOpenOpts = !this.isOpenOpts;
  }

  private _setRange(startPage: number) {
    0 > startPage && (startPage = 0);
    let endPage = startPage + this._navigationSize - 1;
    this.info.totalPages - 1 < endPage && (endPage = this.info.totalPages - 1);

    this.range = [];
    for (let idx = startPage; idx <= endPage; idx++) {
      this.range.push(idx);
    }
  }
}
