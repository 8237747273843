import { WorkspaceDetailViewModel, WorkspaceListDetailViewModel } from '@selfai-platform/bi-api';
import { PublicType, WorkspaceType } from '../enums';
import { Workspace, WorkspaceList, WorkspaceListItem, createWorkspace } from '../models';

export function workspaceApiNormalizer(workspaceApi: WorkspaceDetailViewModel): Workspace {
  return createWorkspace({
    ...workspaceApi,
    type: workspaceApi.type as WorkspaceType,
    publicType: workspaceApi.publicType as PublicType,
    workBooks: workspaceApi.workBooks as any[],
    countByBookType: workspaceApi.countByBookType as any,
    permissions: workspaceApi.permissions as any,
    owner: workspaceApi.owner as any,
  });
}

export function workspaceListItemApiNormalizer(workspaceApi: WorkspaceDetailViewModel): WorkspaceListItem {
  return {
    ...workspaceApiNormalizer(workspaceApi),
    countWorkbooks: workspaceApi.countByBookType?.workBook,
    createdBy: workspaceApi.createdBy.fullName || workspaceApi.createdBy.username,
  };
}

export function workspaceListApiNormalizer(workspaceListApi: WorkspaceListDetailViewModel): WorkspaceList {
  return {
    workspaces: workspaceListApi._embedded?.workspaces.map(workspaceListItemApiNormalizer) || [],
    page: workspaceListApi.page,
  };
}
