import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControlStatus } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { S3ConnectionParamsComponentService } from '@selfai-platform/bi-domain';
import { Observable, map, startWith, tap } from 'rxjs';

@Component({
    selector: 'selfai-platform-s3-connection-params',
    templateUrl: './s3-connection-params.component.html',
    styleUrls: ['./s3-connection-params.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class S3ConnectionParamsComponent implements OnInit {
  form = this.s3ConnectionParamsComponentService.form;

  get conectionFieldNames(): string[] {
    return Object.keys(this.s3ConnectionParamsComponentService.form.controls.connection.getRawValue()).map(
      (fieldName) => fieldName,
    );
  }

  connectionValidStatus$: Observable<FormControlStatus>;
  locale$: Observable<string>;

  constructor(
    private readonly s3ConnectionParamsComponentService: S3ConnectionParamsComponentService,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.connectionValidStatus$ = this.form.controls.connectionValid.statusChanges.pipe(
      tap((status) => {
        if (status === 'VALID') {
          this.form.markAsUntouched();
        }
      }),
      startWith(this.form.controls.connectionValid.status),
    );

    this.locale$ = this.translate.onLangChange.pipe(
      map((event: LangChangeEvent) => event.lang),
      startWith(this.translate.currentLang),
    );
  }

  addCustomParameter(): void {
    return this.s3ConnectionParamsComponentService.addCustomParameter();
  }

  removeCustomParameter(index: number): void {
    return this.s3ConnectionParamsComponentService.removeCustomParameter(index);
  }

  validateConnection() {
    return this.s3ConnectionParamsComponentService.validateConnection();
  }
}
