<form class="my-3" [formGroup]="form">
  <ng-container *ngFor="let fieldName of conectionFieldNames" [formGroup]="form.controls.connection">
    <div class="flex align-items-baseline gap-3 mb-1">
      <div class="w-15rem flex flex-column flex-shrink-0">
        <label class="m-0" [htmlFor]="fieldName">
          {{ 's3-connection-params.form.fields.label.' + fieldName | translate }}
        </label>
        <small *ngIf="(locale$ | async) !== 'en'">
          {{ 's3-connection-params.form.fields.label.english.' + fieldName | translate }}
        </small>
      </div>
      <div class="w-full">
        <input [id]="fieldName" pInputText type="text" [formControlName]="fieldName" class="w-full" />
        <small
          [ngStyle]="{
            opacity:
              form.controls.connection.get(fieldName).invalid && form.controls.connection.get(fieldName).touched ? 1 : 0
          }"
          class="error-hint text-danger"
        >
          {{ 's3-connection-params.form.fields.error-required' | translate }}
        </small>
      </div>
    </div>
  </ng-container>
  <ng-container *ngFor="let paramForm of form.controls.customParameters.controls; let i = index">
    <div class="flex align-items gap-3 mb-3">
      <ng-container [formGroup]="paramForm">
        <div class="w-15rem flex-shrink-0">
          <input pInputText type="text" formControlName="key" class="w-full" />
        </div>
        <div class="w-full">
          <input pInputText type="text" formControlName="value" class="w-full" />
        </div>
        <button pButton (click)="removeCustomParameter(i)" class="p-button-text" icon="pi pi-times"></button>
      </ng-container>
    </div>
  </ng-container>
  <div class="flex justify-content-between align-items-baseline mt-3">
    <div *ngIf="form.controls.connectionValid as connectionValid" class="flex align-items-center gap-3">
      <ng-container *ngIf="{ connectionValidStatus: connectionValidStatus$ | async } as obs">
        <button
          pButton
          class="p-button-secondary"
          [ngClass]="{
            'p-button-success': connectionValid.valid && form.untouched
          }"
          [disabled]="obs.connectionValidStatus === 'PENDING'"
          [icon]="obs.connectionValidStatus === 'PENDING' ? 'pi pi-spin pi-spinner' : ''"
          type="button"
          label="{{ 's3-connection-params.button.validate-connection' | translate }}"
          (click)="validateConnection()"
        ></button>
        <ng-container [ngSwitch]="obs.connectionValidStatus">
          <small *ngSwitchCase="'INVALID'" class="check-valid-hint text-danger">
            {{ connectionValid.errors?.['connectionInvalid'] }}
          </small>
          <small *ngSwitchCase="'VALID'" class="check-valid-hint text-success">
            {{ 's3-connection-params.form.fields.connection-valid' | translate }}
          </small>
        </ng-container>
      </ng-container>
    </div>

    <button
      pButton
      type="button"
      icon="pi pi-plus"
      label="{{ 's3-connection-params.button.add-custom-parameter' | translate }}"
      (click)="addCustomParameter()"
    ></button>
  </div>
</form>
