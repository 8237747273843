import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConnectionType, DatasourceDomainService, DatasourceList, Workspace } from '@selfai-platform/bi-domain';
import { FileSizePipe, PageParams } from '@selfai-platform/shared';
import {
  DataListViewPaginationComponent,
  DialogService,
  UI_SETTINGS_VIEW_PAGE_DEFAULT_SIZE,
  getUiSettingsDefaultPageSizes,
} from '@selfai-platform/shell';
import { AutoFocusModule } from 'primeng/autofocus';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { SkeletonModule } from 'primeng/skeleton';
import { Table, TableFilterEvent, TableModule } from 'primeng/table';
import { Observable, startWith, tap } from 'rxjs';

@Component({
    selector: 'selfai-platform-datasource-list-dialog',
    imports: [
        CommonModule,
        TableModule,
        TranslateModule,
        DataListViewPaginationComponent,
        InputTextModule,
        AutoFocusModule,
        CheckboxModule,
        FileSizePipe,
        SkeletonModule,
        DropdownModule,
        MultiSelectModule,
        FormsModule,
    ],
    templateUrl: './datasource-list-dialog.component.html',
    styleUrls: ['./datasource-list-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatasourceListDialogComponent implements OnInit {
  data$: Observable<DatasourceList>;

  pageParams: PageParams = { pageSize: UI_SETTINGS_VIEW_PAGE_DEFAULT_SIZE, pageNumber: 1, totalItems: 0 };

  tableConfig: { key: string; label: string; width: string }[] = [
    { key: 'name', label: this.translate.instant('msg.comm.th.ds'), width: 'calc(100% - 53rem)' },
    { key: 'connType', label: this.translate.instant('msg.comm.th.type'), width: '14rem' },
    { key: 'summary.size', label: this.translate.instant('msg.comm.th.full.size'), width: '12rem' },
    { key: 'modifiedTime', label: this.translate.instant('msg.comm.th.updated'), width: '10rem' },
    { key: 'published', label: this.translate.instant('msg.comm.ui.list.ds.opendata'), width: '15rem' },
  ];

  loading = true;

  typeFilter: { label: string; value: string }[] = [
    {
      label: this.translate.instant('msg.comm.ui.ds.engine'),
      value: ConnectionType.ENGINE,
    },
    {
      label: this.translate.instant('msg.comm.ui.ds.link'),
      value: ConnectionType.LINK,
    },
  ];

  pageSizes = getUiSettingsDefaultPageSizes();

  constructor(
    private readonly datasourceDomainService: DatasourceDomainService,
    private readonly dialogService: DialogService,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit() {
    if (this.workspaceData) {
      this.data$ = this.datasourceDomainService
        .loadDatasourceList(this.workspaceData.id, { pageSize: 999, pageNumber: 1 })
        .pipe(
          tap((res) => {
            this.pageParams = { pageSize: 10, pageNumber: 1, totalItems: res.page.totalElements };
            this.loading = false;
          }),
          startWith({ datasources: [], page: null }),
        );
    }
  }

  get workspaceData(): Workspace | undefined {
    return this.dialogService.data;
  }

  onPageChange(event: PageParams): void {
    this.pageParams = { ...event, totalItems: this.pageParams.totalItems };
  }

  onSearch(table: Table, target: EventTarget): void {
    table.filter((target as HTMLInputElement).value, 'name', 'contains');
  }

  onFilter(tableFilterEvent: TableFilterEvent) {
    this.pageParams = {
      pageSize: this.pageParams.pageSize,
      pageNumber: 1,
      totalItems: tableFilterEvent.filteredValue.length,
    };
  }
}
