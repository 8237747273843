import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { FavoriteApiService } from '@selfai-platform/bi-api';
import { Observable } from 'rxjs';
import { FAVORITE_LIST_ENTITY_NAME } from '../constants';
import { FavoriteListItem } from '../models';

@Injectable({ providedIn: 'root' })
export class FavoriteListDataService extends DefaultDataService<FavoriteListItem> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private favoriteApiService: FavoriteApiService) {
    super(FAVORITE_LIST_ENTITY_NAME, http, httpUrlGenerator);
  }

  override delete(id: string): Observable<string> {
    return this.favoriteApiService.remove(id);
  }
}
