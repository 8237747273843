import { Injectable } from '@angular/core';
import {
  AllMyWorkspaceApiParams,
  PublicWorkspaceApiParams,
  WorkspaceApiService,
  WorkspaceDetailViewModel,
} from '@selfai-platform/bi-api';
import { PageParams } from '@selfai-platform/shared';
import { mapFiltersBiToApiQueryParams } from '@selfai-platform/shell';
import { Observable, map } from 'rxjs';
import { GUEST_WORKSPACE_ID, MY_WORKSPACE_ID } from '../constants';
import { Workspace, WorkspaceList } from '../models';
import { workspaceApiNormalizer, workspaceListApiNormalizer } from '../normalizers';

@Injectable({ providedIn: 'root' })
export class WorkspaceApiToDomainService {
  constructor(private readonly workspaceApiService: WorkspaceApiService) {}

  deleteWorkspaceRoleGroup(roleGroup: number): Observable<any> {
    return this.workspaceApiService.deleteWorkspaceRoleGroup(roleGroup);
  }

  createWorkspaceRoleGroup(roleGroup: string): Observable<any> {
    return this.workspaceApiService.createWorkspaceRoleGroup(roleGroup);
  }

  setOwners(workspaceId: string, owners: string[]): Observable<any> {
    return this.workspaceApiService.setOwners(workspaceId, owners);
  }

  getMembers(workspaceId: string): Observable<any> {
    return this.workspaceApiService.getMembers(workspaceId);
  }

  setWorkspaceMembers(workspaceId: string, members: any[]): Observable<any> {
    return this.workspaceApiService.setWorkspaceMembers(workspaceId, members);
  }

  createRole(role: any): Observable<any> {
    return this.workspaceApiService.createRole(role);
  }

  updateWorkspaceRoleGroup(workspaceId: string, roleName: string): Observable<any> {
    return this.workspaceApiService.updateWorkspaceRoleGroup(workspaceId, roleName);
  }

  deleteWorkspaceMembers(id: string): Observable<any> {
    return this.workspaceApiService.deleteWorkspaceMembers(id);
  }

  updateWorkspaceById(id: string, workspace: Partial<WorkspaceDetailViewModel>): Observable<WorkspaceDetailViewModel> {
    return this.workspaceApiService.updateWorkspaceById(id, workspace);
  }

  createWorkspace(workspace: Partial<WorkspaceDetailViewModel>): Observable<WorkspaceDetailViewModel> {
    return this.workspaceApiService.createWorkspace(workspace);
  }

  removeRole(roleId: number): Observable<any> {
    return this.workspaceApiService.removeRole(roleId);
  }

  getUsers(params: any = {}): Observable<any> {
    return this.workspaceApiService.getUsers(params);
  }

  getWorkspaceRoleGroups(): Observable<any> {
    return this.workspaceApiService.getWorkspaceRoleGroups();
  }

  getWorkspaceRoles(params: any): Observable<any> {
    return this.workspaceApiService.getWorkspaceRoles(params);
  }

  getMyWorkspace(): Observable<Workspace> {
    return this.workspaceApiService
      .getWorkspaceById(MY_WORKSPACE_ID)
      .pipe(map((res) => ({ ...workspaceApiNormalizer(res), id: MY_WORKSPACE_ID })));
  }

  getGuestWorkspace(): Observable<Workspace> {
    return this.workspaceApiService
      .getWorkspaceById(GUEST_WORKSPACE_ID)
      .pipe(map((res) => workspaceApiNormalizer(res)));
  }

  getPublicWorkspace(id: string): Observable<Workspace> {
    return this.workspaceApiService.getWorkspaceById(id).pipe(map((res) => workspaceApiNormalizer(res)));
  }

  getPublicWorkspaceForListWithPermission(id: string): Observable<Workspace> {
    return this.workspaceApiService
      .getWorkspaceForListWithPermission(id)
      .pipe(map((res) => workspaceApiNormalizer(res)));
  }

  getPublicWorkspaceList(pageParams: PageParams): Observable<WorkspaceList> {
    const apiParams: PublicWorkspaceApiParams = {
      page: pageParams.pageNumber - 1,
      size: pageParams.pageSize,
      sort: pageParams.sortField ? `${pageParams.sortField},${pageParams.sortOrder}` : undefined,
    };

    return this.workspaceApiService.getPublicWorkspaces(apiParams).pipe(map((res) => workspaceListApiNormalizer(res)));
  }

  getAllWorkspaceList(pageParams: PageParams): Observable<WorkspaceList> {
    const filters = mapFiltersBiToApiQueryParams(pageParams.filters);

    const apiParams: AllMyWorkspaceApiParams = {
      page: pageParams.pageNumber - 1,
      size: pageParams.pageSize,
      sort: pageParams.sortField ? `${pageParams.sortField},${pageParams.sortOrder}` : undefined,
      name: pageParams.query,
      ...filters,
    };

    return this.workspaceApiService.getAllWorkspaces(apiParams).pipe(map((res) => workspaceListApiNormalizer(res)));
  }

  removeRoleFromGroup(roleGroup: string): Observable<any> {
    return this.workspaceApiService.removeRoleFromGroup(roleGroup);
  }

  getRoleGroups(): Observable<any> {
    return this.workspaceApiService.getRoleGroups();
  }

  updateWorkspace(workspaceId: string, workspace: Partial<Workspace>): Observable<Workspace> {
    return this.workspaceApiService.updateWorkspace(workspaceId, workspace as any) as any;
  }

  addToFavorite(id: string): Observable<void> {
    return this.workspaceApiService.addToFavorite(id);
  }

  deleteFromFavorite(id: string): Observable<void> {
    return this.workspaceApiService.deleteFromFavorite(id);
  }
}
