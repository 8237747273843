import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { SkeletonModule } from 'primeng/skeleton';
import { WorkspaceSearchToolboxComponent } from './workspace-search-toolbox.component';

@NgModule({
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    TranslateModule,
    PanelModule,
    ButtonModule,
    RouterModule,
    SkeletonModule,
  ],
  declarations: [WorkspaceSearchToolboxComponent],
  exports: [WorkspaceSearchToolboxComponent],
})
export class WorkspaceSearchToolboxComponentModule {}
