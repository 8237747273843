import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PageLoaderService } from '../../services/page-loader.service';

@Component({
    selector: 'selfai-platform-page-loader',
    imports: [CommonModule, ProgressSpinnerModule, BlockUIModule],
    templateUrl: './page-loader.component.html',
    styleUrls: ['./page-loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageLoaderComponent {
  loading$ = this.pageLoaderService.loading$;

  constructor(private readonly pageLoaderService: PageLoaderService) {}
}
