import { ChangeDetectionStrategy, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { DestroyService } from '@selfai-platform/shared';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { CopyPasteCubeService } from '../../services/copy-paste-cube.service';

@Component({
    selector: 'selfai-platform-cube-list-sidebar',
    templateUrl: './cube-list-sidebar.component.html',
    styleUrls: ['./cube-list-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DestroyService, CopyPasteCubeService],
    standalone: false
})
export class CubeListSidebarComponent implements OnInit {
  visible$ = new BehaviorSubject(false);

  private documentEscapeListener?: (() => void) | null;

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
    private readonly destroy$: DestroyService,
  ) {}

  ngOnInit(): void {
    this.visible$.pipe(takeUntil(this.destroy$)).subscribe((visible) => {
      if (visible) {
        this.bindDocumentEscapeListener();
      } else {
        this.unbindDocumentEscapeListener();
      }
    });
  }

  private bindDocumentEscapeListener(): void {
    const documentTarget: HTMLElement = this.el ? this.el.nativeElement.ownerDocument : 'document';

    this.documentEscapeListener = this.renderer.listen(documentTarget, 'keydown', (event) => {
      if (event.which == 27) {
        this.visible$.next(false);
      }
    });
  }

  private unbindDocumentEscapeListener(): void {
    if (this.documentEscapeListener) {
      this.documentEscapeListener();
      this.documentEscapeListener = null;
    }
  }
}
