import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TooltipModule } from 'primeng/tooltip';
import { Observable } from 'rxjs';
import { BreadcrumbsMenuItem } from './breadcrumbs-menu.model';
import { BreadcrumbsMenuService } from './breadcrumbs-menu.service';

@Component({
    selector: 'selfai-platform-breadcrumbs-menu',
    templateUrl: './breadcrumbs-menu.component.html',
    styleUrls: ['./breadcrumbs-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, TieredMenuModule, OverlayPanelModule, ButtonModule, TooltipModule]
})
export class BreadcrumbsMenuComponent {
  @ViewChild('op') overlayPanel: OverlayPanel;

  breadcrumbItems$: Observable<BreadcrumbsMenuItem[] | undefined> = this.breadcrumbsMenuService.getBreadcrumbsMenu();

  constructor(private breadcrumbsMenuService: BreadcrumbsMenuService) {}
}
