import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  FavoriteDomainService,
  FavoriteListItem,
  WorkspaceListBreadcrumbService,
  favoriteListItemToDomainItem,
} from '@selfai-platform/bi-domain';
import {
  BiPermission,
  DestroyService,
  LocalStorageKey,
  PREFIX_SETTINGS_LOCAL_STORAGE_KEY,
  PermissionService,
  providePageParams,
} from '@selfai-platform/shared';
import {
  BreadcrumbsMenuService,
  GroupAction,
  SelectedItemsService,
  TableColumn,
  ToolbarAction,
  UI_SETTINGS_VIEW_PAGE_DEFAULT_SIZE,
  provideDataListView,
} from '@selfai-platform/shell';
import { Observable, catchError, forkJoin, mergeMap, of, take, takeUntil, tap } from 'rxjs';
import { FavoriteActionsForItemService, FavoriteDataListViewService } from '../services';

@Component({
    selector: 'selfai-platform-favorite-list',
    templateUrl: './favorite-list.component.html',
    styleUrls: ['./favorite-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        WorkspaceListBreadcrumbService,
        DestroyService,
        ...provideDataListView(FavoriteDataListViewService, FavoriteActionsForItemService),
        ...providePageParams({
            pageSize: UI_SETTINGS_VIEW_PAGE_DEFAULT_SIZE,
            pageNumber: 1,
            sortField: 'targetModifiedTime',
            sortOrder: 'desc',
        }),
        {
            provide: PREFIX_SETTINGS_LOCAL_STORAGE_KEY,
            useValue: LocalStorageKey.FAVORITE_LIST_PREFIX_SETTINGS,
        },
    ],
    standalone: false
})
export class FavoriteListComponent implements OnInit {
  actions$: Observable<ToolbarAction[]>;
  groupActions$: Observable<GroupAction[]>;
  columns: TableColumn<FavoriteListItem>[] = this.favoriteDataListViewService.getColumns();

  iconsByType: Record<FavoriteListItem['domain'] | string, string> = {
    DASHBOARD: 'pi pi-chart-bar',
    WORKBOOK: 'pi pi-book',
    WORKSPACE: 'pi pi-briefcase',
  };

  constructor(
    private readonly favoriteDataListViewService: FavoriteDataListViewService,
    private readonly workspaceListBreadcrumbService: WorkspaceListBreadcrumbService,
    private readonly selectedItemsService: SelectedItemsService<FavoriteListItem>,
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    private readonly permissionService: PermissionService,
    private readonly favoriteDomainService: FavoriteDomainService,
    private readonly destroy$: DestroyService,
  ) {
  }

  ngOnInit(): void {
    this.actions$ = this.getActions();

    this.groupActions$ = this.getGroupActions();

    this.workspaceListBreadcrumbService
      .getBreadcrumbsMenu()
      .pipe(takeUntil(this.destroy$))
      .subscribe((items) => {
        this.breadcrumbsMenuService.setBreadcrumbsMenu(items);
      });
  }

  private getActions(): Observable<ToolbarAction[]> {
    const actions: ToolbarAction[] = [];

    return of(actions);
  }

  private getGroupActions(): Observable<GroupAction[]> {
    const actions: GroupAction[] = [];

    if (this.hasPermission(BiPermission.KD_WORKSPACE_DELETE_ALL)) {
      actions.push({
        icon: 'pi pi-trash',
        buttonClass: 'p-button-primary',
        labelTranslate: 'msg.favorite-list.table-actions.remove-selected',
        action: (eventTarget) => this.removeSelectedFromFavorite(eventTarget),
      });
    }

    return of(actions);
  }

  private removeSelectedFromFavorite(eventTarget: EventTarget): void {
    this.selectedItemsService
      .getSelectedItems()
      .pipe(
        mergeMap((selectedItems) =>
          forkJoin(
            selectedItems.map((item) =>
              this.favoriteDomainService
                .removeFromFavorite(favoriteListItemToDomainItem(item.domain), item.targetId)
                .pipe(catchError(() => of(null))),
            ),
          ),
        ),
        take(1),
        tap(() => this.favoriteDataListViewService.triggerReloadData()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private hasPermission(permission: BiPermission): boolean {
    return this.permissionService.hasBiPermission(permission);
  }
}
