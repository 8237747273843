<form [formGroup]="form" class="flex flex-column gap-3" (ngSubmit)="save()">
  <div class="flex flex-column gap-2">
    <label htmlFor="name">{{ 'msg.workspace-edit-name-dialog.name.label' | translate }}</label>
    <input
      id="name"
      class="w-full"
      pAutoFocus
      [autofocus]="true"
      formControlName="name"
      pInputText
      type="text"
      placeholder="{{ 'msg.workspace-edit-name-dialog.name.placeholder' | translate }}"
    />
  </div>

  <div class="flex flex-column gap-2">
    <label htmlFor="description">
      {{ 'msg.workspace-edit-name-dialog.description.label' | translate }}
      &nbsp;
      <i class="pi pi-info-circle"></i>
    </label>
    <textarea
      rows="4"
      class="w-full"
      pInputTextarea
      id="description"
      formControlName="description"
      placeholder="{{ 'msg.workspace-edit-name-dialog.description.placeholder' | translate }}"
      [autoResize]="true"
    ></textarea>
  </div>

  <div class="flex align-self-end gap-3">
    <button pButton type="button" class="p-button-text p-button-link" (click)="close()">
      {{ 'msg.workspace-edit-name-dialog.button.cancel' | translate }}
    </button>
    <button pButton type="submit">{{ 'msg.workspace-edit-name-dialog.button.save' | translate }}</button>
  </div>
</form>
