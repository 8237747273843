import { Injectable, OnDestroy } from '@angular/core';
import { WorkbookPinsDomainService } from '@selfai-platform/bi-domain';
import { Subject, Subscription, take, takeUntil, tap, timer } from 'rxjs';

export const SECONDS_TO_DELETE = 5;

@Injectable()
export class WorkbookPinsDeleteService implements OnDestroy {
  markAsDeletedMap = new Map<string, boolean>();

  private cancelDeleteMap = new Map<string, Subject<boolean>>();
  private subscription = new Subscription();

  constructor(private readonly workbookPinsDomainService: WorkbookPinsDomainService) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  markAsDeleted(workbookId: string, dashboardId: string): void {
    this.cancelDeleteMap.set(dashboardId, new Subject<boolean>());

    this.subscription.add(
      timer(SECONDS_TO_DELETE * 1000)
        .pipe(
          tap(() => {
            this.workbookPinsDomainService.deleteDashboardFromPins(workbookId, dashboardId).pipe(take(1)).subscribe();
          }),
          takeUntil(this.cancelDeleteMap.get(dashboardId)),
        )
        .subscribe({
          complete: () => {
            this.markAsDeletedMap.delete(dashboardId);
            this.cancelDeleteMap.delete(dashboardId);
          },
        }),
    );

    this.markAsDeletedMap.set(dashboardId, true);
  }

  cancelDelete(dashboardId: string): void {
    this.cancelDeleteMap.get(dashboardId).next(true);
    this.markAsDeletedMap.delete(dashboardId);
  }
}
