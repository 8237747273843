import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'selfai-platform-workspaces-roles-manager-bottom',
    templateUrl: './workspaces-roles-manager-bottom.component.html',
    styleUrls: ['./workspaces-roles-manager-bottom.component.scss', '../workspaces-roles-manager-styles.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WorkspacesRolesManagerBottomComponent {
  @Input() isSaving: boolean;
  @Input() disabled: boolean;

  @Output() apply = new EventEmitter<void>();
  @Output() saveAndExit = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
}
