import { Component, OnInit } from '@angular/core';

import { DestroyService } from '@selfai-platform/shared';
import { SelfaiTranslateService } from '@selfai-platform/shell';
import { takeUntil } from 'rxjs';
import { AG_GRID_LOCALE_EN, AG_GRID_TRANSLATION_DICTIONARY } from '../../../translation/ag-grid';
import { provideBaseChartServices } from '../../services';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'input-grid-chart',
    template: '',
    // template: `
    //   <ag-grid-angular
    //     #agGrid
    //     style="width: 100%; height: 100%;"
    //     id="myGrid"
    //     class="ag-theme-balham"
    //     [localeText]="localeText"
    //     [columnDefs]="columnDefs"
    //     [defaultColDef]="defaultColDef"
    //     [sideBar]="this.sideBar"
    //     [rowData]="rowData"
    //     (gridReady)="onGridReady($event)"
    //   ></ag-grid-angular>
    // `,
    providers: [...provideBaseChartServices()],
    standalone: false
})
export class InputGridChartComponent implements OnInit {
  columnDefs: any;
  defaultColDef = {
    flex: 1,
    minWidth: 100,
    enableValue: true,
    enableRowGroup: true,
    enablePivot: true,
    editable: true,
    sortable: true,
    filter: true,
    showToolPanel: true,
  };
  sideBar = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      },
    ],
    defaultToolPanel: 'columns',
  };
  rowData: any;
  localeText!: { [key: string]: string };

  private gridApi: any;
  private gridColumnApi: any;

  constructor(
    private readonly destroy$: DestroyService,
    private readonly selfaiTranslateService: SelfaiTranslateService,
  ) {
    this.setGridTranslation();
    this.selfaiTranslateService.$onLanguageChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setGridTranslation();
    });
  }

  ngOnInit() {
    this.columnDefs = [];
    this.rowData = JSON.parse(localStorage.getItem('share') as string);

    const columnsKeys = Reflect.ownKeys(this.rowData[0]);

    const modifyKeys = columnsKeys;

    for (const item of modifyKeys) {
      this.columnDefs.push({ field: item });
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.rowData = JSON.parse(localStorage.getItem('share') as string);
  }

  private setGridTranslation() {
    const currentLanguage = this.selfaiTranslateService.getCurrentLanguage();
    let localeText = AG_GRID_TRANSLATION_DICTIONARY[currentLanguage];
    if (!localeText) {
      localeText = AG_GRID_LOCALE_EN;
    }
    this.localeText = localeText;
  }
}
