import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'selfai-platform-constant-value-field',
    templateUrl: './constant-value-field.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ConstantValueFieldComponent),
            multi: true,
        },
    ],
    standalone: false
})
export class ConstantValueFieldComponent implements ControlValueAccessor {
  value = '';
  disabled = false;

  @Input() label!: string;
  @Input() placeholder = '';

  private onChange!: (_: string) => void;
  private onTouched!: () => void;

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: (_: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  inputOnChange(value: unknown): void {
    this.updateValue(value as string);
  }

  protected updateValue(value: string): void {
    this.value = value;
    this.onChange(value);
    this.onTouched();
  }
}
