import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  CubeWorkflowData,
  EnrichmentToolBatchFormGroup,
  EnrichmentToolBatchParameters,
  EnrichmentToolBatchRawParameters,
  GraphNode,
} from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { CodeEditorComponentModule } from '../../../../code-editor';
import { EditSelectionFieldComponentModule } from '../../../../edit-selection-field/edit-selection-field.module';
import { FormFieldComponentModule } from '../../../../form-field/form-field.module';
import { WorkflowEditorFacadeService } from '../../../../workflow-editor';
import { CubeDialogManagementService } from '../../../services/cube-dialog-management.service';
import { DialogHeaderComponentModule } from '../../dialog-header';
import { DialogHeaderService } from '../../dialog-header/dialog-header.service';

@Component({
    selector: 'selfai-platform-enrichment-tool-batch',
    imports: [
        CommonModule,
        InputTextModule,
        ReactiveFormsModule,
        FormFieldComponentModule,
        DialogHeaderComponentModule,
        TranslateModule,
        MessageModule,
        CodeEditorComponentModule,
        EditSelectionFieldComponentModule,
    ],
    templateUrl: './enrichment-tool-batch.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DialogHeaderService]
})
export class EnrichmentToolBatchComponent {
  get node(): GraphNode<EnrichmentToolBatchRawParameters> {
    return this.dialogService.data.selectedNode;
  }

  get nodeId(): string {
    return this.node.id;
  }

  get hasParameters(): boolean {
    return Boolean(this.node.parameters);
  }

  get nodeParameters(): EnrichmentToolBatchParameters {
    return this.normalizeRawParameters(this.node.parameters?.serialize());
  }

  form = new FormGroup<EnrichmentToolBatchFormGroup>({
    dataSourceId: new FormControl(null, [Validators.required]),
    sqlQuery: new FormControl(null),
    jdbcDriver: new FormControl(null, [Validators.required]),
    jdbcUrl: new FormControl(null, [Validators.required]),
    column: new FormControl(null, [Validators.required]),
  });

  constructor(
    private readonly workflowEditorFacadeService: WorkflowEditorFacadeService,
    private readonly dialogService: DialogService<undefined, CubeWorkflowData<EnrichmentToolBatchRawParameters>>,
    private readonly dialogHeaderService: DialogHeaderService,
    private readonly cubeDialogManagementService: CubeDialogManagementService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.form.patchValue(this.nodeParameters);
    this.dialogHeaderService.initCustomHeaderComponent(
      this.nodeId,
      this.translateService.instant('workflow.cubes.enrichment-tool.modal-header'),
      this.onCloseDialog.bind(this),
      this.onSubmit.bind(this),
    );
    setTimeout(() => {
      this.cubeDialogManagementService.setFocus(this.nodeId);
    });
  }

  onSubmit(): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.workflowEditorFacadeService.updateNodeParamterValues({
        id: this.nodeId,
        parameters: this.normalizeFormValuesToApiModel(this.form.value as EnrichmentToolBatchParameters),
      });
      this.dialogService.close();
    }
  }

  onCloseDialog(): void {
    this.dialogService.close();
  }

  private normalizeRawParameters(params: EnrichmentToolBatchRawParameters): EnrichmentToolBatchParameters {
    return {
      dataSourceId: params['data source'],
      sqlQuery: params['SQL query'],
      jdbcDriver: params['JBDC Driver class'],
      jdbcUrl: params['JDBC URL'],
      // we don't support index type
      column:
        params['Source column mapping'] && params['Source column mapping'].type !== 'index'
          ? params['Source column mapping'].value
          : null,
    };
  }

  private normalizeFormValuesToApiModel(formValues: EnrichmentToolBatchParameters): EnrichmentToolBatchRawParameters {
    return {
      'data source': formValues.dataSourceId,
      'SQL query': formValues.sqlQuery,
      'JBDC Driver class': formValues.jdbcDriver,
      'JDBC URL': formValues.jdbcUrl,
      'Source column mapping': formValues.column ? { type: 'column', value: formValues.column } : null,
    };
  }
}
