import { Injectable } from '@angular/core';
import { CHART_STRING_DELIMITER, ChartColorList, ChartPivotType, PivotTableInfo } from '@selfai-platform/bi-domain';
import { LegendComponentOption } from 'echarts';
import _ from 'lodash';
import { LegendOptionConverter } from '../../converters';
import { ChartLegendService } from '../chart-legend.service';

@Injectable()
export class PieChartLegendService extends ChartLegendService {
  override setLegendOptions(
    fieldInfo: PivotTableInfo,
    pivotInfo: PivotTableInfo,
    fieldOriginInfo: PivotTableInfo,
  ): void {
    const uiOption = this.chartOptionsService.getUiOptions();
    const chartOption = this.chartOptionsService.getEChartOptions();

    if (chartOption.legend) {
      let legendData: string[];
      let fieldIdx: number;
      let pivotType: ChartPivotType;

      const fieldInfo = _.cloneDeep(fieldOriginInfo);

      Object.entries(fieldInfo).forEach(([key, value]) => {
        if (value.indexOf(uiOption.color['targetField']) > -1) {
          fieldIdx = value.indexOf(uiOption.color['targetField']);
          pivotType =
            key === ChartPivotType.COLS
              ? ChartPivotType.COLS
              : key === ChartPivotType.ROWS
              ? ChartPivotType.ROWS
              : ChartPivotType.AGGS;
        }
      });

      if (fieldInfo[pivotType] && fieldInfo[pivotType].length > 1) {
        legendData = pivotInfo[pivotType].map((value) => {
          return !value.split(CHART_STRING_DELIMITER)[fieldIdx] ? value : value.split(CHART_STRING_DELIMITER)[fieldIdx];
        });

        legendData = [...new Set(legendData)];
      } else {
        legendData = pivotInfo[pivotType];
      }

      (chartOption.legend as LegendComponentOption) = Object.assign(chartOption.legend as LegendComponentOption, {
        data: legendData,
        lineStyle: { color: ChartColorList[uiOption.color['schema']] },
      });

      if ((chartOption['dataInfo'] as any).existEtcData) {
        const legend = chartOption.legend as LegendComponentOption;
        this.chartOptionsService.setEChartOptions({
          ...LegendOptionConverter.convertLegend(chartOption, uiOption),
          legend: {
            ...legend,
            data: legend.data ? [...legend.data, 'OTHER'] : ['OTHER'],
          },
        });
      } else {
        this.chartOptionsService.setEChartOptions(LegendOptionConverter.convertLegend(chartOption, uiOption));
      }
    }
  }
}
