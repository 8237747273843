import { Injectable, Optional } from '@angular/core';

import { Observable, filter, forkJoin, from, map, merge, mergeMap, of, switchMap, take, tap } from 'rxjs';

import { Loader, LoaderUtil, UrlPageParamsLegacyService } from '@selfai-platform/shared';

import {
  ChartApi,
  ChartApiService,
  ConfigurationDataSourceApiModel,
  WidgetApiModel,
  WidgetListQueryParams,
} from '@selfai-platform/bi-api';
import { buildApiUrlImage } from '../../core';
import {
  BoardConfiguration,
  BoardDataSource,
  Dashboard,
  DashboardDomainService,
  GetDashboardResult,
  LayoutWidgetInfo,
  createBoardSource,
  createLayoutWidgetInfo,
  findDataSourceOnBoard,
  setDataSourceAndRelations,
} from '../../dashboard';
import { Datasource, DatasourceDomainService } from '../../datasource';
import { Chart, ChartList } from '../models';
import { WidgetApiToDomainService } from './widget-api-to-domain.service';

const defaultWidgetPageParams: WidgetListQueryParams = {
  page: 0,
  size: 20,
  type: 'page',
  sort: 'modifiedTime,desc',
  projection: 'forPageWidgetListView',
};

@Injectable({ providedIn: 'root' })
export class ChartListService extends LoaderUtil<ChartList> {
  constructor(
    private readonly chartApiService: ChartApiService,
    private readonly widgetApiToDomainService: WidgetApiToDomainService,
    private readonly datasourceDomainService: DatasourceDomainService,
    private readonly dashboardDomainService: DashboardDomainService,
    @Optional() private readonly urlPageParamsService?: UrlPageParamsLegacyService,
  ) {
    super(undefined);
  }

  loadChartList(): void {
    this.getWidgetQueryPageParams().subscribe((widgetListQueryParams) => {
      this.loadFromSource(
        this.chartApiService.loadList(widgetListQueryParams).pipe(
          tap(({ page }) => {
            this.urlPageParamsService?.setPageParams({
              pageNumber: page.number + 1,
              pageSize: page.size,
              totalItems: page.totalElements,
            });
          }),
          map((response) => ({
            ...response,
            charts: (response._embedded?.widgets || []).map(this.normalizeChart.bind(this)),
          })),
        ),
      );
    });
  }

  getChartListState(): Observable<Loader<ChartList>> {
    return this.asObservable().pipe(filter(Boolean));
  }

  removeChart(id: string, datasourceId: string): Observable<unknown> {
    return forkJoin([
      from(this.widgetApiToDomainService.getWidget(id)),
      this.datasourceDomainService.loadDatasourceDetail(datasourceId),
      this.dashboardDomainService.getDashboardByWidgetId(id).pipe(filter(Boolean)),
    ]).pipe(
      switchMap(([, datasource, dashboard]) => {
        const dashboardConfig = this.removeWidgetFromGrid(id, dashboard);
        dashboardConfig.dataSource = this.getDashboardConfigurationFromDatasource(datasource);

        return from([
          this.dashboardDomainService.updateDashboard({
            id: dashboard.id,
            configuration: dashboardConfig,
          }),
          this.widgetApiToDomainService.deleteWidget(id),
        ]);
      }),
    );
  }

  moveChart(id: string, targetDashboardId: string, datasourceId: string): Observable<unknown> {
    return forkJoin([
      from(this.widgetApiToDomainService.getWidget(id)),
      this.dashboardDomainService.loadDashboard(targetDashboardId),
      this.datasourceDomainService.loadDatasourceDetail(datasourceId),
      this.dashboardDomainService.getDashboardByWidgetId(id).pipe(filter(Boolean)),
    ]).pipe(
      switchMap(([oldWidget, targetDashboard, datasource, sourceDashboard]) =>
        this.chartApiService
          .moveChart(id, targetDashboardId)
          .pipe(map((newWidget) => ({ oldWidget, newWidget, targetDashboard, datasource, sourceDashboard }))),
      ),
      mergeMap(({ oldWidget, newWidget, targetDashboard, datasource, sourceDashboard }) => {
        const sourceDashboardConfig = this.removeWidgetFromGrid(oldWidget.id, sourceDashboard);
        sourceDashboardConfig.dataSource = this.getDashboardConfigurationFromDatasource(datasource);
        const targetDashboardConfig = this.addWidgetToGrid(newWidget, targetDashboard);
        targetDashboardConfig.dataSource = this.getDashboardConfigurationFromDatasource(datasource);

        return merge(
          from([
            this.dashboardDomainService.updateDashboard({
              id: targetDashboardId,
              configuration: targetDashboardConfig,
            }),
            this.dashboardDomainService.updateDashboard({
              id: oldWidget.dashBoardId,
              configuration: sourceDashboardConfig,
            }),
          ]),
        );
      }),
    );
  }

  copyToChart(id: string, targetDashboardId: string, datasourceId: string): Observable<unknown> {
    return forkJoin([
      this.chartApiService.copyToChart(id, targetDashboardId),
      this.dashboardDomainService.loadDashboard(targetDashboardId),
      this.datasourceDomainService.loadDatasourceDetail(datasourceId),
    ]).pipe(
      map(([widget, targetDashboard, datasource]) => ({ widget, targetDashboard, datasource })),
      switchMap(({ widget, targetDashboard, datasource }) => {
        const targetDashboardConfig = this.addWidgetToGrid(widget, targetDashboard);
        targetDashboardConfig.dataSource = this.getDashboardConfigurationFromDatasource(datasource);

        return from([
          this.dashboardDomainService.updateDashboard({
            id: targetDashboardId,
            configuration: targetDashboardConfig,
          }),
        ]);
      }),
    );
  }

  private getDashboardConfigurationFromDatasource(datasourceFull: Datasource): BoardDataSource {
    const dataSource = createBoardSource();
    dataSource.name = datasourceFull.name;
    dataSource.id = datasourceFull.id;
    dataSource.engineName = datasourceFull.engineName;
    dataSource.temporary = false;
    dataSource.type = 'default';
    dataSource.uiDescription = datasourceFull.description;

    return dataSource;
  }

  private getWidgetQueryPageParams(): Observable<WidgetListQueryParams> {
    if (this.urlPageParamsService) {
      return this.urlPageParamsService.getPageParams().pipe(
        take(1),
        map((pageParams) => {
          return {
            ...defaultWidgetPageParams,
            page: pageParams.pageNumber - 1,
            size: pageParams.pageSize,
            containsText: pageParams.query,
            sort: pageParams.sortField && `${pageParams.sortField},${pageParams.sortOrder}`,
          };
        }),
      );
    }

    return of(defaultWidgetPageParams);
  }

  private normalizeChart(chartApi: ChartApi): Chart {
    return {
      ...chartApi,
      createdTime: new Date(chartApi.createdTime),
      modifiedTime: new Date(chartApi.modifiedTime),
      imageUrl: buildApiUrlImage(chartApi.imageUrl),
    };
  }

  private removeWidgetFromGrid(id: string, dashboard: Dashboard) {
    const widgets: LayoutWidgetInfo[] = dashboard.configuration.widgets
      .filter(({ ref: widgetId }) => widgetId !== id)
      .map((widget) => ({ ...widget, isInLayout: true }));

    dashboard.configuration = { ...dashboard.configuration, widgets };

    return dashboard.configuration;
  }

  private addWidgetToGrid(widget: WidgetApiModel, dashboard: Dashboard | GetDashboardResult): BoardConfiguration {
    const currentWidgets = dashboard?.configuration?.widgets || [];

    const widgets: LayoutWidgetInfo[] = [
      ...currentWidgets,
      createLayoutWidgetInfo(widget.id, widget.type || widget.configuration?.type),
    ].map((widget) => ({ ...widget, isInLayout: true }));

    dashboard.configuration = { ...dashboard.configuration, widgets };

    if (
      widget.configuration?.dataSource &&
      widget.configuration.dataSource.type !== 'multi' &&
      !findDataSourceOnBoard(dashboard, widget.configuration.dataSource as ConfigurationDataSourceApiModel)
    ) {
      return setDataSourceAndRelations(dashboard as any, [
        ...(dashboard.dataSources as any[]),
        widget.configuration.dataSource,
      ]).configuration;
    }

    return dashboard.configuration;
  }

  private buildUrlImage(url: string): string {
    return '/api/images/load/url?url=' + encodeURIComponent(url);
  }
}
