import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, filter, switchMap, tap } from 'rxjs';
import { Workspace } from '../models';
import { WorkspaceDomainService } from './workspace-domain.service';

@Injectable()
export class CurrentWorkspaceDomainService {
  constructor(
    private readonly workspaceDomainService: WorkspaceDomainService,
    private readonly route: ActivatedRoute,
  ) {}

  getCurrentWorkspace(): Observable<Workspace> {
    return this.route.paramMap.pipe(
      tap((paramMap) => {
        if (!paramMap.has('workspaceId')) {
          console.error(
            `Workspace id not found in route params \n\r`,
            'WorkspaceDomainService must be provided in component which include in route',
          );
        }
      }),
      filter((paramMap) => paramMap.has('workspaceId')),
      switchMap((paramMap) => {
        return this.workspaceDomainService.getWorkspace(paramMap.get('workspaceId') as string);
      }),
    );
  }
}
