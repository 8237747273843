import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataconnectionResult, DataconnectionSchema, S3ConnectionParams } from '../models';
import { DataconnectionDomainApiService } from './dataconnection-domain-api.service';

@Injectable({ providedIn: 'root' })
export class DataconnectionDomainService {
  constructor(private readonly dataconnectionDomainApiService: DataconnectionDomainApiService) {}

  checkConnectionS3(params: S3ConnectionParams): Observable<DataconnectionResult> {
    return this.dataconnectionDomainApiService.checkConnectionS3(params);
  }

  getDataSchemaS3(params: S3ConnectionParams): Observable<DataconnectionSchema> {
    return this.dataconnectionDomainApiService.getDataSchemaS3(params);
  }
}
