import { Component, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Modal } from '../../../domain/modal';
import { AbstractComponent } from '../../abstract.component';

@Component({
    selector: 'app-delete-modal',
    templateUrl: './delete.component.html',
    standalone: false
})
export class DeleteModalComponent extends AbstractComponent implements OnInit, OnDestroy {
  @Output() public deleteConfirm = new EventEmitter();

  @Input() public enableCloseOutput = false;
  @Output() public closeOutput = new EventEmitter();

  public isShow = false;

  public modal: Modal;

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public init(modal: Modal) {
    this.modal = modal;
    this.isShow = true;
  }

  public done() {
    this.isShow = false;
    this.deleteConfirm.emit(this.modal);
  }

  public close() {
    this.isShow = false;
    if (this.enableCloseOutput) {
      this.closeOutput.emit();
    }
  }
}
