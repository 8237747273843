import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { KdConfigService, normalizeApiEndpoint } from '@selfai-platform/shared';

import { ChartListApiResponse, WidgetApiModel, WidgetListQueryParams } from '../models';

const PATH_CHART_LIST = '/widgets';
const PATH_CHART = '/widgets';

@Injectable({
  providedIn: 'root',
})
export class ChartApiService {
  get apiUrl(): string {
    const config = this.kdConfigService.getConfig();

    return [config.apiUrl, 'api'].join('/');
  }

  constructor(private readonly http: HttpClient, private readonly kdConfigService: KdConfigService) {}

  loadList(queryParams: WidgetListQueryParams): Observable<ChartListApiResponse> {
    return this.http.get<ChartListApiResponse>(this.buildUrl(PATH_CHART_LIST), {
      params: new HttpParams({ fromObject: this.cleanUndefinedParams(queryParams) }),
    });
  }

  removeChart(id: string): Observable<unknown> {
    return this.http.delete<unknown>(`${this.buildUrl(PATH_CHART)}/${id}`);
  }

  moveChart(id: string, dashboardId: string): Observable<WidgetApiModel> {
    return this.http.post<WidgetApiModel>(`${this.buildUrl(PATH_CHART)}/${id}/move`, null, {
      params: new HttpParams({ fromObject: this.cleanUndefinedParams({ dashboardId, projection: 'forDetailView' }) }),
    });
  }

  copyToChart(id: string, dashboardId?: string): Observable<WidgetApiModel> {
    return this.http.post<WidgetApiModel>(`${this.buildUrl(PATH_CHART)}/${id}/copy`, null, {
      params: new HttpParams({ fromObject: this.cleanUndefinedParams({ dashboardId, projection: 'forDetailView' }) }),
    });
  }

  private buildUrl(endpoint: string): string {
    return `${this.apiUrl}/${normalizeApiEndpoint(endpoint)}`;
  }

  private cleanUndefinedParams<T extends Record<string, unknown>>(queryParams: T): T {
    return Object.fromEntries(Object.entries(queryParams).filter(([, value]) => Boolean(value))) as T;
  }
}
