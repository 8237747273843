import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { SkeletonModule } from 'primeng/skeleton';
import { S3ConnectionParamsComponent } from './s3-connection-params.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, InputTextModule, ButtonModule, TranslateModule, SkeletonModule],
  declarations: [S3ConnectionParamsComponent],
  exports: [S3ConnectionParamsComponent],
})
export class S3ConnectionParamsComponentModule {}
