import { PaginationApi } from '../../common';

export type FavoriteListItemApiDomain = 'WORKBOOK' | 'DASHBOARD' | 'WORKSPACE';

export interface FavoriteListItemApi {
  id: string;
  version: number;
  domain: FavoriteListItemApiDomain;
  createdBy: string;
  createdTime: string;
  targetId: string;
  targetModifiedBy: string;
  targetModifiedTime: string;
  targetName: string;
  workbookId?: string;
}

export interface FavoriteListApi {
  _embedded?: {
    favorites: FavoriteListItemApi[];
  };
  page: PaginationApi;
}

export interface FavoriteListQueryParams
  extends Partial<Record<string, string | number | boolean | ReadonlyArray<string | number | boolean>>> {
  page?: number;
  size?: number;
  /**
   * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   */
  sort?: string;
  search?: string;
}

export const FavoriteDomainApi = {
  Workbooks: 'workbooks',
  Dashboard: 'dashboards',
  Workspace: 'workspaces',
} as const;

export type FavoriteDomainApi = typeof FavoriteDomainApi[keyof typeof FavoriteDomainApi];
