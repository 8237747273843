import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

import {
  BoardConfiguration,
  Dashboard,
  createBoardSyncOptions,
  createBoardWidgetOptions,
  BoardGlobalOptions,
  BoardLayoutOptions,
  BoardLayoutType,
  BoardWidgetOptions,
  SourceType,
  WidgetShowType,
} from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../../common/component/abstract.component';
import { EventBroadcaster } from '../../../common/event/event.broadcaster';

@Component({
    selector: 'app-dashboard-layout-config',
    templateUrl: './dashboard.layout.config.component.html',
    standalone: false
})
export class DashboardLayoutConfigComponent extends AbstractComponent implements OnInit {
  private _widgetOpts: BoardWidgetOptions = createBoardWidgetOptions();

  @Input('dashboard')
  public inputDashboard: Dashboard;

  @Input('boardConf')
  public inputBoardConf: BoardConfiguration;

  @Output()
  public changeBoardConf: EventEmitter<BoardConfiguration> = new EventEmitter();

  public boardConf: BoardConfiguration;
  public dashboard: Dashboard;

  public layoutHeight = 900;
  public layoutMargin = 5;

  public isLiveDatasource = false;
  public isShowIntervalList = false;
  public syncIntervals: SyncInterval[] = [
    { name: this.translateService.instant('msg.comm.ui.not-used'), interval: 0 },
    { name: '1', interval: 1 },
    { name: '5', interval: 5 },
    { name: '10', interval: 10 },
    { name: '30', interval: 30 },
    { name: '60', interval: 60 },
  ];
  public selectedInterval: SyncInterval;

  constructor(protected broadCaster: EventBroadcaster, protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
    this.selectedInterval = this.syncIntervals[0];
  }

  public ngOnInit() {
    super.ngOnInit();

    const boardConf: BoardConfiguration = this.boardConf;
    const globalOpts: BoardGlobalOptions = boardConf.options;

    this.isLiveDatasource = this.dashboard.dataSources.some((item) => {
      return SourceType.REALTIME === item.srcType && item.id === this.dashboard.configuration.dataSource.id;
    });
    if (this.isLiveDatasource && globalOpts.sync && globalOpts.sync.enabled) {
      this.selectedInterval = this.syncIntervals.find((item) => item.interval === globalOpts.sync.interval);
    }

    globalOpts.layout.layoutType || (globalOpts.layout.layoutType = BoardLayoutType.FIT_TO_SCREEN);
    globalOpts.layout.layoutHeight && (this.layoutHeight = globalOpts.layout.layoutHeight);
    globalOpts.layout.widgetPadding && (this.layoutMargin = globalOpts.layout.widgetPadding);

    this._widgetOpts.showTitle = globalOpts.widget.showTitle ? globalOpts.widget.showTitle : WidgetShowType.BY_WIDGET;
    this._widgetOpts.showLegend = globalOpts.widget.showLegend
      ? globalOpts.widget.showLegend
      : WidgetShowType.BY_WIDGET;
    this._widgetOpts.showMinimap = globalOpts.widget.showMinimap
      ? globalOpts.widget.showMinimap
      : WidgetShowType.BY_WIDGET;

    this.subscriptions.push(
      this.broadCaster.on<any>('TOGGLE_TITLE').subscribe(() => {
        this._widgetOpts.showTitle = WidgetShowType.BY_WIDGET;
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<any>('TOGGLE_LEGEND').subscribe(() => {
        this._widgetOpts.showLegend = WidgetShowType.BY_WIDGET;
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on<any>('TOGGLE_MINIMAP').subscribe(() => {
        this._widgetOpts.showMinimap = WidgetShowType.BY_WIDGET;
      }),
    );

    this.safelyDetectChanges();
  }

  public ngOnChanges(changes: SimpleChanges) {
    const confChanges: SimpleChange = changes.inputBoardConf;
    const boardChanges: SimpleChange = changes.inputDashboard;
    if (confChanges && confChanges.currentValue) {
      this.boardConf = confChanges.currentValue;
    }
    if (boardChanges && boardChanges.currentValue) {
      this.dashboard = boardChanges.currentValue;
    }
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public isFitToScreen() {
    return this.boardConf.options.layout.layoutType === BoardLayoutType.FIT_TO_SCREEN;
  }

  public setFitToScreen() {
    const layoutInfo: BoardLayoutOptions = this.boardConf.options.layout;
    layoutInfo.layoutType = BoardLayoutType.FIT_TO_SCREEN;
    delete layoutInfo.layoutHeight;
    this.changeConfig();
  }

  public isFitToHeight() {
    return this.boardConf.options.layout.layoutType === BoardLayoutType.FIT_TO_HEIGHT;
  }

  public setFitToHeight() {
    const layoutInfo: BoardLayoutOptions = this.boardConf.options.layout;
    layoutInfo.layoutType = BoardLayoutType.FIT_TO_HEIGHT;
    layoutInfo.layoutHeight = this.layoutHeight;
    this.changeConfig();
  }

  public setLayoutHeight(heightValue: number) {
    const layoutInfo: BoardLayoutOptions = this.boardConf.options.layout;
    if (BoardLayoutType.FIT_TO_HEIGHT === layoutInfo.layoutType) {
      if (heightValue < 900) {
        heightValue = 900;
      } else if (heightValue > 2400) {
        heightValue = 2400;
      }
      this.layoutHeight = heightValue;
      layoutInfo.layoutHeight = heightValue;
      this.changeConfig();
    }
  }

  public setLayoutMargin(marginValue: number) {
    if (marginValue < 0) {
      marginValue = 0;
    } else if (marginValue > 100) {
      marginValue = 100;
    }
    this.layoutMargin = marginValue;
    this.boardConf.options.layout.widgetPadding = marginValue;
    this.changeConfig();
  }

  public isByWidgetFeature(feature: string) {
    return this._widgetOpts[feature] === WidgetShowType.BY_WIDGET;
  }

  public isCheckedFeature(feature: string) {
    return this._widgetOpts[feature] === WidgetShowType.ON;
  }

  public getFeatureLabel(feature: string): string {
    return this._widgetOpts[feature];
  }

  public featureClickHandler(feature: string) {
    this._widgetOpts[feature] =
      this._widgetOpts[feature] === WidgetShowType.ON ? WidgetShowType.OFF : WidgetShowType.ON;
    this.boardConf.options.widget = this._widgetOpts;
    this.changeConfig();
  }

  public changeConfig() {
    this.changeBoardConf.emit(this.boardConf);
  }

  public selectSyncInterval(item: SyncInterval) {
    this.selectedInterval = item;
    if (0 < item.interval) {
      this.boardConf.options.sync = createBoardSyncOptions(item.interval);
    } else {
      this.boardConf.options.sync = createBoardSyncOptions();
    }
    this.changeConfig();
  }
}

class SyncInterval {
  public name: string;
  public interval: number;
}
