import { Injectable } from '@angular/core';
import { WorkbookListItem, Workspace } from '@selfai-platform/bi-domain';
import { BiEntityPermission } from '@selfai-platform/shared';
import { ActionsForItemService, DataListItemAction } from '@selfai-platform/shell';
import { map, Observable } from 'rxjs';
import { WorkbookDataListViewService } from './workbook-data-list-view.service';

@Injectable()
export class WorkbookActionsForItemService extends ActionsForItemService<WorkbookListItem> {
  constructor(private readonly workbookDataListViewService: WorkbookDataListViewService) {
    super();
  }

  override getActionsForItem(workbook: WorkbookListItem): Observable<DataListItemAction[]> {
    return this.workbookDataListViewService.workspace$.pipe(
      map((workspace) => {
        const menuItems: DataListItemAction[] = [];
        if (this.hasPermissionForEntity(workspace, BiEntityPermission.WORKBOOK_CREATE)) {
          menuItems.push({
            labelTranslate: 'msg.workbook-list.table-actions.duplicate',
            icon: 'pi pi-clone',
            action: () => this.workbookDataListViewService.duplicate(workbook),
          });
        }

        if (this.hasPermissionForEntity(workspace, BiEntityPermission.WORKBOOK_VIEW)) {
          menuItems.push({
            labelTranslate: 'msg.workbook-list.table-actions.export',
            icon: 'pi pi-upload',
            action: () => this.workbookDataListViewService.export(workbook),
          });
        }

        if (this.hasPermissionForEntity(workspace, BiEntityPermission.WORKBOOK_DELETE)) {
          menuItems.push({
            labelTranslate: 'msg.workbook-list.table-actions.remove',
            icon: 'pi pi-trash',
            action: () => this.workbookDataListViewService.remove(workbook),
          });
        }

        return menuItems;
      }),
    );
  }

  private hasPermissionForEntity(entity: Workspace, permission: BiEntityPermission): boolean {
    return entity.permissions?.includes(permission);
  }
}
