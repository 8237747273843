import { Component, ElementRef, Injector, Input } from '@angular/core';

import * as _ from 'lodash';

import { ChartUtil, TooltipOptionConverter } from '@selfai-platform/bi-chart-engine';
import { DashboardField as Field, Shelf, UIMapOption, UiChartDataLabelDisplayType } from '@selfai-platform/bi-domain';

import { TooltipOptionComponent } from '../tooltip-option.component';

@Component({
    selector: 'map-tooltip-option',
    templateUrl: './map-tooltip-option.component.html',
    standalone: false
})
export class MapTooltipOptionComponent extends TooltipOptionComponent {
  public selectedLayerItems: Field[] = [];

  public unselectedLayerItems: Field[] = [];

  public originUnselectedLayerItems: Field[] = [];

  public searchText: string;

  public addColumnShowFl = false;

  public uiOption: UIMapOption;

  @Input('uiOption')
  public set setUiOption(uiOption: UIMapOption) {
    if (!uiOption.toolTip) {
      uiOption.toolTip = {};
    }

    this.uiOption = uiOption;
  }

  public shelf: Shelf;

  @Input('shelf')
  public set setShelf(shelf: Shelf) {
    if (!shelf || !shelf.layers || !shelf.layers[this.uiOption.layerNum]) return;

    let layerItems = [];

    if (
      !_.isUndefined(this.uiOption.analysis) &&
      !_.isUndefined(this.uiOption.analysis['use']) &&
      this.uiOption.analysis['use'] === true
    ) {
      const tempFields = shelf.layers[this.uiOption.layerNum].fields;
      for (let fieldIndex = 0; tempFields.length > fieldIndex; fieldIndex++) {
        if (tempFields[fieldIndex].name == this.uiOption.analysis.operation.aggregation.column) {
          if (
            _.isUndefined(this.uiOption.analysis.operation.aggregation.type) &&
            tempFields[fieldIndex].isCustomField == true
          ) {
            layerItems = [];
            layerItems.push(tempFields[fieldIndex]);
            break;
          } else if (
            _.isUndefined(tempFields[fieldIndex].isCustomField) ||
            (!_.isUndefined(tempFields[fieldIndex].isCustomField) && tempFields[fieldIndex].isCustomField == false)
          ) {
            layerItems.push(tempFields[fieldIndex]);
          }
        }
      }
    } else {
      for (let layerIndex = 0; this.uiOption.layers.length > layerIndex; layerIndex++) {
        if (shelf && !_.isUndefined(shelf.layers[layerIndex])) {
          shelf.layers[layerIndex].fields.forEach((field) => {
            layerItems.push(field);
          });
        }
      }
    }

    for (const item of layerItems) {
      item['alias'] = ChartUtil.getAlias(item);
    }

    const uniqList = TooltipOptionConverter.returnTooltipDataValue(layerItems);

    if (
      _.isUndefined(this.uiOption.toolTip['isFirstOpenTooltipOption']) ||
      (!_.isUndefined(this.uiOption.toolTip['isFirstOpenTooltipOption']) &&
        this.uiOption.toolTip['isFirstOpenTooltipOption'])
    ) {
      this.uiOption.toolTip['isFirstOpenTooltipOption'] = false;

      this.uiOption.toolTip.displayColumns = ChartUtil.returnNameFromField(uniqList);
    }

    this.selectedLayerItems = [];
    this.unselectedLayerItems = [];

    _.each(uniqList, (field) => {
      const alias = ChartUtil.getAlias(field);

      if (-1 !== this.uiOption.toolTip.displayColumns.indexOf(alias)) {
        this.selectedLayerItems.push(field as any);
      } else {
        this.unselectedLayerItems.push(field as any);
      }
    });
  }

  private setColumns(columns: string[]): Field[] {
    const fields: Field[] = [];

    let field: Field;
    columns.forEach((alias) => {
      field = <any>_.find(this.selectedLayerItems, (field) => {
        return _.eq(alias, ChartUtil.getAggregationAlias(field));
      });

      if (field) fields.push(field);
    });

    return fields;
  }

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public toggleDisplayType(displayType: string, typeIndex: number): void {
    if (!this.uiOption.toolTip.displayTypes) {
      this.uiOption.toolTip.displayTypes = [];
    }

    let isFind = false;
    _.each(this.uiOption.toolTip.displayTypes, (type, index) => {
      if (_.eq(type, displayType)) {
        isFind = true;
        this.uiOption.toolTip.displayTypes[index] = null;
      }
    });

    if (!isFind) {
      this.uiOption.toolTip.displayTypes[typeIndex] = UiChartDataLabelDisplayType[displayType];
    }

    this.apply();
  }

  public returnSearchFields(): Field[] {
    if (_.isEmpty(_.trim(this.searchText))) {
      this.unselectedLayerItems = _.cloneDeep(this.originUnselectedLayerItems);

      return this.unselectedLayerItems;
    }

    this.unselectedLayerItems = this.unselectedLayerItems.filter((item) => {
      if (-1 !== item.name.indexOf(_.trim(this.searchText))) {
        return item;
      }
    });

    return this.unselectedLayerItems;
  }

  public initSearchFields(): void {
    event.stopPropagation();

    this.searchText = '';

    this.unselectedLayerItems = _.cloneDeep(this.originUnselectedLayerItems);
  }

  public deleteSelectedField(index: number): void {
    const deleteField = this.selectedLayerItems.splice(index, 1);

    if (deleteField && deleteField.length > 0) {
      this.unselectedLayerItems.push(deleteField[0]);
      this.originUnselectedLayerItems = _.cloneDeep(this.unselectedLayerItems);
    }

    this.uiOption.toolTip.displayColumns = ChartUtil.returnNameFromField(this.selectedLayerItems);

    this.apply();
  }

  public toggleAddColumn(): void {
    event.stopPropagation();

    this.addColumnShowFl = !this.addColumnShowFl;
  }

  public addColumn(item: Field): void {
    event.stopPropagation();

    const alias = ChartUtil.getAggregationAlias(item);
    const index = _.findIndex(this.selectedLayerItems, (field) => {
      return _.eq(alias, ChartUtil.getAggregationAlias(field));
    });

    if (-1 !== index) return;

    this.selectedLayerItems.push(item);

    const removeIndex = _.findIndex(this.unselectedLayerItems, (field) => {
      return _.eq(alias, ChartUtil.getAggregationAlias(field));
    });
    this.unselectedLayerItems.splice(removeIndex, 1);
    this.originUnselectedLayerItems = _.cloneDeep(this.unselectedLayerItems);

    this.uiOption.toolTip.displayColumns = ChartUtil.returnNameFromField(this.selectedLayerItems);

    this.apply();
  }

  public returnMapTooltip(tooltipType: string): boolean {
    if (
      this.uiOption.toolTip.displayTypes &&
      -1 !== this.uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType[tooltipType])
    ) {
      return true;
    }

    return false;
  }

  public returnDataValueName(item) {
    return ChartUtil.getAlias(item);
  }
}
