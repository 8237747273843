import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { catchError } from 'rxjs';

import { RecycleBinApiToDomainService, Workspace } from '@selfai-platform/bi-domain';
import { BI_ROOT_ROUTE } from '@selfai-platform/shell';

import { AbstractComponent } from '../../../common/component/abstract.component';
import { ConfirmModalComponent } from '../../../common/component/modal/confirm/confirm.component';
import { Modal } from '../../../common/domain/modal';
import { EventBroadcaster } from '../../../common/event/event.broadcaster';
import { Page } from '../../../domain/common/page';
import { WorkspaceService } from '../../service/workspace.service';

@Component({
    selector: 'app-workspace-list',
    templateUrl: './workspace-list.component.html',
    styleUrls: ['./workspace-list.component.scss'],
    standalone: false
})
export class WorkspaceListComponent extends AbstractComponent implements OnInit, OnDestroy {
  private workspaceId: string;

  private updateFl = false;

  @ViewChild('inputSearch')
  private _inputSearch: ElementRef;

  @ViewChild(ConfirmModalComponent, { static: true })
  private confirmModalComponent: ConfirmModalComponent;

  @Output()
  public updateComplete = new EventEmitter();

  public isFavorFl = false;
  public isMyWokrspaceFl = false;
  public isOpenWorkspaceFl = false;

  public isShow = false;

  public sharedWorkspace: Workspace[] = [];
  public cntAllWorkspaces = 0;

  public sort = [
    { name: this.translateService.instant('msg.comm.ui.list.name.asc'), value: 'name,asc', selected: true },
    { name: this.translateService.instant('msg.comm.ui.list.name.desc'), value: 'name,desc', selected: false },
  ];

  public sortText = this.sort[0];

  public searchText = '';

  public isSortFl = false;

  public params = '';

  public page: Page = new Page();

  public sharedWorkspaceManager = false;

  constructor(
    private broadCaster: EventBroadcaster,
    protected renderer: Renderer2,
    protected element: ElementRef,
    protected injector: Injector,
    private workspaceService: WorkspaceService,
    private readonly recycleBinService: RecycleBinApiToDomainService,
  ) {
    super(element, injector);
  }

  public override ngOnDestroy() {
    super.ngOnDestroy();
    this.renderer.removeStyle(document.body, 'overflow');
  }

  public init(workspaceId?: string) {
    this.renderer.setStyle(document.body, 'overflow', 'hidden');

    this.reset();

    this.isShow = true;

    this.workspaceId = workspaceId;

    this.sharedWorkspaceManager = true;

    this.getSharedWorkspace(0);
  }

  public onWorkspaceCreateComplete(id: string) {
    this.router.navigate([BI_ROOT_ROUTE, 'workspace', id]);
  }

  public getSharedWorkspace(pageNum?: number) {
    this.pageLoaderService.show();

    'undefined' !== typeof pageNum && (this.page.page = pageNum);

    const params = {
      size: this.page.size,
      page: this.page.page,
      sort: this.sortText.value,
    };

    if (this.isMyWokrspaceFl) {
      params['myWorkspace'] = true;
    }

    if (this.isFavorFl) {
      params['onlyFavorite'] = true;
    }

    if (this.isOpenWorkspaceFl) {
      params['published'] = true;
    }

    if (this.isMyWokrspaceFl && this.isOpenWorkspaceFl) {
      delete params['myWorkspace'];
      delete params['published'];
    }

    if (this.searchText !== '') {
      this.searchText = this.searchText.trim();
      params['nameContains'] = this.searchText;
    }

    0 === this.page.page && (this.sharedWorkspace = []);

    this.workspaceService
      .getSharedWorkspaces('forListView', params)
      .then((workspaces) => {
        this.pageResult = workspaces['page'];
        this.cntAllWorkspaces = this.pageResult.totalElements;

        if (workspaces['_embedded']) {
          this.sharedWorkspace = this.sharedWorkspace.concat(workspaces['_embedded']['workspaces']);
          this.page.page += 1;
        }

        this.pageLoaderService.hide();
      })
      .catch(() => {
        this.alertPrimeService.error(this.translateService.instant('msg.space.alert.retrieve'));

        this.pageLoaderService.hide();
      });
  }

  public filterFavoriteWorkspace() {
    this.isFavorFl = !this.isFavorFl;
    this.getSharedWorkspace(0);
  }

  public filterOpenWorkspace() {
    this.isOpenWorkspaceFl = !this.isOpenWorkspaceFl;
    this.getSharedWorkspace(0);
  }

  public filterOwnWorkspaces() {
    this.isMyWokrspaceFl = !this.isMyWokrspaceFl;
    this.getSharedWorkspace(0);
  }

  public favoriteEvent(workspace: Workspace) {
    workspace.favorite = !workspace.favorite;

    if (workspace.favorite) {
      this.setFavorite(workspace);
    } else {
      this.deleteFavorite(workspace);
    }
  }

  public searchEventPressKey(event: KeyboardEvent) {
    13 === event.keyCode && this.searchEvent();
  }

  public searchEvent() {
    this.searchText = this._inputSearch.nativeElement.value;
    this.getSharedWorkspace(0);
  }

  public resetSearchText(isClear: boolean) {
    if (isClear) {
      this._inputSearch.nativeElement.value = '';
    } else {
      this._inputSearch.nativeElement.value = this.searchText;
    }
  }

  public selectSort(item: any) {
    this.sort.forEach((value) => {
      value.selected = false;
    });

    this.sortText = item;
    item.selected = true;

    this.getSharedWorkspace(0);
  }

  public close() {
    this.isShow = false;
    this.renderer.removeStyle(document.body, 'overflow');
    this.updateComplete.emit(this.updateFl);
  }

  public moveWorkspace(workspace?: Workspace) {
    if (workspace && !workspace.active) {
      const modal = new Modal();
      modal.name = this.translateService.instant('msg.space.alert.workspace.disabled');
      modal.description = this.translateService.instant('msg.space.alert.workspace.disabled.desc');
      modal.subDescription = this.translateService.instant('msg.space.alert.workspace.disabled.desc.sub');
      modal.isShowCancel = false;
      modal.btnName = this.translateService.instant('msg.comm.ui.ok');
      modal.data = {
        type: 'INACTIVE',
        afterConfirm: function () {},
      };
      this.confirmModalComponent.init(modal);
    } else {
      this.isShow = false;
      this.renderer.removeStyle(document.body, 'overflow');

      const workspaceId: string = workspace ? workspace.id : '';
      const navigateInfo: string[] = [BI_ROOT_ROUTE, 'workspace'];
      if (workspaceId) {
        navigateInfo.push(workspaceId);
      }

      if (this.router.url === navigateInfo.join('/')) {
        this.broadCaster.broadcast('moveFromLnb', workspaceId);
      } else {
        this.router.navigate(navigateInfo);
      }
    }
  }

  public removeWorkspace(event: MouseEvent, workspaceId: string) {
    event.stopPropagation();
    event.preventDefault();

    if ('' !== workspaceId) {
      const modal = new Modal();
      modal.name = this.translateService.instant('msg.space.ui.del.workspace.del.title');
      modal.description = this.translateService.instant('msg.space.ui.del.workspace.del.description');
      modal.subDescription = this.translateService.instant('msg.comm.ui.del.description');
      modal.data = { eventType: 'DELETE', target: workspaceId };
      this.confirmModalComponent.init(modal);
    }
  }

  public onModalConfirm(modal: Modal) {
    switch (modal.data.eventType) {
      case 'DELETE':
        this.pageLoaderService.show();
        this.recycleBinService
          .recycleBinWorkspace(modal.data.target)
          .pipe(
            catchError((error) => {
              this.pageLoaderService.hide();
              this.alertPrimeService.error(this.translateService.instant('msg.space.alert.del.workspace.fail'));
              return error;
            }),
          )
          .subscribe(() => {
            this.pageLoaderService.hide();
            this.alertPrimeService.success(this.translateService.instant('msg.space.alert.del.workspace.success'));
            this.getSharedWorkspace(0);
          });

        break;
    }
  }

  private setFavorite(workspace: Workspace) {
    this.pageLoaderService.show();

    this.workspaceService
      .setFavorite(workspace.id)
      .then(() => {
        this.updateFl = true;

        this.pageLoaderService.hide();
      })
      .catch(() => {
        this.alertPrimeService.error(this.translateService.instant('msg.space.alert.add.favor'));

        this.pageLoaderService.hide();
      });
  }

  private deleteFavorite(workspace: Workspace) {
    this.pageLoaderService.show();

    this.workspaceService
      .deleteFavorite(workspace.id)
      .then(() => {
        this.updateFl = true;

        this.pageLoaderService.hide();
      })
      .catch(() => {
        this.alertPrimeService.error(this.translateService.instant('msg.space.alert.del.favor'));

        this.pageLoaderService.hide();
      });
  }

  private reset() {
    this.isShow = false;
    this.isFavorFl = false;
    this.isMyWokrspaceFl = false;
    this.isOpenWorkspaceFl = false;
    this.updateFl = false;

    this.sharedWorkspace = [];
    this.cntAllWorkspaces = 0;

    this.sortText = this.sort[0];

    this.searchText = '';

    this.isSortFl = false;

    this.params = '';

    this.workspaceId = null;

    this.page.page = 0;
    this.page.size = 20;
  }
}
