import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';

import { FormsModule } from '@angular/forms';
import { DataListViewPaginationComponent } from '@selfai-platform/shell';
import { DropdownModule } from 'primeng/dropdown';
import { SkeletonModule } from 'primeng/skeleton';
import { WorkbookListSelectorComponentModule } from '../../../workbook';
import { DashboardListSelectorComponentModule } from '../dashboard-list-selector';
import { DashbordPreviewImageComponent } from '../dashbord-preview-image';
import { DashboardListComponent } from './dashboard-list.component';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    AutoFocusModule,
    TooltipModule,
    ConfirmPopupModule,
    OverlayPanelModule,
    DialogModule,
    DashboardListSelectorComponentModule,
    WorkbookListSelectorComponentModule,
    RouterModule,
    TranslateModule,
    SkeletonModule,
    DropdownModule,
    FormsModule,
    DataListViewPaginationComponent,
    DashbordPreviewImageComponent,
  ],
  declarations: [DashboardListComponent],
  exports: [DashboardListComponent],
})
export class DashboardListComponentModule {}
