import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Dashboard, MainDashboardService } from '@selfai-platform/bi-domain';
import { DestroyService } from '@selfai-platform/shared';
import { BreadcrumbsMenuItem, BreadcrumbsMenuService, PATH_MAIN_DASHBOARD } from '@selfai-platform/shell';

@Component({
    selector: 'selfai-bi-main-dashboard',
    templateUrl: './main-dashboard.component.html',
    styleUrls: ['./main-dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DestroyService],
    standalone: false
})
export class MainDashboardComponent implements OnInit {
  dashboard$: Observable<Dashboard>;

  private readonly breadcrumbItems: BreadcrumbsMenuItem[] = [
    {
      label: this.translate.instant('shell.menu.maindashboard'),
      routerLink: ['/', PATH_MAIN_DASHBOARD],
    },
  ];

  constructor(
    private readonly mainDashboardService: MainDashboardService,
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    private readonly translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.dashboard$ = this.mainDashboardService.loadMainDashboard();
    this.breadcrumbsMenuService.setBreadcrumbsMenu(this.breadcrumbItems);
  }
}
