import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import {
  GraphNodeOptionSerialized,
  OperationTree,
  OperationTreeItem,
  OperationTreeType,
} from '@selfai-platform/pipeline-common';
import { AlertService } from '@selfai-platform/shared';
import { Tree } from 'primeng/tree';
import { Observable } from 'rxjs';
import { CopyPasteCubeService } from '../../services/copy-paste-cube.service';
import { OperationDetailService } from '../../services/operations/operation-detail.service';
import { OperationTreeService } from '../../services/operations/operation-tree.service';

@Component({
    selector: 'selfai-platform-operation-list',
    templateUrl: './operation-list.component.html',
    styleUrls: ['./operation-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OperationListComponent implements OnInit {
  constructor(
    private readonly operationTreeService: OperationTreeService,
    private readonly alertService: AlertService,
    private readonly copyPasteCubeService: CopyPasteCubeService,
    private readonly operationDetailService: OperationDetailService,
  ) {}

  operationTreeType = OperationTreeType;
  tree$!: Observable<OperationTree>;

  @ViewChild(Tree, { static: false, read: Tree }) treeRef?: Tree;

  ngOnInit(): void {
    this.operationTreeService.loadTree();

    this.tree$ = this.operationTreeService.getTree();
  }

  toggle(event: Event, node: OperationTreeItem) {
    if (node.expanded) this.collapse(event, node);
    else this.expand(event, node);
  }

  expand(event: Event, node: OperationTreeItem) {
    node.expanded = true;

    if (this.treeRef?.virtualScroll) {
      this.treeRef?.updateSerializedValue();
    }

    this.treeRef?.onNodeExpand.emit({ originalEvent: event, node });
  }

  collapse(event: Event, node: OperationTreeItem) {
    node.expanded = false;
    if (this.treeRef?.virtualScroll) {
      this.treeRef?.updateSerializedValue();
    }
    this.treeRef?.onNodeCollapse.emit({ originalEvent: event, node });
  }

  copyToClipboard(operationId: string): void {
    this.getCubeObservableByOperationId(operationId).subscribe((cube) => {
      this.copyPasteCubeService.copyToClipboard(cube).subscribe({
        complete: () => {
          this.alertService.success('Node copied to clipboard');
        },
      });
    });
  }

  getCubeObservableByOperationId(operationId: string): Observable<GraphNodeOptionSerialized> {
    return this.operationDetailService.getCubeByOperationId(operationId);
  }
}
