import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'primeng/tooltip';
import { WorkspaceSearchToolboxComponentModule } from '../../../../workspace/components';
import { WorkspaceRolesMangerToolbarComponent } from './workspaces-roles-manager-toolbar.component';

@NgModule({
  imports: [CommonModule, WorkspaceSearchToolboxComponentModule, TooltipModule, TranslateModule, RouterModule],
  declarations: [WorkspaceRolesMangerToolbarComponent],
  exports: [WorkspaceRolesMangerToolbarComponent],
})
export class WorkspaceRolesMangerToolbarComponentModule {}
