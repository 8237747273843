import { Injectable } from '@angular/core';
import { DataconnectionApiService } from '@selfai-platform/bi-api';
import { Observable } from 'rxjs';
import { DataconnectionResult, DataconnectionSchema, S3ConnectionParams } from '../models';

@Injectable({ providedIn: 'root' })
export class DataconnectionDomainApiService {
  constructor(private readonly dataconnectionApiService: DataconnectionApiService) {}

  checkConnectionS3(params: S3ConnectionParams): Observable<DataconnectionResult> {
    return this.dataconnectionApiService.checkConnection({
      connection: {
        type: 'S3',
        properties: params,
      },
    });
  }

  getDataSchemaS3(params: S3ConnectionParams, query?: string): Observable<DataconnectionSchema> {
    return this.dataconnectionApiService.loadDataSchema({
      type: 'TABLE',
      query: query || '',
      connection: {
        type: 'S3',
        properties: params,
      },
    }) as any;
  }
}
