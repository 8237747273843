import { ChangeDetectionStrategy, Component, ElementRef, Injector, Input, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable, take, takeUntil } from 'rxjs';

import {
  BoardWidgetOptions,
  Dashboard,
  DashboardDomainService,
  Filter,
  LayoutMode,
  LayoutWidgetInfo,
  WIDGET_CONTEXT_ID,
  Widget,
} from '@selfai-platform/bi-domain';
import { DestroyService } from '@selfai-platform/shared';

import { AbstractComponent } from '../../../common/component/abstract.component';

@Component({
    selector: 'dashboard-widget',
    templateUrl: './dashboard.widget.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DestroyService],
    standalone: false
})
export class DashboardWidgetComponent extends AbstractComponent implements OnDestroy {
  @Input()
  public widget: Widget;
  @Input()
  public widgetOpts: BoardWidgetOptions;
  @Input()
  public layoutMode: LayoutMode;
  @Input()
  public widgetInfo: LayoutWidgetInfo;

  @Input() readonly = false;

  @Input() isDashboardLoaded = false;
  @Input()
  globalFilters: Filter[] | null;

  public isShowTitle = false;
  public isWidget = false;
  public isTextWidget = false;
  public isPageWidget = false;
  public isFilterWidget = false;

  dashboard$: Observable<Dashboard>;

  private contextId: string = inject(WIDGET_CONTEXT_ID);

  constructor(
    protected elementRef: ElementRef,
    protected activatedRoute: ActivatedRoute,
    protected injector: Injector,
    private readonly dashboardDomainService: DashboardDomainService,
    private readonly destroy$: DestroyService,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit(): void {
    if (this.widget && this.widgetOpts && this.layoutMode && this.widgetInfo) {
      this.dashboardDomainService
        .loadDashboardByWidgetIdIfNotLoaded(this.widget.id, this.contextId)
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe();
      this.dashboard$ = this.dashboardDomainService.getDashboardByWidgetId(this.widget.id);

      this.init();
    }
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public init() {
    super.ngOnInit();
    switch (this.widget.type) {
      case 'page':
        this.isPageWidget = true;
        break;
      case 'text':
        this.isTextWidget = true;
        break;
      case 'filter':
        this.isFilterWidget = true;
        break;
    }
    this.isWidget = true;
    this.widgetInfo && (this.isShowTitle = this.widgetInfo.title);
    this.safelyDetectChanges();
  }

  public getWidgetId(): string {
    return this.widget.id;
  }

  public getWidget(): Widget {
    return this.widget;
  }

  public getBoardWidgetOpts(): BoardWidgetOptions {
    return this.widgetOpts;
  }

  public getLayoutMode(): LayoutMode {
    return this.layoutMode;
  }
}
