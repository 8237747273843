import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  Condition,
  CreateTriggerFormGroup,
  TriggerRule,
  TriggerRuleFormGroup,
  WorkflowTriggerData,
} from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { Table } from 'primeng/table';
import { filter, take, takeUntil } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { DialogHelperService } from '../../../../dialog';
import { normalizeToLegacyDataCreateTrigger } from '../../../converters/create-trigger.normalizer';
import { CubeDialogManagementService } from '../../../services/cube-dialog-management.service';
import { SaveConfirmationService } from '../../../services/save-confirmation.service';
import { SelectionStoreService } from '../../../services/selection-store.service';
import { AbstractCubeDialogFormWithTableComponent } from '../../abstract-cube-dialog-form-with-table.component';
import { DialogHeaderService } from '../../dialog-header/dialog-header.service';
import { CreateTriggerComponentService } from './create-trigger-component.service';

@Component({
    selector: 'selfai-platform-create-trigger',
    templateUrl: './create-trigger.component.html',
    styleUrls: ['./create-trigger.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        DestroyService,
        DialogHelperService,
        SelectionStoreService,
        SaveConfirmationService,
        CreateTriggerComponentService,
        DialogHeaderService,
    ],
    animations: [
        trigger('rowExpansionTrigger', [
            state('void', style({
                'max-height': '0px',
            })),
            state('active', style({
                'max-height': '1000px',
            })),
            transition('* <=> *', animate('1000ms')),
        ]),
    ],
    standalone: false
})
export class CreateTriggerComponent extends AbstractCubeDialogFormWithTableComponent implements OnInit {
  override itemsMap = new Map<string, FormGroup<TriggerRuleFormGroup>>();
  conditions = Object.values(Condition);
  override form: FormGroup<CreateTriggerFormGroup> = this.createTriggerComponentService.getFormGroup();

  override initialItem: TriggerRule = {
    id: '',
    attrColumnName: '',
    condition: null,
    primary: false,
    resultColumn: '',
    value: '',
    ignoreCase: null,
    startPosition: null,
    startRange: null,
    includeStartRange: null,
    endRange: null,
    includeEndRange: null,
  };
  sizingTableName = 'createTrigger';

  @ViewChild('tableRef', { static: false, read: Table }) tableRef!: Table;
  @ViewChild('tableColRef') tableColRef!: ElementRef<HTMLElement>;

  get dataForWorkflow(): WorkflowTriggerData {
    return normalizeToLegacyDataCreateTrigger({
      resultColumn: this.form.controls.resultColumn.value || '',
      debugMode: this.form.controls.debugMode.value || false,
      conditions: this.items.map((v) => v.value as TriggerRule),
    });
  }

  constructor(
    private readonly createTriggerComponentService: CreateTriggerComponentService,
    injector: Injector,
    private readonly dialogHeaderService: DialogHeaderService,
    private readonly cubeDialogManagementService: CubeDialogManagementService,
    private readonly translateService: TranslateService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.createTriggerComponentService
      .getTriggerRulesFormGroups()
      .pipe(take(1), filter(Boolean), takeUntil(this.destroy$))
      .subscribe((items) => {
        items.forEach((formGroup) => this.itemsMap.set(formGroup.controls.id.value, formGroup));
        this.markFormAsInitialized();
      });
    this.dialogHeaderService.initCustomHeaderComponent(
      this.nodeId,
      this.translateService.instant('workflow.cubes.create-trigger.modal-header'),
      this.closeDialog.bind(this),
      this.onClickSave.bind(this),
    );
    setTimeout(() => {
      this.cubeDialogManagementService.setFocus(this.nodeId);
    });
  }

  override addItem(): void {
    const id = uuidv4();
    const item = { ...this.initialItem, id };
    const form = this.mapItemToFormGroup(item);

    this.itemsMap.set(id, form);
  }

  filter(event: Event): void {
    this.tableRef.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  mapItemToFormGroup(item: TriggerRule): FormGroup<TriggerRuleFormGroup> {
    return this.createTriggerComponentService.mapItemToFormGroup(item);
  }
}
