import { AbstractControl, ValidationErrors } from '@angular/forms';

export function notEmptyArrayValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value;
  if (Array.isArray(value) && value.length > 0) {
    return null;
  }

  return { notEmptyArray: true };
}
