import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'baseSort',
    standalone: false
})
@Injectable()
export class BaseSort implements PipeTransform {
  transform(items: any[], args: any[]): any[] {
    if (items == null || items.length < 2 || args.length < 2) {
      return items;
    }

    const filterKey = args[0];

    const orderType = args[1] === 'desc' ? 'desc' : 'asc';

    if (args[1] === '' || args[1] === 'default') {
      return items;
    }

    let result;

    if (orderType === 'desc') {
      result = items.sort((a, b) => {
        let first = a;
        let second = b;
        if (filterKey) {
          first = first[filterKey];
          second = second[filterKey];
        }
        let comparison = 0;

        if (first < second) {
          comparison = 1;
        } else if (second < first) {
          comparison = -1;
        }

        return comparison;
      });
    } else {
      result = items.sort((a, b) => {
        let first = a;
        let second = b;
        if (filterKey) {
          first = first[filterKey];
          second = second[filterKey];
        }
        let comparison = 0;

        if (first > second) {
          comparison = 1;
        } else if (second > first) {
          comparison = -1;
        }

        return comparison;
      });
    }
    return result;
  }
}
