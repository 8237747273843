<ng-container *ngIf="activeDashboardId$ | async as activeDashboardId">
  <div class="flex align-items-center">
    <div
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="dropPin($event)"
      class="flex align-items-center"
    >
      <ng-container *ngIf="hasPermission(biEntityPermission.DASHBOARD_EDIT); else noDragNDrop">
        <div
          *ngFor="let pin of pins$ | async"
          cdkDrag
          [ngClass]="{
            'pin-item-active': pin.dashboardId === activeDashboardId,
            'pin-item-mark-as-deleted': markAsDeletedMap.get(pin.dashboardId)
          }"
          class="pin-item text-color flex align-items-center gap-2 cursor-pointer p-3 pr-0"
        >
          <ng-container *ngTemplateOutlet="pinItem; context: { $implicit: pin }"></ng-container>
          <p-button
            *ngIf="!markAsDeletedMap.get(pin.dashboardId); else undoDeleteTemplate"
            class="text-2xl text-color"
            (onClick)="markAsDeleted(pin.dashboardId)"
            [link]="true"
            icon="pi pi-times"
            [pTooltip]="'msg.workbook-pins.delete-pin' | translate"
            tooltipPosition="top"
          ></p-button>
          <ng-template #undoDeleteTemplate>
            <p-button
              class="text-2xl text-color"
              (onClick)="undoDelete(pin.dashboardId)"
              [link]="true"
              icon="pi pi-history"
              label="{{ secondToDelete | countdown | async }}"
              [pTooltip]="'msg.workbook-pins.undo-delete' | translate"
              tooltipPosition="top"
            ></p-button>
          </ng-template>
        </div>
      </ng-container>
    </div>
    <div class="border-left-2 surface-border">
      <button
        *ngIf="hasPermission(biEntityPermission.DASHBOARD_CREATE)"
        type="button"
        pButton
        size="large"
        appearance="icon"
        class="p-button-success p-button-icon-only p-button-link"
        icon="pi pi-plus-circle"
        tooltipPosition="top"
        [pTooltip]="'msg.comm.ui.create.board' | translate"
        (click)="creatingDashboardShow.emit(true)"
      ></button>
    </div>
  </div>

  <ng-template #noDragNDrop>
    <div
      *ngFor="let pin of pins$ | async"
      [ngClass]="{ 'pin-item-active': pin.dashboardId === activeDashboardId }"
      class="pin-item text-color flex align-items-center gap-2 cursor-pointer p-3 text-xl"
    >
      <ng-container *ngTemplateOutlet="pinItem; context: { $implicit: pin }"></ng-container>
    </div>
  </ng-template>

  <ng-template #pinItem let-pin>
    <i class="pi pi-chart-bar"></i>
    <a
      class="short-text"
      [routerLink]="['/', biRouteRoot, 'workbook', workbookId]"
      [queryParams]="{ dashboardId: pin.dashboardId }"
    >
      {{ pin.name }}
    </a>
  </ng-template>
</ng-container>
