<div class="card">
  <p-steps [model]="steps" [readonly]="true" [(activeIndex)]="activeIndexStep"></p-steps>
</div>

<div class="card">
  <ng-container [ngSwitch]="activeIndexStep">
    <ng-container *ngSwitchCase="0">
      <p-fileUpload
        #fileUpload
        mode="advanced"
        name="importFile"
        accept="application/json"
        [fileLimit]="1"
        chooseLabel="{{ 'msg.workbook.import-form.choose-file' | translate }}"
        [auto]="true"
        [customUpload]="true"
        (uploadHandler)="onUpload($event)"
        (onError)="onError()"
      >
        <ng-template let-file pTemplate="file">
          <div class="p-fileupload-row">
            <div class="p-fileupload-filename">{{ file.name }}</div>
            <div>{{ fileUpload.formatSize(file.size) }}</div>
          </div>
        </ng-template>
      </p-fileUpload>
    </ng-container>
    <ng-container *ngSwitchCase="1">
      <form class="flex gap-3" (ngSubmit)="sumbitStep()">
        <input
          name="workbookName"
          [formControl]="workbookNameControl"
          pAutoFocus
          [autofocus]="true"
          pInputText
          type="text"
          placeholder="{{ 'msg.workbook.import-form.edit-name' | translate }}"
        />
      </form>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <selfai-platform-data-source-mapping-form
        [dataSources]="dataSources"
        [(isValid)]="isValidDataSourceMapping"
        [(mappedDatasources)]="mappedDatasources"
      ></selfai-platform-data-source-mapping-form>
    </ng-container>
  </ng-container>
</div>

<div class="card">
  <div class="flex gap-3">
    <button
      *ngIf="activeIndexStep > 0"
      type="button"
      class="p-button-secondary p-button-outlined"
      (click)="prevStep()"
      pRipple
      pButton
      label="{{ 'msg.workbook.import-form.prev-step' | translate }}"
    ></button>
    <button
      *ngIf="activeIndexStep < steps.length - 1"
      type="button"
      class="p-button"
      (click)="nextStep()"
      pRipple
      pButton
      [disabled]="!isSubmittingAvailable()"
      label="{{ 'msg.workbook.import-form.next-step' | translate }}"
    ></button>
    <button
      *ngIf="activeIndexStep === steps.length - 1"
      type="button"
      class="p-button"
      (click)="finishImport()"
      pRipple
      pButton
      [disabled]="!isSubmittingAvailable()"
      label="{{ 'msg.workbook.import-form.finish-step' | translate }}"
    ></button>
  </div>
</div>
