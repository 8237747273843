import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AggregatorRoute } from '@selfai-platform/pipeline-common';
import { map, Observable, of } from 'rxjs';
import { AggregatorActiveItemService } from '../../../services/aggregator-active-item.service';
import { AggregatorRouteListService } from '../../../services/aggregator-camel-list.service';

type Item = Pick<AggregatorRoute, 'name' | 'uuid'>;

@Component({
    selector: 'selfai-platform-step-to',
    templateUrl: './step-to.component.html',
    styleUrls: ['./step-to.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StepToComponent implements OnInit {
  @Input()
  form!: UntypedFormGroup;
  @Input() activeItemId!: string;

  toItems$: Observable<Item[]> = of([]);

  constructor(
    private readonly aggregatorCamelListService: AggregatorRouteListService,
    private readonly aggregatorActiveItemService: AggregatorActiveItemService,
  ) {}

  ngOnInit(): void {
    // exclude active route from the items
    this.toItems$ = this.aggregatorCamelListService
      .getRoutes()
      .pipe(
        map((aggregatorRoutes) =>
          aggregatorRoutes
            .filter(({ uuid }) => uuid !== this.aggregatorActiveItemService.getActiveItemId())
            .map(({ name, uuid }) => ({ name, uuid })),
        ),
      );
  }
}
