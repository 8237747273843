import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';

import { RecycleApiService } from '@selfai-platform/bi-api';

import { DataListViewPaginationComponent } from '@selfai-platform/shell';
import { AutoFocusModule } from 'primeng/autofocus';
import { SkeletonModule } from 'primeng/skeleton';
import { TooltipModule } from 'primeng/tooltip';
import { RecycleBinComponent } from './recycle-bin.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: RecycleBinComponent,
  },
  {
    path: ':role',
    component: RecycleBinComponent,
  },
];

@NgModule({
  declarations: [RecycleBinComponent],
  providers: [RecycleApiService, ConfirmationService, MessageService, TranslateService],
  imports: [
    CommonModule,
    DropdownModule,
    InputTextModule,
    TableModule,
    ButtonModule,
    TranslateModule,
    PaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    ToastModule,
    TranslateModule,
    RouterModule.forChild(routes),
    SkeletonModule,
    AutoFocusModule,
    DataListViewPaginationComponent,
    TooltipModule,
  ],
})
export class RecycleBinComponentModule {}
