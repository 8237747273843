import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  DestroyService,
  LocalStorageKey,
  PREFIX_SETTINGS_LOCAL_STORAGE_KEY,
  providePageParams,
} from '@selfai-platform/shared';
import {
  DataListViewComponent,
  GroupAction,
  provideDataListView,
  provideDialogService,
  UI_SETTINGS_VIEW_PAGE_DEFAULT_SIZE,
} from '@selfai-platform/shell';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { Observable } from 'rxjs';
import {
  DataSourceActionsForItemService,
  DataSourceDataListViewService,
  DataSourcePermissionService,
} from '../../services';

@Component({
    selector: 'selfai-platform-data-source-list',
    templateUrl: './data-source-list.component.html',
    styleUrls: ['./data-source-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, TranslateModule, DataListViewComponent, MenuModule, ButtonModule],
    providers: [
        DestroyService,
        ...provideDataListView(DataSourceDataListViewService, DataSourceActionsForItemService),
        provideDialogService(),
        ...providePageParams({
            pageSize: UI_SETTINGS_VIEW_PAGE_DEFAULT_SIZE,
            pageNumber: 1,
            sortField: 'creationDateTime',
            sortOrder: 'desc',
        }),
        {
            provide: PREFIX_SETTINGS_LOCAL_STORAGE_KEY,
            useValue: LocalStorageKey.DATA_SOURCE_LIST_PREFIX_SETTINGS,
        },
    ]
})
export class DataSourceListComponent {
  public canCreate = this.dataSourcePermissionService.canCreateSource();
  public columns = this.dataSourceDataListViewService.getColumns();
  public createMenu$: Observable<MenuItem[]> = this.dataSourceDataListViewService.getCreateMenu();
  public groupActions$: Observable<GroupAction[]> = this.dataSourceDataListViewService.getGroupActions();

  constructor(
    private readonly dataSourceDataListViewService: DataSourceDataListViewService,
    private readonly dataSourcePermissionService: DataSourcePermissionService,
  ) {}
}
