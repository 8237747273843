import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import {
  DataListViewComponent,
  KE_ROOT_ROUTE,
  KE_SCHEDULES_PATH,
  provideDataListView,
  provideDialogService,
  ToolbarAction,
} from '@selfai-platform/shell';
import { MultiSelectModule } from 'primeng/multiselect';
import { Router } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import {
  DestroyService,
  SchedulerPermissionAction,
} from '@selfai-platform/shared';
import { SchedulesDataListViewService } from '../../services/schedules-data-list-view.service';
import { SchedulesActionsForItemsService } from '../../services/schedules-actions-for-items.service';
import { FILTER_FIELDS } from '../../constants/schedules-table-config';
import { Observable, combineLatest, map } from 'rxjs';
import { SchedulesPermissionService } from '../../services/schedules-permission.service';

@Component({
    selector: 'selfai-platform-schedules-list',
    templateUrl: './schedules-list.component.html',
    styleUrls: ['./schedules-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        AsyncPipe,
        DataListViewComponent,
        MultiSelectModule,
        TooltipModule,
        TranslateModule,
    ],
    providers: [
        DestroyService,
        ...provideDialogService(),
        ...provideDataListView(SchedulesDataListViewService, SchedulesActionsForItemsService)
    ]
})
export class SchedulesListComponent {
  public columns = this.schedulesDataListViewService.getColumns();
  public actions$: Observable<ToolbarAction[]> = this.getActions();
  public groupActions$ = this.schedulesDataListViewService.getGroupActions();
  public filtersFields = FILTER_FIELDS;

  constructor(
    private readonly schedulesDataListViewService: SchedulesDataListViewService,
    private readonly permissionService: SchedulesPermissionService,
    private readonly router: Router,
  ) {}

  private getActions(): Observable<ToolbarAction[]> {
    return combineLatest({
      createPermission: this.permissionService.checkPermission(SchedulerPermissionAction.Create),
    }).pipe(map(({ createPermission}) => {
      const actions = [];
      if (createPermission) {
        actions.push({
          tooltipTranslate: 'schedules-list.toolbar.actions.create',
          icon: 'pi pi-plus-circle',
          buttonClass: 'p-button-success',
          action: () => {
            this.router.navigate(['/', KE_ROOT_ROUTE, KE_SCHEDULES_PATH, 'create']).then();
          },
        });
      }
      return actions as ToolbarAction[];
    }));
  }

  public ngOnInit(): void {}
}
