<div class="toolbar">
  <ng-container *ngIf="{ layoutType: layoutType$ | async } as obs">
    <ng-container *ngIf="pageParams$ | async as pageParams">
      <div class="toolbar-left-side flex gap-2">
        <p-selectButton
          optionValue="value"
          [allowEmpty]="false"
          [options]="layoutOptions"
          [ngModel]="obs.layoutType"
          (onChange)="onChangeViewType($event.value)"
        >
          <ng-template let-item pTemplate>
            <i [ngClass]="[item.label]"></i>
          </ng-template>
        </p-selectButton>
        <ng-container *ngIf="obs.layoutType === 'grid'">
          <p-dropdown
            [options]="orderBy$ | async"
            [ngModel]="pageParams.sortField"
            (ngModelChange)="onChangeSortField($event)"
          ></p-dropdown>
          <p-dropdown
            [options]="sortBy$ | async"
            [ngModel]="pageParams.sortOrder"
            (ngModelChange)="onChangeSortOrder($event)"
          ></p-dropdown>
        </ng-container>
      </div>
      <div class="toolbar-right-side flex gap-2">
        <ng-container *ngIf="(selectedItems$ | async).length > 0">
          <button
            *ngIf="hasPermission(biPermission.KD_WORKSPACE_DELETE_ALL)"
            type="button"
            pButton
            class="p-button-danger"
            appearance="icon"
            (click)="removeSelected($event)"
            icon="pi pi-trash"
            tooltipPosition="top"
            pTooltip="{{ 'msg.workspace-list.table-actions.remove-selected' | translate }}"
          ></button>
        </ng-container>
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            class="search-input"
            style="width: 230px"
            pAutoFocus
            [autofocus]="true"
            [ngModel]="querySearch$ | async"
            [ngModelOptions]="{ standalone: true }"
            pInputText
            type="text"
            (input)="onSearch($event)"
            placeholder="{{ 'msg.workspace-list.search.input-placeholder' | translate }}"
          />
        </div>
        <p-dropdown
          [options]="pageSizes"
          [ngModel]="pageParams.pageSize"
          (ngModelChange)="onPageChange({ pageSize: $event, pageNumber: 1 })"
        ></p-dropdown>
        <ng-container *ngIf="hasPermission(biPermission.KD_WORKSPACE_CREATE) && showCreateWorkspaceButton">
          <button
            pButton
            type="button"
            class="p-button-success"
            [pTooltip]="'msg.workspaces.addWorkspace' | translate"
            appearance="icon"
            icon="pi pi-plus-circle"
            tooltipposition="top"
            [routerLink]="['/', kdRootRoute, 'workspace', 'create']"
          ></button>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>
