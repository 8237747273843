import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import {
  CodeLanguage,
  FilterCondition,
  JsonPathFormGroup,
  StepFilterFormGroup,
} from '@selfai-platform/pipeline-common';

@Component({
    selector: 'selfai-platform-step-filter',
    templateUrl: './step-filter.component.html',
    styleUrls: ['./step-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StepFilterComponent {
  @Input()
  form!: FormGroup<StepFilterFormGroup> | UntypedFormGroup;
  @Input() index!: number;

  // hack typings of UntypedFormGroup
  get filterAttrName(): FormGroup<JsonPathFormGroup> {
    return (this.form as FormGroup<StepFilterFormGroup>).controls.filterAttrName;
  }

  readonly filterTypeItems = Object.values(FilterCondition);
  readonly filterType = FilterCondition;
  readonly codeLanguage = CodeLanguage;
}
