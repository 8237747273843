<div class="workbook-thumbnails-container flex gap-3 align-items-center" *ngIf="showThumbnails && dashboards.length">
  <p-button
    *ngIf="dashboards.length * slideSize > sliderContent.clientWidth + sliderGap"
    [text]="true"
    size="large"
    icon="pi pi-angle-left"
    [disabled]="sliderTranslateX === 0"
    (click)="scrollSlider('prev')"
  ></p-button>

  <div class="overflow-hidden">
    <div #sliderContent class="flex mt-5 gap-3 transition-duration-200" [style.translate.px]="sliderTranslateX">
      <ng-container *ngIf="pins$ | async as pins">
        <div *ngFor="let dashboard of dashboards">
          <selfai-platform-thumbnail
            [workspace]="workspace"
            [highlight]="dashboard.id === selectedDashboard?.id"
            [dashboard]="dashboard"
            [workbookId]="workbookId"
            [isPinned]="pins.includes(dashboard.id)"
            (dashboardSelect)="selectDashboard($event)"
            (dashboardClone)="cloneDashboard($event)"
            (dashboardDelete)="deleteDashboard($event)"
          ></selfai-platform-thumbnail>
        </div>
      </ng-container>
    </div>
  </div>

  <p-button
    *ngIf="dashboards.length * slideSize > sliderContent.clientWidth + sliderGap"
    [text]="true"
    size="large"
    icon="pi pi-angle-right"
    [disabled]="checkDisabledArrow | executeWith: dashboards.length:sliderContent:sliderTranslateX"
    (click)="scrollSlider('next')"
  ></p-button>
</div>
<p-button (onClick)="toggleThumbnails()" class="workbook-thumbnails-toggler" icon="pi pi-chart-pie"></p-button>
