import { FavoriteDomainApi, FavoriteListItemApiDomain } from '@selfai-platform/bi-api';

export function favoriteListItemToDomainItem(listItemDomain: FavoriteListItemApiDomain): FavoriteDomainApi {
  switch (listItemDomain) {
    case 'DASHBOARD':
      return FavoriteDomainApi.Dashboard;
    case 'WORKBOOK':
      return FavoriteDomainApi.Workbooks;
    case 'WORKSPACE':
      return FavoriteDomainApi.Workspace;
  }

  throw new Error('Unkonw domain');
}
