import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, Injector, OnDestroy } from '@angular/core';

import { AbstractComponent } from '../common/component/abstract.component';
import { EventBroadcaster } from '../common/event/event.broadcaster';
import { Alert } from '../common/util';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    animations: [
        trigger('slideInOut1', [
            state('in', style({ height: '*', opacity: 1 })),
            transition(':leave', [
                style({ height: '*', opacity: 1 }),
                group([animate(300, style({ height: 0 })), animate('200ms ease-in-out', style({ opacity: '1' }))]),
            ]),
            transition(':enter', [
                style({ height: '0', opacity: 1 }),
                group([animate(300, style({ height: '*' })), animate('400ms ease-in-out', style({ opacity: '1' }))]),
            ]),
        ]),
    ],
    standalone: false
})
export class LayoutComponent extends AbstractComponent implements OnDestroy, AfterViewInit {
  public nextConfig: any;
  public navCollapsed: boolean;
  public navCollapsedMob: boolean;
  public windowWidth: number;
  public layoutType: string;
  public showedMenu = true;

  constructor(private broadCaster: EventBroadcaster, protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);

    this.nextConfig = NextConfig.config;
    this.windowWidth = window.innerWidth;
    this.nextConfig.collapseMenu = false;
    this.navCollapsed = this.windowWidth >= 992 ? this.nextConfig.collapseMenu : false;
    this.navCollapsedMob = false;
  }

  ngAfterViewInit() {
    this.broadCaster.broadcast('ENTER_LAYOUT_MODULE');

    // eslint-disable-next-line deprecation/deprecation
    this._setAlertTranslateMsg();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  /**
   * @deprecated
   */
  private _setAlertTranslateMsg() {
    Alert.ERROR_NAME = this.translateService.instant('msg.comm.alert.error.msg');
    Alert.MORE_BTN_DESC = this.translateService.instant('msg.comm.alert.error.btn');
    Alert.CLOSE_BTN = this.translateService.instant('msg.comm.btn.close');
  }
}

export class NextConfig {
  public static config = {
    layout: 'horizontal',
    subLayout: '',
    collapseMenu: false,
    layoutType: 'light',
    headerBackColor: 'background-blue',
    rtlLayout: false,
    navFixedLayout: false,
    headerFixedLayout: false,
    boxLayout: false,
  };
}
