<div class="toolbar py-0 pl-0">
  <div>
    <selfai-platform-workbook-pins
      [workspace]="workspace"
      [workbookId]="workbook.id"
      (creatingDashboardShow)="creatingDashboardShow.emit($event)"
    ></selfai-platform-workbook-pins>
  </div>
  <div class="flex gap-2">
    <button
      *ngIf="hasPermission(biEntityPermission.DASHBOARD_EDIT)"
      type="button"
      pButton
      size="large"
      appearance="icon"
      class="p-button p-button-primary"
      icon="pi pi-pencil"
      [pTooltip]="'msg.navbar.edit' | translate"
      tooltipPosition="top"
      (click)="workbookActionsService.setUpdateDashboardConfig({ view: true })"
    ></button>

    <button
      type="button"
      pButton
      size="large"
      appearance="icon"
      class="p-button p-button-primary"
      *ngIf="{ activeMode: workbookActionsService.isActiveMode$ | async } as obs"
      [icon]="obs.activeMode ? 'pi pi-lock-open' : 'pi pi-lock'"
      [pTooltip]="
        obs.activeMode ? ('msg.page.prediction.analysis' | translate) : ('msg.workspacesEdit.view' | translate)
      "
      tooltipPosition="top"
      (click)="workbookActionsService.setActiveMode(!obs.activeMode)"
    ></button>

    <ng-container *ngIf="menuMoreItems$ | async as menuMoreItems">
      <ng-container *ngIf="menuMoreItems.length">
        <button
          type="button"
          pButton
          size="large"
          class="p-button-primary"
          appearance="icon"
          (click)="menu.toggle($event)"
          icon="pi pi-ellipsis-v"
          tooltipPosition="top"
          pTooltip="{{ 'msg.menu-actions-more.button-tooltip' | translate }}"
        ></button>
        <p-menu #menu [model]="menuMoreItems" [popup]="true"></p-menu>
      </ng-container>
    </ng-container>
  </div>
</div>

<p-confirmDialog></p-confirmDialog>
