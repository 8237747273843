import { Injectable } from '@angular/core';
import { Observable, map, take, tap } from 'rxjs';
import { WorkbookDashboardListStore } from '../stores';
import { WorkbookDashboardListDomainService } from './workbook-dashboard-list-domain.service';

@Injectable({ providedIn: 'root' })
export class MainWorkbookDashboardListDomainService {
  constructor(
    private readonly workbookDashboardListStore: WorkbookDashboardListStore,
    private readonly workbookDashboardListDomainService: WorkbookDashboardListDomainService,
  ) {}

  resetMainDashboardCache(workbookId: string): Observable<void> {
    return this.workbookDashboardListDomainService.getDashboardList(workbookId).pipe(
      take(1),
      tap((dashboardList) => {
        this.workbookDashboardListStore.updateOneInCache({
          workbookId,
          dashboards: dashboardList.dashboards.map((d) => ({ ...d, main: false })),
          notRemovedDashboards: dashboardList.notRemovedDashboards?.map((d) => ({ ...d, main: false })),
        });
      }),
      map(() => void 0),
    );
  }

  setMainDashboardInCache(workbookId: string, dashboardId: string): void {
    this.workbookDashboardListDomainService.updateOneInCache(workbookId, dashboardId, { main: true });
  }

  isMainDashboard(workbookId: string, dashboardId: string): Observable<boolean> {
    return this.workbookDashboardListDomainService.getDashboardList(workbookId).pipe(
      map((dashboardList) => {
        return dashboardList.dashboards.some((d) => d.id === dashboardId && d.main);
      }),
    );
  }
}
