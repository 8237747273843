<div class="workspaces-roles-manager">
  <selfai-platform-workspace-list-toolbar
    *ngIf="(workspaceId$ | async) === null"
  ></selfai-platform-workspace-list-toolbar>

  <div class="workspaces-roles-content">
    <selfai-platform-workspaces-roles-manager-setting
      [form]="form"
      [roles]="roles$ | async"
      (addRole)="addRoleComponent.showCreateRole()"
      [workspaceId]="workspaceId$ | async"
    ></selfai-platform-workspaces-roles-manager-setting>

    <ng-container *ngFor="let role of this.form.get('roles').valueChanges | async; trackBy: trackByName">
      <selfai-platform-workspaces-roles-manager-access
        [role]="role"
        [edit]="form.get('useDefaultRoles').value === false"
        [users]="users$ | async"
        [loadedMembers$]="loadedMembers$"
        (members)="addMembers($event[0], $event[1])"
        (remove)="removeRole($event)"
        (changePermissions)="changePermissions($event)"
      ></selfai-platform-workspaces-roles-manager-access>
    </ng-container>

    <selfai-platform-workspaces-roles-manager-bottom
      (apply)="apply()"
      (saveAndExit)="saveAndExit()"
      (cancel)="cancel()"
      [isSaving]="isSaving$ | async"
      [disabled]="form.invalid"
    ></selfai-platform-workspaces-roles-manager-bottom>
  </div>
</div>

<selfai-platform-workspaces-add-role (createdRole)="addRole($event)"></selfai-platform-workspaces-add-role>

<p-toast></p-toast>
