import { Component, ElementRef, Injector, OnDestroy, OnInit } from '@angular/core';

import { ClipboardService } from 'ngx-clipboard';

import { Log } from '../../../domain/modal';
import { AbstractComponent } from '../../abstract.component';

@Component({
    selector: 'app-log-component',
    templateUrl: './log.component.html',
    standalone: false
})
export class LogComponent extends AbstractComponent implements OnInit, OnDestroy {
  public isShow = false;

  public log: Log;

  public subTitles: string[];

  constructor(
    private _clipboardService: ClipboardService,
    protected element: ElementRef,
    protected injector: Injector,
  ) {
    super(element, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public init(log: Log) {
    this.isShow = true;
    this.log = log;

    if (log.hasOwnProperty('subTitle')) {
      this.subTitles = log.subTitle;
    }
  }

  public close() {
    this.isShow = false;
  }

  public copyToClipboard() {
    this._clipboardService.copyFromContent(this.log.data);

    this.alertPrimeService.success(this.translateService.instant('msg.storage.alert.clipboard.copy'));
  }
}
