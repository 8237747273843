export enum PublicType {
  PRIVATE = 'PRIVATE',
  SHARED = 'SHARED',
}

export enum WorkspaceType {
  DEFAULT = 'DEFAULT',
  TYPE_1 = 'TYPE_1',
  TYPE_2 = 'TYPE_2',
}

export enum MemberType {
  GROUP = 'GROUP',
  USER = 'USER',
}

export enum DomainType {
  SYSTEM = 'SYSTEM',
  WORKSPACE = 'WORKSPACE',
}
