import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';

import { WorkbookListSelectorComponent } from './workbook-list-selector.component';

@NgModule({
  imports: [CommonModule, TranslateModule, TableModule, ButtonModule, InputTextModule, AutoFocusModule],
  declarations: [WorkbookListSelectorComponent],
  exports: [WorkbookListSelectorComponent],
})
export class WorkbookListSelectorComponentModule {}
