import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';

import { ConfirmationService } from 'primeng/api';
import { Observable, filter, map } from 'rxjs';

import { Dashboard, DashboardDomainService, DashboardStore } from '@selfai-platform/bi-domain';
import { PageParamsAdapter, PageParamsService } from '@selfai-platform/shared';
import { provideDialogService } from '@selfai-platform/shell';

@Component({
    selector: 'selfai-platform-bi-ui-dashboard-list-selector',
    templateUrl: './dashboard-list-selector.component.html',
    styleUrls: ['./dashboard-list-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ConfirmationService,
        provideDialogService(),
        {
            provide: PageParamsAdapter,
            useClass: PageParamsService,
        },
    ],
    standalone: false
})
export class DashboardListSelectorComponent implements OnInit {
  dashboardList$: Observable<Dashboard[]>;
  loading$: Observable<boolean>;
  sortField = 'modifiedTime';
  sortOrder = -1;

  @Output() selectDashboard = new EventEmitter<Dashboard>();
  @Input() textButton: string;
  @Input() dataSourceId: string;

  selectedDashboard: Dashboard;

  constructor(
    protected readonly dashboardDomainService: DashboardDomainService,
    protected readonly dashboardStore: DashboardStore,
    @Optional() private readonly pageParamsAdapter?: PageParamsAdapter,
  ) {}

  ngOnInit(): void {
    this.dashboardList$ = this.getDashboardList().pipe(
      map((dashboards) => dashboards.filter(({ dataSources }) => dataSources.length === 1)),
    );
    this.loading$ = this.dashboardStore.loading$;
    this.pageParamsAdapter?.setPageParams({
      pageSize: 9999,
      sortField: this.sortField,
      sortOrder: this.sortOrder > 0 ? 'asc' : 'desc',
      filters: [
        {
          fieldName: 'dataSources.id',
          value: this.dataSourceId,
        },
      ],
    });
    this.dashboardStore.load();
  }

  getDashboardList(): Observable<Dashboard[]> {
    return this.dashboardStore.entities$.pipe(filter(Boolean));
  }

  onSelectDashboard(item: Dashboard): void {
    this.selectDashboard.emit(item);
  }
}
