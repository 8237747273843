import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  BiPermissionService,
  WorkspaceDomainService,
  WorkspaceListBreadcrumbService,
  WorkspaceListItem,
} from '@selfai-platform/bi-domain';
import { BiPermission, DestroyService, SearchQueryService, mapPaginationBiToPrime } from '@selfai-platform/shared';
import {
  BreadcrumbsMenuService,
  KNOWLEDGE_DESIGNER_ROOT_ROUTE,
  TableDataComponentService,
  convertMessageToHtml,
  getUiSettingsDefaultPageSizes,
} from '@selfai-platform/shell';
import { ConfirmationService } from 'primeng/api';
import { Observable, catchError, combineLatest, map, mergeMap, take, takeUntil, tap, throwError } from 'rxjs';

@Component({
    selector: 'selfai-platform-workspace-list-toolbar',
    templateUrl: './workspace-list-toolbar.component.html',
    styleUrls: ['./workspace-list-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [WorkspaceListBreadcrumbService, DestroyService, ConfirmationService],
    standalone: false
})
export class WorkspaceListToolbarComponent implements OnInit, OnDestroy {
  kdRootRoute = KNOWLEDGE_DESIGNER_ROOT_ROUTE;
  biPermission = BiPermission;
  pageSizes = getUiSettingsDefaultPageSizes();
  layoutOptions = [
    { label: 'pi pi-bars', value: 'table' },
    { label: 'pi pi-th-large', value: 'grid' },
  ];

  selectedItems$: Observable<WorkspaceListItem[]> = this.tableDataComponentService.getSelectedItems();
  sortBy$: Observable<{ label: string; value: 'asc' | 'desc' }[]>;
  orderBy$: Observable<{ label: string; value: string }[]>;
  layoutType$ = this.tableDataComponentService.layoutType$;
  querySearch$ = this.searchQueryService.querySearch$;
  pageParams$ = this.tableDataComponentService.pageParams$;

  @Input()
  showCreateWorkspaceButton = false;

  constructor(
    private readonly permissionService: BiPermissionService,
    private readonly workspaceListBreadcrumbService: WorkspaceListBreadcrumbService,
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    private readonly destroy$: DestroyService,
    private readonly searchQueryService: SearchQueryService,
    private readonly tableDataComponentService: TableDataComponentService<WorkspaceListItem>,
    private readonly confirmationService: ConfirmationService,
    private readonly translate: TranslateService,
    private readonly workspaceDomainService: WorkspaceDomainService,
  ) {}

  ngOnInit(): void {
    this.workspaceListBreadcrumbService
      .getBreadcrumbsMenu()
      .pipe(takeUntil(this.destroy$))
      .subscribe((items) => this.breadcrumbsMenuService.setBreadcrumbsMenu(items));

    this.mapOrderBy();
    this.mapSortBy();
  }

  ngOnDestroy(): void {
    this.breadcrumbsMenuService.clearBreadcrumbsMenu();
  }

  mapOrderBy() {
    this.orderBy$ = combineLatest({
      orderFieldByName: this.translate.stream('msg.workspace-list.table-header.name'),
      orderFieldByCreatedAt: this.translate.stream('msg.workspace-list.table-header.created'),
      orderFieldByUpdatedAt: this.translate.stream('msg.workspace-list.table-header.updated'),
      orderFieldByAuthor: this.translate.stream('msg.workspace-list.table-header.author'),
    }).pipe(
      map((translations) => [
        { label: translations.orderFieldByName, value: 'name' },
        { label: translations.orderFieldByCreatedAt, value: 'createdTime' },
        { label: translations.orderFieldByUpdatedAt, value: 'modifiedTime' },
        { label: translations.orderFieldByAuthor, value: 'createdBy' },
      ]),
    );
  }

  mapSortBy() {
    this.sortBy$ = combineLatest({
      orderAsc: this.translate.stream('msg.toolbar.order-direction-asc'),
      orderDesc: this.translate.stream('msg.toolbar.order-direction-desc'),
    }).pipe(
      map((translations) => [
        { label: translations.orderAsc, value: 'asc' },
        { label: translations.orderDesc, value: 'desc' },
      ]),
    );
  }

  onSearch(event: Event): void {
    const { value } = event.target as HTMLInputElement;
    this.searchQueryService.setQuerySearch(value);
  }

  onPageChange(event: { pageSize: number; pageNumber: number }): void {
    this.tableDataComponentService.onPageChange(mapPaginationBiToPrime(event));
  }

  onChangeSortField(value: string) {
    this.tableDataComponentService.onSort({ sortField: value });
  }

  onChangeSortOrder(value: 'asc' | 'desc') {
    this.tableDataComponentService.onSort({ sortOrder: value });
  }

  onChangeViewType(valueType: 'table' | 'grid') {
    this.tableDataComponentService.changeViewType(valueType);
  }

  removeSelected(event: Event): void {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: convertMessageToHtml(this.translate.instant('msg.workspace-list.delete-selected.confirmation')),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.tableDataComponentService.loading$.next(true);

        this.selectedItems$
          .pipe(
            mergeMap((selectedItems) =>
              selectedItems.map((item) => this.workspaceDomainService.removeToTrash(item.id)),
            ),
            take(1),
            tap(() => this.tableDataComponentService.triggerReloadData()),
            takeUntil(this.destroy$),
            catchError((e) => {
              this.tableDataComponentService.loading$.next(false);

              return throwError(() => e);
            }),
          )
          .subscribe();
      },
    });
  }

  hasPermission(permission: BiPermission): boolean {
    return this.permissionService.hasPermission(permission);
  }
}
