import { Component, ElementRef, Injector, Input } from '@angular/core';

import * as _ from 'lodash';

import {
  FontSize,
  GridViewType,
  Operator,
  TotalValueStyle,
  UIGridChart,
  UIOption,
  UIPosition,
} from '@selfai-platform/bi-domain';

import { BaseOptionComponent } from './base-option.component';

@Component({
    selector: 'calc-option',
    templateUrl: './calc-option.component.html',
    standalone: false
})
export class CalculatedRowOptionComponent extends BaseOptionComponent {
  public operatorDefaultIdx = 0;

  public hAlignDefaultIdx = 0;

  @Input('uiOption')
  public set setUiOption(uiOption: UIOption) {
    this.uiOption = uiOption;

    if (GridViewType.MASTER == (<UIGridChart>this.uiOption).dataType && this.uiOption['totalValueStyle']) {
      this.uiOption = <UIOption>_.extend({}, this.uiOption, { totalValueStyle: null });
      this.update();
    }
  }

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public showCalculatedRow(): void {
    const uiOption = <UIGridChart>this.uiOption;

    if (!uiOption.totalValueStyle) {
      uiOption.totalValueStyle.fontSize = FontSize.NORMAL;
      uiOption.totalValueStyle.fontStyles = [];
      uiOption.totalValueStyle.fontColor = '';
      uiOption.totalValueStyle.backgroundColor = '#eeeeee';
      uiOption.totalValueStyle.hAlign = UIPosition.AUTO;
      uiOption.totalValueStyle.vAlign = UIPosition.MIDDLE;
      uiOption.totalValueStyle.aggregationType = Operator.SUM;
    } else {
      uiOption.totalValueStyle = null;
    }

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { totalValueStyle: uiOption.totalValueStyle });

    this.update();
  }

  public onChangedCalculatedRowSlider(style: TotalValueStyle) {
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { totalValueStyle: style });
    this.update();
  }

  public showCalculatedColumn(): void {
    const uiOption = <UIGridChart>this.uiOption;

    if (!uiOption.showCalculatedColumnStyle) {
      uiOption.showCalculatedColumnStyle.fontSize = FontSize.NORMAL;
      uiOption.showCalculatedColumnStyle.fontStyles = [];
      uiOption.showCalculatedColumnStyle.fontColor = '';
      uiOption.showCalculatedColumnStyle.backgroundColor = '#eeeeee';
      uiOption.showCalculatedColumnStyle.hAlign = UIPosition.AUTO;
      uiOption.showCalculatedColumnStyle.vAlign = UIPosition.MIDDLE;
      uiOption.showCalculatedColumnStyle.aggregationType = Operator.SUM;
    } else {
      uiOption.showCalculatedColumnStyle = null;
    }

    this.uiOption = <UIOption>(
      _.extend({}, this.uiOption, { showCalculatedColumnStyle: uiOption.showCalculatedColumnStyle })
    );

    this.update();
  }

  public onChangedCalculatedColumnSlider(style: TotalValueStyle) {
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { showCalculatedColumnStyle: style });
    this.update();
  }

  private apply(totalValueStyle: TotalValueStyle): void {
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { totalValueStyle: totalValueStyle });
    this.update();
  }
}
