import { Datasource } from '../../datasource';
import { BoardDataSource, Dashboard, GetDashboardResult } from '../models';
import { isSameDataSource } from '../normalizers';

export function findDataSourceOnBoard(
  board: Dashboard | GetDashboardResult,
  dataSource: Partial<Pick<Datasource, 'id' | 'engineName' | 'name'>>,
): BoardDataSource {
  const boardDs: BoardDataSource = board.configuration.dataSource as BoardDataSource;
  if ('multi' === boardDs.type) {
    return boardDs.dataSources.find((item) => isSameDataSource(item, dataSource));
  } else {
    return isSameDataSource(boardDs, dataSource) ? boardDs : undefined;
  }
}
