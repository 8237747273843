import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  CubeWorkflowData,
  EnrichmentToolFormGroup,
  EnrichmentToolParameters,
  EnrichmentToolRawParameters,
  GraphNode,
} from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { MessageModule } from 'primeng/message';
import { CodeEditorComponentModule } from '../../../../code-editor';
import { FormFieldComponentModule } from '../../../../form-field/form-field.module';
import { WorkflowEditorFacadeService } from '../../../../workflow-editor';
import { CubeDialogManagementService } from '../../../services/cube-dialog-management.service';
import { DataSourceSelectorComponent } from '../../data-source-selector/data-source-selector.component';
import { DialogHeaderComponentModule } from '../../dialog-header';
import { DialogHeaderService } from '../../dialog-header/dialog-header.service';

@Component({
    selector: 'selfai-platform-enrichment-tool',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormFieldComponentModule,
        DialogHeaderComponentModule,
        DataSourceSelectorComponent,
        TranslateModule,
        MessageModule,
        CodeEditorComponentModule,
    ],
    templateUrl: './enrichment-tool.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DialogHeaderService]
})
export class EnrichmentToolComponent {
  get node(): GraphNode<EnrichmentToolRawParameters> {
    return this.dialogService.data.selectedNode;
  }

  get nodeId(): string {
    return this.node.id;
  }

  get hasParameters(): boolean {
    return Boolean(this.node.parameters);
  }

  get nodeParameters(): EnrichmentToolParameters {
    return this.normalizeRawParameters(this.node.parameters?.serialize());
  }

  form = new FormGroup<EnrichmentToolFormGroup>({
    dataSourceId: new FormControl(null, [Validators.required]),
    sqlQuery: new FormControl(null),
  });

  constructor(
    private readonly workflowEditorFacadeService: WorkflowEditorFacadeService,
    private readonly dialogService: DialogService<undefined, CubeWorkflowData<EnrichmentToolRawParameters>>,
    private readonly dialogHeaderService: DialogHeaderService,
    private readonly cubeDialogManagementService: CubeDialogManagementService,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.form.patchValue(this.nodeParameters);
    this.dialogHeaderService.initCustomHeaderComponent(
      this.nodeId,
      this.translateService.instant('workflow.cubes.enrichment-tool.modal-header'),
      this.onCloseDialog.bind(this),
      this.onSubmit.bind(this),
    );
    setTimeout(() => {
      this.cubeDialogManagementService.setFocus(this.nodeId);
    });
  }

  onSubmit(): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.workflowEditorFacadeService.updateNodeParamterValues({
        id: this.nodeId,
        parameters: this.normalizeFormValuesToApiModel(this.form.value as EnrichmentToolParameters),
      });
      this.dialogService.close();
    }
  }

  onCloseDialog(): void {
    this.dialogService.close();
  }

  private normalizeRawParameters(params: EnrichmentToolRawParameters): EnrichmentToolParameters {
    return {
      sqlQuery: params['Enrichment SQL query'],
      dataSourceId: params['Choose JDBC Datasource'],
    };
  }

  private normalizeFormValuesToApiModel(formValues: EnrichmentToolParameters): EnrichmentToolRawParameters {
    return {
      'Enrichment SQL query': formValues.sqlQuery,
      'Choose JDBC Datasource': formValues.dataSourceId,
    };
  }
}
