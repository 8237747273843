import { Injectable } from '@angular/core';
import { EntityOp } from '@ngrx/data';
import { PageParams } from '@selfai-platform/shared';
import { Observable, catchError, filter, finalize, map, switchMap, take, tap, throwError } from 'rxjs';
import { FavoriteDomainService } from '../../favorite';
import { WorkbookList, WorkbookListItem, WorkbookListState } from '../models';
import { WorkbookListStore } from '../stores';
import { WorkbookApiToDomainService } from './workbook-domain-api.service';
import { WorkbookDomainService } from './workbook-domain.service';

@Injectable({ providedIn: 'root' })
export class WorkbookListDomainService {
  constructor(
    private readonly workbookApiToDomainService: WorkbookApiToDomainService,
    private readonly favoriteDomainService: FavoriteDomainService,
    private readonly workbookDomainService: WorkbookDomainService,
    private readonly workbookListStore: WorkbookListStore,
  ) {}

  loadWorkbookList(workspaceId: string, pageParams?: PageParams): Observable<WorkbookList> {
    this.workbookListStore.setLoading(true);

    return this.workbookApiToDomainService.getWorkbookList(workspaceId, pageParams).pipe(
      take(1),
      tap((workbookList) => {
        this.workbookListStore.setLoaded(true);
        this.workbookListStore.upsertOneInCache({ workspaceId, ...workbookList });
      }),
      catchError((err: unknown) => {
        this.workbookListStore.setLoaded(false);
        this.workbookListStore.createAndDispatch(EntityOp.QUERY_LOAD_ERROR);

        return throwError(() => err);
      }),
      finalize(() => {
        this.workbookListStore.setLoading(false);
      }),
    );
  }

  getWorkbookList(workspaceId: string): Observable<WorkbookListState> {
    return this.workbookListStore.entityMap$.pipe(
      map((entityMap) => entityMap?.[workspaceId]),
      filter(Boolean),
    );
  }

  getWorkbookListLoading(): Observable<boolean> {
    return this.workbookListStore.loading$;
  }

  getWorkbookListError(): Observable<unknown> {
    return this.workbookListStore.errors$;
  }

  getWorkbookListSuccess(): Observable<boolean> {
    return this.workbookListStore.loaded$;
  }

  updateOne(workspaceId: string, workbookdId: string, workbook: Partial<WorkbookListItem>): Observable<void> {
    this.workbookDomainService.updateWorkbook(workbookdId, workbook);

    return this.getWorkbookList(workspaceId).pipe(
      take(1),
      tap((workbookListState) => {
        const needUpdatingDashboardIndex = workbookListState.workbooks.findIndex((w) => w.id === workbookdId);
        if (needUpdatingDashboardIndex !== -1) {
          const updatedDashboard = { ...workbookListState.workbooks[needUpdatingDashboardIndex], ...workbook };
          const updatedDashboards = [
            ...workbookListState.workbooks.slice(0, needUpdatingDashboardIndex),
            updatedDashboard,
            ...workbookListState.workbooks.slice(needUpdatingDashboardIndex + 1),
          ];

          this.workbookListStore.updateOneInCache({ ...workbookListState, workbooks: updatedDashboards });
        }
      }),
      map(() => void 0),
    );
  }

  addToFavorite({ workbookId, workspaceId }: { workspaceId: string; workbookId: string }): Observable<void> {
    return this.favoriteDomainService
      .addToFavorite('workbooks', workbookId)
      .pipe(switchMap(() => this.updateOne(workspaceId, workbookId, { favorite: true })));
  }

  deleteFromFavorite({ workbookId, workspaceId }: { workspaceId: string; workbookId: string }): Observable<void> {
    return this.favoriteDomainService
      .removeFromFavorite('workbooks', workbookId)
      .pipe(switchMap(() => this.updateOne(workspaceId, workbookId, { favorite: false })));
  }
}
