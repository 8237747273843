<selfai-platform-data-list-view
  [actions]="actions$ | async"
  [groupActions]="groupActions$ | async"
  [columns]="columns"
  [showEntityIcon]="true"
  [dataIsLazy]="true"
  [emptyMessage]="'msg.workbook-list.table-empty' | translate"
  (renameItem)="saveName($event)"
  (toggleFavorite)="toggleFavorite($event)"
>
  <ng-template selfaiPlatformDataListViewTemplateName="gridItemPreview" let-item>
    <div class="surface-ground border-round square">
      <div class="grid p-3">
        <ng-container *ngIf="item.notRemovedDashboards?.length">
          <div *ngFor="let dashboard of item.notRemovedDashboards?.slice(0, 4)" class="col-6">
            <div class="dashboard-preview square flex">
              <a [routerLink]="['/', biRootRoute, 'workbook', item.id]" [queryParams]="{ dashboardId: dashboard.id }">
                <img [src]="dashboard.imageThumbnailUrl || thumbnailImgDefault" [pTooltip]="dashboard.name" />
              </a>
            </div>
          </div>
        </ng-container>
        <div *ngIf="item.notRemovedDashboards?.length < 4" class="col-6 dashboard-preview-add">
          <a
            class="button-add bg-white border-round square flex align-items-center justify-content-center"
            [routerLink]="['/', biRootRoute, 'workbook', item.id]"
            [queryParams]="{ createDashboard: true }"
          >
            <i class="pi pi-plus-circle" style="font-size: 5rem"></i>
          </a>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template selfaiPlatformDataListViewTemplateName="gridItemPreviewSkeleton">
    <div class="surface-ground border-round square">
      <div class="grid p-3">
        <div *ngFor="let _ of [].constructor(4)" class="col-6">
          <div class="dashboard-preview square flex">
            <p-skeleton width="100%" height="100%"></p-skeleton>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</selfai-platform-data-list-view>

<p-blockUI *ngIf="importStatus$ | async as importStatus" [blocked]="true">
  <div class="flex flex-column align-items-center">
    <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
    <p class="status-import mt-3">
      {{ importStatus }}
    </p>
  </div>
</p-blockUI>
