import { AnnotationPosition, FontSize, GridViewType, Operator, UIFontStyle, UIOrient, UIPosition } from '../../enums';
import { UIOption } from './ui-option.model';
import { UIChartMeasureColorByCell } from './ui-color.model';
import { Format } from '../../../workbook';

export interface IGridModel {
  checkboxData: boolean;
  xProperties: any;
  yProperties: any;
  zProperties: any;
  axisSelectMode: ESelectionMode;
  onAxisXClick: (e: MouseEvent) => void | null;
  onAxisYClick: (e: MouseEvent) => void | null;
  onBodyCellClick: (e: MouseEvent) => void | null;
  cellWidth: number;
  cellHeight: number;
  showAxisZ: boolean;
  customParams: any;
  gridSelectInfo: any;
  shelve: any;
  min: any;
  max: any;
  header: {
    font: any;
    align: any;
    showHeader?: boolean;
    backgroundColor?: string;
  }
  body: {
    font: any;
    color: {
      stepColors: any[];
      stepTextColors: any[];
      stepRangeColors?: any;
      measureCellColors: any[];
      showColorStep?: boolean;
      colorTarget?: string;
    },
    align: any;
    showAxisZ: boolean;
  },
  useSelectStyle?: boolean;
  leftAxisWidth?: number;
  showColorStep?: boolean;
  showDataColumn?: boolean;
  dataColumnMode?: string;
  remark?: Annotation;
  format?: any;
  measureFormat?: {[key: string]: Format};
  totalValueStyle?: TotalValueStyle;
  showCalculatedColumnStyle?: TotalValueStyle;
}

export interface UIGridChart extends UIOption {
  dataType?: GridViewType;
  measureLayout?: UIOrient;
  headerStyle?: ValueStyle;
  contentStyle?: ValueStyle;
  annotation?: Annotation;
  totalValueStyle?: TotalValueStyle;
  showCalculatedColumnStyle?: TotalValueStyle;
  measureColors?: UIChartMeasureColorByCell[];
}

export interface ValueStyle {
  showHeader?: boolean;
  vAlign?: UIPosition;
  hAlign?: UIPosition;
  fontSize?: FontSize;
  fontStyles?: Array<UIFontStyle>;
  backgroundColor?: string;
  fontColor?: string;
}

export interface Annotation {
  label?: string;
  pos?: AnnotationPosition;
}

export interface TotalValueStyle {
  label?: string;
  aggregationType?: Operator;
  hAlign?: UIPosition;
  vAlign?: UIPosition;
  fontSize?: FontSize;
  fontStyles?: Array<UIFontStyle>;
  backgroundColor?: string;
  fontColor?: string;
  font?: IGridFontStyle,
  align?: IGridAlignStyle,
}

export interface IGridFontStyle {
  size: any;
  color: any;
  styles: any;
  font: IGridFontStyle,
  align: IGridAlignStyle,
}

export interface IGridFontStyle {
  size: any;
  color: any;
  styles: any;
}

export interface IGridAlignStyle {
  hAlign: UIPosition;
  vAlign: UIPosition;
}

export interface IGridModel {
  checkboxData: boolean;
  xProperties: any;
  yProperties: any;
  zProperties: any;
  axisSelectMode: ESelectionMode;
  onAxisXClick: (e: MouseEvent) => void | null;
  onAxisYClick: (e: MouseEvent) => void | null;
  onBodyCellClick: (e: MouseEvent) => void | null;
  cellWidth: number;
  cellHeight: number;
  showAxisZ: boolean;
  customParams: any;
  gridSelectInfo: any;
  shelve: any;
  min: any;
  max: any;
  header: {
    font: any;
    align: any;
    showHeader?: boolean;
    backgroundColor?: string;
  }
  body: {
    font: any;
    color: {
      stepColors: any[];
      stepTextColors: any[];
      stepRangeColors?: any;
      measureCellColors: any[];
      showColorStep?: boolean;
      colorTarget?: string;
    },
    align: any;
    showAxisZ: boolean;
  },
  useSelectStyle?: boolean;
  leftAxisWidth?: number;
  showColorStep?: boolean;
  showDataColumn?: boolean;
  dataColumnMode?: string;
  remark?: Annotation;
  format?: any;
  measureFormat?: {[key: string]: Format};
  totalValueStyle?: TotalValueStyle;
  showCalculatedColumnStyle?: TotalValueStyle;
  measuresMinMax?: Record<string, { minValue: number, maxValue: number }>
}

export interface IGridAlignStyle {
  hAlign: UIPosition;
  vAlign: UIPosition;
}

export enum ESelectionMode {
  ONESIDE = 'ONESIDE',
  SINGLE = 'SINGLE',
  MULTI = 'MULTI',
}


export const INITIAL_GRID_MODEL = {
  checkboxData: true,
  cellWidth: 120,
  cellHeight: 30,
  showAxisZ: false,
  header: {
    font: {},
    align: {},
  },
  body: {
    font: {},
    color: {
      stepColors: new Array([]),
      stepTextColors: new Array([]),
      measureCellColors: new Array([]),
    },
    align: {},
    showAxisZ: false,
  },
};

export type TDataColMode = {
  TOP: string;
  LEFT: string;
}
