<ng-container *ngIf="{ data: chartList$ | async } as obs">
  <p-table
    #dt
    styleClass="p-datatable-sm"
    paginatorStyleClass="prime-table-custom__paginator"
    [value]="obs.data"
    [showLoader]="false"
    [paginator]="true"
    [rows]="pageParams.pageSize"
    [first]="pageParams.pageSize * (pageParams.pageNumber - 1)"
    [sortField]="pageParams.sortField || 'modifiedTime'"
    [sortOrder]="pageParams.sortOrder === 'asc' ? 1 : -1"
    [totalRecords]="totalRecords"
    [lazy]="true"
    [loading]="loading$ | async"
    (onLazyLoad)="loadChartList($event)"
  >
    <ng-template pTemplate="loadingbody">
      <tr tabindex="-1">
        <td *ngFor="let _ of [].constructor(8)">
          <p-skeleton [ngStyle]="{ width: 100 / 8 + '%' }"></p-skeleton>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td [colSpan]="8">{{ 'msg.board.filter.ui.search-all.nodata' | translate }}</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="caption">
      <div class="flex justify-content-between">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>

          <input
            style="width: 300px"
            pAutoFocus
            [autofocus]="true"
            [ngModel]="querySearch$ | async"
            pInputText
            type="text"
            (input)="onSearch($event)"
            placeholder="{{ 'msg.workbook-list.search.input-placeholder' | translate }}"
          />
        </span>

        <p-dropdown
          [options]="pageSizes"
          [ngModel]="pageParams.pageSize"
          (ngModelChange)="onPageChange({ pageSize: $event, pageNumber: pageParams.pageNumber })"
        ></p-dropdown>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th class="no-breaks" pSortableColumn="name">
          <p-sortIcon field="name"></p-sortIcon>
          Name
        </th>
        <th class="no-breaks" style="min-width: 7rem" pSortableColumn="chartType">
          <p-sortIcon field="chartType"></p-sortIcon>
          Type
        </th>
        <th class="no-breaks" pSortableColumn="dashBoard.name">
          <p-sortIcon field="dashBoard.name"></p-sortIcon>
          Dashboard
        </th>
        <th class="no-breaks" pSortableColumn="dashBoard.workBook.name">
          <p-sortIcon field="dashBoard.workBook.name"></p-sortIcon>
          Projects
        </th>
        <th>Datasources</th>
        <th class="no-breaks" pSortableColumn="createdTime">
          <p-sortIcon field="createdTime"></p-sortIcon>
          Created
        </th>
        <th class="no-breaks" pSortableColumn="modifiedTime">
          <p-sortIcon field="modifiedTime"></p-sortIcon>
          Modified
        </th>
        <th style="width: 200px">Actions</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-item>
      <tr>
        <td class="short-text" [pTooltip]="item.name">
          <a [routerLink]="getChartRouterLink(item)">{{ item.name }}</a>
        </td>
        <td>{{ item.chartType }}</td>

        <td class="short-text" [pTooltip]="item.dashBoard.name">
          <a
            [routerLink]="getWorkbookRouterLink(item.dashBoard.workBook)"
            [queryParams]="{ dashboardId: item.dashBoard.id }"
          >
            {{ item.dashBoard.name }}
          </a>
        </td>
        <td class="short-text" [pTooltip]="item.dashBoard.workBook.name">
          <a [routerLink]="getWorkbookRouterLink(item.dashBoard.workBook)">
            {{ item.dashBoard.workBook.name }}
          </a>
        </td>
        <td>
          <a
            *ngFor="let dataSource of item.dataSources; let i = index"
            class="block text-ellipsis mb-1"
            [routerLink]="getDataSourceRouterLink(dataSource)"
          >
            {{ dataSource.name }}
          </a>
        </td>
        <td>
          <span class="no-breaks">{{ item.createdTime | date: 'dd.MM.yyyy, hh:mm' }}</span>
        </td>
        <td>
          <span class="no-breaks">{{ item.modifiedTime | date: 'dd.MM.yyyy, hh:mm' }}</span>
        </td>
        <td tabindex="-1">
          <button
            type="button"
            pButton
            class="p-button-text p-button-link ml-2 p-0"
            appearance="icon"
            (click)="moveTo(item)"
            icon="pi pi-file-export"
            tooltipPosition="left"
            pTooltip="Move"
          ></button>
          <button
            (click)="copyTo(item)"
            type="button"
            pButton
            class="p-button-text p-button-link ml-2 p-0"
            appearance="icon"
            icon="pi pi-clone"
            tooltipPosition="left"
            pTooltip="Duplicate"
          ></button>
          <button
            *ngIf="item.imageUrl"
            (click)="overlayPanel.toggle($event)"
            type="button"
            pButton
            class="p-button-text p-button-link ml-2 p-0"
            appearance="icon"
            icon="pi pi-search"
            tooltipPosition="left"
            pTooltip="Show preview"
          ></button>
          <p-overlayPanel appendTo="body" [dismissable]="true" [showCloseIcon]="true" #overlayPanel>
            <div class="preview">
              <img *ngIf="overlayPanel.overlayVisible" [src]="item.imageUrl" />
            </div>
          </p-overlayPanel>
          <button
            type="button"
            pButton
            class="p-button-text p-button-link p-button-danger p-0"
            appearance="icon"
            (click)="remove($event, item)"
            icon="pi pi-trash"
            tooltipPosition="left"
            pTooltip="Delete chart"
          ></button>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer">
      <tr>
        <td class="p-0" [colSpan]="8">
          <selfai-platform-data-list-view-pagination
            *ngIf="pageParams.totalItems"
            [pageNumber]="pageParams.pageNumber"
            [pageSize]="pageParams.pageSize"
            [totalRecords]="pageParams.totalItems"
            (pageChange)="onPageChange($event)"
          ></selfai-platform-data-list-view-pagination>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>

<p-confirmPopup></p-confirmPopup>
<p-dialog
  [modal]="true"
  header="Choose dashboard"
  [(visible)]="visibleDashboardDialogSelector"
  [style]="{ width: '90%' }"
>
  <selfai-platform-bi-ui-dashboard-list-selector
    *ngIf="visibleDashboardDialogSelector"
    (selectDashboard)="onSelectDashboard($event)"
    [textButton]="textButton"
    [dataSourceId]="dataSourceId"
  ></selfai-platform-bi-ui-dashboard-list-selector>
</p-dialog>
