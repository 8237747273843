import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WorkspaceInDashboardList } from '@selfai-platform/bi-domain';
import { DialogService } from '@selfai-platform/shell';

@Component({
    selector: 'selfai-platform-workspace-edit-name-dialog',
    templateUrl: './workspace-edit-name-dialog.component.html',
    styleUrls: ['./workspace-edit-name-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WorkspaceEditNameDialogComponent {
  form = new FormGroup<{
    name: FormControl<string>;
    description: FormControl<string>;
  }>({
    name: new FormControl(this.workspaceData.name, [Validators.required, Validators.maxLength(255)]),
    description: new FormControl(this.workspaceData.description, [Validators.maxLength(255)]),
  });

  get workspaceData(): { name: string; description: string } {
    return this.dialogService.data;
  }

  constructor(
    private readonly dialogService: DialogService<
      { wasSaved: boolean; newData?: { name: string; description: string } },
      WorkspaceInDashboardList
    >,
  ) {}

  close() {
    this.dialogService.close({
      wasSaved: false,
    });
  }

  save() {
    this.dialogService.close({
      wasSaved: true,
      newData: this.form.value as Required<typeof this.form.value>,
    });
  }
}
