export enum Condition {
  BEGINS_FROM = 'begins_from',
  NOT_BEGINS_FROM = 'not_begins_from',
  ENDS_WITH = 'ends_with',
  NOT_ENDS_WITH = 'not_ends_with',
  EQUALS_TO = 'equals_to',
  NOT_EQUALS_TO = 'not_equals_to',
  IS_EMPTY = 'is_empty',
  NON_EMPTY = 'non_empty',
  CONTAINS = 'contains',
  REGEXP = 'regexp',
  LESS_THAN = 'less_than',
  MORE_THAN = 'more_than',
  MORE_EQUALS_THAN = 'more_equals_than',
  LESS_EQUALS_THAN = 'less_equals_than',
  BETWEEN = 'between',
  EXPRESSION = 'expression',
}

export const ConditionLegacy = {
  'begins from': Condition.BEGINS_FROM,
  'not begins from': Condition.NOT_BEGINS_FROM,
  'ends with': Condition.ENDS_WITH,
  'not ends with': Condition.NOT_ENDS_WITH,
  'equals to': Condition.EQUALS_TO,
  'not equals to': Condition.NOT_EQUALS_TO,
  'is empty': Condition.IS_EMPTY,
  'non empty': Condition.NON_EMPTY,
  contains: Condition.CONTAINS,
  regexp: Condition.REGEXP,
  'less than': Condition.LESS_THAN,
  'more than': Condition.MORE_THAN,
  'more equals than': Condition.MORE_EQUALS_THAN,
  'less equals than': Condition.LESS_EQUALS_THAN,
  between: Condition.BETWEEN,
  expression: Condition.EXPRESSION,
};

export type ConditionKeyLegacy = keyof typeof ConditionLegacy;
