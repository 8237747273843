
import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import * as _ from 'lodash';

import { ChartUtil } from '@selfai-platform/bi-chart-engine';
import {
  LogicalType,
  Shelf,
  ShelveFieldType,
  UIMapOption,
  DashboardField as AbstractField,
  DashboardField as Field,
} from '@selfai-platform/bi-domain';
import { isNullOrUndefined } from '@selfai-platform/shared';

import { AbstractComponent } from '../../../../common/component/abstract.component';

@Component({
    selector: 'map-spatial',
    templateUrl: './map-spatial.component.html',
    standalone: false
})
export class MapSpatialComponent extends AbstractComponent implements OnInit, OnDestroy, OnChanges {
  @Output('changeAnalysisNoti')
  private changeAnalysis = new EventEmitter();

  @Input()
  public uiOption: UIMapOption;

  @Input()
  public shelf: Shelf;

  public baseList: any = { layers: [] };
  public baseIndex = 0;
  public compareList: any = { layers: [] };
  public compareIndex = 0;

  public calSpatialList: any = [
    { name: 'With In', value: 'within' },
    { name: 'Intersection', value: 'intersects' },
  ];
  public calSpatialIndex = 0;

  public unitList: any = [
    { name: 'Meters', value: 'meters' },
    { name: 'Kilometers', value: 'kilometers' },
  ];
  public unitIndex = 0;
  public unitInput = '100';

  public isBufferOn = false;
  public bufferList: any = [
    { name: 'Meters', value: 'meters' },
    { name: 'Kilometers', value: 'kilometers' },
  ];
  public bufferIndex = 0;
  public bufferInput = '100';

  public isChoroplethOn = false;

  public fieldList: any = {
    measureList: [],
    dimensionList: [],
  };
  public colorByIndex = 0;

  public aggregateTypes = [
    { name: 'SUM', value: 'SUM' },
    { name: 'AVG', value: 'AVG' },
    { name: 'CNT', value: 'COUNT' },
    { name: 'MED', value: 'MEDIAN' },
    { name: 'MIN', value: 'MIN' },
    { name: 'MAX', value: 'MAX' },
  ];
  public aggregateTypesIndex = 0;

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      !_.isUndefined(changes) &&
      !_.isUndefined(changes['uiOption']) &&
      !_.isUndefined(changes['uiOption']['currentValue']['analysis']) &&
      !_.isUndefined(changes['uiOption']['currentValue']['analysis']['use']) &&
      changes['uiOption']['currentValue']['analysis']['use'] == true &&
      this.baseList.layers.length > 0
    ) {
      return;
    } else if (!_.isUndefined(changes) && !_.isUndefined(changes['uiOption'])) {
      this.uiOption = <UIMapOption>changes['uiOption'].currentValue;
      this.mapSpatialChanges(this.uiOption, this.shelf);
    }
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public mapSpatialChanges(uiOption, shelf) {
    this.uiOption = <UIMapOption>uiOption;
    this.shelf = shelf;
    this.baseList.layers = [];
    this.compareList.layers = [];

    if (!_.isUndefined(this.shelf) && this.shelf.layers.length > 0) {
      let isChanged = false;
      let shelfIndex = 0;
      this.shelf.layers.forEach((layer) => {
        if (!_.isUndefined(layer.fields) && layer.fields.length > 0 && shelfIndex < 2) {
          layer.fields.forEach((field) => {
            if (
              !_.isUndefined(field) &&
              !_.isUndefined(field.field) &&
              !_.isUndefined(field.field.logicalType) &&
              (field.field.logicalType === LogicalType.GEO_POINT ||
                field.field.logicalType === LogicalType.GEO_POLYGON ||
                field.field.logicalType === LogicalType.GEO_LINE)
            ) {
              if (
                !_.isUndefined(this.uiOption) &&
                !_.isUndefined(this.uiOption.layers) &&
                this.uiOption.layers.length > 0 &&
                !_.isUndefined(this.uiOption.layers[shelfIndex].name)
              ) {
                this.baseList.layers.push(this.uiOption.layers[shelfIndex].name);
                if (
                  !isNullOrUndefined(this.uiOption) &&
                  !isNullOrUndefined(this.uiOption.analysis) &&
                  !isNullOrUndefined(this.uiOption.analysis.selectedLayerNum)
                ) {
                  this.baseList['selectedNum'] = this.uiOption.analysis.selectedLayerNum;
                } else {
                  if (!isChanged) {
                    this.baseList['selectedNum'] = shelfIndex;
                    isChanged = true;
                  }
                }
              }
            }
          });
        }
        shelfIndex++;
      });
      if (this.baseList.layers.length > 1) {
        this.compareList.layers = _.cloneDeep(this.baseList.layers);
        this.compareList.layers.splice(this.baseList['selectedNum'], 1);

        this.compareIndex = 0;

        this.setMeasureList();

        if (
          !_.isUndefined(this.uiOption.analysis) &&
          !_.isUndefined(this.uiOption.analysis['use']) &&
          this.uiOption.analysis['use']
        ) {
          const operation = this.uiOption.analysis.operation;
          this.isBufferOn = operation.buffer == 0 ? false : true;
          if (this.isBufferOn) {
            this.isBufferOn = true;

            let tempBufferIndex = 0;
            this.bufferList.forEach((buffer) => {
              if (buffer.value == operation['bufferUnit']) {
                this.bufferIndex = tempBufferIndex;
              }
              tempBufferIndex++;
            });
            if (this.bufferList[this.bufferIndex].value == 'kilometers') {
              this.bufferInput = (Number(operation.buffer) / 1000).toString();
            } else {
              this.bufferInput = operation.buffer.toString();
            }
          } else {
            this.isBufferOn = false;
            this.bufferIndex = 0;
          }
          this.isChoroplethOn = operation.choropleth;
          if (this.isChoroplethOn) {
            const measureList = this.fieldList.measureList;
            for (let index = 0; index < measureList.length; index++) {
              if (_.isUndefined(operation.aggregation.type)) {
                this.colorByIndex = 0;
                break;
              }
              if (measureList[index].name == operation.aggregation.column) {
                this.colorByIndex = index;
                break;
              }
            }
          }
          if (!_.isUndefined(operation.aggregation.type)) {
            for (let index = 0; index < this.aggregateTypes.length; index++) {
              if (this.aggregateTypes[index].name == operation.aggregation.type) {
                this.aggregateTypesIndex = index;
                break;
              }
            }
          }
        }
      }
    }
  }

  public onSelectBase(value) {
    this.doEnableAnalysisBtn();

    this.baseList['selectedNum'] = this.baseList.layers.findIndex((baseItem) => baseItem === value);
    this.baseIndex = this.baseList['selectedNum'];
    this.compareList.layers = [];
    this.compareList.layers = _.cloneDeep(this.baseList.layers);
    this.compareList.layers = _.remove(this.compareList.layers, function (layer) {
      return layer != value;
    });
    this.compareIndex = 0;
    this.setMeasureList();
    this.changeDetect.detectChanges();
  }

  public onSelectCompare(value) {
    this.doEnableAnalysisBtn();

    this.compareList['selectedNum'] = this.compareList.layers.findIndex((compareItem) => compareItem === value);
    this.compareIndex = this.compareList['selectedNum'];
  }

  public onSelectSpatial(value) {
    this.doEnableAnalysisBtn();

    this.calSpatialIndex = this.calSpatialList.findIndex((spatialItem) => spatialItem === value);
  }

  public onSelectUnit(value) {
    this.doEnableAnalysisBtn();

    this.unitIndex = this.unitList.findIndex((unitItem) => unitItem === value);
  }

  public bufferInputChange() {
    this.doEnableAnalysisBtn();
  }

  public onSelectBuffer(value) {
    this.doEnableAnalysisBtn();

    this.bufferIndex = this.bufferList.findIndex((unitItem) => unitItem === value);
  }

  public choroplethBtn() {
    this.doEnableAnalysisBtn();

    this.isChoroplethOn = !this.isChoroplethOn;
  }

  public bufferBtn() {
    this.doEnableAnalysisBtn();

    this.isBufferOn = !this.isBufferOn;
  }

  public selectColor(item) {
    this.doEnableAnalysisBtn();

    this.colorByIndex = this.fieldList['measureList'].findIndex((colorByItem) => colorByItem === item);
  }

  public selectAggregate(item) {
    this.doEnableAnalysisBtn();

    this.aggregateTypesIndex = this.aggregateTypes.findIndex((aggregateItem) => aggregateItem === item);
  }

  public spatialAnalysisBtn() {
    if (
      !_.isUndefined(this.uiOption['analysis']) &&
      this.uiOption['analysis']['use'] == true &&
      (isNullOrUndefined(this.uiOption.analysis['isReAnalysis']) ||
        (!isNullOrUndefined(this.uiOption.analysis['isReAnalysis']) && this.uiOption.analysis['isReAnalysis'] == false))
    ) {
      return;
    }

    const spatialDataValue: string = this.calSpatialList[this.calSpatialIndex].value;
    const baseData: string = this.baseList.layers[this.baseIndex];
    const compareData: string = this.compareList.layers[this.compareIndex];

    const bufferData: string = this.bufferList[this.bufferIndex].value;
    const unitData: string = this.unitList[this.unitIndex].value;

    if (this.spatialAnalysisCommonValidation(baseData, compareData) == false) {
      return;
    }
    let mapUIOption = <UIMapOption>this.uiOption;
    switch (spatialDataValue) {
      case 'dwithin':
        if (this.spatialAnalysisAdditionalValidation(bufferData, spatialDataValue) == false) {
          return;
        }

        mapUIOption = this.dWithinSetData(baseData, compareData, unitData, spatialDataValue, mapUIOption);
        break;
      case 'within':
        if (this.spatialAnalysisAdditionalValidation(bufferData, spatialDataValue) == false) {
          return;
        }

        mapUIOption = this.withinOrIntersectsSetData(baseData, compareData, bufferData, spatialDataValue, mapUIOption);
        break;
      case 'intersects':
        if (this.spatialAnalysisAdditionalValidation(bufferData, spatialDataValue) == false) {
          return;
        }

        mapUIOption = this.withinOrIntersectsSetData(baseData, compareData, bufferData, spatialDataValue, mapUIOption);
        break;
      case 'symmetricdiff':
        mapUIOption = this.symmetricalSetData(baseData, compareData, spatialDataValue, mapUIOption);
        break;
      default:
        this.alertPrimeService.warn(this.translateService.instant('msg.page.chart.map.spatial.select.analysis'));
        return;
    }

    const value = {
      action: 'analysis',
      uiOption: mapUIOption,
    };

    if (
      !isNullOrUndefined(this.uiOption.analysis['isReAnalysis']) &&
      this.uiOption.analysis['isReAnalysis'] == true &&
      this.uiOption.layers.length >= 3
    ) {
      value.action = 'reAnalysis';
    }
    delete this.uiOption.analysis['isReAnalysis'];

    this.changeAnalysis.emit(value);
  }

  private spatialAnalysisCommonValidation(baseData: string, compareData: string): boolean {
    if (_.isUndefined(baseData)) {
      this.alertPrimeService.warn(this.translateService.instant('msg.page.chart.map.spatial.select.mainlayer'));
      return false;
    }
    if (_.isUndefined(compareData)) {
      this.alertPrimeService.warn(this.translateService.instant('msg.page.chart.map.spatial.select.comparelayer'));
      return false;
    }

    return true;
  }

  private spatialAnalysisAdditionalValidation(bufferData: string, spatialDataValue: string): boolean {
    if (_.isUndefined(this.unitInput) || this.unitInput.trim() === '' || isNaN(Number(this.unitInput.trim()))) {
      this.alertPrimeService.warn(this.translateService.instant('msg.page.chart.map.spatial.select.range'));
      return false;
    }

    return true;
  }

  private dWithinSetData(
    baseData: string,
    compareData: string,
    unitData: string,
    spatialDataValue: string,
    mapUIOption: UIMapOption,
  ): UIMapOption {
    let unitInputData = Number(this.unitInput.trim());
    if (unitData == 'kilometers') {
      unitInputData = unitInputData * 1000;
    }

    const tempReAnalysis =
      !isNullOrUndefined(this.uiOption.analysis) && !isNullOrUndefined(this.uiOption.analysis['isReAnalysis'])
        ? this.uiOption.analysis['isReAnalysis']
        : false;

    mapUIOption.analysis = {
      use: true,
      isReAnalysis: tempReAnalysis,
      type: 'geo',

      layerNum: 0,
      selectedLayerNum: this.baseIndex,
      mainLayer: baseData,
      compareLayer: compareData,
      operation: {
        type: spatialDataValue,
        distance: unitInputData,
        unit: unitData,
        aggregation: {
          column:
            this.colorByIndex == 0
              ? this.fieldList['measureList'][this.colorByIndex]['alias']
              : this.fieldList['measureList'][this.colorByIndex]['name'],
          type: this.aggregateTypes[this.aggregateTypesIndex]['value'],
        },
      },
    };

    mapUIOption.analysis['operation']['choropleth'] = this.isChoroplethOn;

    return mapUIOption;
  }

  private withinOrIntersectsSetData(
    baseData: string,
    compareData: string,
    bufferData: string,
    spatialDataValue: string,
    mapUIOption: UIMapOption,
  ): UIMapOption {
    let bufferDataValue = Number(this.bufferInput.trim());
    if (bufferData == 'kilometers') {
      bufferDataValue = bufferDataValue * 1000;
    }

    const tempReAnalysis =
      !isNullOrUndefined(this.uiOption.analysis) && !isNullOrUndefined(this.uiOption.analysis['isReAnalysis'])
        ? this.uiOption.analysis['isReAnalysis']
        : false;

    mapUIOption.analysis = {
      use: true,
      isReAnalysis: tempReAnalysis,
      type: 'geo',

      layerNum: 0,
      selectedLayerNum: this.baseIndex,
      mainLayer: baseData,
      compareLayer: compareData,
      operation: {
        type: spatialDataValue,
        aggregation: {
          column:
            this.colorByIndex == 0
              ? this.fieldList['measureList'][this.colorByIndex]['alias']
              : this.fieldList['measureList'][this.colorByIndex]['name'],
          type: this.aggregateTypes[this.aggregateTypesIndex]['value'],
        },
      },
    };

    const findCompareIndex = this.baseIndex == 0 ? 1 : 0;

    mapUIOption.analysis['operation']['bufferUnit'] = this.bufferList[this.bufferIndex].value;
    if (bufferDataValue > 0 && this.isBufferOn == true) {
      mapUIOption.analysis['operation']['buffer'] = bufferDataValue;
    } else if (
      this.uiOption.layers[findCompareIndex].type.toString().toLowerCase().indexOf('polygon') != -1 &&
      this.isBufferOn == false
    ) {
      mapUIOption.analysis['operation']['buffer'] = 0;
    } else {
      mapUIOption.analysis['operation']['buffer'] = 1;
    }

    if (mapUIOption.analysis.operation.aggregation.column == 'count' && this.colorByIndex == 0) {
      delete mapUIOption.analysis.operation.aggregation.type;
    }

    mapUIOption.analysis['operation']['choropleth'] = this.isChoroplethOn;

    return mapUIOption;
  }

  private symmetricalSetData(
    baseData: string,
    compareData: string,
    spatialDataValue: string,
    mapUIOption: UIMapOption,
  ): UIMapOption {
    const tempReAnalysis =
      !isNullOrUndefined(this.uiOption.analysis) && !isNullOrUndefined(this.uiOption.analysis['isReAnalysis'])
        ? this.uiOption.analysis['isReAnalysis']
        : false;

    mapUIOption.analysis = {
      use: true,
      isReAnalysis: tempReAnalysis,
      type: 'geo',
      layerNum: this.baseIndex,
      mainLayer: baseData,
      compareLayer: compareData,
      operation: {
        type: spatialDataValue,
      },
    };
    return mapUIOption;
  }

  private setMeasureList() {
    const shelf = this.shelf;

    this.fieldList = {
      measureList: [{ name: 'Count', alias: 'count' }],
      dimensionList: [],
    };
    let tempObj: object = {};
    let measureList: Field[];
    let dimensionList: Field[];

    for (let index = 0; index < shelf.layers.length; index++) {
      if (this.uiOption.layers[index].name != this.baseList.layers[this.baseIndex]) {
        continue;
      }

      const layers = _.cloneDeep(shelf.layers[index].fields);

      const getShelveReturnField = (shelve: any, typeList: ShelveFieldType[]): AbstractField[] => {
        const resultList: any[] = [];
        shelve.map((item) => {
          if (
            (_.eq(item.type, typeList[0]) || _.eq(item.type, typeList[1])) &&
            item.field &&
            ('user_expr' === item.field.type || (item.field.logicalType && -1 == item.field.logicalType.indexOf('GEO')))
          ) {
            item['alias'] = ChartUtil.getAlias(item);
            if (resultList.length == 0) {
              resultList.push({ name: 'Count', alias: 'count' });
            }
            resultList.push(item);
          }
        });
        return resultList;
      };

      measureList = getShelveReturnField(layers, [ShelveFieldType.MEASURE, ShelveFieldType.CALCULATED]);
      dimensionList = getShelveReturnField(layers, [ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP]);
      tempObj = {
        measureList: measureList,
        dimensionList: dimensionList,
      };
      if (measureList.length > 0) {
        this.fieldList = tempObj;
      }

      if (isNullOrUndefined(this.fieldList['measureList'][this.colorByIndex])) {
        this.colorByIndex = 0;
      }
    }
  }

  private doEnableAnalysisBtn() {
    if (
      !_.isUndefined(this.uiOption.analysis) &&
      !_.isUndefined(this.uiOption.analysis['use']) &&
      this.uiOption.analysis['use'] == true
    ) {
      this.uiOption.analysis['isReAnalysis'] = true;
    }
  }
}
