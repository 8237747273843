import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AggregatorRouteApiModel } from '@selfai-platform/pipeline-common';
import {
  DestroyService,
  LocalStorageKey,
  PREFIX_SETTINGS_LOCAL_STORAGE_KEY,
  confirmSaveWhenLeaveSite,
} from '@selfai-platform/shared';
import {
  DialogService,
  GroupAction,
  SelectedItemsService,
  TableColumn,
  ToolbarAction,
  convertMessageToHtml,
  provideDataListView,
  provideDialogService,
} from '@selfai-platform/shell';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Observable, map, take, takeUntil } from 'rxjs';
import { AggregatorRouteListService } from '../../services/aggregator-camel-list.service';
import { AggregatorExportService } from '../../services/aggregator-export.service';
import { AggregatorImportService } from '../../services/aggregator-import.service';
import { AggregatorItemService } from '../../services/aggregator-item.service';
import { AggregatorImportFormComponent } from '../aggregator-import-form';
import { AggregatorRouteAddComponent } from '../aggregator-route-add';
import { AggregatorRouteActionsForItemService } from './aggregator-route-actions-for-item.service';
import { AggregatorRouteDataListViewService, AggregatorRouteListItem } from './aggregator-route-data-list-view.service';

@Component({
    selector: 'selfai-platform-aggregator-route-list',
    templateUrl: './aggregator-route-list.component.html',
    styleUrls: ['./aggregator-route-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        provideDialogService(),
        ...provideDataListView(AggregatorRouteDataListViewService, AggregatorRouteActionsForItemService),
        ConfirmationService,
        DestroyService,
        {
            provide: PREFIX_SETTINGS_LOCAL_STORAGE_KEY,
            useValue: LocalStorageKey.AGGREGATOR_ROUTE_LIST_PREFIX_SETTINGS,
        },
    ],
    standalone: false
})
export class AggregatorRouteListComponent implements OnInit {
  actions$: Observable<ToolbarAction[]>;
  groupActions$: Observable<GroupAction[]> = this.getGroupActions();
  columns: TableColumn<AggregatorRouteListItem>[] = this.aggregatorRouteDataListViewService.getColumns();

  saving$: Observable<boolean> = this.aggregatorRouteDataListViewService.saving$;
  isSaved$: Observable<boolean> = this.aggregatorRouteDataListViewService.isSaved$;
  activeStatuses: SelectItem[] = [
    { value: true, label: 'Active', icon: 'pi pi-check-circle' },
    { value: false, label: 'Disabled', icon: 'pi pi-ban' },
  ];

  constructor(
    private readonly aggregatorRouteListService: AggregatorRouteListService,
    private readonly aggregatorItemService: AggregatorItemService,
    private readonly aggregatorRouteDataListViewService: AggregatorRouteDataListViewService,
    private readonly dialogService: DialogService<{ routeName: string; routeData?: AggregatorRouteApiModel }>,
    private readonly router: Router,
    private readonly confirmationService: ConfirmationService,
    private readonly translate: TranslateService,
    private readonly aggregatorExportService: AggregatorExportService,
    private readonly aggregatorImportService: AggregatorImportService,
    private readonly dialogImportService: DialogService<AggregatorRouteApiModel[], { showReplaceWarn?: boolean }>,
    private readonly selectedItemsService: SelectedItemsService<AggregatorRouteListItem>,
    private readonly destroy$: DestroyService,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.actions$ = this.getActions();

    confirmSaveWhenLeaveSite(this.isSaved$).pipe(takeUntil(this.destroy$)).subscribe();
  }

  addRoute(routeName: string): void {
    const uuid = this.aggregatorRouteListService.addRoute({
      name: routeName,
      isActive: true,
      input: false,
      steps: [],
    });

    this.navigateToRoute(uuid);
  }

  showAddRouteForm(): void {
    this.dialogService
      .showDialog(AggregatorRouteAddComponent, {
        header: 'New route',
        width: '50%',
      })
      .subscribe(({ routeName, routeData }) => {
        if (routeName) {
          if (routeData) {
            this.importRoute({ ...routeData, name: routeName });
          } else {
            this.addRoute(routeName);
          }
        }
      });
  }

  exportAllRoutes(): void {
    this.aggregatorExportService.exportAllRoutes();
  }

  importRoute(route: AggregatorRouteApiModel): void {
    const uuid = this.aggregatorRouteListService.addRoute(route);

    this.navigateToRoute(uuid);
  }

  openImportForm(): void {
    this.dialogImportService
      .showDialog(AggregatorImportFormComponent, {
        header: this.translate.instant('aggregator-route-list.import-form.header'),
        width: '50%',
        data: { showReplaceWarn: true },
      })
      .subscribe((data?: AggregatorRouteApiModel[]) => {
        if (data) {
          this.aggregatorImportService.replaceAllRoutes(data);
        }
      });
  }

  toggleActiveRoute(uuid: string): void {
    this.aggregatorItemService.toggleActiveStatusRoute(uuid);
  }

  save(): void {
    this.aggregatorRouteListService.saveRoutes().subscribe();
  }

  private navigateToRoute(uuid: string): void {
    this.router.navigate([uuid], {
      relativeTo: this.route,
    });
  }

  private getActions(): Observable<ToolbarAction[]> {
    return this.aggregatorRouteDataListViewService.isSaved$.pipe(
      map((isSaved) => {
        const actions: ToolbarAction[] = [
          {
            icon: 'pi pi-save',
            labelTranslate: 'aggregator-route-list.actions.save',
            buttonClass: 'p-button-success',
            action: () => this.save(),
            disabled: isSaved,
          },
          {
            icon: 'pi pi-plus',
            labelTranslate: 'aggregator-route-list.actions.add',
            action: () => this.showAddRouteForm(),
          },
          {
            icon: 'pi pi-download',
            labelTranslate: 'aggregator-route-list.actions.export-all',
            action: () => this.exportAllRoutes(),
          },
          {
            icon: 'pi pi-upload',
            labelTranslate: 'aggregator-route-list.actions.import',
            action: () => this.openImportForm(),
          },
        ];

        return actions;
      }),
    );
  }

  private getGroupActions(): Observable<GroupAction[]> {
    return this.aggregatorRouteListService.isSaved$.pipe(
      map((isSaved) => {
        const actions: GroupAction[] = [];

        actions.push({
          tooltipTranslate: 'aggregator-route-list.group-actions.export-to-file',
          icon: 'pi pi-download',
          action: () => this.exportSelected(),
        });

        actions.push({
          tooltipTranslate: 'aggregator-route-list.group-actions.clone',
          icon: 'pi pi-copy',
          action: () => this.cloneSelected(),
          disabled: !isSaved,
        });

        actions.push({
          tooltipTranslate: 'aggregator-route-list.group-actions.remove',
          icon: 'pi pi-trash',
          buttonClass: 'p-button-danger',
          action: (eventTarget) => this.removeSelected(eventTarget),
          disabled: !isSaved,
        });

        return actions;
      }),
    );
  }

  private removeSelected(eventTarget: EventTarget): void {
    this.confirmationService.confirm({
      target: eventTarget,
      message: convertMessageToHtml(this.translate.instant('aggregator-route-list.actions.remove-confirm')),
      dismissableMask: true,
      closeOnEscape: true,
      acceptLabel: this.translate.instant('aggregator-route-list.actions.remove-confirm.accept-label'),
      rejectLabel: this.translate.instant('aggregator-route-list.actions.remove-confirm.reject-label'),
      acceptButtonStyleClass: 'p-button-success',
      rejectButtonStyleClass: 'p-button-text p-button-link',
      acceptIcon: 'pi pi-trash',
      accept: () => {
        this.selectedItemsService
          .getSelectedItems()
          .pipe(take(1), takeUntil(this.destroy$))
          .subscribe((routes) => {
            routes.forEach((route) => {
              this.aggregatorRouteListService.removeRoute(route.uuid);
            });
          });
      },
    });
  }

  private cloneSelected(): void {
    this.selectedItemsService
      .getSelectedItems()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe((processes) => {
        processes.forEach((process) => {
          this.aggregatorItemService.cloneRoute(process.uuid);
        });
      });
  }

  private exportSelected(): void {
    this.selectedItemsService
      .getSelectedItems()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe((routes) => {
        routes.forEach((route) => {
          this.aggregatorExportService.exportRoute(route.uuid);
        });
      });
  }
}
