import { Directive, Output, EventEmitter, AfterContentInit, Inject, ElementRef } from '@angular/core';

@Directive({
    selector: '[invoke]',
    standalone: false
})
export class InvokeDirective implements AfterContentInit {
  @Output() invoke: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Inject(ElementRef) private element: ElementRef) {}

  public ngAfterContentInit() {
    setTimeout(() => {
      this.invoke.emit(this.element);
    }, 250);
  }
}
