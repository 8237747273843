import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  BiPermissionService,
  Dashboard,
  PermissionForEntityFactory,
  WorkbookPinsDomainService,
  Workspace,
} from '@selfai-platform/bi-domain';
import { BiEntityPermission, ExecuteWithPipe } from '@selfai-platform/shared';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { Observable, map, take } from 'rxjs';
import { ThumbnailComponent } from '../thumbnail/thumbnail.component';

@Component({
    selector: 'selfai-platform-workbook-thumbnails',
    imports: [
        CommonModule,
        TranslateModule,
        ButtonModule,
        TooltipModule,
        CommonModule,
        AutoFocusModule,
        ExecuteWithPipe,
        ThumbnailComponent,
    ],
    templateUrl: './workbook-thumbnails.component.html',
    styleUrls: ['./workbook-thumbnails.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkbookThumbnailsComponent implements OnInit {
  @ViewChild('sliderContent ') sliderContent: ElementRef;

  @Input() workspace: Workspace;
  @Input() workbookId: string;
  @Input() dashboards: Dashboard[];
  @Input() selectedDashboard: Dashboard;

  @Output() dashboardClone: EventEmitter<Dashboard> = new EventEmitter();
  @Output() dashboardDelete: EventEmitter<Dashboard> = new EventEmitter();
  @Output() dashboardSelect: EventEmitter<Dashboard> = new EventEmitter<Dashboard>();

  private workspacePermission: PermissionForEntityFactory;
  biEntityPermission = BiEntityPermission;

  search = new FormControl('');

  sliderTranslateX = 0;
  sliderGap = 14;
  slideSize = 240;
  loading = true;

  pins$: Observable<string[]>;
  showThumbnails = false;

  constructor(
    private readonly permissionService: BiPermissionService,
    private readonly workbookPinsDomainService: WorkbookPinsDomainService,
  ) {
    this.checkDisabledArrow = this.checkDisabledArrow.bind(this);
  }

  ngOnInit() {
    this.workbookPinsDomainService.loadPins(this.workbookId).pipe(take(1)).subscribe();
    this.workspacePermission = this.permissionService.getPermissionsForEntityFactory(this.workspace.permissions);
    this.pins$ = this.workbookPinsDomainService
      .getPins(this.workbookId)
      .pipe(map((pins) => pins.map((pin) => pin.dashboardId)));
  }

  hasPermission(permission: BiEntityPermission): boolean {
    return this.workspacePermission?.hasPermission(permission);
  }

  selectDashboard(dashboard: Dashboard) {
    this.dashboardSelect.emit(dashboard);
  }

  deleteDashboard(dashboard: Dashboard) {
    this.dashboardDelete.emit(dashboard);
  }

  cloneDashboard(dashboard: Dashboard) {
    this.dashboardClone.emit(dashboard);
  }

  scrollSlider(direction: 'prev' | 'next') {
    if (direction === 'prev') {
      this.sliderTranslateX += this.slideSize;
    }

    if (direction === 'next') {
      this.sliderTranslateX -= this.slideSize;
    }
  }

  checkDisabledArrow(length: number, sliderContent: HTMLElement, sliderTranslateX: number): boolean {
    const maxTranslate = (length - Math.floor(sliderContent.clientWidth / this.slideSize)) * this.slideSize;

    return Math.abs(sliderTranslateX) === maxTranslate;
  }

  toggleThumbnails(): void {
    this.showThumbnails = !this.showThumbnails;
  }
}
