import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs';

@Component({
    selector: 'selfai-platform-workspaces-add-role',
    templateUrl: './workspaces-add-role.component.html',
    styleUrls: ['./workspaces-add-role.component.scss', '../workspaces-roles-manager-styles.scss'],
    standalone: false
})
export class WorkspacesAddRoleComponent {
  @Output() createdRole = new EventEmitter<any>();

  constructor(private readonly activatedRoute: ActivatedRoute, private readonly router: Router) {}

  form = new FormGroup({
    name: new FormControl(''),
  });

  showCreateRole$ = this.activatedRoute.queryParams.pipe(map((params) => params['showCreateRole']));

  createRole() {
    const { name } = this.form.getRawValue();

    this.createdRole.emit({
      id: null,
      name,
      permissions: [],
    });
    this.form.reset();
    this.hideCreateRole();
  }

  showCreateRole() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        showCreateRole: true,
      },
    });
  }

  hideCreateRole() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        showCreateRole: null,
      },
    });
  }
}
