import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { WORKBOOK_ENTITY_NAME } from '../const';
import { WorkbookState } from '../models';
import { WorkbookDataService } from '../services/workbook-data.service';

@Injectable({ providedIn: 'root' })
export class WorkbookStore extends EntityCollectionServiceBase<WorkbookState> {
  constructor(
    entityDataService: EntityDataService,
    workbookDataService: WorkbookDataService,
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(WORKBOOK_ENTITY_NAME, workbookDataService);
    super(WORKBOOK_ENTITY_NAME, serviceElementsFactory);
  }
}
