import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkspaceApiToDomainService } from '@selfai-platform/bi-domain';
import { DestroyService, SearchQueryService } from '@selfai-platform/shared';
import { KNOWLEDGE_DESIGNER_ROOT_ROUTE } from '@selfai-platform/shell';
import { MessageService } from 'primeng/api';
import {
  BehaviorSubject,
  concatMap,
  filter,
  finalize,
  forkJoin,
  map,
  of,
  shareReplay,
  startWith,
  Subscription,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { WorkspacesAddRoleComponent } from './workspaces-add-role/workspaces-add-role.component';
import { IWorkspaceRole, WorkspaceWithNormalOwner } from './workspaces-roles-manager.interfaces';
import { WorkspacesRolesManagerService } from './workspaces-roles-manager.service';
import { notEmptyArrayValidator } from './workspaces-roles-manager.validators';

@Component({
    selector: 'selfai-platform-workspaces-roles-manager',
    templateUrl: './workspaces-roles-manager.component.html',
    styleUrls: ['./workspaces-roles-manager.component.scss', './workspaces-roles-manager-styles.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DestroyService, MessageService, SearchQueryService],
    standalone: false
})
export class WorkspacesRolesManagerComponent {
  @ViewChild(WorkspacesAddRoleComponent) addRoleComponent: WorkspacesAddRoleComponent;

  form = new FormGroup({
    name: new FormControl('', Validators.required),
    description: new FormControl(''),
    publicType: new FormControl('SHARED'),
    roles: new FormControl([]),
    owners: new FormControl<any[]>([], notEmptyArrayValidator),
    useDefaultRoles: new FormControl(true),
  });

  users$ = this.workspaceService.getUsers().pipe(
    map((usersWrapper) => {
      return usersWrapper._embedded.users;
    }),
    shareReplay(1),
  );

  roles$ = new BehaviorSubject<IWorkspaceRole[]>([]);
  members$ = new BehaviorSubject<Record<string, string[]>>({});
  loadedMembers$ = new BehaviorSubject([]);
  workspaceId$ = new BehaviorSubject<string>(null);
  isSaving$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly destroy$: DestroyService,
    private readonly workspaceService: WorkspaceApiToDomainService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly messageService: MessageService,
    private readonly router: Router,
    private readonly rolesManagerService: WorkspacesRolesManagerService,
    private readonly location: Location,
  ) {
    this.changeWorkspace();

    this.workspaceId$.pipe(takeUntil(this.destroy$)).subscribe((workspaceId) => {
      if (workspaceId) {
        this.initialWorkspace(workspaceId);
      } else {
        this.changeUseDefaultRoles(workspaceId);
      }
    });
  }

  addMembers(name: string, members: string[]) {
    this.members$.next({
      ...this.members$.getValue(),
      [name]: members,
    });
  }

  addRole(role: IWorkspaceRole): void {
    const roles = this.roles$.getValue();
    roles.push(role);
    this.roles$.next(roles);
  }

  removeRole(roleName: string): void {
    const roles = this.roles$.getValue();
    const index = roles.findIndex((role) => role.name === roleName);
    roles.splice(index, 1);
    this.roles$.next(roles);
    this.form.patchValue({ roles });
  }

  changePermissions(role: IWorkspaceRole): void {
    const roles = this.roles$.getValue();
    const index = roles.findIndex((r) => r.name === role.name);
    roles[index] = role;
    this.roles$.next(roles);
    this.form.patchValue({ roles }, { emitEvent: false });
  }

  saveAndExit(): void {
    const isSaving = this.isSaving$.getValue();

    if (isSaving) {
      return;
    }
    this.save()
      .pipe(takeUntil(this.destroy$))
      .subscribe((newWorkspaceId: string) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Workspace updated',
        });

        if (!this.workspaceId$.value) {
          this.router.navigate([KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'workspace', newWorkspaceId]).then();
        } else {
          this.router.navigate(['../'], { relativeTo: this.activatedRoute }).then();
        }
      });
  }

  cancel() {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      const workspaceId = this.activatedRoute.snapshot.paramMap.get('workspaceId');
      if (workspaceId) {
        this.router.navigate(['../'], { relativeTo: this.activatedRoute });
      } else {
        this.router.navigate([KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'workspaces']);
      }
    }
  }

  apply(): void {
    const isSaving = this.isSaving$.getValue();

    if (isSaving) {
      return;
    }

    this.save()
      .pipe(takeUntil(this.destroy$))
      .subscribe((newWorkspaceId: string) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Workspace updated',
        });

        if (!this.workspaceId$.value) {
          this.router.navigate(['../', newWorkspaceId, 'manage-roles'], { relativeTo: this.activatedRoute });
        }
      });
  }

  save() {
    let workspaceId = this.workspaceId$.value;
    const { useDefaultRoles, roles } = this.form.getRawValue();

    const isEdit = !!this.workspaceId$.value;
    const members = this.members$.getValue() as any;
    const owners = this.form.get('owners').value.map((owner: any) => owner.username);

    this.isSaving$.next(true);

    return of({}).pipe(
      concatMap(() => {
        const form = this.form.getRawValue();
        if (isEdit) {
          return this.rolesManagerService.updateWorkspace(this.workspaceId$.value, form);
        }

        return this.rolesManagerService
          .createWorkspace({
            name: form.name,
            description: form.description,
            publicType: form.publicType,
          })
          .pipe(
            tap((workspace) => {
              workspaceId = workspace.id;
            }),
          );
      }),
      concatMap(() => {
        if (!useDefaultRoles) {
          return this.rolesManagerService.createRoleGroup(workspaceId, roles);
        }

        return of(true);
      }),
      concatMap(() => {
        const roleSetName = useDefaultRoles ? 'DEFAULT' : workspaceId;

        return this.workspaceService.updateWorkspaceRoleGroup(workspaceId, roleSetName);
      }),
      concatMap(() => {
        return this.rolesManagerService.updateMembers(workspaceId, members);
      }),
      concatMap(() => {
        return this.workspaceService.setOwners(workspaceId, owners).pipe(
          map(() => {
            return workspaceId;
          }),
        );
      }),
      finalize(() => {
        this.isSaving$.next(false);
      }),
    );
  }

  trackByName(_index: number, role: IWorkspaceRole): string {
    return role.name;
  }

  private changeWorkspace(): void {
    this.activatedRoute.paramMap
      .pipe(
        map((params) => params.get('workspaceId')),
        filter((id) => !!id),
        takeUntil(this.destroy$),
      )
      .subscribe((workspaceId: string) => {
        this.workspaceId$.next(workspaceId);
      });
  }

  private updateFormData(workspace: WorkspaceWithNormalOwner): void {
    const { name, description, publicType, owners, roleGroups } = workspace;
    const [roleGroup] = roleGroups;

    this.form.patchValue(
      {
        name,
        description,
        publicType,
        owners: owners.map((owner) => {
          return {
            ...owner,
            fullName: owner.fullName.length ? owner.fullName : owner.username,
          };
        }),
        useDefaultRoles: roleGroup.name === 'DEFAULT',
      },
      { emitEvent: false },
    );
  }

  private changeUseDefaultRoles(workspaceId: string): Subscription {
    return this.form
      .get('useDefaultRoles')
      .valueChanges.pipe(
        startWith(this.form.get('useDefaultRoles').value),
        switchMap((isDefaultRoleGroup) => {
          const roleName = isDefaultRoleGroup || !workspaceId ? 'DEFAULT' : workspaceId;

          return this.rolesManagerService.loadRoles(roleName).pipe(
            filter((roles) => !!roles.length),
            tap((roles) => {
              this.roles$.next(roles);
              this.form.get('roles').setValue(roles);
            }),
          );
        }),
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  private initialWorkspace(workspaceId: string): void {
    forkJoin([
      this.rolesManagerService.loadWorkspace(workspaceId).pipe(
        map((workspace: WorkspaceWithNormalOwner) => {
          const roleGroups = workspace.roleGroups;
          const roleGroup = roleGroups[0] || { id: 1, name: 'DEFAULT' };

          return {
            ...workspace,
            roleGroups: [roleGroup],
          };
        }),
        tap((workspace: WorkspaceWithNormalOwner) => {
          this.updateFormData(workspace);
          this.changeUseDefaultRoles(workspaceId);
        }),
      ),

      this.rolesManagerService.loadMembers(workspaceId).pipe(
        tap((members: any) => {
          this.loadedMembers$.next(members);
        }),
      ),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.form.markAllAsTouched();
      });
  }
}
