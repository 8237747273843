<ng-container *ngIf="{ data: dashboardList$ | async } as obs">
  <p-table
    #dt
    styleClass="p-datatable-sm"
    paginatorStyleClass="prime-table-custom__paginator"
    [value]="obs.data"
    [showLoader]="false"
    [paginator]="true"
    [rows]="pageParams.pageSize"
    [first]="first"
    [sortField]="sortField"
    [sortOrder]="sortOrder"
    [totalRecords]="totalRecords"
    [lazy]="true"
    [loading]="loading$ | async"
    (onLazyLoad)="loadDashboardList($event)"
  >
    <ng-template pTemplate="loadingbody">
      <tr *ngFor="let item of [].constructor(12)" tabindex="-1">
        <td *ngFor="let _ of [].constructor(6)">
          <p-skeleton [ngStyle]="{ width: 100 / 5 + '%' }"></p-skeleton>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td [colSpan]="6">{{ 'msg.board.filter.ui.search-all.nodata' | translate }}</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="caption">
      <div class="flex justify-content-between">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>

          <input
            style="width: 300px"
            pAutoFocus
            [autofocus]="true"
            pInputText
            type="text"
            (input)="onSearch($event)"
            placeholder="{{ 'msg.workbook-list.search.input-placeholder' | translate }}"
          />
        </span>

        <p-dropdown
          [options]="pageSizes"
          [ngModel]="pageParams.pageSize"
          (ngModelChange)="onPageChange({ pageSize: $event, pageNumber: pageParams.pageNumber })"
        ></p-dropdown>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name">
          <p-sortIcon field="name"></p-sortIcon>
          Name
        </th>
        <th pSortableColumn="workBook.name">
          <p-sortIcon field="workBook.name"></p-sortIcon>
          Projects
        </th>
        <th>Datasources</th>
        <th pSortableColumn="createdTime">
          <p-sortIcon field="createdTime"></p-sortIcon>
          Created
        </th>
        <th pSortableColumn="modifiedTime">
          <p-sortIcon field="modifiedTime"></p-sortIcon>
          Modified
        </th>
        <th>Actions</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-item>
      <tr>
        <td class="short-text" [pTooltip]="item.name">
          <a [routerLink]="getWorkbookRouterLink(item.workBook)" [queryParams]="{ dashboardId: item.id }">
            {{ item.name }}
          </a>
        </td>
        <td class="short-text" [pTooltip]="item.workBook.name">
          <a *ngIf="item.workBook" [routerLink]="getWorkbookRouterLink(item.workBook)">{{ item.workBook.name }}</a>
        </td>
        <td>
          <div class="flex flex-column">
            <a
              *ngFor="let dataSource of item.dataSources; let i = index"
              class="text-overflow-ellipsis overflow-hidden mb-1"
              [ngClass]="{ hidden: i > 0 && !isDataSourceSpoilerOpened(item.id) }"
              [routerLink]="getDataSourceRouterLink(dataSource)"
              [pTooltip]="dataSource.name"
            >
              {{ dataSource.name }}
            </a>
            <button
              *ngIf="item.dataSources.length > 1"
              pButton
              class="p-button-link px-0"
              (click)="
                isDataSourceSpoilerOpened(item.id) ? closeDataSourceSpoiler(item.id) : openDataSourceSpoiler(item.id)
              "
            >
              {{
                (isDataSourceSpoilerOpened(item.id)
                  ? 'msg.dashboard.list.button.hide'
                  : 'msg.dashboard.list.button.show-more'
                ) | translate
              }}
            </button>
          </div>
        </td>
        <td>
          <span class="no-breaks">{{ item.createdTime | date: 'dd.MM.yyyy, hh:mm' }}</span>
        </td>
        <td>
          <span class="no-breaks">{{ item.modifiedTime | date: 'dd.MM.yyyy, hh:mm' }}</span>
        </td>
        <td tabindex="-1">
          <div class="flex flex-nowrap">
            <button
              type="button"
              pButton
              class="p-button-text p-button-link ml-2 p-0"
              appearance="icon"
              icon="pi pi-file-export"
              tooltipPosition="left"
              pTooltip="Move"
            ></button>
            <button
              type="button"
              pButton
              class="p-button-text p-button-link ml-2 p-0"
              appearance="icon"
              icon="pi pi-clone"
              tooltipPosition="left"
              pTooltip="Duplicate"
            ></button>
            <button
              *ngIf="item.imageUrl"
              type="button"
              pButton
              class="p-button-text p-button-link ml-2 p-0"
              appearance="icon"
              (click)="overlayPanel.toggle($event)"
              icon="pi pi-search"
              tooltipPosition="left"
              pTooltip="Show preview"
            ></button>
            <p-overlayPanel appendTo="body" [dismissable]="true" [showCloseIcon]="true" #overlayPanel>
              <div class="preview">
                <selfai-bi-dashbord-preview-image [imageUrl]="item.imageUrl"></selfai-bi-dashbord-preview-image>
              </div>
            </p-overlayPanel>
            <button
              type="button"
              pButton
              class="p-button-text p-button-link p-button-danger p-0"
              appearance="icon"
              (click)="remove($event, item)"
              icon="pi pi-trash"
              tooltipPosition="left"
              pTooltip="Delete dashboard"
            ></button>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer">
      <tr>
        <td class="p-0" [colSpan]="6">
          <selfai-platform-data-list-view-pagination
            *ngIf="pageParams.totalItems"
            [pageNumber]="pageParams.pageNumber"
            [pageSize]="pageParams.pageSize"
            [totalRecords]="pageParams.totalItems"
            (pageChange)="onPageChange($event)"
          ></selfai-platform-data-list-view-pagination>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>

<p-confirmPopup></p-confirmPopup>

<p-dialog
  [modal]="true"
  header="Choose workbook to move"
  [(visible)]="visibleWorkBookDialogSelector"
  [style]="{ width: '90%' }"
>
  <selfai-platform-bi-ui-workbook-list-selector
    *ngIf="visibleWorkBookDialogSelector"
    (selectWorkbook)="onSelectWorkbook($event)"
    [textButton]="textButton"
  ></selfai-platform-bi-ui-workbook-list-selector>
</p-dialog>
