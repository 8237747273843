import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, ViewChild } from '@angular/core';

@Component({
    selector: 'th[selfaiPlatformResizableColumn]',
    imports: [],
    template: '<div #cellContentWrapper class="inline-flex"><ng-content></ng-content></div>',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResizableColumnComponent implements AfterViewInit {
  @ViewChild('cellContentWrapper', { static: true }) cellContentWrapper: ElementRef<HTMLDivElement>;

  @HostBinding('style.min-width.px') minWidth: number;

  ngAfterViewInit(): void {
    this.setMinWidth();
  }

  private setMinWidth(): void {
    this.minWidth = this.cellContentWrapper.nativeElement.offsetWidth + 20;
  }
}
