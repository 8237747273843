import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { map, switchMap, take, tap } from 'rxjs';

import {
  CurrentWorkspaceDomainService,
  Datasource,
  DatasourceDomainService,
  ImportWorkbookMappedDatasource,
} from '@selfai-platform/bi-domain';
import { DestroyService } from '@selfai-platform/shared';

@Component({
    selector: 'selfai-platform-data-source-mapping-form',
    templateUrl: './data-source-mapping-form.component.html',
    styleUrls: ['./data-source-mapping-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DestroyService],
    standalone: false
})
export class DataSourceMappingFormComponent implements OnInit {
  @Input() dataSources: Datasource[];

  @Input() set mappedDatasources(dataSources: { [oldDatasourceId: string]: ImportWorkbookMappedDatasource }) {
    Object.entries(dataSources).forEach(([id, dataSource]) => {
      const value = this.allDatasourceList.find(({ name }) => dataSource?.name.toLowerCase() === name.toLowerCase());
      this.datasourceMap[id] = value
        ? { ...value, oldEngineName: dataSource.oldEngineName || dataSource.engineName }
        : undefined;

      this.cdr.markForCheck();
    });
  }

  @Input() isValid = false;

  @Output() isValidChange = new EventEmitter<boolean>();
  @Output() mappedDatasourcesChange = new EventEmitter<{
    [oldDatasourceId: string]: ImportWorkbookMappedDatasource;
  }>();

  filteredDatasource: Datasource[] = [];
  datasourceMap: Record<string, ImportWorkbookMappedDatasource> = {};

  allDatasourceList: Datasource[] = [];

  get formControlsLength(): number {
    return Object.values(this.datasourceMap).length;
  }

  constructor(
    private readonly datasourceDomainService: DatasourceDomainService,
    private readonly currentWorkspaceDomainService: CurrentWorkspaceDomainService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.currentWorkspaceDomainService
      .getCurrentWorkspace()
      .pipe(
        take(1),
        switchMap(({ id }) =>
          this.datasourceDomainService.loadDatasourceList(id, { pageNumber: 1, pageSize: 999 }).pipe(
            map(({ datasources }) => datasources),
            tap((dataSources: Datasource[]) => {
              this.allDatasourceList = dataSources;
              this.filteredDatasource = this.allDatasourceList;
            }),
            tap(() => {
              const mappedDatasources = {} as Record<string, ImportWorkbookMappedDatasource>;
              this.dataSources.forEach((dataSource) => {
                mappedDatasources[dataSource.id] = { ...dataSource, oldEngineName: dataSource.engineName };
                this.onDatasourceChange(dataSource, dataSource.id, dataSource.engineName);
              });

              this.mappedDatasources = mappedDatasources;
            }),
          ),
        ),
      )
      .subscribe();
  }

  filterDatasource(event: { query: string }): void {
    const { query } = event;

    if (!query) {
      this.filteredDatasource = [...this.allDatasourceList];
    } else {
      this.filteredDatasource = this.allDatasourceList.filter(({ name }) =>
        name.toLowerCase().includes(query.toLowerCase()),
      );
    }
  }

  // in event we have Datasource when choose from dropdown or string when type in autocomplete input
  onDatasourceChange(event: Datasource | string, oldDatasourceId: string, oldEngineName: string): void {
    if (typeof event === 'string') {
      return;
    }

    this.datasourceMap[oldDatasourceId] = { ...event, oldEngineName };

    const isValid = Object.values(this.datasourceMap).every((value) => !!value);

    this.isValidChange.emit(isValid);
    this.mappedDatasourcesChange.emit(this.datasourceMap);
  }
}
