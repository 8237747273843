import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbsMenu, BreadcrumbsMenuItem, KNOWLEDGE_DESIGNER_ROOT_ROUTE } from '@selfai-platform/shell';
import { Observable, combineLatest, map } from 'rxjs';

@Injectable()
export class WorkspaceListBreadcrumbService implements BreadcrumbsMenu {
  constructor(private readonly translate: TranslateService) {}

  getBreadcrumbsMenu(): Observable<BreadcrumbsMenuItem[]> {
    return combineLatest({
      publicWorkspaceName: this.translate.stream('msg.workspace-list.toolbar.public-workspace'),
      knowledgeDesigner: this.translate.stream('shell.menu.knowledge-designer'),
    }).pipe(
      map(({ publicWorkspaceName, knowledgeDesigner }) => {
        return [
          { label: knowledgeDesigner },
          {
            label: publicWorkspaceName,
            routerLink: ['/', KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'workspaces'],
          },
        ];
      }),
    );
  }
}
