import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  CurrentWorkspaceDomainService,
  THUMBNAIL_IMAGE_DEFAULT,
  WorkbookDomainService,
  WorkbookImportDomainService,
  WorkbookListBreadcrumbService,
  WorkbookListItem,
} from '@selfai-platform/bi-domain';
import {
  DestroyService,
  LocalStorageKey,
  PREFIX_SETTINGS_LOCAL_STORAGE_KEY,
  providePageParams,
} from '@selfai-platform/shared';
import {
  BI_ROOT_ROUTE,
  BreadcrumbsMenuService,
  GroupAction,
  KNOWLEDGE_DESIGNER_ROOT_ROUTE,
  TableColumn,
  ToolbarAction,
  UI_SETTINGS_VIEW_PAGE_DEFAULT_SIZE,
  provideDataListView,
  provideDialogService,
} from '@selfai-platform/shell';
import { ConfirmationService } from 'primeng/api';
import { Observable, switchMap, takeUntil } from 'rxjs';
import { WorkbookActionsForItemService } from './workbook-actions-for-item.service';
import { WorkbookDataListViewService } from './workbook-data-list-view.service';
import { WorkbookListComponentService } from './workbook-list-component.service';

@Component({
    selector: 'selfai-platform-workbook-list',
    templateUrl: './workbook-list.component.html',
    styleUrls: ['./workbook-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ...provideDataListView(WorkbookDataListViewService, WorkbookActionsForItemService),
        CurrentWorkspaceDomainService,
        WorkbookImportDomainService,
        WorkbookListBreadcrumbService,
        WorkbookListComponentService,
        DestroyService,
        ...provideDialogService(),
        ConfirmationService,
        ...providePageParams({
            pageSize: UI_SETTINGS_VIEW_PAGE_DEFAULT_SIZE,
            pageNumber: 1,
            sortField: 'modified',
            sortOrder: 'desc',
        }),
        {
            provide: PREFIX_SETTINGS_LOCAL_STORAGE_KEY,
            useValue: LocalStorageKey.WORKBOOK_LIST_PREFIX_SETTINGS,
        },
    ],
    standalone: false
})
export class WorkbookListComponent implements OnInit {
  actions$: Observable<ToolbarAction[]>;
  groupActions$: Observable<GroupAction[]>;
  columns: TableColumn<WorkbookListItem>[] = this.workbookDataListViewService.getColumns();

  kdRootRoute = KNOWLEDGE_DESIGNER_ROOT_ROUTE;
  biRootRoute = BI_ROOT_ROUTE;
  thumbnailImgDefault = THUMBNAIL_IMAGE_DEFAULT;
  importStatus$ = this.workbookImportDomainService.getImportStatus();

  private workspaceId$ = this.workbookDataListViewService.workspaceId$;

  constructor(
    private readonly workbookDataListViewService: WorkbookDataListViewService,
    private readonly workbookDomainService: WorkbookDomainService,
    private readonly workbookImportDomainService: WorkbookImportDomainService,
    private readonly workbookListComponentService: WorkbookListComponentService,
    private readonly workbookListBreadcrumbService: WorkbookListBreadcrumbService,
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    private readonly destroy$: DestroyService,
  ) {}

  ngOnInit(): void {
    this.actions$ = this.workbookListComponentService.getActions();
    this.groupActions$ = this.workbookListComponentService.getGroupActions();

    this.workspaceId$
      .pipe(
        switchMap((workspaceId) => {
          return this.workbookListBreadcrumbService.getBreadcrumbsMenu(workspaceId);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((items) => {
        this.breadcrumbsMenuService.setBreadcrumbsMenu(items);
      });
  }

  saveName(editingWorkbook: WorkbookListItem): void {
    this.workbookDomainService.updateWorkbook(editingWorkbook.id, { name: editingWorkbook.name });
  }

  toggleFavorite({ itemId, isFavorite }: { itemId: string; isFavorite: boolean }): void {
    this.workbookListComponentService.toggleFavorite({ itemId, isFavorite });
  }
}
