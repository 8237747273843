<p-dialog
  [header]="'msg.workspacesEdit.addRole' | translate"
  [visible]="showCreateRole$ | async"
  [style]="{ width: '50vw' }"
  [modal]="true"
  [closable]="false"
>
  <form [formGroup]="form">
    <p>
      <input
        id="name"
        type="text"
        class="w-full"
        pInputText
        formControlName="name"
        [placeholder]="'msg.workspacesEdit.enterRoleName' | translate"
      />
    </p>

    <p class="flex flex-row justify-content-end space-x-3">
      <p-button styleClass="p-button-success" (click)="createRole()">
        {{ 'msg.workspacesEdit.creater' | translate }}
      </p-button>
      <p-button styleClass="p-button-secondary " (click)="hideCreateRole()">
        {{ 'msg.workspacesEdit.close' | translate }}
      </p-button>
    </p>
  </form>
</p-dialog>
