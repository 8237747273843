import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { takeUntil, tap } from 'rxjs';

import {
  Book,
  CurrentWorkspaceDomainService,
  Datasource,
  ImportWorkbookMappedDatasource,
  WorkbookImport,
} from '@selfai-platform/bi-domain';
import { AlertService, DestroyService, readJsonFile } from '@selfai-platform/shared';
import { DialogService } from '@selfai-platform/shell';
@Component({
    selector: 'selfai-platform-workbook-import-form',
    templateUrl: './workbook-import-form.component.html',
    styleUrls: ['./workbook-import-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DestroyService],
    standalone: false
})
export class WorkbookImportFormComponent implements OnInit {
  activeIndexStep = 0;
  isValidDataSourceMapping = false;
  steps: MenuItem[] = [];
  workbookNameControl = new FormControl<string>('', [Validators.required]);
  data: WorkbookImport;
  workbookList: Book[];
  mappedDatasources: { [oldDatasourceId: string]: ImportWorkbookMappedDatasource } = {};

  get dataSources(): Datasource[] {
    const dataSources: Datasource[] = [];
    this.data?.dashBoards?.forEach((dashBoard) => {
      dataSources.push(...dashBoard.dataSources);
    });

    return dataSources.reduce((acc: Datasource[], dataSource: Datasource) => {
      if (acc.some(({ id }) => dataSource.id === id)) {
        return acc;
      }

      return [...acc, dataSource];
    }, []);
  }

  constructor(
    private readonly alertService: AlertService,
    private readonly currentWorkspaceDomainService: CurrentWorkspaceDomainService,
    private readonly dialogService: DialogService<{
      workBook: WorkbookImport;
      mappedDatasources: { [oldDatasourceId: string]: ImportWorkbookMappedDatasource };
    }>,
    private readonly translate: TranslateService,
    private readonly destroy$: DestroyService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.translate
      .stream([
        'msg.workbook.import-form.steps-label.upload-file',
        'msg.workbook.import-form.steps-label.edit-name',
        'msg.workbook.import-form.steps-label.data-sources',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations) => {
        this.steps = [
          {
            label: translations['msg.workbook.import-form.steps-label.upload-file'],
          },
          {
            label: translations['msg.workbook.import-form.steps-label.edit-name'],
          },
          {
            label: translations['msg.workbook.import-form.steps-label.data-sources'],
          },
        ];
      });

    this.currentWorkspaceDomainService.getCurrentWorkspace().pipe(
      tap((workspace) => {
        this.workbookList = workspace.workBooks;
        this.workbookNameControl.setValidators([Validators.required, this.workbookValidator.bind(this)]);
      }),
    );
  }

  onError(): void {
    this.alertService.error(this.translate.instant('msg.workbook.import-form.upload-error'));
  }

  onUpload(event: { files: File[] }): void {
    const { files } = event;

    readJsonFile<WorkbookImport>(files[0]).subscribe({
      next: (data: WorkbookImport) => {
        this.startImport(data);
      },
      error: (err: Error) => {
        this.alertService.error(err.message);
      },
    });
  }

  startImport(data: WorkbookImport): void {
    this.data = data;
    this.workbookNameControl.setValue(data.name);
    this.workbookNameControl.markAsDirty();
    this.nextStep();
  }

  sumbitStep(): void {
    if (!this.isSubmittingAvailable()) {
      return;
    }

    switch (this.activeIndexStep) {
      case 1:
        if (this.workbookNameControl.valid) {
          this.nextStep();
        }

        break;
    }
  }

  isSubmittingAvailable(): boolean {
    switch (this.activeIndexStep) {
      case 0:
        return Boolean(this.data);
      case 1:
        return this.workbookNameControl.valid;
      case 2:
        return this.isValidDataSourceMapping;
      default:
        return true;
    }
  }

  nextStep(): void {
    this.activeIndexStep++;
    this.cdr.detectChanges();
  }

  prevStep(): void {
    this.activeIndexStep--;
    this.cdr.detectChanges();
  }

  finishImport(): void {
    this.dialogService.close({
      workBook: { ...this.data, name: this.workbookNameControl.value },
      mappedDatasources: this.mappedDatasources,
    });
  }

  private workbookValidator(control: AbstractControl): { existsWorkbookName: { value: string } } | null {
    const isInList = this.workbookList.some((book) => book.name === control.value);

    return isInList ? { existsWorkbookName: { value: control.value } } : null;
  }
}
