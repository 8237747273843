import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'selfai-platform-cube-dnd-placeholder',
    templateUrl: './cube-dnd-placeholder.component.html',
    styleUrls: ['./cube-dnd-placeholder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CubeDndPlaceholderComponent {
  @Input() cubeUiName? = '';
  @Input() operationName?: string;
}
