
import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import * as _ from 'lodash';

import { ChartUtil, OptionGenerator } from '@selfai-platform/bi-chart-engine';
import {
  Annotation,
  AnnotationPosition,
  AxisLabelType,
  BarColor,
  BarMarkType,
  CellColorTarget,
  ChartType,
  DataLabelPosition,
  EventType,
  FontSize,
  GridViewType,
  LabelLayoutType,
  LabelStyleType,
  LineCornerType,
  LineMarkType,
  LineMode,
  Orient,
  PieSeriesViewType,
  Pivot,
  PointShape,
  ShelveFieldType,
  SymbolType,
  TimeUnit,
  UIBarChart,
  UIChartColorByCell,
  UIChartColorByValue,
  UIChartColorGradationByValue,
  UIChartDataLabel,
  UIFontStyle,
  UIGridChart,
  UILabelAnnotation,
  UILabelChart,
  UILabelChartSeries,
  UILabelIcon,
  UILabelSecondaryIndicator,
  UILineChart,
  UIOption,
  UIOrient,
  UIPosition,
  UIWaterfallChart,
  UiChartDataLabelDisplayType,
  WaterfallBarColor,
} from '@selfai-platform/bi-domain';
import { isNullOrUndefined } from '@selfai-platform/shared';

import { ColorPickerComponent } from '../../common/component/color-picker/color.picker.component';
import { ColorPicker } from '../../common/component/color-picker/colorpicker';
import { SelectComponent } from '../../common/component/select/select.component';
import { Modal } from '../../common/domain/modal';
import { LineStyle } from '../component/value/analysis';

import { BaseOptionComponent } from './base-option.component';

@Component({
    selector: 'common-option',
    templateUrl: './common-option.component.html',
    styles: ['.ddp-list-express {padding: 0; display: flex}'],
    standalone: false
})
export class CommonOptionComponent extends BaseOptionComponent {
  @ViewChild('iconTargetListComp')
  private iconTargetListComp: SelectComponent;

  @ViewChild('textTargetListComp')
  private textTargetListComp: SelectComponent;

  @ViewChildren('iconType')
  private iconTypeComp: QueryList<SelectComponent>;

  @ViewChild('direction')
  private directionComp: SelectComponent;

  @ViewChild('contentFontcolorPicker')
  private contentFontcolorPicker: ColorPickerComponent;

  @ViewChild('fontColorPicker')
  private fontColorPicker: ColorPickerComponent;

  @ViewChild('backgroundColorPicker')
  private backgroundColorPicker: ColorPickerComponent;

  private contentFontColorPickerEle: any;
  private fontColorPickerEle: any;
  private backgroundColorPickerEle: any;

  public chartUtil = ChartUtil;

  public isNoOriginData: boolean;

  @Output()
  public changeAxisByStack: EventEmitter<BarMarkType> = new EventEmitter();

  @Output()
  public showConfirmPopup: EventEmitter<Modal> = new EventEmitter();

  public pivot: Pivot;
  public pivotTemp: Pivot;

  public limitPlaceHolder: number;

  @Input('uiOption')
  public set setUiOption(uiOption: UIOption) {
    this.uiOption = uiOption;

    this._setLimit(uiOption.limit);
    this.limitPlaceHolder = OptionGenerator.defaultLimit(this.uiOption.type);

    if (_.isUndefined(this.pivot)) {
      this.setPivot = this.pivotTemp;
    }

    if (!this.uiOption.fontSize) this.uiOption.fontSize = FontSize.NORMAL;

    if (ChartType.GRID == this.uiOption.type) {
      if (!(<UIChartColorByCell>uiOption.color).colorTarget)
        (<UIChartColorByCell>uiOption.color).colorTarget = CellColorTarget.TEXT;

      this.changeDetect.detectChanges();

      this.fontColorPickerEle = this.initColorPicker(
        this.fontColorPickerEle,
        this.fontColorPicker,
        (<UIGridChart>this.uiOption).headerStyle.fontColor,
      );

      this.backgroundColorPickerEle = this.initColorPicker(
        this.backgroundColorPickerEle,
        this.backgroundColorPicker,
        (<UIGridChart>this.uiOption).headerStyle.backgroundColor,
      );

      this.contentFontColorPickerEle = this.initColorPicker(
        this.contentFontColorPickerEle,
        this.contentFontcolorPicker,
        (<UIGridChart>this.uiOption).contentStyle.fontColor,
      );
    }
  }

  @Input('pivot')
  public set setPivot(pivot: Pivot) {
    if (_.isUndefined(pivot)) {
      return;
    }

    if (_.isUndefined(this.uiOption)) {
      this.pivotTemp = pivot;
      return;
    }

    this.pivot = pivot;

    if (ChartType.GRID == this.uiOption.type) {
      this.isNoOriginData = false;
      for (const item of this.pivot.aggregations) {
        if (item.aggregated) {
          this.isNoOriginData = true;
          break;
        }
      }

      if (this.isNoOriginData && GridViewType.MASTER == this.uiOption['dataType']) {
        const dataType = GridViewType.PIVOT;
        this.uiOption = <UIOption>_.extend({}, this.uiOption, { dataType });
        this.isNoOriginData = false;
        this.update();
      }
    }

    if (this.uiOption.type == ChartType.LABEL) {
      const option: UILabelChart = <UILabelChart>this.uiOption;

      if (option.series) {
        const isIconAll: boolean = this.kpiIsIconAll();
        const isTextAll: boolean = this.kpiIsTextAll();
        this.kpiIconTargetList = [];
        this.kpiIconTargetList.push({ name: this.translateService.instant('msg.comm.ui.list.all'), value: '' });
        const series: UILabelChartSeries[] = [];
        const icons: UILabelIcon[] = [];
        const annotations: UILabelAnnotation[] = [];
        const secondaryIndicators: UILabelSecondaryIndicator[] = [];

        const setFieldName = (item, shelveFieldType?: ShelveFieldType): string => {
          if (!shelveFieldType || (shelveFieldType && item.type === shelveFieldType)) {
            let fieldName = !_.isEmpty(item.alias) ? item.alias : item.name;
            if (item['alias'] && item['alias'] !== item.name) {
              fieldName = item['alias'];
            } else {
              const alias: string = item['fieldAlias']
                ? item['fieldAlias']
                : item['logicalName']
                ? item['logicalName']
                : item['name'];
              fieldName = item.aggregationType ? item.aggregationType + `(${alias})` : `${alias}`;
            }
            return fieldName;
          }
        };

        const aggs = this.pivot.aggregations
          .map((aggregation) => {
            return setFieldName(aggregation, ShelveFieldType.MEASURE);
          })
          .filter((item) => {
            return typeof item !== 'undefined';
          });

        for (let num = 0; num < this.pivot.aggregations.length; num++) {
          const field: any = this.pivot.aggregations[num];
          let alias: string = field['alias']
            ? field['alias']
            : field['fieldAlias']
            ? field['fieldAlias']
            : field['name'];
          const displayName: any = aggs[num];
          if (field.aggregationType && field.aggregationType != '') {
            alias = field.aggregationType + '(' + alias + ')';
          }

          if (option.series.length <= num) {
            if (num > 0) {
              option.series[num] = {
                name: alias,
                displayName: displayName,
              };
              option.icons[num] = {
                seriesName: alias,
                displayName: displayName,
                show: option.icons[0].show,
                iconType: option.icons[0].iconType,
              };
              option.annotations[num] = {
                seriesName: alias,
                displayName: displayName,
                show: option.annotations[0].show,
                description: option.annotations[0].description,
              };
              option.secondaryIndicators[num] = {
                seriesName: alias,
                displayName: displayName,
                show: option.secondaryIndicators[0].show,
                indicatorType: option.secondaryIndicators[0].indicatorType,
                rangeUnit: option.secondaryIndicators[0].rangeUnit,
                targetValue: option.secondaryIndicators[0].targetValue,
                mark: option.secondaryIndicators[0].mark,
                emphasized: option.secondaryIndicators[0].emphasized,
              };
            } else {
              option.series[num] = {};
              option.icons[num] = {};
              option.annotations[num] = {};
              option.secondaryIndicators[num] = {};
            }
          }
          if (
            _.isUndefined(option.series[num].name) ||
            _.isUndefined(option.icons[num].seriesName) ||
            _.isUndefined(option.annotations[num].seriesName) ||
            _.isUndefined(option.secondaryIndicators[num].seriesName)
          ) {
            option.series[num].name = alias;
            option.series[num].displayName = displayName;
            option.icons[num].seriesName = alias;
            option.icons[num].displayName = displayName;
            option.annotations[num].seriesName = alias;
            option.annotations[num].displayName = displayName;
            option.secondaryIndicators[num].seriesName = alias;
            option.secondaryIndicators[num].displayName = displayName;
          }
          this.kpiIconTargetList.push({
            name: displayName,
            value: alias,
          });

          let isPush = false;
          for (let num2 = 0; num2 < this.pivot.aggregations.length; num2++) {
            if (option.series.length >= num2 + 1 && _.eq(alias, option.series[num2].name)) {
              isPush = true;
              series.push(option.series[num2]);
            }
            if (option.icons.length >= num2 + 1 && _.eq(alias, option.icons[num2].seriesName)) {
              icons.push(option.icons[num2]);
            }
            if (option.annotations.length >= num2 + 1 && _.eq(alias, option.annotations[num2].seriesName)) {
              annotations.push(option.annotations[num2]);
            }
            if (
              option.secondaryIndicators.length >= num2 + 1 &&
              _.eq(alias, option.secondaryIndicators[num2].seriesName)
            ) {
              secondaryIndicators.push(option.secondaryIndicators[num2]);
            }
          }

          if (!isPush) {
            option.series[num].name = alias;
            option.series[num].displayName = displayName;
            option.icons[num].seriesName = alias;
            option.icons[num].displayName = displayName;
            option.annotations[num].seriesName = alias;
            option.annotations[num].displayName = displayName;
            option.secondaryIndicators[num].seriesName = alias;
            option.secondaryIndicators[num].displayName = displayName;

            for (let num2 = 0; num2 < this.pivot.aggregations.length; num2++) {
              if (option.series.length >= num2 + 1 && _.eq(alias, option.series[num2].name)) {
                series.push(option.series[num2]);
              }
              if (option.icons.length >= num2 + 1 && _.eq(alias, option.icons[num2].seriesName)) {
                icons.push(option.icons[num2]);
              }
              if (option.annotations.length >= num2 + 1 && _.eq(alias, option.annotations[num2].seriesName)) {
                annotations.push(option.annotations[num2]);
              }
              if (
                option.secondaryIndicators.length >= num2 + 1 &&
                _.eq(alias, option.secondaryIndicators[num2].seriesName)
              ) {
                secondaryIndicators.push(option.secondaryIndicators[num2]);
              }
            }
          }
        }

        option.series = series;
        option.icons = icons;
        option.annotations = annotations;
        option.secondaryIndicators = secondaryIndicators;

        this.changeDetect.detectChanges();
        if (!isIconAll) {
          this.iconTargetListComp.selected(
            this.kpiIconTargetList.length > 1 ? this.kpiIconTargetList[1] : this.kpiIconTargetList[0],
          );
        }
        if (isTextAll) {
          if (option.annotations && 0 < option.annotations.length && option.annotations[0].show) {
            this.kpiText = option.annotations[0].description;
          } else {
            this.kpiText = '';
          }
          this.kpiTextTemp = this.kpiText;
        } else {
          this.textTargetListComp.selected(
            this.kpiIconTargetList.length > 1 ? this.kpiIconTargetList[1] : this.kpiIconTargetList[0],
          );

          if (option.annotations[0].show) {
            this.kpiText = this.kpiIconTargetList.length > 1 ? option.annotations[0].description : '';
          } else {
            this.kpiText = '';
          }
          this.kpiTextTemp = this.kpiText;
        }
      }
    }
  }

  private initColorPicker(colorPickerEle: any, picker: ColorPickerComponent, fontColor: string): any {
    if (!colorPickerEle) {
      const data: ColorPicker = new ColorPicker();
      data.color = fontColor;
      data.showAlpha = true;
      data.showInitial = true;
      data.showInput = true;
      data.showUserColor = true;
      colorPickerEle = picker.init(data);
    }

    return colorPickerEle;
  }

  public kpiIconList: Object[] = [
    { name: this.translateService.instant('msg.page.li.icon.user'), value: 'USER' },
    { name: this.translateService.instant('msg.page.li.icon.visits'), value: 'HITS' },
    { name: this.translateService.instant('msg.page.li.icon.time'), value: 'TIME' },
    { name: this.translateService.instant('msg.page.li.icon.views'), value: 'VIEW' },
  ];

  public kpiDirectionList: Object[] = [
    { name: this.translateService.instant('msg.page.li.kpi.horizontal'), value: 'HORIZONTAL' },
    { name: this.translateService.instant('msg.page.li.kpi.vertical'), value: 'VERTICAL' },
  ];

  public kpiPeriodList: Object[] = [
    { name: this.translateService.instant('msg.page.li.previous.year'), value: TimeUnit.YEAR },
    { name: this.translateService.instant('msg.page.li.previous.quarter'), value: TimeUnit.QUARTER },
    { name: this.translateService.instant('msg.page.li.previous.month'), value: TimeUnit.MONTH },
    { name: this.translateService.instant('msg.page.li.previous.day'), value: TimeUnit.DAY },
  ];

  public kpiIconTargetList: Object[] = [{ name: this.translateService.instant('msg.comm.ui.list.all'), value: '' }];
  public kpiIconTarget: Object = this.kpiIconTargetList[0];
  public kpiTextTarget: Object = this.kpiIconTargetList[0];
  public kpiText = '';
  public kpiTextTemp = '';

  public remarkPositionList: Object[] = [
    {
      name: this.translateService.instant('msg.page.common.grid.enter.position.top.right'),
      value: AnnotationPosition.TOP_RIGHT,
    },
    {
      name: this.translateService.instant('msg.page.common.grid.enter.position.top.left'),
      value: AnnotationPosition.TOP_LEFT,
    },
    {
      name: this.translateService.instant('msg.page.common.grid.enter.position.bottom.right'),
      value: AnnotationPosition.BOTTOM_RIGHT,
    },
    {
      name: this.translateService.instant('msg.page.common.grid.enter.position.bottom.left'),
      value: AnnotationPosition.BOTTOM_LEFT,
    },
  ];

  public hAlignList: Object[] = [
    { name: this.translateService.instant('msg.page.chart.datalabel.text.align.default'), value: UIPosition.AUTO },
    { name: this.translateService.instant('msg.page.chart.datalabel.text.align.left'), value: UIPosition.LEFT },
    { name: this.translateService.instant('msg.page.chart.datalabel.text.align.center'), value: UIPosition.CENTER },
    { name: this.translateService.instant('msg.page.chart.datalabel.text.align.right'), value: UIPosition.RIGHT },
  ];

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  protected barOrientType(type: Orient): void {
    let align = _.cloneDeep((<UIBarChart>this.uiOption).align);

    if (_.eq(align, type)) return;

    const dataLabel = this.setDataLabelByOrient();

    if (Orient.HORIZONTAL == type) dataLabel.enableRotation = false;

    if (this.uiOption.chartZooms && this.uiOption.chartZooms.length == 1) {
      this.uiOption.chartZooms[0].orient = Orient.HORIZONTAL === type ? UIOrient.VERTICAL : UIOrient.HORIZONTAL;
    }

    align = type;

    const row = _.compact(_.concat(this.uiOption.xAxis, this.uiOption.yAxis, this.uiOption.secondaryAxis)).filter(
      (axis) => {
        return _.eq(axis.mode, AxisLabelType.ROW);
      },
    )[0];
    const subRow = _.compact(_.concat(this.uiOption.xAxis, this.uiOption.yAxis, this.uiOption.secondaryAxis)).filter(
      (axis) => {
        return _.eq(axis.mode, AxisLabelType.SUBROW);
      },
    )[0];
    const column = _.compact(_.concat(this.uiOption.xAxis, this.uiOption.yAxis, this.uiOption.secondaryAxis)).filter(
      (axis) => {
        return _.eq(axis.mode, AxisLabelType.COLUMN);
      },
    )[0];
    const subColumn = _.compact(_.concat(this.uiOption.xAxis, this.uiOption.yAxis, this.uiOption.secondaryAxis)).filter(
      (axis) => {
        return _.eq(axis.mode, AxisLabelType.SUBCOLUMN);
      },
    )[0];

    let axis = _.cloneDeep(this.uiOption.xAxis);
    switch (axis.mode) {
      case AxisLabelType.ROW:
        axis.name = column.name;
        axis.defaultName = column.defaultName;
        axis.customName = column.customName;
        break;
      case AxisLabelType.SUBROW:
        axis.name = subColumn.name;
        axis.defaultName = subColumn.defaultName;
        axis.customName = subColumn.customName;
        break;
      case AxisLabelType.COLUMN:
        axis.name = row.name;
        axis.defaultName = row.defaultName;
        axis.customName = row.customName;
        break;
      case AxisLabelType.SUBCOLUMN:
        axis.name = subRow.name;
        axis.defaultName = subRow.defaultName;
        axis.customName = subRow.customName;
        break;
    }
    const xAxis = axis;

    axis = _.cloneDeep(this.uiOption.yAxis);
    switch (axis.mode) {
      case AxisLabelType.ROW:
        axis.name = column.name;
        axis.defaultName = column.defaultName;
        axis.customName = column.customName;
        break;
      case AxisLabelType.SUBROW:
        axis.name = subColumn.name;
        axis.defaultName = subColumn.defaultName;
        axis.customName = subColumn.customName;
        break;
      case AxisLabelType.COLUMN:
        axis.name = row.name;
        axis.defaultName = row.defaultName;
        axis.customName = row.customName;
        break;
      case AxisLabelType.SUBCOLUMN:
        axis.name = subRow.name;
        axis.defaultName = subRow.defaultName;
        axis.customName = subRow.customName;
        break;
    }
    const yAxis = axis;

    const xAxisLabel = _.cloneDeep(xAxis.label);
    const yAxisLabel = _.cloneDeep(yAxis.label);

    if (Orient.HORIZONTAL == type) {
      xAxis.label = yAxisLabel;
      yAxis.label = xAxisLabel;
    } else {
      xAxis.label = yAxisLabel;
      yAxis.label = xAxisLabel;
    }

    this.uiOption = <UIOption>_.extend({}, this.uiOption, {
      align: align,
      xAxis: xAxis,
      yAxis: yAxis,
      dataLabel: dataLabel,
    });

    if (this.uiOption.secondaryAxis) {
      axis = _.cloneDeep(this.uiOption.secondaryAxis);
      switch (axis.mode) {
        case AxisLabelType.ROW:
          axis.name = column.name;
          axis.defaultName = column.defaultName;
          axis.customName = column.customName;
          break;
        case AxisLabelType.SUBROW:
          axis.name = subColumn.name;
          axis.defaultName = subColumn.defaultName;
          axis.customName = subColumn.customName;
          break;
        case AxisLabelType.COLUMN:
          axis.name = row.name;
          axis.defaultName = row.defaultName;
          axis.customName = row.customName;
          break;
        case AxisLabelType.SUBCOLUMN:
          axis.name = subRow.name;
          axis.defaultName = subRow.defaultName;
          axis.customName = subRow.customName;
          break;
      }
      this.uiOption = <UIOption>_.extend({}, this.uiOption, { secondaryAxis: axis });
    }

    this.update();
  }

  public barSeriesViewType(seriesType: BarMarkType): void {
    if (
      seriesType !== (<UIBarChart>this.uiOption).mark &&
      (<UIChartColorByValue>this.uiOption.color).ranges &&
      (<UIChartColorByValue>this.uiOption.color).ranges.length > 0
    ) {
      const modal = new Modal();
      modal.name = this.translateService.instant('msg.page.chart.color.measure.range.grid.original.description');
      modal.data = {
        data: seriesType,
        eventType: EventType.SERIES_VIEW,
      };

      this.showConfirmPopup.emit(modal);
      return;
    }

    this.changeBarSeriesViewType(seriesType);
  }

  public changeBarSeriesViewType(mark: BarMarkType) {
    if (this.uiOption['mark'] == mark) {
      return;
    }

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { mark });

    const spliceSeriesTypeList = (seriesTypeList, dataLabel: any): any => {
      if (!dataLabel) return dataLabel;

      let index: number;
      for (const item of seriesTypeList) {
        index = dataLabel.displayTypes.indexOf(item);
        if (-1 !== index) {
          dataLabel.displayTypes[index] = null;
          _.remove(dataLabel.previewList, { value: item });
        }
      }
      return dataLabel;
    };

    if (this.uiOption.dataLabel && this.uiOption.dataLabel.displayTypes && BarMarkType.MULTIPLE == mark) {
      const seriesTypeList = [
        UiChartDataLabelDisplayType.SERIES_NAME,
        UiChartDataLabelDisplayType.SERIES_VALUE,
        UiChartDataLabelDisplayType.SERIES_PERCENT,
      ];

      this.uiOption.dataLabel = spliceSeriesTypeList(seriesTypeList, this.uiOption.dataLabel);

      this.uiOption.toolTip = spliceSeriesTypeList(seriesTypeList, this.uiOption.toolTip);
    }

    this.changeAxisByStack.emit(mark);

    this.update();
  }

  public lineSeriesViewType(mark: LineMarkType): void {
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { mark });
    this.update();
  }

  public lineSeriesCornerType(curveStyle: LineCornerType): void {
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { curveStyle });
    this.update();
  }

  public showLine(lineStyle: LineStyle): void {
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { lineStyle });
    this.update();
  }

  public cumulativeMode(lineMode: LineMode): void {
    const uiLineMode = (<UILineChart>this.uiOption).lineMode;
    const ranges = (<UIChartColorByValue>this.uiOption.color).ranges;

    if (_.eq(uiLineMode, lineMode)) {
      return;
    } else if (ranges && ranges.length > 0 && !_.eq(uiLineMode, lineMode)) {
      const modal = new Modal();
      modal.name = this.translateService.instant('msg.page.chart.color.measure.range.grid.original.description');
      modal.data = {
        data: lineMode,
        eventType: 'cumulativeMode',
      };

      this.showConfirmPopup.emit(modal);
      return;
    }

    this.changeCumulative(lineMode);
  }

  public changeCumulative(lineMode: LineMode): void {
    (<UILineChart>this.uiOption).lineMode = lineMode;
    this.update({ resultFormatOptions: { lineMode: lineMode }, type: 'cumulativeMode' });
  }

  public textAlign(hAlign: Object, headerFl: boolean): void {
    if (headerFl) {
      const headerStyle = _.cloneDeep((<UIGridChart>this.uiOption).headerStyle);
      headerStyle.hAlign = hAlign['value'];
      this.uiOption = <UIOption>_.extend({}, this.uiOption, { headerStyle });
    } else {
      const contentStyle = _.cloneDeep((<UIGridChart>this.uiOption).contentStyle);
      contentStyle.hAlign = hAlign['value'];
      this.uiOption = <UIOption>_.extend({}, this.uiOption, { contentStyle });
    }

    this.update();
  }

  public textVAlign(vAlign: UIPosition, headerFl: boolean): void {
    if (headerFl) {
      const headerStyle = _.cloneDeep((<UIGridChart>this.uiOption).headerStyle);
      headerStyle.vAlign = vAlign;
      this.uiOption = <UIOption>_.extend({}, this.uiOption, { headerStyle });
    } else {
      const contentStyle = _.cloneDeep((<UIGridChart>this.uiOption).contentStyle);
      contentStyle.vAlign = vAlign;
      this.uiOption = <UIOption>_.extend({}, this.uiOption, { contentStyle });
    }

    this.update();
  }

  public gridViewType(gridType: GridViewType): void {
    if (this.isNoOriginData || this.uiOption['totalValueStyle'] || this.uiOption['showCalculatedColumnStyle']) return;

    const dataType = (<UIGridChart>this.uiOption).dataType;
    const ranges = (<UIChartColorByValue>this.uiOption.color).ranges;

    if (gridType == dataType) return;

    if (ranges && ranges.length > 0 && gridType !== dataType) {
      const modal = new Modal();
      modal.name = this.translateService.instant('msg.page.chart.color.measure.range.grid.original.description');
      modal.data = {
        data: gridType,
        eventType: EventType.GRID_ORIGINAL,
      };

      this.showConfirmPopup.emit(modal);
      return;
    }

    this.changeGridViewType(gridType);
  }

  public changeGridViewType(dataType: GridViewType) {
    if (GridViewType.MASTER) {
      (<UIGridChart>this.uiOption).measureLayout = UIOrient.VERTICAL;
      (<UIGridChart>this.uiOption).contentStyle = (<UIGridChart>this.uiOption).contentStyle
        ? (<UIGridChart>this.uiOption).contentStyle
        : {};
      (<UIGridChart>this.uiOption).contentStyle.showHeader = false;
      delete (<UIGridChart>this.uiOption).measureColors;
    } else {
      if ('origin' === (<UIGridChart>this.uiOption).valueFormat.type) {
        (<UIGridChart>this.uiOption).valueFormat.type = 'number';
      }
    }

    this.uiOption['dataType'] = dataType;
    this.update({ type: EventType.GRID_ORIGINAL });
  }

  public gridLayout(measureLayout: UIOrient): void {
    if (_.eq(measureLayout, UIOrient.HORIZONTAL) && _.eq((<UIGridChart>this.uiOption).dataType, GridViewType.MASTER)) {
      this.alertPrimeService.info(this.translateService.instant('msg.page.alert.original.view.error'));
      return;
    }

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { measureLayout });

    this.update();
  }

  public symbolFill(pointTransparency: number): void {
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { pointTransparency });

    this.update();
  }

  public symbolType(pointShape: PointShape): void {
    if (
      (<UIChartColorByValue>this.uiOption.color).ranges &&
      (<UIChartColorByValue>this.uiOption.color).ranges.length > 0
    ) {
      (<UIChartColorByValue>this.uiOption.color).ranges.forEach((item) => {
        item.symbol = SymbolType[String(pointShape)];
      });
    }
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { pointShape });

    this.update();
  }

  public pieSeriesViewType(markType: PieSeriesViewType): void {
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { markType });
    this.update();
  }

  public changeMaximumCategores(maxCategory: number): void {
    if (maxCategory < 0) return;

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { maxCategory });
    this.update();
  }

  public combineSeriesViewType(chartType: string, markType: any): void {
    if (String(ChartType.BAR) === chartType) {
      this.uiOption = <UIOption>_.extend({}, this.uiOption, { barMarkType: markType });
    } else {
      this.uiOption = <UIOption>_.extend({}, this.uiOption, { lineMarkType: markType });
    }

    this.update();
  }

  public radarSeriesViewType(mark: any): void {
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { mark });

    this.update();
  }

  public kpiChangeFontColor(color: string): void {
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { contentStyle: { fontColor: color } });
    this.update();
  }

  public kpiChangeLayout(layout: LabelLayoutType): void {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    option.layout = layout;
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { layout: option.layout });
    this.update();
  }

  public kpiChangeStyle(style: LabelStyleType): void {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    option.chartStyle = style;
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { chartStyle: option.chartStyle });
    this.update();
  }

  public kpiShowLabel(idx: number, show: boolean): void {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    option.showLabel = show;
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { showLabel: option.showLabel });
    this.update();
  }

  public kpiChangeColor(): void {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    const isColor = !option.positiveNegativeColor;

    if (isColor) {
      option.positiveNegativeColor = {
        positiveColor: '#08b496',
        negativeColor: '#eb5f53',
      };
    } else {
      delete option.positiveNegativeColor;
    }
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { positiveNegativeColor: option.positiveNegativeColor });
    this.update();
  }

  public kpiChangePositiveColor(color: string): void {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    option.positiveNegativeColor.positiveColor = color;
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { positiveNegativeColor: option.positiveNegativeColor });
    this.update();
  }

  public kpiChangeNegativeColor(color: string): void {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    option.positiveNegativeColor.negativeColor = color;
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { positiveNegativeColor: option.positiveNegativeColor });
    this.update();
  }

  public kpiChangeIcon(): void {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    const showIcon = !option.icons[0].show;
    _.each(option.icons, (series) => {
      series.show = showIcon;
    });
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { icons: option.icons });
    this.update();
  }

  public kpiChangeIconType(iconType: string): void {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    _.each(option.icons, (series) => {
      if (this.kpiIconTarget['value'] == '' || this.kpiIconTarget['value'] == series.seriesName) {
        series.show = true;
        series.iconType = iconType;
      }
    });
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { icons: option.icons });
    this.update();
  }

  public kpiIsIconAll(): boolean {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    let iconType = '';
    let isAll = true;
    _.each(option.icons, (series) => {
      const labelSeries: UILabelIcon = <UILabelIcon>series;
      if (!_.isUndefined(labelSeries.show)) {
        const seriesIconType: string = labelSeries.iconType;
        if (iconType == '') {
          iconType = seriesIconType;
        } else if (iconType != seriesIconType) {
          isAll = false;
        }
      }
    });
    return isAll;
  }

  public kpiGetIconTargetIndex(): number {
    const isAll: boolean = this.kpiIsIconAll();
    return isAll ? 0 : 1;
  }

  public kpiChangeIconTarget(target: Object): void {
    this.kpiIconTarget = target;
    if (this.kpiIconTarget['value'] == '') {
      const option: UILabelChart = <UILabelChart>this.uiOption;
      this.kpiChangeIconType(option.icons[0].iconType);
    }
  }

  public kpiGetIconSelected(iconType: string): boolean {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    if (this.kpiIconTarget['value'] == '') {
      if (iconType == option.icons[0].iconType || (iconType == 'USER' && !option.icons[0].iconType)) {
        return true;
      }
      return false;
    } else {
      const labelSeries: UILabelIcon[] = <UILabelIcon[]>option.icons;
      for (let num = 0; num < labelSeries.length; num++) {
        if (labelSeries[num].seriesName == this.kpiIconTarget['value']) {
          if (iconType == labelSeries[num].iconType || (iconType == 'USER' && !labelSeries[num].iconType)) {
            return true;
          }
          return false;
        }
      }
    }
  }

  public kpiChangeText(): void {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    const show = !option.annotations[0].show;
    if (show) {
      this.kpiText = '';
      this.kpiTextTemp = this.kpiText;
    }
    _.each(option.annotations, (series) => {
      if (show) {
        series.description = '';
        series.show = true;
      } else {
        delete series.description;
        series.show = false;
      }
    });
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { annotations: option.annotations });
    this.update();
  }

  public kpiIsTextAll(): boolean {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    let text: string;
    let isAll = true;
    _.each(option.annotations, (series) => {
      const labelSeries: UILabelAnnotation = <UILabelAnnotation>series;
      if (!_.isUndefined(labelSeries.show)) {
        const seriesText: string = labelSeries.description;
        if (_.isUndefined(text)) {
          text = seriesText;
        } else if (!_.isUndefined(seriesText) && !_.eq(text, seriesText)) {
          isAll = false;
        }
      }
    });
    return isAll;
  }

  public kpiTextIndex(): number {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    let text: string;
    const index = 0;
    _.each(option.annotations, (series, index) => {
      const labelSeries: UILabelAnnotation = <UILabelAnnotation>series;
      if (!_.isUndefined(labelSeries.show)) {
        const seriesText: string = labelSeries.description;
        if (_.isUndefined(text)) {
          text = seriesText;
        } else if (!_.isUndefined(seriesText) && !_.eq(text, seriesText)) {
          index = index;
        }
      }
    });
    return index;
  }

  public kpiGetTextTargetIndex(): number {
    return this.kpiTextIndex();
  }

  public kpiChangeTextTarget(target: Object): void {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    this.kpiTextTarget = target;
    if (this.kpiTextTarget['value'] == '') {
      this.kpiText = option.annotations[0].description ? option.annotations[0].description : '';
      this.kpiTextTemp = this.kpiText;
    } else {
      const labelSeries: UILabelAnnotation[] = <UILabelAnnotation[]>option.annotations;
      for (let num = 0; num < labelSeries.length; num++) {
        if (labelSeries[num].seriesName == this.kpiTextTarget['value']) {
          this.kpiText = labelSeries[num].description ? labelSeries[num].description : '';
          this.kpiTextTemp = this.kpiText;
          break;
        }
      }
    }
    this.kpiApplyText();
  }

  public kpiApplyText(): void {
    this.kpiText = this.kpiTextTemp;
    const option: UILabelChart = <UILabelChart>this.uiOption;
    _.each(option.annotations, (series) => {
      if (this.kpiTextTarget['value'] == '' || this.kpiTextTarget['value'] == series.seriesName) {
        series.description = this.kpiText;
      }
    });
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { annotations: option.annotations });
    this.update();
  }

  public showValueColumn(show: boolean): void {
    (<UIGridChart>this.uiOption).contentStyle = (<UIGridChart>this.uiOption).contentStyle
      ? (<UIGridChart>this.uiOption).contentStyle
      : {};
    (<UIGridChart>this.uiOption).contentStyle.showHeader = show;
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { contentStyle: (<UIGridChart>this.uiOption).contentStyle });

    this.update();
  }

  public onLimitChange(limit: number, isLimitCheck?: boolean): void {
    if (!isNullOrUndefined(isLimitCheck)) {
      this.uiOption = <UIOption>_.extend({}, this.uiOption, { limitCheck: isLimitCheck });
    }

    this.safelyDetectChanges();

    if (_.isNull(limit)) this.uiOption.limitCheck = false;

    this._setLimit(limit);
    this.update({});
  }

  public addAnnotation(): void {
    const uiOption = <UIGridChart>this.uiOption;

    if (!uiOption.annotation) {
      uiOption.annotation = {};
      uiOption.annotation.pos = AnnotationPosition.TOP_RIGHT;
      uiOption.annotation.label = '';
    } else {
      uiOption.annotation = null;
    }

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { annotation: uiOption.annotation });

    this.update();
  }

  public changeRemarkLabel(param: Annotation): void {
    if (!param.label || _.isEmpty(param.label)) return;

    const annotation = _.cloneDeep((<UIGridChart>this.uiOption).annotation);
    annotation.label = param.label;

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { annotation: annotation });
    this.update();
  }

  public changePosition(position: any): void {
    const annotation = _.cloneDeep((<UIGridChart>this.uiOption).annotation);
    annotation.pos = position['value'];
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { annotation: annotation });
    this.update();
  }

  public getPositionIndex(): number {
    return _.findIndex(this.remarkPositionList, (item) => {
      return item['value'] == this.uiOption['annotation']['pos'];
    });
  }

  public changeFontSize(fontSize: FontSize): void {
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { fontSize: fontSize });

    this.update();
  }

  public gridChangeFontSize(fontSize: FontSize, headerFl: boolean): void {
    if (headerFl) {
      (<UIGridChart>this.uiOption).headerStyle.fontSize = fontSize;
      this.uiOption = <UIOption>_.extend({}, this.uiOption, { headerStyle: (<UIGridChart>this.uiOption).headerStyle });
    } else {
      (<UIGridChart>this.uiOption).contentStyle.fontSize = fontSize;
      this.uiOption = <UIOption>(
        _.extend({}, this.uiOption, { contentStyle: (<UIGridChart>this.uiOption).contentStyle })
      );
    }

    this.update();
  }

  public gridColorByMeasure(target: CellColorTarget): void {
    this.uiOption = <UIOption>_.extend({}, this.uiOption, {
      color: {
        type: this.uiOption.color.type,
        colorTarget: target,
        schema: (<UIChartColorByCell>this.uiOption.color).schema,
        ranges: (<UIChartColorByValue>this.uiOption.color).ranges,
        visualGradations: (<UIChartColorGradationByValue>this.uiOption.color).visualGradations,
        customMode: (<UIChartColorByValue>this.uiOption.color).customMode,
      },
    });

    this.update();
  }

  public gridFontStyle(fontStyle: UIFontStyle, headerFl: boolean): void {
    if (headerFl) {
      const headerStyle = _.cloneDeep((<UIGridChart>this.uiOption).headerStyle);
      if (!headerStyle.fontStyles) headerStyle.fontStyles = [];

      if (-1 == headerStyle.fontStyles.indexOf(fontStyle)) {
        headerStyle.fontStyles.push(fontStyle);
      } else {
        headerStyle.fontStyles.splice(headerStyle.fontStyles.indexOf(fontStyle), 1);
      }

      this.uiOption = <UIOption>_.extend({}, this.uiOption, { headerStyle: headerStyle });
    } else {
      const contentStyle = _.cloneDeep((<UIGridChart>this.uiOption).contentStyle);

      if (!contentStyle.fontStyles) contentStyle.fontStyles = [];

      if (-1 == contentStyle.fontStyles.indexOf(fontStyle)) {
        contentStyle.fontStyles.push(fontStyle);
      } else {
        contentStyle.fontStyles.splice(contentStyle.fontStyles.indexOf(fontStyle), 1);
      }

      this.uiOption = <UIOption>_.extend({}, this.uiOption, { contentStyle: contentStyle });
    }

    this.update();
  }

  public changeShowHeader(showHeader: boolean): void {
    const headerStyle = _.cloneDeep((<UIGridChart>this.uiOption).headerStyle);

    headerStyle.showHeader = showHeader;

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { headerStyle: headerStyle });

    this.update();
  }

  public changeContentFontColor(fontColor: any): void {
    (<UIGridChart>this.uiOption).contentStyle.fontColor = fontColor;

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { contentStyle: (<UIGridChart>this.uiOption).contentStyle });
    this.update();
  }

  public changeHeaderFontColor(fontColor: any): void {
    (<UIGridChart>this.uiOption).headerStyle.fontColor = fontColor;

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { headerStyle: (<UIGridChart>this.uiOption).headerStyle });
    this.update();
  }

  public changeHeaderBackgroundColor(fontColor: any): void {
    (<UIGridChart>this.uiOption).headerStyle.backgroundColor = fontColor;

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { headerStyle: (<UIGridChart>this.uiOption).headerStyle });
    this.update();
  }

  public showWaterfallBarColor(): void {
    let barColor = (<UIWaterfallChart>this.uiOption).barColor;

    if (barColor) {
      barColor = null;
    } else {
      const color: BarColor = {};
      color.positive = WaterfallBarColor.POSITIVE.toString();
      color.negative = WaterfallBarColor.NEGATIVE.toString();

      barColor = color;
    }

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { barColor: barColor });
    this.update();
  }

  public changeWaterfallPositiveColor(color: any): void {
    const barColor = (<UIWaterfallChart>this.uiOption).barColor;

    barColor.positive = color;

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { barColor: barColor });
    this.update();
  }

  public changeWaterfallNegativeColor(color: any): void {
    const barColor = (<UIWaterfallChart>this.uiOption).barColor;

    barColor.negative = color;

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { barColor: barColor });
    this.update();
  }

  private _setLimit(limit: number) {
    if (this.uiOption.limitCheck) {
      this.uiOption.limit = limit ? limit : OptionGenerator.defaultLimit(this.uiOption.type);
    } else {
      this.uiOption.limit = undefined;
    }
    this.safelyDetectChanges();
  }

  private setDataLabelByOrient(): UIChartDataLabel {
    this.uiOption.dataLabel.pos = DataLabelPosition.CENTER;

    return this.uiOption.dataLabel;
  }
}
