import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'selfai-platform-page-section-buttons',
    templateUrl: './page-section-buttons.component.html',
    styleUrls: ['./page-section-buttons.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PageSectionButtonsComponent {
  @Input() isDashboard: boolean;
  @Input() isChartShow: boolean;
  @Input() isError: boolean;
  @Input() isEditMode: boolean;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() toggleEditMode: EventEmitter<boolean> = new EventEmitter<boolean>();
}
