import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HomePageWidgetConfig } from './home-page-base-widget-config';

@Component({
    selector: 'selfai-platform-home-page-base-widget',
    templateUrl: './home-page-base-widget.component.html',
    styleUrls: ['./home-page-base-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class HomePageBaseWidgetComponent {
  @Input() config: HomePageWidgetConfig;
}
