import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';

@Component({
    selector: 'selfai-platform-data-source-unique-name',
    imports: [CommonModule, ReactiveFormsModule, InputTextModule, TooltipModule],
    templateUrl: './data-source-unique-name.component.html',
    styleUrl: './data-source-unique-name.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataSourceUniqueNameComponent {
  @Input({ required: true, alias: 'control' }) formControl: FormControl;
}
