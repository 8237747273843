import { BiEntityPermission } from '@selfai-platform/shared';
import { DataListViewItem } from '@selfai-platform/shell';
import { HistoryEntity } from '../../core';
import { RoleSet, UserProfile } from '../../user';
import { Book } from '../../workbook';
import { PublicType, WorkspaceType } from '../enums';
import { Hirearchies } from './folder.model';
import { NotebookConnector } from './notebook.model';
import { WorkspaceMember } from './workspace-member.model';

export interface Workspace extends HistoryEntity {
  id: string;
  name: string;
  description: string;
  Type: PublicType;
  publicType: PublicType;
  published: boolean;
  active: boolean;
  workBooks: Book[];
  members: WorkspaceMember[];
  roleSets: RoleSet[];
  connectors: NotebookConnector[];
  favorite: boolean;
  type: WorkspaceType;
  roles: any;
  countByBookType: CountByBookType;
  countOfDataSources: number;
  countByMemberType: { user: number; group: number };
  hierarchies: Hirearchies[];
  permissions: BiEntityPermission[];
  booksContour: WorkspaceBooksCounter;

  owner: UserProfile;

  ownerId: string;
}

export interface WorkspaceState extends Workspace {
  workspaceId: string;
}

export interface CountByBookType {
  workBook: number;
  folder: number;
  report: number;
  workBench: number;
  notebook: number;
}

export interface WorkspaceBooksCounter {
  all: number;
  limit: number;
  offset: number;
}

export function createWorkspace(initialData: Partial<Workspace> = {}): Workspace {
  return {
    ...initialData,
  } as Workspace;
}

export interface WorkspaceList {
  workspaces: WorkspaceListItem[];
  page: {
    number: number;
    size: number;
    totalElements: number;
    totalPages: number;
  };
}

export interface WorkspaceListState extends WorkspaceList {
  id: 'workspaces';
}

export interface WorkspaceListItem
  extends DataListViewItem,
    Pick<
      Workspace,
      | 'id'
      | 'name'
      | 'createdTime'
      | 'modifiedTime'
      | 'publicType'
      | 'active'
      | 'type'
      | 'permissions'
      | 'favorite'
      | 'countByBookType'
    > {
  countWorkbooks?: number;
  createdBy: string;
  favorite: boolean;
}
