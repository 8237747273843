<div class="ddp-wrap-chart-contents" [class.ddp-size]="ddpSize">
  <ng-container *ngTemplateOutlet="chartContents"></ng-container>
  <ng-container *ngTemplateOutlet="chartMenu"></ng-container>
</div>

<ng-template #chartContents>
  <div class="ddp-view-chart-contents">
    <!--Navigation-->
    <div class="chart-wrapper-navi" *ngIf="widget">
      <div class="chart-wrapper-navi-input-wrap">
        <div class="chart-wrapper-navi-input-content" [class.ddp-selected]="isPageNameEdit">
          <div
            class="name-content"
            *ngIf="!isPageNameEdit"
            (click)="!isPageNameEdit ? onNameEdit.emit($event) : $event.stopPropagation()"
          >
            <div class="name-content-title">{{ widget.name }}</div>
            <div class="name-content-icon">
              <img src="/assets/bi/images/svg/edit_icon_feather_style.svg" />
            </div>
          </div>
          <div class="input-content">
            <component-input
              *ngIf="isPageNameEdit"
              [inputClass]="'ddp-input-navi'"
              [value]="editingPageName"
              [maxLen]="50"
              (changeValue)="changePageName($event)"
            ></component-input>
            <div *ngIf="isPageNameEdit" #pageNameCheck (click)="$event.stopPropagation(); onNameChange.emit()">
              <img src="/assets/bi/images/svg/check-icon.svg" />
            </div>
            <div *ngIf="!uiOption.split" class="ddp-box-btnselect">
              <a
                href="javascript:"
                class="ddp-btn-selected"
                *ngIf="
                  uiOption['type'] &&
                  (uiOption['type'].toString() !== 'gauge' ||
                    (uiOption['type'].toString() === 'gauge' && 'measure' === uiOption?.color?.type.toString())) &&
                  uiOption['type'].toString() !== 'sankey' &&
                  uiOption['type'].toString() !== 'map'
                "
                [ngClass]="{ 'ddp-selected': uiOption.legend?.auto }"
                (click)="toggelShowLegend()"
              >
                <em class="ddp-icon-tool-legend"></em>
                {{ 'msg.page.ui.legend' | translate }}
              </a>
              <a
                href="javascript:"
                class="ddp-btn-selected"
                *ngIf="uiOption['type']?.toString() !== 'map' && uiOption.chartZooms"
                [ngClass]="{
                  'ddp-selected': uiOption.chartZooms[0].auto
                }"
                (click)="showDataZoom.emit(!uiOption.chartZooms[0].auto)"
              >
                <em class="ddp-icon-tool-minimap"></em>
                {{ 'msg.page.ui.minimap' | translate }}
              </a>
            </div>
          </div>
        </div>

        <div>
          <div class="chart-wrap-chart-menu">
            <a
              href="javascript:"
              [class.ddp-selected]="rnbMenu === 'filter'"
              [title]="'msg.toolbar.button.create.filter' | translate"
              (click)="toggleRnb.emit('filter')"
            >
              <img src="/assets/bi/images/svg/settings-icon.svg" />
            </a>
            <span class="chart-ui-menu-layout" (mouseover)="drawChartTooltip.emit($event)">
              <a
                *ngIf="possibleChartCheck('common', selectChart)"
                href="javascript:"
                [ngClass]="{
                  'ddp-disabled': !isChartShow || (uiOption && uiOption.split)
                }"
                [class.ddp-selected]="isChartShow && rnbMenu === 'common'"
                [title]="'msg.toolbar.button.main.settings' | translate"
                (click)="uiOption && uiOption.split ? null : toggleRnb.emit('common')"
              >
                <img src="/assets/bi/images/svg/sliders-icon.svg" />
                <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-right"></em>
                  {{ 'msg.page.first.draw.chart' | translate }}
                </div>
              </a>
              <a
                *ngIf="possibleChartCheck('color', selectChart) && !(uiOption.dataType === 'MASTER' && uiOption.type === 'grid')"
                href="javascript:"
                [ngClass]="{
                  'ddp-disabled': !isChartShow || (uiOption && uiOption.split)
                }"
                [class.ddp-selected]="isChartShow && rnbMenu === 'color'"
                [title]="'msg.toolbar.button.color.settings' | translate"
                (click)="toggleColor()"
              >
                <img src="/assets/bi/images/svg/complex-line-shapes-icon.svg" />
                <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-right"></em>
                  {{ 'msg.page.first.draw.chart' | translate }}
                </div>
              </a>
              <a
                *ngIf="possibleChartCheck('legend', selectChart)"
                href="javascript:"
                (click)="uiOption && uiOption.split ? null : toggleRnb.emit('legend')"
                [ngClass]="{
                  'ddp-disabled': !isChartShow || (uiOption && uiOption.split)
                }"
                [class.ddp-selected]="isChartShow && rnbMenu === 'legend'"
              >
                <img src="/assets/bi/images/svg/document-lines-icon.svg" />
                <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-right"></em>
                  {{ 'msg.page.first.draw.chart' | translate }}
                </div>
              </a>
              <a
                *ngIf="possibleChartCheck('format', selectChart)"
                href="javascript:"
                [ngClass]="{
                  'ddp-disabled': !isChartShow || (uiOption && uiOption.split)
                }"
                [class.ddp-selected]="isChartShow && rnbMenu === 'format'"
                [title]="'msg.toolbar.button.number.format' | translate"
                (click)="uiOption && uiOption.split ? null : toggleRnb.emit('format')"
              >
                <img src="/assets/bi/images/svg/equalizer-icon.svg" />
                <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-right"></em>
                  {{ 'msg.page.first.draw.chart' | translate }}
                </div>
              </a>
              <a
                *ngIf="possibleChartCheck('yAxis', selectChart)"
                href="javascript:"
                [ngClass]="{
                  'ddp-disabled': !isChartShow || (uiOption && uiOption.split)
                }"
                [class.ddp-selected]="isChartShow && rnbMenu === 'yAxis'"
                [title]="'msg.toolbar.button.y.axis.settings' | translate"
                (click)="uiOption && uiOption.split ? null : toggleRnb.emit('yAxis')"
              >
                <img src="/assets/bi/images/svg/upload-icon.svg" />
                <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-right"></em>
                  {{ 'msg.page.first.draw.chart' | translate }}
                </div>
              </a>
              <a
                *ngIf="possibleChartCheck('xAxis', selectChart)"
                href="javascript:"
                [ngClass]="{
                  'ddp-disabled': !isChartShow || (uiOption && uiOption.split)
                }"
                [class.ddp-selected]="isChartShow && rnbMenu === 'xAxis'"
                [title]="'msg.toolbar.button.x.axis.settings' | translate"
                (click)="uiOption && uiOption.split ? null : toggleRnb.emit('xAxis')"
              >
                <img src="/assets/bi/images/svg/arrow-navigation-icon.svg" />
                <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-right"></em>
                  {{ 'msg.page.first.draw.chart' | translate }}
                </div>
              </a>
              <a
                *ngIf="possibleChartCheck('secondaryAxis', selectChart)"
                href="javascript:"
                (click)="uiOption && uiOption.split ? null : toggleRnb.emit('secondaryAxis')"
                [ngClass]="{
                  'ddp-disabled': !isChartShow || (uiOption && uiOption.split)
                }"
                [class.ddp-selected]="isChartShow && rnbMenu === 'secondaryAxis'"
              >
                <img src="/assets/bi/images/svg/audio-equalizer-icon.svg" />
                <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-right"></em>
                  {{ 'msg.page.first.draw.chart' | translate }}
                </div>
              </a>
              <a
                *ngIf="possibleChartCheck('dataLabel', selectChart)"
                href="javascript:"
                [ngClass]="{
                  'ddp-disabled': !isChartShow || (uiOption && uiOption.split)
                }"
                [class.ddp-selected]="isChartShow && rnbMenu === 'dataLabel'"
                [title]="'msg.toolbar.button.label.settings' | translate"
                (click)="uiOption && uiOption.split ? null : toggleRnb.emit('dataLabel')"
              >
                <img src="/assets/bi/images/svg/bookmark-icon.svg" />
                <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-right"></em>
                  {{ 'msg.page.first.draw.chart' | translate }}
                </div>
              </a>
              <a
                *ngIf="possibleChartCheck('tooltip', selectChart)"
                href="javascript:"
                [ngClass]="{
                  'ddp-disabled': !isChartShow || (uiOption && uiOption.split)
                }"
                [class.ddp-selected]="isChartShow && rnbMenu === 'tooltip'"
                [title]="'msg.toolbar.button.tooltip.settings' | translate"
                (click)="uiOption && uiOption.split ? null : toggleRnb.emit('tooltip')"
              >
                <img src="/assets/bi/images/svg/cloud-message-icon.svg" />
                <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-right"></em>
                  {{ 'msg.page.first.draw.chart' | translate }}
                </div>
              </a>
              <a
                *ngIf="false && possibleChartCheck('split', selectChart)"
                href="javascript:"
                (click)="toggleRnb.emit('split')"
                [ngClass]="{ 'ddp-disabled': !isChartShow }"
                [class.ddp-selected]="isChartShow && rnbMenu === 'split'"
              >
                <em class="ddp-icon-menu-split"></em>
                <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-right"></em>
                  {{ 'msg.page.first.draw.chart' | translate }}
                </div>
              </a>
              <a
                *ngIf="possibleChartCheck('calculatedRow', selectChart)"
                href="javascript:"
                (click)="toggleRnb.emit('calculatedRow')"
                [ngClass]="{ 'ddp-disabled': !isChartShow }"
                [class.ddp-selected]="isChartShow && rnbMenu === 'calculatedRow'"
              >
                <img src="/assets/bi/images/svg/multifunction-icon.svg" />
                <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-right"></em>
                  {{ 'msg.page.first.draw.chart' | translate }}
                </div>
              </a>
              <a
                *ngIf="possibleChartCheck('secondaryIndicator', selectChart)"
                href="javascript:"
                (click)="uiOption && uiOption.split ? null : toggleRnb.emit('secondaryIndicator')"
                [ngClass]="{
                  'ddp-disabled': !isChartShow || (uiOption && uiOption.split)
                }"
                [class.ddp-selected]="isChartShow && rnbMenu === 'secondaryIndicator'"
              >
                <img src="/assets/bi/images/svg/cloud-message-icon.svg" />
                <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-right"></em>
                  {{ 'msg.page.first.draw.chart' | translate }}
                </div>
              </a>
              <a
                *ngIf="selectChart === 'map'"
                href="javascript:"
                (click)="toggleRnb.emit('mapCommon')"
                [ngClass]="{ 'ddp-disabled': !isChartShow }"
                [class.ddp-selected]="isChartShow && rnbMenu === 'mapCommon'"
              >
                <img src="/assets/bi/images/svg/audio-equalizer-icon.svg" />
                <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-right"></em>
                  {{ 'msg.page.first.draw.chart' | translate }}
                </div>
              </a>
              <div *ngIf="selectChart === 'map'">
                <div *ngFor="let item of shelf.layers; let index = index">
                  <a
                    *ngIf="
                      uiOption['analysis'] === null ||
                      uiOption['analysis']['use'] === null ||
                      uiOption['analysis']['use'] === false ||
                      (uiOption['analysis']['use'] && index !== shelf.layers.length - 1)
                    "
                    (click)="toggleMapLayer('mapLayer' + (index + 1), index)"
                    [ngClass]="{
                      'ddp-disabled':
                        !isChartShow ||
                        setDisableShelf(index) ||
                        ('map' === selectChart && uiOption['analysis'] !== null && uiOption['analysis']['use'] === true)
                    }"
                    [class.ddp-selected]="
                      isChartShow && rnbMenu === 'mapLayer' + (index + 1) && !setDisableShelf(index)
                    "
                    href="javascript:"
                  >
                    <em *ngIf="index === 0" class="ddp-icon-menu-mapview1"></em>
                    <em *ngIf="index === 1" class="ddp-icon-menu-mapview2"></em>
                    <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
                      <em class="ddp-icon-view-right"></em>
                      {{ 'msg.page.first.draw.chart' | translate }}
                    </div>
                  </a>
                  <a
                    *ngIf="
                      uiOption['analysis'] !== null &&
                      uiOption['analysis']['use'] === true &&
                      index === shelf.layers.length - 1
                    "
                    (click)="toggleMapLayer('mapLayer' + (index + 1), index)"
                    [class.ddp-selected]="
                      isChartShow && rnbMenu === 'mapLayer' + (index + 1) && !setDisableShelf(index)
                    "
                    href="javascript:"
                  >
                    <em class="ddp-icon-menu-analysis"></em>
                    <div class="ddp-ui-tooltip-info">
                      <em class="ddp-icon-view-right"></em>
                      Analysis
                    </div>
                  </a>
                </div>
              </div>
              <a
                *ngIf="selectChart === 'map'"
                href="javascript:"
                (click)="toggleRnb.emit('mapLegend')"
                [ngClass]="{ 'ddp-disabled': !isChartShow }"
                [class.ddp-selected]="isChartShow && rnbMenu === 'mapLegend'"
              >
                <img src="/assets/bi/images/svg/text-document-icon.svg" />
                <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-right"></em>
                  {{ 'msg.page.first.draw.chart' | translate }}
                </div>
              </a>
              <a
                *ngIf="selectChart === 'map'"
                href="javascript:"
                (click)="toggleRnb.emit('mapFormat')"
                [ngClass]="{ 'ddp-disabled': !isChartShow }"
                [class.ddp-selected]="isChartShow && rnbMenu === 'mapFormat'"
              >
                <img src="/assets/bi/images/svg/equalizer-icon.svg" />
                <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-right"></em>
                  {{ 'msg.page.first.draw.chart' | translate }}
                </div>
              </a>
              <a
                *ngIf="selectChart === 'map'"
                href="javascript:"
                (click)="toggleRnb.emit('mapTooltip')"
                [ngClass]="{ 'ddp-disabled': !isChartShow }"
                [class.ddp-selected]="isChartShow && rnbMenu === 'mapTooltip'"
              >
                <img src="/assets/bi/images/svg/cloud-message-icon.svg" />
                <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
                  <em class="ddp-icon-view-right"></em>
                  {{ 'msg.page.first.draw.chart' | translate }}
                </div>
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!--Pivot-->
    <ng-container *ngIf="dashboard?.configuration">
      <page-pivot
        #pagePivot
        *ngIf="pivot && selectChart !== 'map' && widget?.configuration"
        [widget]="widget"
        [pivot]="pivotValue"
        [shelf]="shelfValue"
        [uiOption]="uiOptionValue"
        [widgetConfig]="widgetConfigValue"
        [chartType]="selectChart"
        [globalFilters]="dashboard.configuration['filters']"
        (deletePivotItem)="onDeletePivotItem.emit($event)"
        (changePivotItem)="onChangePivotItem.emit($event)"
        (changePivot)="onChangePivot.emit($event)"
        (toggleFilter)="toggleFilter.emit($event)"
        (changeFormat)="onChangePivotFormat.emit($event)"
        (customField)="openCustomFieldPopup.emit($event)"
        (showPopup)="onShowPopup.emit($event)"
      ></page-pivot>
      <map-page-pivot
        #mapPivot
        *ngIf="shelf && selectChart === 'map'"
        [pageDimensions]="pageDimensions"
        [(pivot)]="pivotValue"
        [(shelf)]="shelfValue"
        [(uiOption)]="uiOptionValue"
        [(widgetConfig)]="widgetConfigValue"
        [geoType]="geoType"
        [widget]="widget"
        [chartType]="selectChart"
        [globalFilters]="dashboard.configuration['filters']"
        (deletePivotItem)="onDeletePivotItem.emit($event)"
        (changePivotItem)="onChangePivotItem.emit($event)"
        (changeShelf)="onChangeShelf.emit($event)"
        (toggleFilter)="toggleFilter.emit($event)"
        (changeFormat)="onChangePivotFormat.emit($event)"
        (customField)="openCustomFieldPopup.emit($event)"
        (showPopup)="onShowPopup.emit($event)"
        (changeLayer)="onChangeLayer.emit(shelf)"
        (removeAnalysisLayer)="removeAnalysisLayer.emit($event)"
      ></map-page-pivot>
    </ng-container>
    <!--Chart-->
    <div
      class="ddp-ui-chart-contents wh100r-tblr"
      [ngClass]="{
        'ddp-map-chart': 'map' === selectChart,
        'ddp-shelf1': 'map' === selectChart && 0 === shelf.layers.length - 1,
        'ddp-shelf2': 'map' === selectChart && 1 === shelf.layers.length - 1,
        'ddp-shelf3': 'map' === selectChart && uiOption['analysis'] !== null && uiOption['analysis']['use'] === true
      }"
    >
      <div class="ddp-wrap-chart-layout" [ngStyle]="{'opacity': isEditMode ? 0.5 : 1}">
        <div class="ddp-ui-chart-basic" *ngIf="selectChart === ''">
          <span class="ddp-ui-info">{{ 'msg.page.ui.sel.chart.type' | translate }}</span>
          <ul class="ddp-list-chart-type" *ngIf="!selectChart">
            <ng-container *ngFor="let chartType of chartList">
              <li
                [class.ddp-recommend]="recommendCharts.indexOf(chartType) > -1"
                [class.ddp-selected]="selectChart === chartType"
                (click)="changeChart(chartType)"
              >
                <a class="a-hover-tooltip" href="javascript:">
                  <em [class]="'ddp-img-graph-' + chartType"></em>
                  <span>{{ 'msg.page.ui.' + chartType | translate }}</span>
                </a>
              </li>
            </ng-container>
            <!-- <li
              [class.ddp-recommend]="recommendCharts.indexOf('bar') > -1"
              [class.ddp-selected]="selectChart === 'bar'"
              (click)="changeChart('bar')"
            >
              <a class="a-hover-tooltip" href="javascript:">
                <em class="ddp-img-graph-bar"></em>
                <span>{{ 'msg.page.ui.bar' | translate }}</span>
              </a>
            </li>
            <li
              [class.ddp-recommend]="recommendCharts.indexOf('grid') > -1"
              [class.ddp-selected]="selectChart === 'grid'"
              (click)="changeChart('grid')"
            >
              <a class="a-hover-tooltip" href="javascript:">
                <em class="ddp-img-graph-grid"></em>
                <span>{{ 'msg.page.ui.grid' | translate }}</span>
              </a>
            </li>
            <li
              [class.ddp-recommend]="recommendCharts.indexOf('line') > -1"
              [class.ddp-selected]="selectChart === 'line'"
              (click)="changeChart('line')"
            >
              <a class="a-hover-tooltip" href="javascript:">
                <em class="ddp-img-graph-line"></em>
                <span>{{ 'msg.page.ui.line' | translate }}</span>
              </a>
            </li>
            <li
              [class.ddp-recommend]="recommendCharts.indexOf('scatter') > -1"
              [class.ddp-selected]="selectChart === 'scatter'"
              (click)="changeChart('scatter')"
            >
              <a class="a-hover-tooltip" href="javascript:">
                <em class="ddp-img-graph-scatter"></em>
                <span>{{ 'msg.page.ui.scatter' | translate }}</span>
              </a>
            </li>
            <li
              [class.ddp-recommend]="recommendCharts.indexOf('heatmap') > -1"
              [class.ddp-selected]="selectChart === 'heatmap'"
              (click)="changeChart('heatmap')"
            >
              <a class="a-hover-tooltip" href="javascript:">
                <em class="ddp-img-graph-heatmap"></em>
                <span>{{ 'msg.page.ui.heatmap' | translate }}</span>
              </a>
            </li>
            <li
              [class.ddp-recommend]="recommendCharts.indexOf('pie') > -1"
              [class.ddp-selected]="selectChart === 'pie'"
              (click)="changeChart('pie')"
            >
              <a class="a-hover-tooltip" href="javascript:">
                <em class="ddp-img-graph-pie"></em>
                <span>{{ 'msg.page.ui.pie' | translate }}</span>
              </a>
            </li>
            <li
              [class.ddp-recommend]="recommendCharts.indexOf('label') > -1"
              [class.ddp-selected]="selectChart === 'label'"
              (click)="changeChart('label')"
            >
              <a class="a-hover-tooltip" href="javascript:">
                <em class="ddp-img-graph-label"></em>
                <span>{{ 'msg.page.ui.label' | translate }}</span>
              </a>
            </li>
            <li
              [class.ddp-recommend]="recommendCharts.indexOf('boxplot') > -1"
              [class.ddp-selected]="selectChart === 'boxplot'"
              (click)="changeChart('boxplot')"
            >
              <a class="a-hover-tooltip" href="javascript:">
                <em class="ddp-img-graph-boxplot"></em>
                <span>{{ 'msg.page.ui.boxplot' | translate }}</span>
              </a>
            </li>
            <li
              [class.ddp-recommend]="recommendCharts.indexOf('waterfall') > -1"
              [class.ddp-selected]="selectChart === 'waterfall'"
              (click)="changeChart('waterfall')"
            >
              <a class="a-hover-tooltip" href="javascript:">
                <em class="ddp-img-graph-waterfall"></em>
                <span>{{ 'msg.page.ui.waterfall' | translate }}</span>
              </a>
            </li>
            <li
              [class.ddp-recommend]="recommendCharts.indexOf('wordcloud') > -1"
              [class.ddp-selected]="selectChart === 'wordcloud'"
              (click)="changeChart('wordcloud')"
            >
              <a class="a-hover-tooltip" href="javascript:">
                <em class="ddp-img-graph-wordcloud"></em>
                <span>{{ 'msg.page.ui.wordcloud' | translate }}</span>
              </a>
            </li>
            <li
              [class.ddp-recommend]="recommendCharts.indexOf('combine') > -1"
              [class.ddp-selected]="selectChart === 'combine'"
              (click)="changeChart('combine')"
            >
              <a class="a-hover-tooltip" href="javascript:">
                <em class="ddp-img-graph-combine"></em>
                <span>{{ 'msg.page.ui.combine' | translate }}</span>
              </a>
            </li>
            <li
              [class.ddp-recommend]="recommendCharts.indexOf('treemap') > -1"
              [class.ddp-selected]="selectChart === 'treemap'"
              (click)="changeChart('treemap')"
            >
              <a class="a-hover-tooltip" href="javascript:">
                <em class="ddp-img-graph-treemap"></em>
                <span>{{ 'msg.page.ui.treemap' | translate }}</span>
              </a>
            </li>
            <li
              [class.ddp-recommend]="recommendCharts.indexOf('radar') > -1"
              [class.ddp-selected]="selectChart === 'radar'"
              (click)="changeChart('radar')"
            >
              <a class="a-hover-tooltip" href="javascript:">
                <em class="ddp-img-graph-radar"></em>
                <span>{{ 'msg.page.ui.radar' | translate }}</span>
              </a>
            </li>
            <li
              [class.ddp-recommend]="recommendCharts.indexOf('graph') > -1"
              [class.ddp-selected]="selectChart === 'graph'"
              (click)="changeChart('graph')"
            >
              <a class="a-hover-tooltip" href="javascript:">
                <em class="ddp-img-graph-graph"></em>
                <span>{{ 'msg.page.ui.graph' | translate }}</span>
              </a>
            </li>
            <li
              class="graphv2"
              [class.ddp-recommend]="recommendCharts.indexOf('graph') > -1"
              [class.ddp-selected]="selectChart === 'graphv2'"
              (click)="changeChart('graphv2')"
            >
              <a class="a-hover-tooltip" href="javascript:">
                <em class="ddp-img-graph-graph"></em>
                <span>{{ 'msg.page.ui.graphv2' | translate }}</span>
              </a>
            </li>
            <li
              [class.ddp-recommend]="recommendCharts.indexOf('gauge') > -1"
              [class.ddp-selected]="selectChart === 'gauge'"
              (click)="changeChart('gauge')"
            >
              <a class="a-hover-tooltip" href="javascript:">
                <em class="ddp-img-graph-gauge"></em>
                <span>{{ 'msg.page.ui.gauge' | translate }}</span>
              </a>
            </li>
            <li
              [class.ddp-recommend]="recommendCharts.indexOf('sankey') > -1"
              [class.ddp-selected]="selectChart === 'sankey'"
              (click)="changeChart('sankey')"
            >
              <a class="a-hover-tooltip" href="javascript:">
                <em class="ddp-img-graph-sankey"></em>
                <span>{{ 'msg.page.ui.sankey' | translate }}</span>
              </a>
            </li> -->
          </ul>
        </div>
        <div *ngIf="selectChart !== '' && !isChartShow" style="width: 100%; height: 100%">
          <div
            class="ddp-ui-cahrt-layout ddp-box-layout1"
            *ngIf="this.guideLayout['layout6'].indexOf(selectChart) > -1"
          >
            <div
              class="ddp-ui-layout-center"
              [dragula]="'dragbag'"
              [dragulaModel]="[]"
              [attr.data-container]="'layer-guide'"
            >
              <div class="ddp-ui-condition">
                <em class="ddp-icon-mapview-guide"></em>
                <span *ngIf="'GEO_POINT' === geoType?.toString()" class="ddp-txt-dimension">
                  {{ 'msg.page.pivot.layout.condition.dimension.over.desc' | translate: { value: '1' } }}
                  (geo type)
                </span>
                <span *ngIf="'GEO_POINT' !== geoType?.toString()" class="ddp-txt-dimension">
                  {{ 'msg.page.pivot.layout.condition.dimension.desc' | translate: { value: '1' } }}
                  (geo type)
                </span>
              </div>
              <em class="ddp-bg-chart-mapview" *ngIf="selectChart === 'map'"></em>
            </div>
          </div>
          <div
            class="ddp-ui-cahrt-layout ddp-box-layout2"
            *ngIf="this.guideLayout['layout5'].indexOf(selectChart) > -1"
          >
            <div
              class="ddp-ui-layout-center"
              [dragula]="'dragbag'"
              [dragulaModel]="[]"
              [attr.data-container]="'aggregation-guide'"
            >
              <div class="dragIgnore ddp-ui-condition">
                <em class="ddp-icon-cross2"></em>
                <span class="ddp-txt-measure">
                  {{ 'msg.page.pivot.layout.condition.measure.desc' | translate: { value: '1' } }}
                </span>
              </div>
              <em class="ddp-bg-chart-gauge" *ngIf="selectChart === 'gauge'"></em>
            </div>
            <div
              class="ddp-ui-layout-bottom"
              [dragula]="'dragbag'"
              [dragulaModel]="[]"
              [attr.data-container]="'row-guide'"
            >
              <div class="dragIgnore dragIgnore ddp-ui-condition">
                <em class="ddp-icon-columns2"></em>
                <span class="ddp-txt-dimension">
                  {{ 'msg.page.pivot.layout.condition.dimension.desc' | translate: { value: '1' } }}
                </span>
              </div>
            </div>
          </div>
          <div
            class="ddp-ui-cahrt-layout ddp-box-layout3"
            *ngIf="this.guideLayout['layout4'].indexOf(selectChart) > -1"
          >
            <div
              class="ddp-ui-layout-left"
              [dragula]="'dragbag'"
              [dragulaModel]="[]"
              [attr.data-container]="'row-guide'"
            >
              <div class="dragIgnore ddp-ui-condition">
                <em *ngIf="'graph' !== selectChart && 'graphv2' !== selectChart" class="ddp-icon-columns2"></em>
                <em *ngIf="'graph' === selectChart || selectChart === 'graphv2'" class="ddp-icon-target"></em>
                <span
                  *ngIf="
                    selectChart !== 'scatter' &&
                    selectChart !== 'graph' &&
                    selectChart !== 'graphv2' &&
                    selectChart !== 'boxplot'
                  "
                  class="ddp-txt-dimension"
                >
                  {{ 'msg.page.pivot.layout.condition.dimension.over.desc' | translate: { value: '1' } }}
                </span>
                <span
                  *ngIf="selectChart === 'graph' || selectChart === 'graphv2' || selectChart === 'boxplot'"
                  class="ddp-txt-dimension"
                >
                  {{ 'msg.page.pivot.layout.condition.dimension.desc' | translate: { value: '1' } }}
                </span>
                <span *ngIf="selectChart === 'scatter'" class="ddp-txt-measure">
                  {{ 'msg.page.pivot.layout.condition.measure.desc' | translate: { value: '1' } }}
                </span>
              </div>
            </div>
            <div
              class="ddp-ui-layout-center"
              [dragula]="'dragbag'"
              [dragulaModel]="[]"
              [attr.data-container]="'aggregation-guide'"
            >
              <div class="dragIgnore ddp-ui-condition">
                <em *ngIf="'graph' !== selectChart && selectChart !== 'graphv2'" class="ddp-icon-cross2"></em>
                <em *ngIf="'graph' === selectChart || selectChart === 'graphv2'" class="ddp-icon-link"></em>
                <span
                  *ngIf="
                    selectChart !== 'scatter' &&
                    selectChart !== 'graph' &&
                    selectChart !== 'graphv2' &&
                    selectChart !== 'heatmap' &&
                    selectChart !== 'boxplot' &&
                    selectChart !== 'treemap'
                  "
                  class="ddp-txt-measure"
                >
                  {{ 'msg.page.pivot.layout.condition.measure.over.desc' | translate: { value: '1' } }}
                </span>
                <span
                  *ngIf="
                    selectChart === 'graph' ||
                    selectChart === 'graphv2' ||
                    selectChart === 'heatmap' ||
                    selectChart === 'boxplot' ||
                    selectChart === 'treemap'
                  "
                  class="ddp-txt-measure"
                >
                  {{ 'msg.page.pivot.layout.condition.measure.desc' | translate: { value: '1' } }}
                </span>
                <span *ngIf="selectChart === 'scatter'" class="ddp-txt-dimension">
                  {{ 'msg.page.pivot.layout.condition.dimension.over.desc' | translate: { value: '1' } }}
                </span>
              </div>
              <em class="ddp-bg-chart-bar" *ngIf="selectChart === 'bar'"></em>
              <em class="ddp-bg-chart-boxplot" *ngIf="selectChart === 'boxplot'"></em>
              <em class="ddp-bg-chart-combo" *ngIf="selectChart === 'combine'"></em>
              <em class="ddp-bg-chart-heatmap" *ngIf="selectChart === 'heatmap'"></em>
              <em class="ddp-bg-chart-kpi" *ngIf="selectChart === 'label'"></em>
              <em class="ddp-bg-chart-line" *ngIf="selectChart === 'line'"></em>
              <em class="ddp-bg-chart-network" *ngIf="selectChart === 'graph' || selectChart === 'graphv2'"></em>
              <em class="ddp-bg-chart-pie" *ngIf="selectChart === 'pie'"></em>
              <em class="ddp-bg-chart-randar" *ngIf="selectChart === 'radar'"></em>
              <em class="ddp-bg-chart-scatter" *ngIf="selectChart === 'scatter'"></em>
              <em class="ddp-bg-chart-table" *ngIf="selectChart === 'grid'"></em>
              <em class="ddp-bg-chart-treemap" *ngIf="selectChart === 'treemap'"></em>
              <em class="ddp-bg-chart-waterfall" *ngIf="selectChart === 'waterfall'"></em>
              <em class="ddp-bg-chart-wordcloud" *ngIf="selectChart === 'wordcloud'"></em>
            </div>
            <div
              class="ddp-ui-layout-bottom"
              [dragula]="'dragbag'"
              [dragulaModel]="[]"
              [attr.data-container]="'column-guide'"
            >
              <div class="dragIgnore ddp-ui-condition">
                <em *ngIf="'graph' !== selectChart && selectChart !== 'graphv2'" class="ddp-icon-rows2"></em>
                <em *ngIf="'graph' === selectChart || selectChart === 'graphv2'" class="ddp-icon-source"></em>
                <span
                  *ngIf="
                    selectChart !== 'scatter' &&
                    selectChart !== 'graph' &&
                    selectChart !== 'graphv2' &&
                    selectChart !== 'treemap'
                  "
                  class="ddp-txt-dimension"
                >
                  {{ 'msg.page.pivot.layout.condition.dimension.over.desc' | translate: { value: '1' } }}
                </span>
                <span
                  *ngIf="selectChart === 'graph' || selectChart === 'graphv2' || selectChart === 'treemap'"
                  class="ddp-txt-dimension"
                >
                  {{ 'msg.page.pivot.layout.condition.dimension.desc' | translate: { value: '1' } }}
                </span>
                <span *ngIf="selectChart === 'scatter'" class="ddp-txt-measure">
                  {{ 'msg.page.pivot.layout.condition.measure.desc' | translate: { value: '1' } }}
                </span>
              </div>
            </div>
          </div>
          <div
            class="ddp-ui-cahrt-layout ddp-box-layout2"
            *ngIf="this.guideLayout['layout3'].indexOf(selectChart) > -1"
          >
            <div
              class="ddp-ui-layout-center"
              [dragula]="'dragbag'"
              [dragulaModel]="[]"
              [attr.data-container]="'column-guide'"
            >
              <div class="dragIgnore ddp-ui-condition">
                <em class="ddp-icon-rows2"></em>
                <span *ngIf="selectChart === 'waterfall'" class="ddp-txt-dimension">
                  {{ 'msg.page.pivot.layout.condition.datetime.desc' | translate: { value: '1' } }}
                </span>
                <span *ngIf="selectChart === 'sankey'" class="ddp-txt-dimension">
                  {{ 'msg.page.pivot.layout.condition.dimension.over.desc' | translate: { value: '2' } }}
                </span>
              </div>
              <em class="ddp-bg-chart-bar" *ngIf="selectChart === 'bar'"></em>
              <em class="ddp-bg-chart-boxplot" *ngIf="selectChart === 'boxplot'"></em>
              <em class="ddp-bg-chart-combo" *ngIf="selectChart === 'combine'"></em>
              <em class="ddp-bg-chart-heatmap" *ngIf="selectChart === 'heatmap'"></em>
              <em class="ddp-bg-chart-kpi" *ngIf="selectChart === 'label'"></em>
              <em class="ddp-bg-chart-line" *ngIf="selectChart === 'line'"></em>
              <em class="ddp-bg-chart-network" *ngIf="selectChart === 'graph' || selectChart === 'graphv2'"></em>
              <em class="ddp-bg-chart-pie" *ngIf="selectChart === 'pie'"></em>
              <em class="ddp-bg-chart-randar" *ngIf="selectChart === 'radar'"></em>
              <em class="ddp-bg-chart-scatter" *ngIf="selectChart === 'scatter'"></em>
              <em class="ddp-bg-chart-table" *ngIf="selectChart === 'grid'"></em>
              <em class="ddp-bg-chart-treemap" *ngIf="selectChart === 'treemap'"></em>
              <em class="ddp-bg-chart-waterfall" *ngIf="selectChart === 'waterfall'"></em>
              <em class="ddp-bg-chart-wordcloud" *ngIf="selectChart === 'wordcloud'"></em>
            </div>
            <div
              class="ddp-ui-layout-bottom"
              [dragula]="'dragbag'"
              [dragulaModel]="[]"
              [attr.data-container]="'aggregation-guide'"
            >
              <div class="dragIgnore ddp-ui-condition">
                <em class="ddp-icon-cross2"></em>
                <span *ngIf="selectChart !== 'control'" class="ddp-txt-measure">
                  {{ 'msg.page.pivot.layout.condition.measure.desc' | translate: { value: '1' } }}
                </span>
              </div>
            </div>
          </div>
          <div
            class="ddp-ui-cahrt-layout ddp-box-layout2"
            *ngIf="this.guideLayout['layout2'].indexOf(selectChart) > -1"
          >
            <div
              class="ddp-ui-layout-center"
              [dragula]="'dragbag'"
              [dragulaModel]="[]"
              [attr.data-container]="'aggregation-guide'"
            >
              <div class="dragIgnore ddp-ui-condition">
                <em class="ddp-icon-cross2"></em>
                <span *ngIf="selectChart !== 'combine'" class="ddp-txt-measure">
                  {{ 'msg.page.pivot.layout.condition.measure.over.desc' | translate: { value: '1' } }}
                </span>
                <span *ngIf="selectChart === 'combine'" class="ddp-txt-measure">
                  {{ 'msg.page.pivot.layout.condition.measure.over.desc' | translate: { value: '2' } }}
                </span>
              </div>
              <em class="ddp-bg-chart-bar" *ngIf="selectChart === 'bar'"></em>
              <em class="ddp-bg-chart-boxplot" *ngIf="selectChart === 'boxplot'"></em>
              <em class="ddp-bg-chart-combo" *ngIf="selectChart === 'combine'"></em>
              <em class="ddp-bg-chart-heatmap" *ngIf="selectChart === 'heatmap'"></em>
              <em class="ddp-bg-chart-kpi" *ngIf="selectChart === 'label'"></em>
              <em class="ddp-bg-chart-line" *ngIf="selectChart === 'line'"></em>
              <em class="ddp-bg-chart-network" *ngIf="selectChart === 'graph' || selectChart === 'graphv2'"></em>
              <em class="ddp-bg-chart-pie" *ngIf="selectChart === 'pie'"></em>
              <em class="ddp-bg-chart-randar" *ngIf="selectChart === 'radar'"></em>
              <em class="ddp-bg-chart-scatter" *ngIf="selectChart === 'scatter'"></em>
              <em class="ddp-bg-chart-table" *ngIf="selectChart === 'grid'"></em>
              <em class="ddp-bg-chart-treemap" *ngIf="selectChart === 'treemap'"></em>
              <em class="ddp-bg-chart-waterfall" *ngIf="selectChart === 'waterfall'"></em>
              <em class="ddp-bg-chart-wordcloud" *ngIf="selectChart === 'wordcloud'"></em>
            </div>
            <div
              class="ddp-ui-layout-bottom"
              [dragula]="'dragbag'"
              [dragulaModel]="[]"
              [attr.data-container]="'column-guide'"
            >
              <div class="dragIgnore ddp-ui-condition">
                <em class="ddp-icon-rows2"></em>
                <span class="ddp-txt-dimension">
                  {{ 'msg.page.pivot.layout.condition.dimension.over.desc' | translate: { value: '1' } }}
                </span>
              </div>
            </div>
          </div>
          <div
            class="ddp-ui-cahrt-layout ddp-box-layout1"
            *ngIf="this.guideLayout['layout1'].indexOf(selectChart) > -1"
          >
            <div
              class="ddp-ui-layout-center"
              [dragula]="'dragbag'"
              [dragulaModel]="[]"
              [attr.data-container]="'aggregation-guide'"
            >
              <div class="dragIgnore ddp-ui-condition">
                <em class="ddp-icon-cross2"></em>
                <span
                  *ngIf="selectChart !== 'wordcloud' && selectChart !== 'radar' && selectChart !== 'pie'"
                  class="ddp-txt-measure"
                >
                  {{ 'msg.page.pivot.layout.condition.measure.over.desc' | translate: { value: '1' } }}
                </span>
                <span *ngIf="selectChart === 'radar'" class="ddp-txt-measure">
                  {{ 'msg.page.pivot.layout.condition.measure.over.desc' | translate: { value: '1' } }}
                </span>
                <span *ngIf="selectChart === 'radar'" class="ddp-txt-dimension">
                  {{ 'msg.page.pivot.layout.condition.dimension.and.desc' | translate: { value: '1' } }}
                </span>
                <span *ngIf="selectChart === 'wordcloud'" class="ddp-txt-measure">
                  {{ 'msg.page.pivot.layout.condition.measure.desc' | translate: { value: '1' } }}
                </span>
                <span *ngIf="selectChart === 'wordcloud'" class="ddp-txt-dimension">
                  {{ 'msg.page.pivot.layout.condition.dimension.and.over.desc' | translate: { value: '1' } }}
                </span>
                <span *ngIf="selectChart === 'pie'" class="ddp-txt-dimension">
                  {{ 'msg.page.pivot.layout.condition.dimension.desc' | translate: { value: '1' } }}
                </span>
                <span *ngIf="selectChart === 'pie'" class="ddp-txt-measure">
                  {{ 'msg.page.pivot.layout.condition.measure.desc.add' | translate: { value: '1' } }}
                </span>
              </div>
              <em class="ddp-bg-chart-bar" *ngIf="selectChart === 'bar'"></em>
              <em class="ddp-bg-chart-boxplot" *ngIf="selectChart === 'boxplot'"></em>
              <em class="ddp-bg-chart-combo" *ngIf="selectChart === 'combine'"></em>
              <em class="ddp-bg-chart-heatmap" *ngIf="selectChart === 'heatmap'"></em>
              <em class="ddp-bg-chart-kpi" *ngIf="selectChart === 'label'"></em>
              <em class="ddp-bg-chart-line" *ngIf="selectChart === 'line'"></em>
              <em class="ddp-bg-chart-network" *ngIf="selectChart === 'graph' || selectChart === 'graphv2'"></em>
              <em class="ddp-bg-chart-pie" *ngIf="selectChart === 'pie'"></em>
              <em class="ddp-bg-chart-randar" *ngIf="selectChart === 'radar'"></em>
              <em class="ddp-bg-chart-scatter" *ngIf="selectChart === 'scatter'"></em>
              <em class="ddp-bg-chart-table" *ngIf="selectChart === 'grid'"></em>
              <em class="ddp-bg-chart-waterfall" *ngIf="selectChart === 'waterfall'"></em>
              <em class="ddp-bg-chart-wordcloud" *ngIf="selectChart === 'wordcloud'"></em>
              <em class="ddp-bg-chart-treemap" *ngIf="selectChart === 'treemap'"></em>
            </div>
          </div>
        </div>
        <div class="ddp-ui-chart-main" [style.display]="selectChart === '' || isChartShow === false ? 'none' : 'block'">
          <div class="ddp-ui-top ddp-clear">
            <div class="ddp-ui-control"></div>
          </div>
          <div
            [class.ddp-sankey]="isSankeyNotAllNode && !isNoData && isChartView && selectChart === 'sankey'"
            class="ddp-ui-chart-area"
          >
            <div *ngIf="limitInfo.isShow" class="ddp-top-control ddp-clear" style="right: 5px">
              <div class="ddp-box-btn2">
                <em class="ddp-icon-widget-info2"></em>
                <div class="ddp-box-info ddp-limitation">
                  <em class="ddp-icon-info"></em>
                  <span
                    class="ddp-total"
                    [innerHTML]="
                      'msg.page.ui.limit-msg-1'
                        | translate
                          : {
                              currentCnt: limitInfo.currentCnt | numberCommas,
                              maxCnt: limitInfo.maxCnt | numberCommas
                            }
                    "
                  ></span>
                  {{ 'msg.page.ui.limit-msg-2' | translate }}
                </div>
              </div>
            </div>
            <div
              *ngIf="isSankeyNotAllNode && !isNoData && isChartView && selectChart === 'sankey'"
              class="ddp-txt-detail"
            >
              ※ {{ 'msg.page.ui.sankey.data.part' | translate }}
            </div>
            <div *ngIf="isNoData" class="ddp-box-data-none">
              <div class="ddp-wrap-data-none">
                <div class="ddp-ui-data-none">
                  <div class="ddp-txt-none">
                    <selfai-bi-chart-icon
                      class="ddp-error"
                      [chartType]="widget?.configuration?.chart?.type"
                    ></selfai-bi-chart-icon>
                    <span class="ddp-data-name">{{ widget.name }}</span>
                    <span class="ddp-txt-det">
                      {{ 'msg.page.ui.no.data.show' | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="isError" class="ddp-box-data-none">
              <div class="ddp-wrap-data-none">
                <div class="ddp-ui-data-none">
                  <div class="ddp-txt-none">
                    <selfai-bi-chart-icon
                      class="ddp-error"
                      [chartType]="widget?.configuration?.chart?.type"
                    ></selfai-bi-chart-icon>
                    <span class="ddp-data-name">{{ widget.name }}</span>
                    <span class="ddp-txt-det">{{ 'msg.page.ui.no.data.error' | translate }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div
              style="width: 100%; height: 100%"
              [style.display]="!isChartView ? 'block' : 'none'"
              *ngIf="!isNoData && isAvaliableGrid()"
            >
              <grid-chart
                #gridChart
                *ngIf="!isChartView && uiOptionValue"
                [widgetId]="widget.id"
                [uiOption]="uiOptionValue"
                [viewMode]="true"
                (noData)="onNoData.emit()"
                [resizeDelay]="800"
              ></grid-chart>
            </div>
            <div
              style="width: 100%; height: 100%"
              [style.display]="!isError && !isNoData && isChartView ? 'block' : 'none'"
            >
              <!-- something wrong -->
              <!-- <dpie-chart
                #chart
                [isPage]="true"
                *ngIf="selectChart === 'dpie'"
                [events]="eventSubject.asObservable()"
                [dataSource]="dataSource"
                [uiOption]="setUiOptions()"
                (noData)="onNoData.emit()"
                (chartSelectInfo)="chartSelectInfo.emit($event)"
                [resizeDelay]="500"
                [data]="this.resultData"
                [aggregation]="this.aggregation"
              ></dpie-chart>
              {{ modifyDataVariable | json }}
              <ddonat-chart
                #chart
                [isPage]="true"
                *ngIf="selectChart === 'ddonat'"
                [dataSource]="dataSource"
                [events]="eventSubject.asObservable()"
                [uiOption]="setUiOptions()"
                (modifyDataEvent)="modifyDataEvent($event)"
                (noData)="onNoData.emit()"
                (chartSelectInfo)="chartSelectInfo.emit($event)"
                [resizeDelay]="500"
                [data]="this.resultData"
                [aggregation]="this.aggregation"
              ></ddonat-chart>
              {{ modifyDataVariable | json }} -->
              <bar-chart
                #chart
                *ngIf="selectChart === 'bar'"
                [widgetId]="widget.id"
                [isPage]="true"
                [uiOption]="uiOptionValue"
                (noData)="onNoData.emit()"
              ></bar-chart>
              <line-chart
                #chart
                *ngIf="selectChart === 'line'"
                [widgetId]="widget.id"
                [isPage]="true"
                [uiOption]="uiOptionValue"
                (noData)="onNoData.emit()"
                (chartSelectInfo)="chartSelectInfo.emit($event)"
                [resizeDelay]="500"
              ></line-chart>
              <scatter-chart
                #chart
                *ngIf="selectChart === 'scatter'"
                [widgetId]="widget.id"
                [isPage]="true"
                [uiOption]="uiOptionValue"
                (noData)="onNoData.emit()"
                (chartSelectInfo)="chartSelectInfo.emit($event)"
                [resizeDelay]="500"
              ></scatter-chart>
              <grid-chart
                #chart
                *ngIf="selectChart === 'grid'"
                [widgetId]="widget.id"
                [isPage]="true"
                [uiOption]="uiOptionValue"
                (noData)="onNoData.emit()"
                (chartSelectInfo)="chartSelectInfo.emit($event)"
                [resizeDelay]="500"
              ></grid-chart>
              <heatmap-chart
                #chart
                *ngIf="selectChart === 'heatmap'"
                [widgetId]="widget.id"
                [isPage]="true"
                [uiOption]="uiOptionValue"
                (noData)="onNoData.emit()"
                (chartSelectInfo)="chartSelectInfo.emit($event)"
                [resizeDelay]="500"
              ></heatmap-chart>
              <boxplot-chart
                #chart
                *ngIf="selectChart === 'boxplot'"
                [widgetId]="widget.id"
                [isPage]="true"
                [uiOption]="uiOptionValue"
                (noData)="onNoData.emit()"
                (chartSelectInfo)="chartSelectInfo.emit($event)"
                [resizeDelay]="500"
              ></boxplot-chart>
              <pie-chart
                #chart
                *ngIf="selectChart === 'pie'"
                [widgetId]="widget.id"
                [isPage]="true"
                [uiOption]="uiOptionValue"
                (noData)="onNoData.emit()"
                (chartSelectInfo)="chartSelectInfo.emit($event)"
                [resizeDelay]="500"
              ></pie-chart>
              <label-chart
                #chart
                *ngIf="selectChart === 'label'"
                [isPage]="true"
                [uiOption]="uiOptionValue"
                (noData)="onNoData.emit()"
                (chartSelectInfo)="chartSelectInfo.emit($event)"
                [query]="query"
                [widget]="widget"
                [resizeDelay]="500"
              ></label-chart>
              <wordcloud-chart
                #chart
                *ngIf="selectChart === 'wordcloud'"
                [widgetId]="widget.id"
                [isPage]="true"
                [uiOption]="uiOptionValue"
                (noData)="onNoData.emit()"
                (chartSelectInfo)="chartSelectInfo.emit($event)"
                [resizeDelay]="500"
              ></wordcloud-chart>
              <waterfall-chart
                #chart
                *ngIf="selectChart === 'waterfall'"
                [widgetId]="widget.id"
                [isPage]="true"
                [uiOption]="uiOptionValue"
                (noData)="onNoData.emit()"
                (chartSelectInfo)="chartSelectInfo.emit($event)"
                [resizeDelay]="500"
              ></waterfall-chart>
              <combine-chart
                #chart
                *ngIf="selectChart === 'combine'"
                [widgetId]="widget.id"
                [isPage]="true"
                [uiOption]="uiOptionValue"
                (noData)="onNoData.emit()"
                (chartSelectInfo)="chartSelectInfo.emit($event)"
                [resizeDelay]="500"
              ></combine-chart>
              <treemap-chart
                #chart
                *ngIf="selectChart === 'treemap'"
                [widgetId]="widget.id"
                [isPage]="true"
                [uiOption]="uiOptionValue"
                (noData)="onNoData.emit()"
                (chartSelectInfo)="chartSelectInfo.emit($event)"
                [resizeDelay]="500"
              ></treemap-chart>
              <radar-chart
                #chart
                *ngIf="selectChart === 'radar'"
                [widgetId]="widget.id"
                [isPage]="true"
                [uiOption]="uiOptionValue"
                (noData)="onNoData.emit()"
                (chartSelectInfo)="chartSelectInfo.emit($event)"
                [resizeDelay]="500"
              ></radar-chart>
              <network-chart
                #chart
                *ngIf="selectChart === 'graph'"
                [isPage]="true"
                [widgetId]="widget.id"
                [uiOption]="uiOptionValue"
                (noData)="onNoData.emit()"
                (chartSelectInfo)="chartSelectInfo.emit($event)"
                [resizeDelay]="500"
              ></network-chart>
              <ng-container *ngIf="selectChart === 'graphv2' && !isError && !isNoData && isChartShow">
                <selfai-bi-chart-graph-chart
                  #chart
                  [widgetId]="widget.id"
                  [isNew]="!widget?.createdTime"
                  [dashBoard]="dashboard"
                ></selfai-bi-chart-graph-chart>
              </ng-container>
              <sankey-chart
                #chart
                *ngIf="selectChart === 'sankey'"
                [isPage]="true"
                [widgetId]="widget.id"
                [uiOption]="uiOptionValue"
                (noData)="onNoData.emit()"
                (notAllNode)="isSankeyNotAllNode = $event"
                (chartSelectInfo)="chartSelectInfo.emit($event)"
                [resizeDelay]="500"
              ></sankey-chart>
              <gauge-chart
                #chart
                *ngIf="selectChart === 'gauge'"
                [widgetId]="widget.id"
                [isPage]="true"
                [sorts]="sorts"
                [uiOption]="uiOptionValue"
                (noData)="onNoData.emit()"
                (changePivotData)="onChangePivotData.emit($event)"
                (showGuide)="onShowGuide.emit()"
                (chartSelectInfo)="chartSelectInfo.emit($event)"
                [resizeDelay]="500"
              ></gauge-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #chartMenuItem let-type="type" let-icon="icon">
  <span
    (click)="uiOption && uiOption.split ? null : toggleRnb.emit(type)"
    [ngClass]="{
      'ddp-disabled': !isChartShow || (uiOption && uiOption.split)
    }"
    [class.ddp-selected]="isChartShow && rnbMenu === type"
    [class.ddp-disabled]="!isChartShow || (uiOption && uiOption.split)"
    style="cursor: pointer"
  >
    <em class="ddp-icon-menu-{{ icon }}"></em>
    <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
      <em class="ddp-icon-view-right"></em>
      {{ 'msg.page.first.draw.chart' | translate }}
    </div>
  </span>
</ng-template>

<ng-template #chartMenu>
  <div class="ddp-wrap-chart-menu">
    <a href="javascript:" (click)="toggleRnb.emit('filter')" [class.ddp-selected]="rnbMenu === 'filter'">
      <em class="ddp-icon-menu-filter"></em>
    </a>
    <span class="ddp-ui-menu-layout" (mouseover)="drawChartTooltip.emit($event)">
      <ng-container *ngIf="possibleChartCheck('common', selectChart)">
        <ng-container *ngTemplateOutlet="chartMenuItem; context: { type: 'common', icon: 'general' }"></ng-container>
      </ng-container>

      <ng-container *ngIf="possibleChartCheck('color', selectChart)">
        <ng-container *ngTemplateOutlet="chartMenuItem; context: { type: 'color', icon: 'color' }"></ng-container>
      </ng-container>

      <ng-container *ngIf="possibleChartCheck('legend', selectChart)">
        <ng-container *ngTemplateOutlet="chartMenuItem; context: { type: 'legend', icon: 'legend' }"></ng-container>
      </ng-container>

      <ng-container *ngIf="possibleChartCheck('format', selectChart)">
        <ng-container *ngTemplateOutlet="chartMenuItem; context: { type: 'format', icon: 'num' }"></ng-container>
      </ng-container>

      <ng-container *ngIf="possibleChartCheck('yAxis', selectChart)">
        <ng-container *ngTemplateOutlet="chartMenuItem; context: { type: 'yAxis', icon: 'axis-y' }"></ng-container>
      </ng-container>

      <ng-container *ngIf="possibleChartCheck('xAxis', selectChart)">
        <ng-container *ngTemplateOutlet="chartMenuItem; context: { type: 'xAxis', icon: 'axis-x' }"></ng-container>
      </ng-container>

      <ng-container *ngIf="possibleChartCheck('secondaryAxis', selectChart)">
        <ng-container
          *ngTemplateOutlet="chartMenuItem; context: { type: 'secondaryAxis', icon: 'axis' }"
        ></ng-container>
      </ng-container>

      <ng-container *ngIf="possibleChartCheck('dataLabel', selectChart)">
        <ng-container *ngTemplateOutlet="chartMenuItem; context: { type: 'dataLabel', icon: 'label' }"></ng-container>
      </ng-container>

      <ng-container *ngIf="possibleChartCheck('tooltip', selectChart)">
        <ng-container *ngTemplateOutlet="chartMenuItem; context: { type: 'tooltip', icon: 'tooltip' }"></ng-container>
      </ng-container>

      <ng-container *ngIf="false && possibleChartCheck('split', selectChart)">
        <ng-container *ngTemplateOutlet="chartMenuItem; context: { type: 'split', icon: 'split' }"></ng-container>
      </ng-container>

      <ng-container *ngIf="possibleChartCheck('calculatedRow', selectChart)">
        <ng-container
          *ngTemplateOutlet="chartMenuItem; context: { type: 'calculatedRow', icon: 'calculated' }"
        ></ng-container>
      </ng-container>

      <ng-container *ngIf="possibleChartCheck('secondaryIndicator', selectChart)">
        <ng-container
          *ngTemplateOutlet="chartMenuItem; context: { type: 'secondaryIndicator', icon: 'tooltip' }"
        ></ng-container>
      </ng-container>

      <ng-container *ngIf="selectChart === 'map'">
        <ng-container *ngTemplateOutlet="chartMenuItem; context: { type: 'mapCommon', icon: 'general' }"></ng-container>
      </ng-container>

      <ng-container *ngIf="selectChart === 'map'">
        <div *ngFor="let item of shelf.layers; let index = index">
          <a
            *ngIf="
              uiOption['analysis'] === null ||
              uiOption['analysis']['use'] === null ||
              uiOption['analysis']['use'] === false ||
              (uiOption['analysis']['use'] && index !== shelf.layers.length - 1)
            "
            (click)="toggleMapLayer('mapLayer' + (index + 1), index)"
            [ngClass]="{
              'ddp-disabled':
                !isChartShow ||
                setDisableShelf(index) ||
                ('map' === selectChart && uiOption['analysis'] !== null && uiOption['analysis']['use'] === true)
            }"
            [class.ddp-selected]="isChartShow && rnbMenu === 'mapLayer' + (index + 1) && !setDisableShelf(index)"
            href="javascript:"
          >
            <em *ngIf="index === 0" class="ddp-icon-menu-mapview1"></em>
            <em *ngIf="index === 1" class="ddp-icon-menu-mapview2"></em>
            <div *ngIf="!isChartShow" class="ddp-ui-tooltip-info">
              <em class="ddp-icon-view-right"></em>
              {{ 'msg.page.first.draw.chart' | translate }}
            </div>
          </a>
          <a
            *ngIf="
              uiOption['analysis'] !== null && uiOption['analysis']['use'] === true && index === shelf.layers.length - 1
            "
            (click)="toggleMapLayer('mapLayer' + (index + 1), index)"
            [class.ddp-selected]="isChartShow && rnbMenu === 'mapLayer' + (index + 1) && !setDisableShelf(index)"
            href="javascript:"
          >
            <em class="ddp-icon-menu-analysis"></em>
            <div class="ddp-ui-tooltip-info">
              <em class="ddp-icon-view-right"></em>
              Analysis
            </div>
          </a>
        </div>

        <ng-container *ngTemplateOutlet="chartMenuItem; context: { type: 'mapLegend', icon: 'legend' }"></ng-container>

        <ng-container *ngTemplateOutlet="chartMenuItem; context: { type: 'mapFormat', icon: 'num' }"></ng-container>

        <ng-container
          *ngTemplateOutlet="chartMenuItem; context: { type: 'mapTooltip', icon: 'tooltip' }"
        ></ng-container>
      </ng-container>
    </span>
  </div>
</ng-template>
