import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { IColorRange } from '../color-option.interfaces';

enum EControlName {
  FROM = 'from',
  TO = 'to',
}

@Component({
    selector: 'selfai-bi-color-option-custom-default',
    templateUrl: './color-option-custom-default.component.html',
    styleUrls: ['./color-option-custom-default.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ColorOptionCustomDefaultComponent implements OnInit {
  @Input() colorRange: IColorRange;
  @Input() isShowAdd: boolean;
  @Input() isShowRemove: boolean;

  @Output() addColorRange = new EventEmitter<boolean>();
  @Output() colorPaletteSelected = new EventEmitter<string>();
  @Output() changeMinColorRange = new EventEmitter<IColorRange>();
  @Output() changeMaxColorRange = new EventEmitter<IColorRange>();
  @Output() removeColorRange = new EventEmitter<IColorRange>();

  form = new FormGroup({
    from: new FormControl<number>(null),
    to: new FormControl<number>(null),
  });

  ngOnInit(): void {
    this.form.get('from').setValue(this.colorRange.gt, { emitEvent: false });
    this.form.get('to').setValue(this.colorRange.lte, { emitEvent: false });
  }

  paletteSelected(event) {
    this.colorPaletteSelected.emit(event);
  }

  changeControl(controlName: EControlName) {
    switch (controlName) {
      case EControlName.FROM:
        this.changeMinRange();
        break;
      case EControlName.TO:
        this.changeMaxRange();
        break;
    }
  }

  removeRange() {
    this.removeColorRange.emit(this.colorRange);
  }

  private changeMinRange() {
    const colorRange = {
      ...this.colorRange,
      gt: this.form.get('from').value,
    };
    this.changeMinColorRange.emit(colorRange);
  }

  private changeMaxRange() {
    const colorRange = {
      ...this.colorRange,
      lte: this.form.get('to').value,
    };
    this.changeMaxColorRange.emit(colorRange);
  }
}
