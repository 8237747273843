import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DestroyService } from '@selfai-platform/shared';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { takeUntil } from 'rxjs';
import { WorkflowPresets } from '../../../workflow-presets/interfaces/workflow-presets.interface';
import { WorkflowSettingsPresetsBinding } from '../../interface/workflow-settings-presets-binding.interface';

@Component({
    selector: 'selfai-platform-workflow-settings-presets',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DropdownModule,
        CardModule,
        ButtonModule,
        OverlayPanelModule,
        TranslateModule,
    ],
    templateUrl: './workflow-settings-presets.component.html',
    styleUrl: './workflow-settings-presets.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DestroyService]
})
export class WorkflowSettingsPresetsComponent implements OnChanges, OnInit {
  form: FormGroup = this.fb.group({
    preset: ['', [Validators.required]],
  });
  currentPreset: WorkflowPresets;

  constructor(
    private fb: FormBuilder,
    private readonly destroy$: DestroyService,
  ) {}

  @Input() presets!: WorkflowPresets[];
  @Input() currentPresetId!: string | undefined;
  @Input() workflowId: string;
  @Output() submitEvent = new EventEmitter<WorkflowSettingsPresetsBinding>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['currentPresetId']?.currentValue) {
      this.form.setValue({ preset: changes?.['currentPresetId']?.currentValue });
      this.findPreset();
    }
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.findPreset();
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.submitEvent.emit({ presetId: this.form.get('preset').value, id: this.workflowId });
    }
  }

  findPreset(): void {
    this.currentPreset = this.presets.find((el) => {
      return el.id === this.form.get('preset').value;
    });
  }
}
