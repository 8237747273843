import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { WORKSPACE_LIST_ENTITY_NAME } from '../constants';
import { WorkspaceListItem } from '../models';
import { WorkspaceListDataService } from '../services';

@Injectable({ providedIn: 'root' })
export class WorkspaceListStore extends EntityCollectionServiceBase<WorkspaceListItem> {
  constructor(
    entityDataService: EntityDataService,
    dataService: WorkspaceListDataService,
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(WORKSPACE_LIST_ENTITY_NAME, dataService);
    super(WORKSPACE_LIST_ENTITY_NAME, serviceElementsFactory);
  }
}
