<form [formGroup]="form">
  <div>
    <div class="flex flex-row justify-content-start align-items-center p-2" style="background-color: #f6f9fe;">
      <div class="flex flex-row justify-content-start space-x-3 align-items-center cursor-pointer mr-2 role-name" (click)="toggleExpanded()">
        <em
          class="pi"
          [class.pi-chevron-down]="(expanded$ | async) === true"
          [class.pi-chevron-up]="(expanded$ | async) === false"
        ></em>
        <span class="font-bold text-gray-800 select-none">{{ role.name }}</span>
        <label tooltipPosition="top" [pTooltip]="'msg.workspacesEdit.selectUsers.tooltip' | translate">
          <em class="pi pi-info-circle ml-2 cursor-pointer"></em>
        </label>
        <ng-container *ngIf="edit">
          <div>
            <em class="pi pi-trash text-red-600 cursor-pointer" [title]="'msg.workspacesEdit.deleteRole' | translate"
              (click)="removeRole()"></em>
          </div>
        </ng-container>

      </div>
      <div class="w-full">
        <p-autoComplete
          styleClass="w-full"
          class="user-multi-autocomplete"
          field="fullName"
          formControlName="members"
          [placeholder]="'msg.workspacesEdit.selectUsers' | translate"
          [suggestions]="(filteredUsers | async)"
          [multiple]="true"
          [dropdown]="true"
          (completeMethod)="filterUsers($event)"
        ></p-autoComplete>
      </div>
    </div>

    <ng-container *ngIf="expanded$ | async">
      <form [formGroup]="form.controls.permissions">
        <table class="border-collapse w-full permission-table">
          <thead>
            <tr class="font-semibold text-left">
              <th class="p-2 w-36">{{ 'msg.workspacesEdit.section' | translate }}</th>
              <th class="p-2">{{ 'msg.workspacesEdit.view' | translate }}</th>
              <th class="p-2">{{ 'msg.workspacesEdit.create' | translate }}</th>
              <th class="p-2">{{ 'msg.workspacesEdit.edit' | translate }}</th>
              <th class="p-2">{{ 'msg.workspacesEdit.delete' | translate }}</th>
              <!-- <th class="p-2">{{ 'msg.workspacesEdit.manageRoles' | translate }}</th> -->
              <!-- <th class="p-2">{{ 'msg.workspacesEdit.editMetadata' | translate }}</th> -->
              <!-- <th class="p-2">{{ 'msg.workspacesEdit.manageAccess' | translate }}</th>
              <th class="p-2">{{ 'msg.workspacesEdit.viewDataSource' | translate }}</th> -->
            </tr>
          </thead>
          <tbody>
            <tr class="text-left bg-grey-50">
              <td class="p-2">{{ 'msg.workspacesEdit.workbook' | translate }}</td>
              <td>
                <p-inputSwitch formControlName="WORKBOOK_VIEW"></p-inputSwitch>
              </td>
              <td>
                <p-inputSwitch formControlName="WORKBOOK_CREATE"></p-inputSwitch>
              </td>
              <td>
                <p-inputSwitch formControlName="WORKBOOK_EDIT"></p-inputSwitch>
              </td>
              <td>
                <p-inputSwitch formControlName="WORKBOOK_DELETE"></p-inputSwitch>
              </td>
              <!-- <td></td> -->
              <!-- <td></td> -->
              <!-- <td></td>
              <td></td> -->
            </tr>
            <tr class="text-left odd-bg-blue-100">
              <td class="p-2">{{ 'msg.workspacesEdit.dashboard' | translate }}</td>
              <td>
                <p-inputSwitch formControlName="DASHBOARD_VIEW"></p-inputSwitch>
              </td>
              <td>
                <p-inputSwitch formControlName="DASHBOARD_CREATE"></p-inputSwitch>
              </td>
              <td>
                <p-inputSwitch formControlName="DASHBOARD_EDIT"></p-inputSwitch>
              </td>
              <td>
                <p-inputSwitch formControlName="DASHBOARD_DELETE"></p-inputSwitch>
              </td>
              <!-- <td></td> -->
              <!-- <td></td> -->
              <!-- <td></td>
              <td></td> -->
            </tr>
            <!-- <tr class="text-left odd-bg-blue-100">
              <td class="p-2">{{ 'msg.workspacesEdit.workspace' | translate }}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td class="text-center">
                <p-inputSwitch formControlName="WORKSPACE_MANAGE_ROLES"></p-inputSwitch>
              </td>
              <td>
                <p-inputSwitch formControlName="WORKSPACE_METADATA_EDIT"></p-inputSwitch>
              </td>
              <td class="text-center">
                <p-inputSwitch formControlName="WORKSPACE_MANAGE_ACCESS"></p-inputSwitch>
              </td>
              <td class="text-center">
                <p-inputSwitch formControlName="WORKSPACE_DATASOURCES_VIEW"></p-inputSwitch>
              </td>
            </tr> -->
          </tbody>
        </table>
      </form>
    </ng-container>
  </div>
</form>
<p-confirmPopup></p-confirmPopup>
<p-toast></p-toast>
