import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DestroyService, LocalStorageKey, PREFIX_SETTINGS_LOCAL_STORAGE_KEY } from '@selfai-platform/shared';
import { BreadcrumbsMenuItem, BreadcrumbsMenuService, KE_ROOT_ROUTE, KE_SCHEDULES_PATH } from '@selfai-platform/shell';
import { map, Observable, takeUntil } from 'rxjs';
import { SchedulesListComponent } from '../../components';
import { SchedulesPresetsListStore } from '../../store/presets-list.store';
import { SchedulesWorkflowsListStore } from '../../store/workflows-list.store';

@Component({
    selector: 'selfai-platform-schedules-list-container',
    templateUrl: './schedules-list-container.component.html',
    styleUrls: ['./schedules-list-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [SchedulesListComponent],
    providers: [
        {
            provide: PREFIX_SETTINGS_LOCAL_STORAGE_KEY,
            useValue: LocalStorageKey.SCHEDULES_LIST_PREFIX_SETTINGS,
        },
    ]
})
export class SchedulesListContainerComponent implements OnInit {
  public breadcrumbItems$: Observable<BreadcrumbsMenuItem[]> = this.translateService
    .stream('shell.menu.schedules')
    .pipe(
      map((label) => [
        {
          label,
          routerLink: ['/', KE_ROOT_ROUTE, KE_SCHEDULES_PATH],
        },
      ]),
    );

  constructor(
    private readonly translateService: TranslateService,
    private readonly destroy$: DestroyService,
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    // TODO: use facades instead of stores
    private readonly schedulesWorkflowsListStore: SchedulesWorkflowsListStore,
    private readonly schedulesPresetsListStore: SchedulesPresetsListStore,
  ) {}

  public ngOnInit(): void {
    this.breadcrumbItems$.pipe(takeUntil(this.destroy$)).subscribe((breadcrumbItems) => {
      this.breadcrumbsMenuService.setBreadcrumbsMenu(breadcrumbItems);
    });
    this.schedulesWorkflowsListStore.getAll();
    this.schedulesPresetsListStore.getAll();
  }
}
