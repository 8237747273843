import { Injectable } from '@angular/core';
import {
  BiPermissionService,
  FavoriteDomainService,
  FavoriteListItem,
  favoriteListItemToDomainItem,
} from '@selfai-platform/bi-domain';
import { BiPermission } from '@selfai-platform/shared';
import { ActionsForItemService, DataListItemAction } from '@selfai-platform/shell';
import { map, Observable, of } from 'rxjs';
import { FavoriteDataListViewService } from './favorite-data-list-view.service';

@Injectable()
export class FavoriteActionsForItemService extends ActionsForItemService<FavoriteListItem> {
  constructor(
    private readonly favoriteDataListViewService: FavoriteDataListViewService,
    private readonly permissionService: BiPermissionService,
    private readonly favoriteDomainService: FavoriteDomainService,
  ) {
    super();
  }

  override getActionsForItem(favoriteItem: FavoriteListItem): Observable<DataListItemAction[]> {
    const menuItems: DataListItemAction[] = [];

    const domain = favoriteListItemToDomainItem(favoriteItem.domain);

    menuItems.push({
      labelTranslate: 'msg.favorite-list.table-actions.remove',
      icon: 'pi pi-trash',
      action: () =>
        this.favoriteDomainService
          .removeFromFavorite(domain, favoriteItem.targetId)
          .pipe(map(() => this.favoriteDataListViewService.triggerReloadData()))
          .subscribe(),
    });

    return of(menuItems);
  }

  hasPermission(permission: BiPermission): boolean {
    return this.permissionService.hasPermission(permission);
  }
}
