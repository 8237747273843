<form [formGroup]="form" class="flex flex-column gap-3" (ngSubmit)="save()">
  <div class="flex flex-column gap-2">
    <label htmlFor="name">{{ 'msg.add-workbook-dialog.name.label' | translate }}</label>
    <input
      id="name"
      class="w-full"
      pAutoFocus
      [autofocus]="true"
      formControlName="name"
      pInputText
      type="text"
      placeholder="{{ 'msg.add-workbook-dialog.name.placeholder' | translate }}"
    />
  </div>

  <div class="flex align-self-end gap-3">
    <button pButton type="button" class="p-button-text p-button-link" (click)="close()">
      {{ 'msg.add-workbook-dialog.button.cancel' | translate }}
    </button>
    <button pButton type="submit">{{ 'msg.add-workbook-dialog.button.save' | translate }}</button>
  </div>
</form>
