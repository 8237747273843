import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { debounceTime, takeUntil, tap } from 'rxjs';

import { DestroyService } from '@selfai-platform/shared';

@Component({
    selector: 'color-picker',
    templateUrl: './color.picker.component.html',
    styleUrls: ['./color.picker.component.scss'],
    standalone: false
})
export class ColorPickerComponent implements OnInit {
  @Output() private selected: EventEmitter<string> = new EventEmitter();
  @Output() private showPicker: EventEmitter<string> = new EventEmitter();
  @Output() private hidePicker: EventEmitter<string> = new EventEmitter();

  @Input() public showAlpha: boolean;
  @Input() public preferredFormat = 'hex';
  @Input() public showInput: boolean;
  @Input() public showPalette = true;
  @Input() public showInitial: boolean;
  @Input() public showUserColor = false;
  @Input() public palette: string[][] = [
    ['#ffcaba', '#fda08c', '#fb7661', '#f8533b', '#f23a2c', '#e5342c', '#d73631', '#c22a32', '#9a0b2c'],
    ['#f6f4b7', '#f9f6a1', '#fee330', '#ffd200', '#fbb700', '#f6a300', '#f28a00', '#f27603', '#fb6e2c'],
    ['#d1e5c2', '#b5d994', '#97cb63', '#83bf47', '#72b235', '#5d9f27', '#4b8a21', '#39751d', '#2d681a'],
    ['#b5e0e1', '#9ad5d2', '#75c4be', '#54b2ae', '#2b9a9e', '#0c8691', '#026e7f', '#015268', '#064059'],
    ['#c4eeed', '#a9e7eb', '#8adfe9', '#6ed0e4', '#58b5da', '#4a95cf', '#3f72c1', '#3452b5', '#23399f'],
    ['#efdffd', '#cdbaf8', '#b099f0', '#9b7fe4', '#8d6dd2', '#7c5ac1', '#6344ad', '#4c309a', '#391f8a'],
    ['#fcc9dd', '#fca0c3', '#fc79ac', '#ee5398', '#e03c8f', '#cd2287', '#ad037c', '#7d0071', '#4c006a'],
    ['#ffffff', '#eeeeee', '#cdcdcd', '#959595', '#797979', '#636363', '#4f4f4f', '#3c3c3c'],
  ];
  @Input() public clickoutFiresChange = false;
  @Input() color: string;

  public show = false;

  public form = new FormGroup({
    color: new FormControl(''),
  });

  constructor(private readonly destroy$: DestroyService) {}

  ngOnInit() {
    this.form.valueChanges
      .pipe(
        debounceTime(300),
        tap((form) => {
          const { color } = form;
          this.selected.emit(color);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.form.get('color').setValue(this.color, { emitEvent: false });
  }

  onColorPickerShow() {
    const color = this.form.get('color').value;
    this.showPicker.emit(color);
  }

  onColorPickerHide() {
    const color = this.form.get('color').value;
    this.hidePicker.emit(color);
  }

  /**
   * @deprecated
   */
  init(data) {
    //
  }
}
