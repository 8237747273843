<form [formGroup]="form">
  <div class="formgrid grid">
    <div class="col-12 md:col-6 lg:col-6 field">
      <div class="grid">
        <div class="field col-12 md:col-4 lg:col-3">
          <label for="name">{{ 'msg.workspacesEdit.name' | translate }}</label>
        </div>
        <div class="field col-12 md:col-8 lg:col-9">
          <h6 class="settings-name" *ngIf="workspaceId === guestWorkspaceId">
            {{ form.get('name').value ? form.get('name').value : 'msg.workspacesEdit.nameUnavailable' | translate }}
          </h6>

          <input
            *ngIf="workspaceId !== guestWorkspaceId"
            id="name"
            type="text"
            class="w-full"
            formControlName="name"
            pInputText
            [placeholder]="'msg.workspacesEdit.nameUnavailable' | translate" />
        </div>

        <div class="field col-12 md:col-4 lg:col-3">
          <label for="owners">{{ 'msg.workspacesEdit.owners' | translate }}</label>
        </div>
        <div class="field col-12 md:col-8 lg:col-9">
          <p-autoComplete
            id="owners"
            styleClass="w-full"
            class="user-multi-autocomplete"
            field="fullName"
            formControlName="owners"
            [placeholder]="'msg.workspacesEdit.selectOwners' | translate"
            [suggestions]="(filteredOwners | async)"
            [multiple]="true"
            [dropdown]="true"
            (completeMethod)="filterOwners($event)">
          </p-autoComplete>
        </div>
      </div>
    </div>

    <div class="col-12 md:col-6 lg:col-6 field">
      <label for="description">{{ 'msg.workspacesEdit.description' | translate }}
        <em class="pi pi-info-circle ml-2 cursor-pointer"></em>
      </label>
      <textarea id="description" rows="5" cols="30" formControlName="description" pInputTextarea class="w-full"></textarea>
    </div>

    <div class="col-12 md:col-6 lg:col-6 field">
      <div class="grid">
        <div class="field col-12 md:col-4 lg:col-3">
          <label for="availableRoles">{{ 'msg.workspacesEdit.availableRoles' | translate }}</label>
        </div>
        <div class="field col-12 md:col-8 lg:col-9">
          <p-multiSelect
            id="availableRoles"
            class="user-multi-select"
            styleClass="w-full"
            [options]="roles"
            [defaultLabel]="'msg.workspacesEdit.selectRole' | translate"
            optionLabel="name"
            formControlName="roles"
            display="chip">
            <ng-template pTemplate="footer">
              <ng-container *ngIf="form.get('useDefaultRoles').value === false">
                <div class="p-2">
                  <p-button styleClass="w-full text-center" (click)="addRole.emit(true)">
                    {{ 'msg.workspacesEdit.addRole' | translate }}
                  </p-button>
                </div>
              </ng-container>
            </ng-template>
          </p-multiSelect>
        </div>
      </div>
    </div>

    <div class="col-12 md:col-6 lg:col-6 field">
      <div class="field-checkbox">
        <p-checkbox formControlName="useDefaultRoles" id="useDefaultRoles" [binary]="true"></p-checkbox>
        <label for="useDefaultRoles">{{ 'msg.workspacesEdit.useDefaultRoles' | translate }}</label>
      </div>
    </div>
  </div>
</form>
