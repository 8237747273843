import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'selfai-platform-form-field',
    templateUrl: './form-field.component.html',
    styleUrls: ['./form-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormFieldComponent {
  @Input() label!: string;
}
