import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  DashboardDomainService,
  DashboardListItem,
  MainDashboardService,
  MainWorkbookDashboardListDomainService,
  WorkbookDashboardListDomainService,
  WorkbookDomainService,
  WorkbookPinsDomainService,
  WorkspaceDomainService,
} from '@selfai-platform/bi-domain';
import { PageParams, UrlPageParamsService } from '@selfai-platform/shared';
import {
  BI_ROOT_ROUTE,
  DataListViewItem,
  DataListViewLazyComponentService,
  convertMessageToHtml,
  SelectedItemsService,
  TableColumn,
} from '@selfai-platform/shell';
import { ConfirmationService } from 'primeng/api';
import { Observable, filter, map, shareReplay, switchMap, take, tap } from 'rxjs';

@Injectable()
export class WorkbookDashboardDataListViewService extends DataListViewLazyComponentService<DashboardListItem> {
  workbookId$: Observable<string> = this.route.paramMap.pipe(
    filter((params) => params.has('workbookId')),
    map((params) => params.get('workbookId')),
  );

  workspace$ = this.workbookId$.pipe(
    switchMap((workbookId) => this.workbookDomainService.getWorkbook(workbookId)),
    switchMap(({ workspaceId }) => {
      return this.workspaceDomainService.loadWorkspace(workspaceId);
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  constructor(
    private readonly route: ActivatedRoute,
    private readonly workbookDashboardListDomainService: WorkbookDashboardListDomainService,
    private readonly workspaceDomainService: WorkspaceDomainService,
    private readonly workbookDomainService: WorkbookDomainService,
    private readonly translate: TranslateService,
    private readonly mainDashboardService: MainDashboardService,
    private readonly mainWorkbookDashboardListDomainService: MainWorkbookDashboardListDomainService,
    private readonly dashboardDomainService: DashboardDomainService,
    private readonly confirmationService: ConfirmationService,
    private readonly workbookPinsDomainService: WorkbookPinsDomainService,
    urlPageParamsService: UrlPageParamsService,
    selectedItemsService: SelectedItemsService<DashboardListItem>,
  ) {
    super(urlPageParamsService, selectedItemsService);

    this.subscription.add(
      this.workbookId$
        .pipe(
          switchMap((workbookId) => {
            return this.workbookDomainService.loadWorkbook(workbookId);
          }),
        )
        .subscribe(),
    );
  }

  override loadData(pageParams: PageParams): Observable<{ items: DashboardListItem[]; totalItems: number }> {
    return this.workbookId$.pipe(
      switchMap((workbookId) =>
        this.workbookDashboardListDomainService
          .loadDashboardList(workbookId, pageParams)
          .pipe(map((state) => ({ items: state.dashboards, totalItems: state.page.totalElements }))),
      ),
    );
  }

  override getData(): Observable<DashboardListItem[]> {
    return this.workbookId$.pipe(
      switchMap((workbookId) =>
        this.workbookDashboardListDomainService.getDashboardList(workbookId).pipe(
          map((state) =>
            state
              ? state.dashboards
                  .map((dashboard) => this.addIcon(dashboard))
                  .map((dashboard) => this.addRouterLink(dashboard, workbookId))
                  .map((dashboard) => this.addLabels(dashboard))
              : [],
          ),
        ),
      ),
    );
  }

  override isLoading(): Observable<boolean> {
    return this.workbookDashboardListDomainService.loading$;
  }

  override isLoaded(): Observable<boolean> {
    return this.workbookDashboardListDomainService.loaded$;
  }

  override hasError(): Observable<boolean> {
    return this.workbookDashboardListDomainService.errors$.pipe(map(Boolean));
  }

  duplicate(dashboard: DashboardListItem): void {
    this.dashboardDomainService
      .copyDashboard(dashboard.id)
      .pipe(
        take(1),
        tap(() => this.triggerReloadData()),
      )
      .subscribe();
  }

  remove(dashboard: DashboardListItem, eventTarget?: EventTarget): void {
    this.confirmationService.confirm({
      target: eventTarget,
      message: convertMessageToHtml(this.translate.instant('msg.workbook-dashboard-list.delete.confirmation')),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.dashboardDomainService
          .deleteDashboard(dashboard.id)
          .pipe(
            take(1),
            tap(() => this.triggerReloadData()),
          )
          .subscribe();
      },
    });
  }

  addToMainPage(dashboard: DashboardListItem): void {
    this.workbookId$
      .pipe(
        switchMap((workbookId) =>
          this.mainWorkbookDashboardListDomainService.resetMainDashboardCache(workbookId).pipe(map(() => workbookId)),
        ),
        switchMap((workbookId) =>
          this.mainDashboardService.setMainDashboard(dashboard.id).pipe(
            take(1),
            tap(() => this.mainWorkbookDashboardListDomainService.setMainDashboardInCache(workbookId, dashboard.id)),
          ),
        ),
      )
      .subscribe();
  }

  addPinDashboard(dashboard: DashboardListItem, workbookId: string): void {
    this.workbookPinsDomainService
      .addDashboardToPins(workbookId, {
        name: dashboard.name,
        dashboardId: dashboard.id,
      })
      .pipe(take(1))
      .subscribe();
  }

  deletePinDashboard(dashboard: DashboardListItem, workbookId: string): void {
    this.workbookPinsDomainService.deleteDashboardFromPins(workbookId, dashboard.id).pipe(take(1)).subscribe();
  }

  getColumns(): TableColumn<DashboardListItem>[] {
    return [
      {
        labelTranslate: 'msg.workbook-dashboard-list.table-header.name',
        fieldCode: 'name',
        fieldType: 'text',
        classStyle: 'no-breaks short-text',
        width: 'calc(100% - 45rem)',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'msg.workbook-dashboard-list.table-header.author',
        fieldCode: 'author',
        fieldType: 'text',
        classStyle: 'no-breaks short-text',
        width: '9rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'msg.workbook-dashboard-list.table-header.created',
        fieldCode: 'create',
        fieldType: 'date',
        classStyle: 'no-breaks short-text',
        width: '10rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'msg.workbook-dashboard-list.table-header.updated',
        fieldCode: 'modified',
        fieldType: 'date',
        classStyle: 'no-breaks short-text',
        width: '10rem',
        resizable: true,
        sortable: true,
      },
    ];
  }

  private addIcon(dashboard: DashboardListItem): DashboardListItem {
    return {
      ...dashboard,
      icon: 'pi pi-chart-bar text-primary',
    };
  }

  private addLabels(dashboard: DashboardListItem): DashboardListItem & Pick<DataListViewItem, 'labels'> {
    if (dashboard.main) {
      return {
        ...dashboard,
        labels: [
          {
            icon: 'pi pi-home text-gray-800',
            tooltipTranslate: 'msg.workbook-dashboard-list.labels.main',
          },
        ],
      };
    }

    return dashboard;
  }

  private addRouterLink(
    dashboard: DashboardListItem,
    workbookId: string,
  ): DashboardListItem & Pick<DataListViewItem, 'routerLinkToItem'> {
    return {
      ...dashboard,
      routerLinkToItem: {
        routerLink: ['/', BI_ROOT_ROUTE, 'workbook', workbookId],
        queryParams: { dashboardId: dashboard.id },
      },
    };
  }
}
