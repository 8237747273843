import { Component, Input, OnInit } from '@angular/core';

import { getChartIconClass } from './get-chart-icon';

@Component({
    selector: 'selfai-bi-chart-icon',
    templateUrl: './chart-icon.component.html',
    styleUrls: ['./chart-icon.component.scss'],
    standalone: false
})
export class ChartIconComponent implements OnInit {
  @Input()
  chartType: string | undefined;

  classIcon: string = '';

  ngOnInit() {
    this.classIcon = getChartIconClass(this.chartType);
  }
}
