@if (columns) {
  <p-table
    class="table"
    dataKey="controls.id.value"
    [resizableColumns]="true"
    #tableRef
    responsiveLayout="scroll"
    [value]="items"
    editMode="row"
  >
    <ng-template pTemplate="header">
      <tr>
        @for (column of columns; track $index) {
          <th pResizableColumn>{{ column.name }}</th>
        }
        <th class="w-10rem"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-editing="editing" let-index="rowIndex">
      <tr [pEditableRow]="item">
        @for (column of columns; track $index) {
          <td
            [pEditableColumn]="item[column.name]"
            [pEditableColumnField]="column.name"
            style="width: {{ 100 / columns.length }}%"
          >
            <p-cellEditor>
              <ng-template pTemplate="input">
                @switch (column.type) {
                  @case ('boolean') {
                    <p-inputSwitch
                      [ngModel]="item[column.name]"
                      (ngModelChange)="onChange(index, column.name, $event)"
                      [falseValue]="false"
                      [trueValue]="true"
                    ></p-inputSwitch>
                  }
                  @default {
                    <input
                      pInputText
                      [type]="column.type"
                      [ngModel]="item[column.name]"
                      (ngModelChange)="onChange(index, column.name, $event)"
                    />
                  }
                }
              </ng-template>
              <ng-template pTemplate="output">
                {{ item[column.name] }}
              </ng-template>
            </p-cellEditor>
          </td>
        }

        <td>
          <button
            type="button"
            pButton
            class="p-button-text p-button-link p-button-danger p-0"
            appearance="icon"
            (click)="removeItem(index)"
            icon="pi pi-trash"
            pTooltip="{{ 'workflow.cubes.create-data.actions.remove' | translate }}"
          ></button>

          <button
            type="button"
            pButton
            class="p-button-text p-button-link p-0"
            appearance="icon"
            (click)="copyItem(index)"
            icon="pi pi-copy"
            pTooltip="{{ 'workflow.cubes.create-data.actions.copy' | translate }}"
          ></button>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="4">{{ 'workflow.cubes.create-data.no-data' | translate }}</td>
      </tr>
    </ng-template>
  </p-table>

  <button
    label="{{ 'workflow.cubes.create-data.actions.add-row' | translate }}"
    pButton
    pRipple
    icon="pi pi-plus"
    type="button"
    class="mr-3 mt-3 p-button-sm"
    (click)="addItem()"
  ></button>
} @else {
  <p-message text="{{ 'workflow.cubes.create-data.no-columns' | translate }}" severity="warning"></p-message>
}
