import { Component, ElementRef, Injector, OnDestroy, OnInit } from '@angular/core';

import * as _ from 'lodash';

import { AbstractComponent } from '../../common/component/abstract.component';

@Component({
    selector: 'app-page-loading',
    templateUrl: 'page-loading.component.html',
    styleUrls: ['./page-loading.component.scss'],
    standalone: false
})
export class PageLoadingComponent extends AbstractComponent implements OnInit, OnDestroy {
  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
