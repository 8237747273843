import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import * as _ from 'lodash';

import { Dashboard, setDataSourceAndRelations } from '@selfai-platform/bi-domain';

import { AbstractPopupComponent } from '../../../common/component/abstract-popup.component';
import { Modal } from '../../../common/domain/modal';
import { EventBroadcaster } from '../../../common/event/event.broadcaster';
import { CommonUtil } from '../../../common/util/common.util';
import { CreateBoardDsNetworkComponent } from '../create-dashboard/create-board-ds-network.component';

@Component({
    selector: 'app-update-datasource',
    templateUrl: './update-datasource.component.html',
    standalone: false
})
export class UpdateDatasourceComponent extends AbstractPopupComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(CreateBoardDsNetworkComponent, { static: false })
  public networkBoardComp: CreateBoardDsNetworkComponent;

  @Input()
  public workbookId: string;

  @Input()
  public workbookName: string;

  @Input()
  public workspaceId: string;

  @Input()
  public sourceDashboard: Dashboard;

  @Output()
  public done: EventEmitter<Dashboard> = new EventEmitter();

  public isDenyDone = false;

  public dashboard: Dashboard;

  constructor(protected elementRef: ElementRef, protected injector: Injector, protected broadCaster: EventBroadcaster) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.useUnloadConfirm = true;
  }

  public ngOnChanges(changes: SimpleChanges) {
    const dashboardChanges: SimpleChange = changes.sourceDashboard;
    if (dashboardChanges.firstChange) {
      this.dashboard = _.cloneDeep(dashboardChanges.currentValue);
    }
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public save() {
    if (this.networkBoardComp.isInvalidate()) {
      return;
    }

    const modal = new Modal();
    modal.name = this.translateService.instant('msg.board.update-datasource.confirm.title');
    modal.description = this.translateService.instant('msg.board.update-datasource.confirm.desc');
    modal.afterConfirm = () => {
      const data = this.networkBoardComp.getData();
      data.boardDataSources.forEach((ds) => {
        if (ds.joins && ds.joins.length > 0) {
          ds.joins.forEach((join) => {
            const joinedSource = data.candidateDs.find((candidateDs) => candidateDs.engineName === join.engineName);
            if (joinedSource && !this.dashboard.dataSources.find((ds) => ds.id === joinedSource.id)) {
              this.dashboard.dataSources.push(joinedSource);
            }
          });
        }
      });

      this.dashboard = setDataSourceAndRelations(this.dashboard, data.boardDataSources, data.relations);
      this.dashboard.configuration.dataSource = this.dashboard.dataSource;
      this.dashboard.dataSources = data.dataSources.map((ds) => {
        ds.valid = true;
        return ds;
      });
      this.done.emit(this.dashboard);
      this.close();
    };
    CommonUtil.confirm(modal);
  }

  public closeComp(isForceClose: boolean = false) {
    if (isForceClose) {
      this.close();
    } else {
      this.unloadConfirmSvc.confirm().subscribe((isClose) => {
        isClose && this.close();
      });
    }
  }

  public checkAllowDone(data: { isDenyNext?: boolean; isShowButtons?: boolean }) {
    if ('isDenyNext' in data) {
      this.isDenyDone = data.isDenyNext;
    }
    this.safelyDetectChanges();
  }
}
