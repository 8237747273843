import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { Table, TableLazyLoadEvent } from 'primeng/table';
import { Observable, of } from 'rxjs';

import { Book } from '@selfai-platform/bi-domain';

// should be remade the same as in libs/bi-ui/src/lib/workbook/components/workbook-list/workbook-list-table/workbook-list-table.component.ts
@Component({
    selector: 'selfai-platform-bi-ui-workbook-list-selector',
    templateUrl: './workbook-list-selector.component.html',
    styleUrls: ['./workbook-list-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WorkbookListSelectorComponent implements OnInit {
  @Output() selectWorkbook = new EventEmitter<Book>();
  @Input() textButton: string;

  workbookList$: Observable<Book[]>;
  loading$: Observable<boolean>;
  pageSize = 10;
  selectedWorkbook: Book;

  get totalRecords(): number {
    return 10;
  }

  @ViewChild('dt', { read: Table }) dataTable: Table;

  ngOnInit(): void {
    this.workbookList$ = this.getWorkbookList();

    this.loading$ = of(false);
  }

  getWorkbookList(): Observable<Book[]> {
    return of([]);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  loadWorkbookList(event: TableLazyLoadEvent): void {}

  onSelectWorkbook(item: Book): void {
    this.selectWorkbook.emit(item);
  }
}
