import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PluralTranslateKeyPipe } from '@selfai-platform/shared';
import { DataListViewComponent, DataListViewTemplateNameDirective } from '@selfai-platform/shell';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { SkeletonModule } from 'primeng/skeleton';
import { TooltipModule } from 'primeng/tooltip';
import { WorkspaceListComponent } from './workspace-list.component';

@NgModule({
  imports: [
    CommonModule,
    DataListViewComponent,
    DataListViewTemplateNameDirective,
    SkeletonModule,
    TooltipModule,
    PluralTranslateKeyPipe,
    TranslateModule,
    ConfirmPopupModule,
    RouterModule.forChild([
      {
        path: '',
        component: WorkspaceListComponent,
      },
    ]),
  ],
  declarations: [WorkspaceListComponent],
  exports: [WorkspaceListComponent],
})
export class WorkspaceListComponentModule {}
