import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { Modal } from '../../../domain/modal';
import { AbstractComponent } from '../../abstract.component';

@Component({
    selector: 'app-confirm-small-modal',
    templateUrl: './confirm-small.component.html',
    standalone: false
})
export class ConfirmSmallComponent extends AbstractComponent implements OnInit, OnDestroy {
  @ViewChild('doneElm')
  private _doneElm: ElementRef;

  public isShow = false;

  public modal: Modal;

  @Output() public confirm = new EventEmitter();

  @Input()
  public useCancelBtn = false;

  @Input()
  public useCloseBtn = true;

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public init(modal: Modal) {
    this.isShow = true;
    this.modal = modal;
    if (!this.useCancelBtn) {
      setTimeout(() => {
        this._doneElm.nativeElement.focus();
      }, 0);
    }
  }

  public done() {
    this.isShow = false;
    this.confirm.emit(this.modal.data);
  }

  public close() {
    this.isShow = false;
  }
}
