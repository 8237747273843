import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogService } from '@selfai-platform/shell';
import { Observable } from 'rxjs';
import { HazelCastService } from '../../services';

interface FormHazelCast {
  hzName: FormControl<string>;
  hzKey: FormControl<string>;
}

@Component({
    selector: 'selfai-platform-aggregator-hazel-cast-monitor',
    templateUrl: './aggregator-hazel-cast-monitor.component.html',
    styleUrls: ['./aggregator-hazel-cast-monitor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AggregatorHazelCastMonitorComponent implements OnInit {
  form = new FormGroup<FormHazelCast>({
    hzName: new FormControl('', Validators.required) as FormControl<string>,
    hzKey: new FormControl('', Validators.required) as FormControl<string>,
  });

  resultJson$!: Observable<Record<string, unknown>>;
  loading$!: Observable<boolean>;
  error$!: Observable<boolean>;

  constructor(protected readonly dialogService: DialogService, private readonly hazelCastService: HazelCastService) {}

  ngOnInit(): void {
    this.resultJson$ = this.hazelCastService.getHazelCast();
    this.loading$ = this.hazelCastService.getLoading();
    this.error$ = this.hazelCastService.getError();
  }

  closeDialog(): void {
    this.dialogService.close();
  }

  submit(): void {
    if (this.form.valid) {
      const { hzName, hzKey } = this.form.value as { hzName: string; hzKey: string };
      this.hazelCastService.loadHazelCast(hzName, hzKey);
    }
  }
}
