import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { isNullOrUndefined } from '@selfai-platform/shared';


@Component({
    selector: 'component-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    standalone: false
})
export class InputComponent implements OnInit, OnDestroy {
  @ViewChild('inputElm')
  private _inputElm: ElementRef;

  @ViewChild('styleElm')
  private _styleElm: ElementRef;

  @Input() public compType: 'default' | 'apply' | 'search' = 'default';

  @Input() public value: number | string;

  @Input() public valueType: 'string' | 'number' = 'string';

  @Input() public maxLen = 0;

  @Input() public placeHolder = '';

  @Input() public disabled = false;

  @Input() public immediately = false;

  @Input() public isTrim = true;

  @Input() public showClear = true;
  @Input() public isEnableDelete: boolean;

  @Input() public inputClass = '';

  @Input() public autoFocus = true;

  @Input() public optionalClass = '';

  @Input() public optionalStyle = '';

  @Input() public beforeChangeValue: Function;

  @Output('changeValue') public changeEvent: EventEmitter<number | string> = new EventEmitter();

  @Output('pressEnter') public pressEnterEvent: EventEmitter<boolean> = new EventEmitter();

  @Output('inputFocus') public inputFocusEvent: EventEmitter<boolean> = new EventEmitter();

  @Output('inputBlur') public inputBlurEvent: EventEmitter<boolean> = new EventEmitter();

  constructor(protected elementRef: ElementRef, protected changeDetect: ChangeDetectorRef) {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges) {
    const valueChanges: SimpleChange = changes.value;
    const disabledChanges: SimpleChange = changes.disabled;
    if (this._inputElm) {
      if (valueChanges && !isNullOrUndefined(valueChanges.currentValue)) {
        this._inputElm.nativeElement.value = valueChanges.currentValue;
      }

      if (disabledChanges && !isNullOrUndefined(disabledChanges.currentValue)) {
        this._inputElm.nativeElement.disabled = disabledChanges.currentValue;
      }
    }
  }

  public ngAfterViewInit() {
    const inputNativeElm = this._inputElm.nativeElement;
    inputNativeElm.disabled = this.disabled;
    inputNativeElm.value = this.value;
    if (0 < this.maxLen) {
      inputNativeElm.maxLength = this.maxLen;
    }
    if (isNullOrUndefined(this.value)) {
      inputNativeElm.value = '';
    }

    if ('' !== this.optionalStyle) {
      this._styleElm.nativeElement.setAttribute('style', this.optionalStyle);
    }
    if ('' === this.inputClass) {
      switch (this.compType) {
        case 'apply':
          this.inputClass = 'ddp-input-txt';
          break;
        case 'default':
          this.inputClass = 'ddp-data-input';
          break;
      }
    }

    this._safelyDetectChanges();

    this.autoFocus &&
      setTimeout(() => {
        inputNativeElm.focus();
      }, 400);
  }

  public ngOnDestroy() {}

  protected keyupHandler(event: KeyboardEvent) {
    if (this.immediately || 13 === event.keyCode) {
      this.setValue();
      if (13 === event.keyCode) {
        this.pressEnterEvent.emit(true);
      }
    } else if (27 === event.keyCode) {
      this.resetValue();
    }
  }

  protected focusHandler() {
    this.inputFocusEvent.emit();
  }

  protected blurHandler() {
    this.inputBlurEvent.emit();
    this.setValue();
  }

  protected setValue() {
    let inputValue = this._inputElm.nativeElement.value;
    inputValue = inputValue ? (this.isTrim ? inputValue.trim() : inputValue) : '';
    if (inputValue !== this.value) {
      if (
        (isNullOrUndefined(this.beforeChangeValue) || this.beforeChangeValue(inputValue)) &&
        ('string' === this.valueType || ('number' === this.valueType && !isNaN(Number(inputValue))))
      ) {
        this.value = inputValue;
        this.changeEvent.emit(this.value);
      } else {
        this._inputElm.nativeElement.value = this.value;
      }
    }
  }

  protected resetValue() {
    this._inputElm.nativeElement.value = this.value;
  }

  protected clearValue() {
    this._inputElm.nativeElement.value = '';
    this.value = '';
    this.changeEvent.emit(this.value);
  }

  protected isNotEmptyInput(): boolean {
    if (this._inputElm) {
      return '' !== this._inputElm.nativeElement.value;
    } else {
      return false;
    }
  }

  private _safelyDetectChanges() {
    if (!this.changeDetect['destroyed']) {
      this.changeDetect.detectChanges();
    }
  }
}
