import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'selfai-platform-spoiler',
    imports: [CommonModule],
    templateUrl: './spoiler.component.html',
    styleUrls: ['./spoiler.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpoilerComponent {
  @Input() text: string;
  @Input() length: number;

  open = false;
}
