import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { DataSourcesVisibilityEnum } from '../../../enums/data-sources-visibility.enum';

@Component({
    selector: 'selfai-platform-data-source-visibility',
    imports: [ReactiveFormsModule, CheckboxModule],
    templateUrl: './data-source-visibility.component.html',
    styleUrl: './data-source-visibility.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataSourceVisibilityComponent {
  @Input({ required: true, alias: 'control' }) formControl: FormControl;

  visibilityEnum = DataSourcesVisibilityEnum;
}
