import { Pipe, PipeTransform } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

@Pipe({
  name: 'countdown',
  standalone: true,
})
export class CountdownPipe implements PipeTransform {
  transform(value: number): Observable<number> {
    return timer(0, 1000).pipe(
      map((elapsed) => value - elapsed),
      takeWhile((remaining) => remaining >= 0),
    );
  }
}
