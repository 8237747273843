<ng-container>
  <ng-container *ngIf="dataSources.length === formControlsLength; else loading">
    <ng-container *ngFor="let dataSource of dataSources">
      <div class="grid align-items-center mb-3">
        <div class="col-6">{{ dataSource.name }}</div>
        <div class="col-6">
          <p-autoComplete
            styleClass="w-full"
            class="{{ datasourceControl.invalid ? 'ng-invalid ng-dirty' : '' }}"
            [ngModel]="datasourceMap[dataSource.id]"
            (ngModelChange)="onDatasourceChange($event, dataSource.id, dataSource.engineName)"
            [dropdown]="true"
            [suggestions]="filteredDatasource"
            (completeMethod)="filterDatasource($event)"
            [required]="true"
            field="name"
            appendTo="body"
            #datasourceControl="ngModel"
          ></p-autoComplete>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div class="grid align-items-center mb-3">
    <ng-container *ngFor="let i of [].constructor(dataSources.length)">
      <div class="col-6">
        <p-skeleton height="2rem"></p-skeleton>
      </div>
      <div class="col-6">
        <p-skeleton height="2rem"></p-skeleton>
      </div>
    </ng-container>
  </div>
</ng-template>
