import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { isNil } from 'lodash';

@Component({
    selector: 'selfai-bi-custom-field-icon',
    templateUrl: './custom-field-icon.component.html',
    styleUrls: ['./custom-field-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CustomFieldIconComponent implements OnChanges {
  iconClass = 'ddp-icon-type-ab';

  @Input() fieldType: string | undefined;
  @Input() logicalType: string | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    const fieldType = changes['fieldType'];
    const logicalType = changes['logicalType'];
    const iconName = this.findNameForIcon(fieldType.currentValue, logicalType.currentValue);
    this.iconClass = this.getFieldIconClass(iconName);
  }

  private findNameForIcon(type?: string, logicalType?: string): string | null {
    if (type === 'USER_DEFINED' || type === 'TEXT') {
      return 'STRING';
    }
    if (type === 'LONG' || type === 'INTEGER' || type === 'DOUBLE' || type === 'CALCULATED') {
      return 'LONG';
    }
    if (!isNil(logicalType)) {
      return logicalType;
    }
    return null;
  }

  private getFieldIconClass(name: string): string {
    const fieldIconClasses = [
      { name: 'STRING', class: 'ddp-icon-type-ab' },
      { name: 'TIMESTAMP', class: 'ddp-icon-type-calen' },
      { name: 'LONG', class: 'ddp-icon-type-sharp' },
      { name: 'LNG', class: 'ddp-icon-type-longitude' },
      { name: 'LNT', class: 'ddp-icon-type-latitude' },
      { name: 'GEO_POINT', class: 'ddp-icon-type-point' },
      { name: 'GEO_LINE', class: 'ddp-icon-type-line' },
      { name: 'GEO_POLYGON', class: 'ddp-icon-type-polygon' },
    ];

    if (isNil(name)) {
      return 'ddp-icon-type-ab';
    }

    const idx = fieldIconClasses.findIndex((item) => {
      return item.name === name;
    });

    return idx !== -1 ? fieldIconClasses[idx].class : 'ddp-icon-type-ab';
  }
}
