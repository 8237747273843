/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import cloneDeep from 'lodash/cloneDeep';
import { fromEvent } from 'rxjs';
import { debounceTime, map, take, takeUntil } from 'rxjs/operators';

import {
  AxisLabelType,
  AxisType,
  BaseOption,
  BrushType,
  CHART_STRING_DELIMITER,
  ChartAxisLabelType,
  ChartColorList,
  ChartColorType,
  ChartMouseMode,
  ChartSelectMode,
  ChartType,
  ColorCustomMode,
  ColorGradationRange,
  ColorRange,
  ColorRangeType,
  createChartSelectInfo,
  DatasourceField,
  DataZoomRangeType,
  DataZoomType,
  EventType,
  Pivot,
  PivotField,
  PivotTableInfo,
  PredictionAnalysis,
  Shelf,
  ShelveFieldType,
  ShelveType,
  UIChartAxisGrid,
  UIChartColor,
  UIChartColorByDimension,
  UIChartColorBySeries,
  UIChartColorByValue,
  UIChartColorGradationByValue,
  UiChartDataLabelDisplayType,
  UIChartZoom,
  UIOption,
  UIScatterChart,
  ChartPivotType,
} from '@selfai-platform/bi-domain';
import { DestroyService } from '@selfai-platform/shared';
import {
  DataZoomComponentOption,
  LegendComponentOption,
  SeriesOption,
  XAXisComponentOption,
  YAXisComponentOption,
} from 'echarts';
import { EChartsType } from 'echarts/core';
import _, { merge } from 'lodash';
import {
  AxisOptionConverter,
  ColorOptionConverter,
  CommonOptionConverter,
  FormatOptionConverter,
  LabelOptionConverter,
  LegendOptionConverter,
  ToolOptionConverter,
  TooltipOptionConverter,
} from '../converters';
import { ChartDataService, ChartLegendService } from '../services';
import { ChartConfigService } from '../services/chart-config.service';
import { EChartService } from '../services/echart.service';
import { OptionGenerator } from '../utils';
import UI = OptionGenerator.UI;

@Directive()
export abstract class BaseChart implements OnInit, OnDestroy, AfterViewInit {
  protected readonly chartDataService: ChartDataService = this.injector.get(ChartDataService);
  protected readonly chartConfigService: ChartConfigService = this.injector.get(ChartConfigService);
  protected readonly chartLegendService: ChartLegendService = this.injector.get(ChartLegendService);

  chart: EChartsType;

  uiOption: UIOption & { isChangeStyle?: boolean };
  chartOption: BaseOption;
  defaultZoomRange: UIChartZoom[];

  @Input()
  isPage = false;

  @Input()
  resizeDelay = 0;

  @Input()
  isUpdateRedraw = true;

  @Input()
  userCustomFunction: any;

  @Input() widgetId: string;

  analysis: PredictionAnalysis | null = null;

  protected data: any;
  protected originalData: any;
  protected pivot: Pivot;
  protected shelf: Shelf;
  protected originPivot: Pivot;
  protected originShelf: Shelf;
  protected saveInfo: UIOption;
  protected fieldInfo: PivotTableInfo;
  protected fieldOriginInfo: PivotTableInfo;
  protected pivotInfo: PivotTableInfo;
  protected params: any;
  protected convertInfo: object;
  protected isSelected: boolean;
  protected existTimeField: boolean;

  protected mouseMode: ChartMouseMode = ChartMouseMode.SINGLE;
  protected brushType: BrushType = BrushType.RECT;

  @Output()
  protected chartSelectInfo = new EventEmitter();

  @Output()
  protected noData = new EventEmitter();

  @Output()
  protected drawFinished = new EventEmitter();

  protected drawByType: EventType | null;
  protected lastDrawSeries: SeriesOption[];
  protected widgetDrawParam: any;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('uiOption')
  set setUIOption(value: UIOption & { isChangeStyle?: boolean }) {
    this.uiOption = value;

    if (this.uiOption['isChangeStyle']) {
      this.draw(false);

      return;
    }

    this.setDataInfo();

    if (this.chart && this.data) {
      this.draw(true);
    }

    this.drawByType = null;
  }

  @Input()
  set resultData(result: any) {
    result = cloneDeep(result);

    if (!result || !result.data) {
      return;
    }

    // TODO: this should set in page-widget.component
    this.chartConfigService.setConfig(result.config);
    // TODO remove this.pivot and this.shelf when removed from other charts
    this.pivot = this.chartConfigService.getConfig().pivot;
    this.shelf = this.chartConfigService.getConfig().shelf;

    if (result.data?.totalFeatures > 0) {
      this.originalData = _.cloneDeep(result.data);
      this.data = this.chartDataService.mapApiData(result.data);

      this.saveInfo = result.uiOption;
      if (result.params) {
        this.params = result.params;
      }
      if (result.type) {
        this.drawByType = result.type;
      }

      this.setDataInfo();

      this.setFieldInfo();

      if (this.chart) {
        this.draw();
      }

      this.drawByType = null;

      return;
    }

    if (this.chartDataService.hasNoData(result.data)) {
      // TODO: this should be in template of parent component
      this.noData.emit();

      return;
    }

    this.originPivot = cloneDeep(this.chartConfigService.getConfig().pivot);
    if (!this.originShelf) this.originShelf = cloneDeep(this.chartConfigService.getConfig().shelf);
    this.originalData = _.cloneDeep(result.data);
    this.widgetDrawParam = _.cloneDeep(result.params);

    if (this.uiOption.yAxis !== undefined && this.uiOption.yAxis.label?.type === ChartAxisLabelType.VALUE) {
      if (
        this.uiOption.yAxis.baseline !== undefined &&
        !isNaN(<number>this.uiOption.yAxis.baseline) &&
        this.uiOption.yAxis.baseline != 0
      ) {
        this.calculateBaseline(<number>this.uiOption.yAxis.baseline, result, true);
      }

      if (this.uiOption.yAxis.grid !== undefined && !this.uiOption.yAxis.grid.autoScaled) {
        this.calculateMinMax(this.uiOption.yAxis.grid, result, true);
      }
    }

    if (this.uiOption.xAxis !== undefined && this.uiOption.xAxis.label?.type === ChartAxisLabelType.VALUE) {
      if (
        this.uiOption.xAxis.baseline !== undefined &&
        !isNaN(<number>this.uiOption.xAxis.baseline) &&
        this.uiOption.xAxis.baseline != 0
      ) {
        this.calculateBaseline(<number>this.uiOption.xAxis.baseline, result, false);
      }

      if (this.uiOption.xAxis.grid !== undefined && !this.uiOption.xAxis.grid.autoScaled) {
        this.calculateMinMax(this.uiOption.xAxis.grid, result, false);
      }
    }

    this.data = this.chartDataService.mapApiData(result.data);

    this.saveInfo = result.uiOption;
    if (result.params) {
      this.params = result.params;
    }
    if (result.type) {
      this.drawByType = result.type;
    }

    this.setDataInfo();

    this.setFieldInfo();

    this.setPivotInfo();

    if (this.chart || this.uiOption.type === ChartType.GRID) {
      this.draw();
    }

    this.drawByType = null;
  }

  protected calculateMinMax(grid: UIChartAxisGrid, result: any, isYAsis: boolean): void {
    if (grid.autoScaled) {
      if (result.data.categories && result.data.categories.length > 0) {
        let min: number | null = null;
        let max: number | null = null;
        result.data.categories.forEach((category) => {
          category.value.forEach((value) => {
            if (min == null || value < min) {
              min = value;
            }
            if (max == null || value > max) {
              max = value;
            }
          });
        });

        if (typeof min === 'number' && typeof max === 'number') {
          grid.min = min > 0 ? Math.ceil(min - (max - min) * 0.05) : min;
          grid.max = max;
        }
      } else {
        grid.min =
          result.data.info.minValue > 0
            ? Math.ceil(result.data.info.minValue - (result.data.info.maxValue - result.data.info.minValue) * 0.05)
            : result.data.info.minValue;
        grid.max = result.data.info.maxValue;
      }
    }

    if ((grid.min === undefined || grid.min == 0) && (grid.max === undefined || grid.max == 0)) {
      return;
    }

    const seriesList: any[] = [];
    result.data.columns.forEach((column: any) => {
      const nameArr = column.name.split(CHART_STRING_DELIMITER);
      let name = '';
      if (nameArr.length > 1) {
        nameArr.forEach((temp, index: number) => {
          if (index < nameArr.length - 1) {
            if (index > 0) {
              name += CHART_STRING_DELIMITER;
            }
            name += temp;
          }
        });
      } else {
        name = nameArr[0];
      }

      if (!seriesList.includes(name)) {
        seriesList.push(name);
      }
    });

    if (!result.data.categories || result.data.categories.length == 0) {
      result.data.columns.map((column: any, index: number) => {
        column.value.map((value: any, index: number) => {
          if (typeof grid.min === 'number' && value < grid.min) {
            column.value[index] = grid.min;
          } else if (typeof grid.max === 'number' && value > grid.max) {
            column.value[index] = grid.max;
          }
        });
      });
    } else {
      result.data.categories.forEach((category, categoryindex: number) => {
        const totalValue: any[] = [];
        const seriesValue: any[] = [];
        result.data.columns.forEach((column: any) => {
          if (column.name.indexOf(category.name) == -1) {
            return;
          }

          column.value.forEach((value: any, index: number) => {
            if (totalValue[index] === undefined || isNaN(totalValue[index])) {
              totalValue[index] = 0;
              seriesValue[index] = 0;
            }

            if (typeof grid.max === 'number' && totalValue[index] > grid.max) {
              column.value[index] = 0;
            } else if (typeof grid.max === 'number' && totalValue[index] + value > grid.max) {
              if (seriesValue[index] <= 0) {
                column.value[index] = grid.max;
              } else {
                column.value[index] = <number>grid.max - totalValue[index];
              }
            } else if (typeof grid.min === 'number' && totalValue[index] + value < grid.min) {
              column.value[index] = 0;
            } else if (
              typeof grid.min === 'number' &&
              totalValue[index] < grid.min &&
              totalValue[index] + value > grid.min
            ) {
              column.value[index] = totalValue[index] + value;
            } else {
              column.value[index] = value;
            }
            seriesValue[index] += column.value[index];
            totalValue[index] += value;
          });
        });

        totalValue.forEach((value: any, valueindex: number) => {
          if (typeof grid.min === 'number' && value < grid.min) {
            result.data.columns.forEach((column: any) => {
              column.value.forEach((value: any, index: number) => {
                if (index == valueindex) {
                  column.value[index] = 0;
                }
              });
            });
          }
        });
      });
    }
  }

  protected calculateBaseline(baseline: number, result: any, isYAsis: boolean): void {
    const seriesList: any[] = [];
    result.data.columns.map((column: any, index: number) => {
      const nameArr = column.name.split(CHART_STRING_DELIMITER);
      let name = '';
      if (nameArr.length > 1) {
        nameArr.map((temp, index: number) => {
          if (index < nameArr.length - 1) {
            if (index > 0) {
              name += CHART_STRING_DELIMITER;
            }
            name += temp;
          }
        });
      } else {
        name = nameArr[0];
      }

      let isAlready = false;
      seriesList.map((series, index: number) => {
        if (series == name) {
          isAlready = true;

          return false;
        }
      });

      if (!isAlready) {
        seriesList.push(name);
      }
    });

    if (!result.data.categories || result.data.categories.length == 0) {
      result.data.columns.map((column: any, index: number) => {
        column.value.map((value: any, index: number) => {
          if (value > 0) {
            column.value[index] = value - baseline;
          } else {
            column.value[index] = (Math.abs(value) + Math.abs(baseline)) * -1;
          }
        });
      });
    } else {
      const categoryVal: any[] = [];
      const categoryPer: any[] = [];
      for (let num = 0; num < result.data.categories.length; num++) {
        const category = result.data.categories[num];
        for (let num2 = 0; num2 < category.value.length; num2++) {
          const value = category.value[num2];
          const index = num * category.value.length + num2;
          const baselineGap = Math.abs(value - baseline);
          const baselinePer = baselineGap / Math.abs(value);
          categoryVal[index] = value;
          categoryPer[index] = baselinePer;
        }
      }

      result.data.columns.map((column: any, index: number) => {
        column.value.map((value: any, index: number) => {
          if (categoryVal[index] < baseline) {
            column.value[index] = Math.abs(value) * categoryPer[index] * -1;
          } else {
            column.value[index] = Math.abs(value) * categoryPer[index];
          }
        });
      });
    }
  }

  protected setUIData(): any {
    const addAllValues = (columns: any, type: any): any => {
      const list: any[] = [];
      columns.forEach((item: any) => {
        if (!item[type]) return;
        item[type].forEach((value: any, index: number) => {
          list[index] = (!list[index] ? 0 : list[index]) + value;
        });
      });

      return list;
    };

    const copyOfData = JSON.parse(JSON.stringify(this.data));
    const copyOfOriginalData = JSON.parse(JSON.stringify(this.originalData));

    _.each(copyOfData.columns, (data, index: number) => {
      data.categoryName = copyOfData.rows;

      data.categoryValue = [];
      data.categoryPercent = [];

      if (!copyOfData.categories || (copyOfData.categories && copyOfData.categories.length == 0)) {
        data.categoryValue = addAllValues(copyOfOriginalData.columns, 'value');
        data.categoryPercent = addAllValues(copyOfData.columns, 'percentage');
        data.seriesName = copyOfData.rows;
      } else {
        if (copyOfData.categories) {
          for (const category of copyOfData.categories) {
            data.categoryValue = category.value;
            data.categoryPercent = category.percentage;
          }
        }

        data.seriesName = data.name.split(CHART_STRING_DELIMITER)[0];
      }

      data.seriesValue = copyOfOriginalData.columns[index].value;
      data.seriesPercent = _.cloneDeep(data.percentage);
    });
    this.data = copyOfData;

    return this.data.columns;
  }

  constructor(
    protected readonly elementRef: ElementRef<HTMLElement>,
    protected readonly destroy$: DestroyService,
    protected readonly eChartService: EChartService,
    protected readonly injector: Injector,
  ) {}

  ngOnInit() {
    const resizeEvent$ = fromEvent(window, 'resize').pipe(
      map(() => document.documentElement.clientWidth + 'x' + document.documentElement.clientHeight),
      debounceTime(500),
    );

    resizeEvent$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      try {
        if (this.chart && this.chart.resize) {
          this.chart.resize();
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    });
  }

  ngOnDestroy(): void {
    this.chart?.dispose?.();
    this.clear();
  }

  ngAfterViewInit(): void {
    //
    this.eChartService
      .initChart(this.widgetId, this.elementRef.nativeElement)
      .pipe(take(1))
      .subscribe((eChartInstance) => {
        this.chart = eChartInstance;
      });

    if (this.data) {
      this.draw();
    }
  }

  clear(): void {
    this.chart?.clear?.();
  }

  draw(isKeepRange?: boolean): void {
    if (!this.chartConfigService.isValid()) {
      // TODO: this should be in template of parent component
      this.noData.emit();

      return;
    }

    this.chartOption = this.initOption();

    this.chartOption = this.convertBasic();

    this.chartOption = this.convertDataInfo();

    this.chartOption = this.convertXAxis();

    this.chartOption = this.convertYAxis();

    this.chartOption = this.convertSeries() as BaseOption;

    this.chartOption = this.convertTooltip();

    this.chartOption = this.convertDataZoom(isKeepRange);

    this.chartOption = this.convertLegend();

    this.chartOption = this.convertGrid();

    this.chartOption = this.convertEtc();

    this.chartOption = this.convertSelectionData();

    this.apply();

    this.defaultZoomRange = this.saveDataZoomRange();

    this.drawFinish();

    if (!this.isPage) {
      this.selection();
    }

    this.datazoom();
  }

  protected initOption(): BaseOption {
    throw new Error('initOption is not Override');
  }

  protected convertBasic(): BaseOption {
    this.chartOption = this.additionalBasic();

    return this.chartOption;
  }

  protected additionalBasic(): BaseOption {
    return this.chartOption;
  }

  protected convertXAxis(): BaseOption {
    if (!this.uiOption.xAxis) return this.chartOption;

    this.chartOption = AxisOptionConverter.convertAxisDefault(this.chartOption, this.uiOption, AxisType.X);

    this.chartOption = this.convertXAxisData();

    this.chartOption = CommonOptionConverter.convertCommonAxis(
      this.chartOption,
      this.uiOption,
      AxisType.X,
      this.fieldInfo,
    );

    this.chartOption = AxisOptionConverter.convertAxis(this.chartOption, this.uiOption, AxisType.X, this.data);

    this.chartOption = this.additionalXAxis();

    return this.chartOption;
  }

  protected convertXAxisData(): BaseOption {
    const xAxisName = this.uiOption.xAxis?.customName
      ? this.uiOption.xAxis.customName
      : _.join(this.fieldInfo.cols, CHART_STRING_DELIMITER);
    if (this.chartOption.xAxis && this.chartOption.xAxis[0]) {
      this.chartOption.xAxis[0].name = xAxisName;
      this.chartOption.xAxis[0].axisName = _.join(this.fieldInfo.cols, CHART_STRING_DELIMITER);
      this.chartOption.xAxis[0].type = this.uiOption.xAxis.type || this.uiOption.xAxis.label.type;
      this.chartOption.xAxis[0].logBase = this.uiOption.xAxis.logBase;

      if (_.eq(this.chartOption.xAxis[0].type, AxisType.CATEGORY)) {
        this.chartOption.xAxis[0].data = this.data.rows;
      }
    }

    return this.chartOption;
  }

  protected additionalXAxis(): BaseOption {
    return this.chartOption;
  }

  protected convertYAxis(): BaseOption {
    if (!this.uiOption.yAxis) return this.chartOption;

    this.chartOption = AxisOptionConverter.convertAxisDefault(this.chartOption, this.uiOption, AxisType.Y);

    this.chartOption = this.convertYAxisData();

    this.chartOption = CommonOptionConverter.convertCommonAxis(
      this.chartOption,
      this.uiOption,
      AxisType.Y,
      this.fieldInfo,
    );

    this.chartOption = AxisOptionConverter.convertAxis(this.chartOption, this.uiOption, AxisType.Y, this.data);

    this.chartOption = this.additionalYAxis();

    return this.chartOption;
  }

  protected convertYAxisData(): BaseOption {
    const yAxisName = this.uiOption.yAxis?.customName
      ? this.uiOption.yAxis.customName
      : _.join(this.fieldInfo.aggs, CHART_STRING_DELIMITER);
    if (this.chartOption.yAxis?.[0] && this.uiOption.yAxis) {
      this.chartOption.yAxis[0].name = yAxisName;
      this.chartOption.yAxis[0].axisName = _.join(this.fieldInfo.aggs, CHART_STRING_DELIMITER);
      this.chartOption.yAxis[0].type = this.uiOption.yAxis.type || this.uiOption.yAxis.label.type;
      this.chartOption.yAxis[0].logBase = this.uiOption.yAxis.logBase;

      if (!_.eq(this.chartOption.xAxis?.[0].type, AxisType.CATEGORY)) {
        this.chartOption.yAxis[0].data = this.data.rows;
      }
    }

    return this.chartOption;
  }

  protected additionalYAxis(): BaseOption {
    return this.chartOption;
  }

  protected convertGrid(): BaseOption {
    this.chartOption = ToolOptionConverter.convertGrid(this.chartOption, this.uiOption);

    this.chartOption = this.additionalGrid();

    return this.chartOption;
  }

  protected additionalGrid(): BaseOption {
    return this.chartOption;
  }

  protected convertSeries(): BaseOption | undefined {
    this.chartOption = this.convertSeriesData();

    this.chartOption = ColorOptionConverter.convertColor(
      this.chartOption,
      this.uiOption,
      this.fieldOriginInfo,
      this.fieldInfo,
      this.pivotInfo,
      this.drawByType || undefined,
      undefined,
      this.data,
    );

    this.chartOption = FormatOptionConverter.convertFormatSeries(
      this.chartOption,
      this.uiOption,
      this.chartConfigService.getConfig().pivot,
    );

    this.chartOption = LabelOptionConverter.convertLabel(this.chartOption, this.uiOption);

    this.chartOption = CommonOptionConverter.convertCommonSeries(this.chartOption, this.uiOption, this.fieldInfo);

    this.chartOption = this.additionalSeries();

    return this.chartOption;
  }

  protected convertSeriesData(): BaseOption {
    throw new Error('convertSeriesData is not Override');
  }

  protected additionalSeries(): BaseOption {
    return this.chartOption;
  }

  protected convertTooltip(): BaseOption {
    this.chartOption = FormatOptionConverter.convertFormatTooltip(
      this.chartOption,
      this.uiOption,
      this.fieldOriginInfo,
      this.chartConfigService.getConfig().pivot,
    );

    this.chartOption = this.additionalTooltip();

    return this.chartOption;
  }

  protected additionalTooltip(): BaseOption {
    return this.chartOption;
  }

  protected convertDataInfo(): BaseOption {
    this.chartOption['dataInfo'] = {
      minValue: this.data.info.minValue,
      maxValue: this.data.info.maxValue,
    };

    this.chartOption = this.additionalDataInfo();

    return this.chartOption;
  }

  protected additionalDataInfo(): BaseOption {
    return this.chartOption;
  }

  protected convertEtc(): BaseOption {
    this.chartOption = CommonOptionConverter.convertCommonFont(this.chartOption, this.uiOption);

    this.chartOption = this.additionalEtc();

    return this.chartOption;
  }

  protected additionalEtc(): BaseOption {
    return this.chartOption;
  }

  protected apply(initFl = true): void {
    if ((this.isUpdateRedraw && initFl) || this.params.externalFilters) {
      this.chart?.dispose();
      this.eChartService
        .initChart(this.widgetId, this.elementRef.nativeElement)
        .pipe(take(1))
        .subscribe((eChartInstance) => {
          this.chart = eChartInstance;
        });
    }

    if (this.userCustomFunction && '' !== this.userCustomFunction && -1 < this.userCustomFunction.indexOf('main')) {
      const strScript = '(' + this.userCustomFunction + ')';
      try {
        // eslint-disable-next-line no-eval
        this.chartOption = eval(strScript)({ name: 'InitWidgetEvent', data: this.chartOption });
      } catch (e) {
        console.error(e);
      }
    }

    this.chart.setOption(this.chartOption, false, false);
  }

  protected convertDataZoom(isKeepRange?: boolean): BaseOption {
    if (this.uiOption.chartZooms && this.uiOption.chartZooms[0].auto) {
      if (this.chart && isKeepRange) {
        (this.chart.getOption()['dataZoom'] as DataZoomComponentOption[]).map(
          (obj: { start: number; end: number; startValue: number; endValue: number }, idx: number) => {
            if (this.chartOption.dataZoom) {
              this.chartOption.dataZoom[idx].start = obj.start;
              this.chartOption.dataZoom[idx].end = obj.end;
              this.chartOption.dataZoom[idx].startValue = obj.startValue;
              this.chartOption.dataZoom[idx].endValue = obj.endValue;
            }
          },
        );
      }

      this.chartOption = this.convertDataZoomRange(this.chartOption, this.uiOption);

      this.chartOption = ToolOptionConverter.convertDataZoom(this.chartOption, this.uiOption);

      this.chartOption = this.additionalDataZoom();
    } else {
      delete this.chartOption.dataZoom;
    }

    return this.chartOption;
  }

  protected additionalDataZoom(): BaseOption {
    if (this.uiOption.chartZooms && _.isUndefined(this.uiOption.chartZooms[0].start)) {
      this.convertDataZoomAutoRange(this.chartOption, 20, 500, 10, this.existTimeField);
    }

    return this.chartOption;
  }

  protected convertLegend(): BaseOption {
    if (!this.chartOption.legend) {
      return this.chartOption;
    }

    this.chartLegendService.setLegendOptions(this.fieldInfo, this.pivotInfo, this.fieldOriginInfo);
    if (this.uiOption.color?.type == ChartColorType.SERIES) {
      (this.chartOption.legend as LegendComponentOption).data = this.fieldInfo.aggs;

      const schema = (<UIChartColorBySeries>this.uiOption.color).schema;
      const colorCodes = _.cloneDeep(ChartColorList[schema as keyof typeof ChartColorList]);

      const colorMapping = (<UIChartColorBySeries>this.uiOption.color).mapping;

      if (colorMapping) {
        Object.values(colorMapping).forEach((value: string, index: number) => {
          colorCodes[index] = value;
        });
      }
      this.chartOption.legend = merge(this.chartOption.legend as LegendComponentOption, {
        lineStyle: { color: colorCodes[0] },
      });
    } else {
      if (this.chartOption.legend) {
        let legendData: string[] = [];

        let fieldIdx: number;

        let pivotType: ChartPivotType | null = null;

        _.forEach(this.fieldOriginInfo, (value: any, key) => {
          const color = this.uiOption.color as UIChartColorByDimension;
          if (_.indexOf(value, color.targetField) > -1) {
            fieldIdx = _.indexOf(value, color.targetField);
            pivotType = _.eq(key, ChartPivotType.COLS)
              ? ChartPivotType.COLS
              : _.eq(key, ChartPivotType.ROWS)
                ? ChartPivotType.ROWS
                : ChartPivotType.AGGS;
          }
        });

        if (pivotType && this.fieldInfo[pivotType as ChartPivotType].length > 1) {
          legendData = this.pivotInfo[pivotType as keyof PivotTableInfo].map((value) => {
            return !_.split(value, CHART_STRING_DELIMITER)[fieldIdx]
              ? value
              : _.split(value, CHART_STRING_DELIMITER)[fieldIdx];
          });

          legendData = _.uniq(legendData);
        } else if (pivotType) {
          legendData = this.pivotInfo[pivotType as keyof PivotTableInfo];
        }

        (this.chartOption.legend as LegendComponentOption).data = legendData;
        this.chartOption.legend = merge(this.chartOption.legend as LegendComponentOption, {
          lineStyle: {
            color:
              ChartColorList[(this.uiOption.color as UIChartColorByDimension).schema as keyof typeof ChartColorList],
          },
        });
      }
    }


    this.chartOption = LegendOptionConverter.convertLegend(this.chartOption, this.uiOption);
    this.chartOption = this.additionalLegend();

    return this.chartOption;
  }

  protected additionalLegend(): BaseOption {
    return this.chartOption;
  }

  protected drawFinish(): void {
    if (Array.isArray(this.chartOption.series) && this.chartOption.series.length) {
      if (!_.isUndefined(this.chart)) {
        setTimeout(() => {
          if (this.chart) {
            this.chart.resize();
            this.drawFinished.emit();
          }
        });
      }
    } else {
      this.drawFinished.emit();
    }
  }

  protected selection(): void {}

  protected datazoom(): void {
    this.addChartDatazoomEventListener();
  }

  protected setFieldInfo(): void {
    const shelve: Pivot = this.chartConfigService.getConfig().pivot;

    this.existTimeField = false;
    const setFieldName = (item: any, shelveFieldType?: ShelveFieldType): string | undefined => {
      if (!shelveFieldType || (shelveFieldType && item.type === shelveFieldType)) {
        const fieldName = !_.isEmpty(item.alias) ? item.alias : item.name;
        if (_.eq(item.type, ShelveFieldType.TIMESTAMP)) this.existTimeField = true;

        return fieldName;
      }

      return undefined;
    };

    const setOriginFieldName = (item: any, shelveFieldType?: ShelveFieldType): string | undefined => {
      if (!shelveFieldType || (shelveFieldType && item.type === shelveFieldType)) {
        if (_.eq(item.type, ShelveFieldType.TIMESTAMP)) this.existTimeField = true;

        return item.name;
      }

      return undefined;
    };

    const cols: string[] = shelve.columns.map((column: any) => {
      return setFieldName(column);
    });
    const rows: string[] = shelve.rows.map((row: any) => {
      return setFieldName(row);
    });
    const aggs = shelve.aggregations
      .map((aggregation: any) => {
        return setFieldName(aggregation, ShelveFieldType.MEASURE);
      })
      .filter((item?: string) => {
        return typeof item !== 'undefined';
      });

    this.fieldInfo = { cols, rows, aggs };

    const originCols: string[] = shelve.columns.map((column: any) => {
      return setOriginFieldName(column);
    });
    const originRows: string[] = shelve.rows.map((row: any) => {
      return setOriginFieldName(row);
    });
    const originAggs = shelve.aggregations
      .map((aggregation: any) => {
        return setOriginFieldName(aggregation);
      })
      .filter((item?: string) => {
        return typeof item !== 'undefined';
      });

    this.fieldOriginInfo = { cols: originCols, rows: originRows, aggs: originAggs };
  }

  protected setPivotInfo(): void {
    const cols: string[] = this.data.rows;
    const rows: string[] = [];

    if (this.data.columns) {
      this.data.columns.map((column: any) => {
        const seriesName: string = column.name;

        const rowNameList = _.split(seriesName, CHART_STRING_DELIMITER);
        if (rowNameList.length > 1) {
          rows.push(_.join(_.dropRight(rowNameList), CHART_STRING_DELIMITER));
        }
      });
    }

    this.pivotInfo = { cols, rows, aggs: this.fieldInfo.aggs };
  }

  protected getFieldTypeCount(pivot: Pivot, shelveType: ShelveType, fieldType: ShelveFieldType) {
    return (
      pivot &&
      pivot[shelveType]?.filter((field) => {
        if (field.type === ShelveFieldType.DIMENSION && field.format && field.format.unit) {
          field.type = ShelveFieldType.TIMESTAMP;
        }

        return field.type === fieldType;
      }).length
    );
  }

  private getShelveReturnString = (shelve: Pivot, typeList: ShelveFieldType[]): string[] => {
    const resultList: string[] = [];
    Object.values(shelve).forEach((value: PivotField[]) => {
      value.forEach((item: PivotField) => {
        if (typeList.includes(item.type as ShelveFieldType)) {
          resultList.push(item.name);
        }
      });
    });
    return resultList;
  };

  private updateTargetField(uiOption: UIOption): void {
    const targetField = (<UIChartColorByDimension>uiOption.color).targetField;

    if (targetField !== undefined && targetField.length > 0) {
      if (uiOption.fieldList.indexOf(targetField) < 0)
        (<UIChartColorByDimension>uiOption.color).targetField =
          uiOption.fieldList[uiOption.fieldList.length - 1];
    } else {
      (<UIChartColorByDimension>uiOption.color).targetField =
        uiOption.fieldList[uiOption.fieldList.length - 1];
    }
  }

  protected setDimensionList(): UIOption {
    const shelve: Pivot = this.chartConfigService.getConfig().pivot;

    if (shelve && this.uiOption.type !== ChartType.GRID) {
      this.uiOption.fieldList = this.getShelveReturnString(shelve, [ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP]);

      if (this.uiOption.color) {
        this.updateTargetField(this.uiOption);
      }
    }

    return this.uiOption;
  }

  protected setMeasureList(): UIOption {
    const shelve: Pivot = this.chartConfigService.getConfig().pivot;

    const getShelveReturnField = (shelve: Pivot, typeList: ShelveFieldType[]): DatasourceField[] => {
      const resultList: DatasourceField[] = [];
      Object.entries(shelve).forEach(([key, value]) => {
        value.forEach((item: any) => {
          if (
            (item.type === typeList[0] || item.type === typeList[1]) &&
            item.field &&
            ('user_expr' === item.field.type ||
              (item.field.logicalType && item.field.logicalType.indexOf('GEO') === -1))
          ) {
            resultList.push(item);
          }
        });
      });

      return resultList;
    };

    if (shelve) {
      this.uiOption.fieldMeasureList = getShelveReturnField(shelve, [
        ShelveFieldType.MEASURE,
        ShelveFieldType.CALCULATED,
      ]);
    }

    if (shelve) {
      this.uiOption.fielDimensionList = getShelveReturnField(shelve, [
        ShelveFieldType.DIMENSION,
        ShelveFieldType.TIMESTAMP,
      ]);
    }

    if (shelve && this.uiOption.type === ChartType.GRID) {
      this.uiOption.fieldList = this.getShelveReturnString(shelve, [ShelveFieldType.MEASURE, ShelveFieldType.TIMESTAMP]);

      if (this.uiOption.color) {
        this.updateTargetField(this.uiOption);
      }
    }

    return this.uiOption;
  }

  protected setAxisNameInfo(setNameFl = true): void {
    const xAxis = _.cloneDeep(
      _.compact(_.concat(this.uiOption.xAxis, this.uiOption.yAxis, this.uiOption.secondaryAxis)).filter((item) => {
        return _.eq(item.mode, AxisLabelType.ROW) || _.eq(item.mode, AxisLabelType.SUBROW);
      }),
    );
    const yAxis = _.cloneDeep(
      _.compact(_.concat(this.uiOption.xAxis, this.uiOption.yAxis, this.uiOption.secondaryAxis)).filter((item) => {
        return _.eq(item.mode, AxisLabelType.COLUMN) || _.eq(item.mode, AxisLabelType.SUBCOLUMN);
      }),
    );

    if (setNameFl) {
      (this.chartOption.xAxis as XAXisComponentOption[])?.forEach((axis, idx) => {
        xAxis[idx].name = axis.name;
      });

      (this.chartOption.yAxis as YAXisComponentOption[])?.forEach((axis, idx) => {
        yAxis[idx].name = axis.name;
      });
    }
  }

  protected setDataInfo(): void {
    if (this.uiOption && this.data && this.data.info) {
      this.uiOption.maxValue = this.data.info.maxValue;
      this.uiOption.minValue = this.data.info.minValue;
    }

    if (!this.uiOption) return;

    this.uiOption = this.setDataLabel();

    this.uiOption = this.setDimensionList();

    this.uiOption = this.setMeasureList();

    if (!_.isEmpty(this.drawByType) && this.uiOption.color && (<UIChartColorByValue>this.uiOption.color).customMode) {
      let colorList: string[] = [];
      const colrObj: UIChartColorGradationByValue = this.uiOption.color;
      switch (colrObj.customMode) {
        case ColorCustomMode.SECTION:
          colorList = (colrObj.ranges || [])
            .map((item) => item.color)
            .filter(Boolean)
            .reverse() as string[];
          (this.uiOption.color as UIChartColorGradationByValue).ranges = ColorOptionConverter.setMeasureColorRange(
            this.uiOption,
            this.data,
            colorList,
          );
          break;
        case ColorCustomMode.GRADIENT:
          {
            if (colrObj.ranges) {
              const prevMaxVal: number = colrObj.ranges[colrObj.ranges.length - 1]['value'] as number;
              const currMaxVal: number = this.uiOption.maxValue as number;
              const resetRange = (item: ColorGradationRange) => {
                if (item.value) {
                  if (item.value < prevMaxVal) {
                    item.value = Math.round(currMaxVal * (item.value / prevMaxVal));
                  } else {
                    item.value = currMaxVal;
                  }
                }

                return item;
              };
              (this.uiOption.color as UIChartColorGradationByValue).ranges = colrObj['ranges'].map((item) =>
                resetRange(item),
              );
              (this.uiOption.color as UIChartColorGradationByValue).visualGradations = colrObj.visualGradations?.map(
                (item) => resetRange(item),
              );
            }
          }
          break;
        default:
          delete (<UIChartColorByValue>this.uiOption.color).ranges;
          delete (<UIChartColorGradationByValue>this.uiOption.color).visualGradations;
          delete (<UIChartColorByValue>this.uiOption.color).customMode;
          colorList =
            ChartColorList[(this.uiOption.color as UIChartColorGradationByValue).schema as keyof typeof ChartColorList];
          (this.uiOption.color as UIChartColorGradationByValue).ranges = ColorOptionConverter.setMeasureColorRange(
            this.uiOption,
            this.data,
            colorList,
          );
      }
    }

    let prevMappingList: { alias: string; color: string }[] = [];

    const mappingArray = (this.uiOption.color as UIChartColorByDimension)?.mappingArray;
    if (mappingArray) {
      prevMappingList = mappingArray;
    }

    this.uiOption.color = this.setMapping();

    const mapping = (this.uiOption.color as UIChartColorByDimension)?.mapping;

    if (0 < prevMappingList.length) {
      const currColorMapObj = mapping;
      const currColorMapList = mappingArray;
      prevMappingList.forEach((prev) => {
        currColorMapList?.some((curr) => {
          if (curr.alias === prev.alias) {
            curr.color = prev.color;

            return true;
          }

          return false;
        });

        let prevColor = currColorMapObj?.[prev.alias as keyof typeof currColorMapObj];

        if (prevColor) {
          prevColor = prev.color;
        }
      });
    }
  }

  protected selectionClear(option: BaseOption): BaseOption {
    const series = option.series as SeriesOption[];

    this.chartConfigService.clearColumnsData();
    this.chartConfigService.clearAggregationsData();

    series?.forEach((obj) => {
      (obj.data as any[])?.forEach((item) => {
        this.clearSelectSeriesData(item);
      });
    });

    return option;
  }

  protected selectSeriesData(seriesData: any) {
    if (seriesData.itemStyle) {
      seriesData.itemStyle.opacity = 1;
      seriesData.selected = true;
    }
  }

  protected unselectSeriesData(seriesData: any) {
    if (seriesData.itemStyle) {
      seriesData.itemStyle.opacity = 0.2;
      seriesData.selected = false;
    }
  }

  protected clearSelectSeriesData(seriesData: any) {
    if (seriesData.itemStyle) {
      seriesData.itemStyle.opacity = 1;
      seriesData.selected = false;
    }
  }

  protected selectionAdd(option: BaseOption, targetData: any, isMulti = false): BaseOption {
    const series = option.series as SeriesOption[];

    const selectSameSeries = (seriesData: any) => {
      series?.forEach((seriesItem) => {
        (seriesItem.data as any[])?.some((dataItem) => {
          if (dataItem.name === seriesData.name) {
            this.selectSeriesData(dataItem);

            return true;
          }

          return false;
        });
      });
    };
    const unselectSameSeries = (seriesData: any) => {
      series?.forEach((seriesItem) => {
        (seriesItem.data as any[])?.some((dataItem) => {
          if (dataItem.name === seriesData.name) {
            this.unselectSeriesData(dataItem);

            return true;
          }

          return false;
        });
      });
    };

    if (isMulti) {
      let selectedIndexs: number[] = targetData.reduce((acc: any, val: any) => acc.concat(val.dataindex), []);
      selectedIndexs = _.uniq(selectedIndexs);
      (series?.[0].data as any[])?.forEach((dataItem, idx) => {
        if (-1 < selectedIndexs.indexOf(idx)) {
          selectSameSeries(dataItem);
        } else {
          unselectSameSeries(dataItem);
        }
      });
    } else {
      series?.forEach((sObj) => {
        (sObj.data as any[])?.forEach((item) => {
          if (isMulti || !item.selected) {
            unselectSameSeries(item);
          }
        });
      });

      selectSameSeries(targetData);
    }

    return option;
  }

  protected selectionSubstract(option: BaseOption, targetData: any): BaseOption {
    const series = option.series as SeriesOption[];

    const unselectSameSeries = (seriesData: any) => {
      series?.forEach((seriesItem) => {
        (seriesItem.data as any[])?.some((dataItem) => {
          if (dataItem.name === seriesData.name) {
            this.unselectSeriesData(dataItem);

            return true;
          }

          return false;
        });
      });
    };
    unselectSameSeries(targetData);

    let isSelected = false;
    series?.some((sItem) => {
      if ((sItem.data as any[])?.some((dItem) => dItem.selected)) {
        isSelected = true;

        return;
      } else {
        return false;
      }
    });
    if (!isSelected) {
      option = this.selectionClear(option);
    }

    return option;
  }

  protected setSelectData(params: any, colValues: string[], rowValues: string[]): any[] {
    const returnDataList: any[] = [];

    const pivot = this.chartConfigService.getConfig().pivot;

    let targetValues: string[] = [];
    Object.entries(pivot).forEach(([key, value]) => {
      const deepCopyShelve: PivotField[] = cloneDeep(value);

      deepCopyShelve
        .filter((field: PivotField) => {
          return field.type === ShelveFieldType.DIMENSION || field.type === ShelveFieldType.TIMESTAMP;
        })
        .forEach((field: PivotField, idx: number) => {
          if (params !== null) {
            targetValues = key === ShelveType.ROWS ? rowValues : colValues;
          }

          if (targetValues.length !== 0 && targetValues[idx]) {
            if (!returnDataList.find((element) => element.name === field.name)) {
              returnDataList.push(field);
            }
            returnDataList[returnDataList.length - 1].data = [targetValues[idx]];
          }
        });
    });

    return returnDataList;
  }

  protected setMapping(): UIChartColor {
    if (
      !this.uiOption.color ||
      ChartColorType.SERIES !== this.uiOption.color.type ||
      !this.uiOption.fieldMeasureList ||
      this.uiOption.fieldMeasureList.length == 0
    )
      return this.uiOption.color as UIChartColor;

    if (!(<UIChartColorBySeries>this.uiOption.color).mapping || EventType.CHANGE_PIVOT == this.drawByType) {
      (<UIChartColorBySeries>this.uiOption.color).mapping = {} as { alias: string; color: string };
    }

    if ((<UIChartColorBySeries>this.uiOption.color).schema) {
      let colorChangedFl = false;
      const mapping = (<UIChartColorBySeries>this.uiOption.color).mapping as { alias: string; color: string };

      for (const key in (<UIChartColorBySeries>this.uiOption.color).mapping) {
        const index = _.findIndex(this.uiOption.fieldMeasureList, { alias: key });

        if (-1 == index || colorChangedFl) {
          delete mapping[key as keyof typeof mapping];
          colorChangedFl = true;
        }
      }

      this.uiOption.fieldMeasureList.forEach((item, index: number) => {
        if ((<UIChartColorBySeries>this.uiOption.color).schema && !mapping[item.alias as keyof typeof mapping]) {
          mapping[item.alias as keyof typeof mapping] =
            ChartColorList[(<UIChartColorBySeries>this.uiOption.color).schema as keyof typeof ChartColorList][index];
        }
      });

      (<UIChartColorBySeries>this.uiOption.color).mappingArray = [];

      Object.keys(mapping).forEach((key) => {
        (<UIChartColorBySeries>this.uiOption.color).mappingArray?.push({
          alias: key,
          color: mapping[key as keyof typeof mapping],
        });
      });
    }

    return this.uiOption.color;
  }

  protected setMeasureColorRange(schema: any): ColorRange[] {
    const rangeList = [];

    const colorList = ChartColorList[schema as keyof typeof ChartColorList];

    let rowsListLength = this.data.rows.length;

    switch (this.uiOption.type) {
      case ChartType.GRID:
        // eslint-disable-next-line no-case-declarations
        let gridRowsListLength = 0;

        if (this.data.rows.length > 0 && !_.isEmpty(this.data.rows[0])) {
          gridRowsListLength += this.data.rows.length;
        }

        if (this.data.columns.length > 0 && -1 !== this.data.columns[0].name.indexOf(CHART_STRING_DELIMITER)) {
          gridRowsListLength += this.data.columns.length;
        } else {
          gridRowsListLength += this.data.columns[0].value.length;
        }

        rowsListLength = gridRowsListLength;
        break;

      case ChartType.PIE:
      case ChartType.WORDCLOUD:
        rowsListLength = this.data.columns[0].value.length;
        break;
      case ChartType.HEATMAP:
        rowsListLength = this.data.columns.length;
        break;
      case ChartType.TREEMAP:
        rowsListLength = colorList.length;
        break;
    }

    const colorListLength = colorList.length > rowsListLength ? rowsListLength - 1 : colorList.length - 1;

    const addValue = ((this.uiOption.maxValue as number) - (this.uiOption.minValue as number)) / colorListLength;

    let maxValue: number = this.uiOption.maxValue as number;

    let shape;
    if ((<UIScatterChart>this.uiOption).pointShape) {
      shape = (<UIScatterChart>this.uiOption).pointShape.toString().toLowerCase();
    }

    for (let index = colorListLength; index >= 0; index--) {
      const color = colorList[index];

      if (colorListLength == index) {
        rangeList.push(
          UI.Range.colorRange(
            ColorRangeType.SECTION,
            color,
            parseFloat(maxValue.toFixed(1)),
            undefined,
            parseFloat(maxValue.toFixed(1)),
            undefined,
            shape,
          ),
        );
      } else {
        let min = 0 == index ? undefined : parseFloat((maxValue - addValue).toFixed(1));

        if (min !== undefined && min < (this.uiOption.minValue as number) && min < 0)
          min = parseInt((this.uiOption.minValue as number).toFixed(1));

        rangeList.push(
          UI.Range.colorRange(
            ColorRangeType.SECTION,
            color,
            min,
            parseFloat(maxValue.toFixed(1)),
            min,
            parseFloat(maxValue.toFixed(1)),
            shape,
          ),
        );

        if (min !== undefined) {
          maxValue = min;
        }
      }
    }

    return rangeList;
  }

  isValid(pivot: Pivot, shelf?: Shelf): boolean {
    throw new Error('isValid is not Override');
  }

  saveDataZoomRange(): UIChartZoom[] {
    const resultList: UIChartZoom[] = [];
    if (!this.chart) {
      return [];
    }
    if (!_.isEmpty(this.chart['_chartsViews'])) {
      (this.chart.getOption()['dataZoom'] as any[]).map((obj: any, idx: number) => {
        if (_.eq(obj.type, DataZoomType.SLIDER)) {
          resultList.push({
            auto: obj.show,
            start: obj.start,
            end: obj.end,
            startValue: obj.startValue,
            endValue: obj.endValue,
            orient: obj.orient.toUpperCase(),
          });
        }
      });
    }

    return resultList;
  }

  convertMouseMode(type: ChartMouseMode, brushType?: BrushType): void {
    this.mouseMode = type;
    let start;
    let end;
    switch (type) {
      case ChartMouseMode.SINGLE:
        this.unsetBrush();
        this.unsetMultipleBrush();
        break;
      case ChartMouseMode.MULTI:
        this.brushType = brushType as BrushType;
        this.setBrush(brushType);
        this.setMultipleBrush();
        break;
      case ChartMouseMode.DRAGZOOMIN:
        this.toggleSelectZoom();
        break;
      case ChartMouseMode.ZOOMIN:
        (this.chart.getOption()['dataZoom'] as any[]).map((dataZoom: any, idx: number) => {
          if (_.eq(dataZoom.type, DataZoomType.SLIDER)) {
            start = dataZoom.start + 10;
            start = start > 50 ? 50 : start;
            end = dataZoom.end - 10;
            end = end < 50 ? 50 : end;
            this.chart.dispatchAction({
              start,
              end,
              type: 'dataZoom',
              dataZoomIndex: idx,
            });
          }
        });
        break;
      case ChartMouseMode.ZOOMOUT:
        (this.chart.getOption()['dataZoom'] as any[]).map((dataZoom: any, idx: number) => {
          if (_.eq(dataZoom.type, DataZoomType.SLIDER)) {
            start = dataZoom.start - 10;
            start = start < 0 ? 0 : start;
            end = dataZoom.end + 10;
            end = end > 100 ? 100 : end;
            this.chart.dispatchAction({
              start,
              end,
              type: 'dataZoom',
              dataZoomIndex: idx,
            });
          }
        });
        break;
      case ChartMouseMode.REVERT:
        // eslint-disable-next-line no-case-declarations
        const defaultZooms = this.defaultZoomRange;
        (this.chart.getOption()['dataZoom'] as any[]).map((dataZoom: any, idx: number) => {
          if (_.eq(dataZoom.type, DataZoomType.SLIDER)) {
            start = defaultZooms[idx].start || 0;
            end = defaultZooms[idx].end || 100;
            this.chart.dispatchAction({
              start,
              end,
              type: 'dataZoom',
              dataZoomIndex: idx,
            });
          }
        });
        break;
      default:
        // eslint-disable-next-line no-restricted-syntax
        console.info(type);
    }
    this.mouseMode = type;
  }

  addChartDatazoomEventListener(): void {}

  addChartSelectEventListener(): void {
    this.chart.off('click');
    this.chart.on('click', (params: any) => {
      if (params && 'series' !== params.componentType) {
        return;
      }

      if (this.userCustomFunction && '' !== this.userCustomFunction && -1 < this.userCustomFunction.indexOf('main')) {
        const strScript = '(' + this.userCustomFunction + ')';
        try {
          // eslint-disable-next-line no-eval
          if (eval(strScript)({ name: 'SelectionEvent', data: params ? params.name : '' })) {
            return;
          }
        } catch (e) {
          console.error(e);
        }
      }

      let selectMode: ChartSelectMode;
      let selectedColValues: string[] | null = null;
      let selectedRowValues: string[] | null = null;

      const series = this.chartOption.series;
      if (_.isNull(params)) {
        selectMode = ChartSelectMode.CLEAR;
        this.chartOption = this.selectionClear(this.chartOption);
      } else if (params != null) {
        if (ChartType.WATERFALL === this.uiOption.type && params && params.seriesIndex === 0) {
          return;
        }

        const targetData = series?.[params.seriesIndex].data?.[params.dataIndex];
        const isSelected = targetData.selected;

        if (isSelected) {
          selectMode = ChartSelectMode.SUBTRACT;
          this.chartOption = this.selectionSubstract(this.chartOption, targetData);
        } else {
          selectMode = ChartSelectMode.ADD;
          this.chartOption = this.selectionAdd(this.chartOption, targetData, false);
        }

        switch (this.uiOption.type) {
          case ChartType.BOXPLOT:
            selectedColValues = _.split(params.name, CHART_STRING_DELIMITER);
            selectedRowValues = [];
            break;
          case ChartType.GAUGE:
            selectedRowValues = [_.split(params.data.name, CHART_STRING_DELIMITER)[1]];
            selectedColValues = selectedRowValues ? [] : null;
            break;
          case ChartType.HEATMAP:
            selectedColValues = [_.split(params.data.name, CHART_STRING_DELIMITER)[0]];
            selectedRowValues = [_.split(params.data.name, CHART_STRING_DELIMITER)[1]];
            break;
          default:
            selectedColValues = _.split(params.name, CHART_STRING_DELIMITER);
            selectedRowValues = _.dropRight(_.split(params.seriesName, CHART_STRING_DELIMITER));
            break;
        }
      } else {
        return;
      }

      if (this.params.externalFilters) this.params.externalFilters = true;
      const selectData = this.setSelectData(params, selectedColValues || [], selectedRowValues || []);

      this.apply(false);
      this.lastDrawSeries = _.cloneDeep(this.chartOption['series']) as SeriesOption[];

      this.params['selectType'] = 'SINGLE';
      this.chartSelectInfo.emit(createChartSelectInfo(selectMode, selectData, this.params));
    });
  }

  addChartMultiSelectEventListener(): void {
    this.chart.off('brushDragEnd');
    this.chart.on('brushDragEnd', (params: any) => {
      let selectDataList: any[] = [];

      const selectedBrushData: any = params.brushSelectData[0].selected;

      if (!selectedBrushData.some((item: any) => item.dataIndex && item.dataIndex.length > 0)) {
        this.clearBrush();

        return;
      }

      this.clearBrush();

      this.chartOption = this.selectionAdd(this.chartOption, selectedBrushData, true);

      const cols = this.pivotInfo.cols;
      const rows = this.pivotInfo.rows;
      const aggs = this.pivotInfo.aggs;

      const setData = (
        colIdxList: string[],
        fields: PivotField[],
        shelveData: string[],
        dataAlter?: PivotField[],
        shelveAlterData?: string[],
      ) => {
        const returnList: any[] = [];

        colIdxList.forEach((colIdx) => {
          const dataName: string = (
            shelveData.length > 0
              ? shelveData[colIdx as keyof typeof shelveData]
              : shelveAlterData?.[colIdx as keyof typeof shelveData]
          ) as string;
          dataName.split(CHART_STRING_DELIMITER).map((name, idx) => {
            const fieldItem: PivotField & { data: any[] } = (
              fields.length > 0 ? fields[idx] : dataAlter?.[idx]
            ) as PivotField & { data: any[] };

            if (returnList.findIndex((obj) => obj.name === fieldItem.name) === -1) {
              const resultItem = JSON.parse(JSON.stringify(fieldItem));
              resultItem['data'] = [];
              returnList.push(resultItem);
            }

            returnList[idx].data = Array.from(new Set([...returnList[idx].data, name]));
          });
        });

        return returnList;
      };

      selectedBrushData.forEach((selected: any) => {
        const colIdxList = selected.dataIndex;
        const pivot = this.chartConfigService.getConfig().pivot;

        if (colIdxList && colIdxList.length > 0) {
          selectDataList = Array.from(
            new Set([...selectDataList, ...setData(colIdxList, pivot.columns, cols, pivot.aggregations, aggs)]),
          );

          if (pivot.rows && pivot.rows.length > 0) {
            selectDataList = Array.from(
              new Set([...selectDataList, ...setData([selected.seriesIndex], pivot.rows, rows)]),
            );
          }
        }
      });

      if (this.params.externalFilters) this.params.externalFilters = false;

      this.apply(false);
      this.lastDrawSeries = cloneDeep(this.chartOption['series']) as SeriesOption[];

      this.params['selectType'] = 'MULTI';
      this.chartSelectInfo.emit(createChartSelectInfo(ChartSelectMode.ADD, selectDataList, this.params));
    });
  }

  addLegendSelectEventListener(): void {
    this.chart.off('legendselectchanged');
    this.chart.on('legendselectchanged', (params: any) => {
      // if (!this.chartOption.legend?.seriesSync) {
      //   const series = this.chartOption.series;
      //   const selectedName = params.name;
      //   const isSelected = params.selected[selectedName];
      //   let fieldIdx: number;
      //   let pivotType: any;
      //   if (_.eq(this.uiOption.color?.type, ChartColorType.DIMENSION)) {
      //     const targetField = (<UIChartColorByDimension>this.uiOption.color).targetField;
      //     _.forEach(this.fieldOriginInfo, (value: any, key) => {
      //       if (_.indexOf(value, targetField) > -1) {
      //         fieldIdx = _.indexOf(value, targetField);
      //         pivotType = _.eq(key, ChartPivotType.COLS)
      //           ? ChartPivotType.COLS
      //           : _.eq(key, ChartPivotType.ROWS)
      //           ? ChartPivotType.ROWS
      //           : ChartPivotType.AGGS;
      //       }
      //     });
      //   } else if (_.eq(this.uiOption.color?.type, ChartColorType.SERIES)) {
      //     pivotType = ChartPivotType.AGGS;
      //   }
      //   series?.map((obj) => {
      //     obj.data?.map((valueData, idx) => {
      //       let compareName;
      //       if (_.eq(pivotType, ChartPivotType.COLS)) {
      //         compareName = !_.isUndefined(this.chartOption.xAxis?.[0].data)
      //           ? this.chartOption.xAxis?.[0].data[idx]
      //           : this.chartOption.yAxis?.[0].data?.[idx];
      //       } else {
      //         compareName = obj.name;
      //       }
      //       if (_.eq(pivotType, ChartPivotType.AGGS)) {
      //         fieldIdx = _.findLastIndex(compareName?.split(CHART_STRING_DELIMITER));
      //       }
      //       compareName = _.split(compareName, CHART_STRING_DELIMITER)[fieldIdx];
      //       if (_.eq(compareName, selectedName)) {
      //         if (_.isObject(valueData)) {
      //           const originValue = _.isUndefined(obj.originData?.[idx].value)
      //             ? obj.originData?.[idx]
      //             : obj.originData?.[idx].value;
      //           obj.data[idx].value = isSelected ? originValue : null;
      //         } else {
      //           obj.data[idx] = isSelected ? obj.originData?.[idx] : null;
      //         }
      //       }
      //     });
      //     return obj;
      //   });
      //   this.apply(false);
      // }
    });
  }

  protected convertDataZoomRangeByType(
    option: BaseOption,
    type: DataZoomRangeType,
    start: number,
    end: number,
    idx?: number,
  ): BaseOption {
    if (_.isUndefined(option.dataZoom)) return option;

    const dataZoomIdx = _.isUndefined(idx) ? 0 : idx;

    if (_.eq(type, DataZoomRangeType.COUNT)) {
      option.dataZoom[dataZoomIdx].startValue = start;
      option.dataZoom[dataZoomIdx].endValue = end;
      delete option.dataZoom[dataZoomIdx].start;
      delete option.dataZoom[dataZoomIdx].end;
    } else {
      option.dataZoom[dataZoomIdx].start = start;
      option.dataZoom[dataZoomIdx].end = end;
      delete option.dataZoom[dataZoomIdx].startValue;
      delete option.dataZoom[dataZoomIdx].endValue;
    }

    return option;
  }

  protected convertDataZoomRange(option: BaseOption, uiOption: UIOption): BaseOption {
    const chartZooms = uiOption.chartZooms;

    if (_.isUndefined(chartZooms)) return this.chartOption;

    chartZooms.forEach((zoom, idx) => {
      if (!_.isUndefined(zoom.start) && !_.isUndefined(zoom.end)) {
        option = this.convertDataZoomRangeByType(option, DataZoomRangeType.PERCENT, zoom.start, zoom.end, idx);
      }
    });

    return option;
  }

  protected convertDataZoomAutoRange(
    option: BaseOption,
    count: number,
    limit: number,
    percent: number,
    isTime: boolean,
    idx?: number,
  ): BaseOption {
    if (_.isUndefined(option.dataZoom)) return option;

    const series = option.series as SeriesOption[];

    const dataZoomIdx = _.isUndefined(idx) ? 0 : idx;

    const colCount =
      (!_.isUndefined(option.xAxis?.[0].data) ? option.xAxis?.[0].data.length : option.yAxis?.[0].data?.length) || 0;

    let startValue = 0;
    let endValue = count - 1;
    const seriesLength = series?.length || 0;

    if (_.gt(colCount, limit)) {
      endValue = seriesLength >= 20 ? 0 : Math.floor(colCount * (percent / 100)) - 1;
    }

    endValue = _.eq(colCount, 1) ? 0 : _.eq(endValue, 0) ? 1 : endValue;

    if (isTime) {
      startValue = colCount - _.cloneDeep(endValue);
      endValue = colCount - 1;
    }

    option.dataZoom[dataZoomIdx].startValue = startValue;
    option.dataZoom[dataZoomIdx].endValue = endValue;
    delete option.dataZoom[dataZoomIdx].start;
    delete option.dataZoom[dataZoomIdx].end;

    (option.dataZoom as DataZoomComponentOption[]).map((obj) => {
      if (_.eq(obj.type, DataZoomType.INSIDE)) {
        obj.startValue = startValue;
        obj.endValue = endValue;
        delete obj.start;
        delete obj.end;
      }
    });

    return option;
  }

  protected setDataLabel(): UIOption {
    return this.uiOption;
  }

  protected convertSelectionData(): BaseOption {
    if (this.widgetDrawParam?.selectFilterListList?.length > 0) {
      _.each(this.chartOption.series, (series: any) => {
        _.each(this.lastDrawSeries, (lastDrawSeries: any) => {
          if (_.eq(series.name, lastDrawSeries.name)) {
            series.itemStyle = lastDrawSeries.itemStyle;
            series.lineStyle = lastDrawSeries.lineStyle;
            series.textStyle = lastDrawSeries.textStyle;
            series.areaStyle = lastDrawSeries.areaStyle;
            series.existSelectData = lastDrawSeries.existSelectData;
            _.each(series.data, (seriesData, index: number) => {
              const lastSeriesData = lastDrawSeries.data[index];
              if (lastSeriesData && isNaN(lastSeriesData)) {
                if (seriesData && isNaN(seriesData)) {
                  seriesData.itemStyle = lastSeriesData.itemStyle;
                  seriesData.lineStyle = lastSeriesData.lineStyle;
                  seriesData.textStyle = lastSeriesData.textStyle;
                  seriesData.areaStyle = lastSeriesData.areaStyle;
                } else {
                  lastSeriesData.value = seriesData;
                  seriesData = lastSeriesData;
                }
                series.data[index] = seriesData;
              }
            });
          }
        });
      });
    }

    return this.chartOption;
  }

  protected setAxisDataLabel(prevPivot: Pivot, checkChangeSeries: boolean): UIOption {
    const pivot = this.chartConfigService.getConfig().pivot;
    if (!pivot || !pivot.aggregations || !pivot.rows) return this.uiOption;

    const spliceSeriesTypeList = (seriesTypeList: any, dataLabel: any): any => {
      const displayTypes = dataLabel.displayTypes ? [...dataLabel.displayTypes] : [];
      let index: number;
      for (const item of seriesTypeList) {
        index = displayTypes.indexOf(item);

        if (-1 !== index) {
          displayTypes[index] = null;
        }
      }

      return displayTypes;
    };

    const setDefaultDisplayTypes = (value: any): any => {
      if (!value || !value.displayTypes) return [];

      let defaultDisplayTypes = [];
      const pivot = this.chartConfigService.getConfig().pivot;

      if (pivot.aggregations.length <= 1 && pivot.rows.length < 1) {
        const disabledList = [
          UiChartDataLabelDisplayType.SERIES_NAME,
          UiChartDataLabelDisplayType.SERIES_VALUE,
          UiChartDataLabelDisplayType.SERIES_PERCENT,
        ];

        defaultDisplayTypes = spliceSeriesTypeList(disabledList, value);

        defaultDisplayTypes[0] = UiChartDataLabelDisplayType.CATEGORY_NAME;
        defaultDisplayTypes[1] = UiChartDataLabelDisplayType.CATEGORY_VALUE;
      } else {
        const disabledList = [UiChartDataLabelDisplayType.CATEGORY_VALUE, UiChartDataLabelDisplayType.CATEGORY_PERCENT];

        defaultDisplayTypes = spliceSeriesTypeList(disabledList, value);

        defaultDisplayTypes[3] = UiChartDataLabelDisplayType.SERIES_NAME;
        defaultDisplayTypes[4] = UiChartDataLabelDisplayType.SERIES_VALUE;
      }

      return defaultDisplayTypes;
    };

    if ((EventType.CHANGE_PIVOT === this.drawByType && checkChangeSeries) || EventType.CHART_TYPE === this.drawByType) {
      const datalabelDisplayTypes = setDefaultDisplayTypes(this.uiOption.dataLabel);

      const tooltipDisplayTypes = setDefaultDisplayTypes(this.uiOption.toolTip);

      if (this.uiOption.dataLabel && this.uiOption.dataLabel.displayTypes) {
        this.uiOption.dataLabel.displayTypes = datalabelDisplayTypes;

        this.uiOption.dataLabel.previewList = LabelOptionConverter.setDataLabelPreviewList(this.uiOption);
      }

      if (this.uiOption.toolTip && this.uiOption.toolTip.displayTypes) {
        this.uiOption.toolTip.displayTypes = tooltipDisplayTypes;

        this.uiOption.toolTip.previewList = TooltipOptionConverter.setTooltipPreviewList(this.uiOption);
      }
    }

    return this.uiOption;
  }

  private unsetBrush(): void {
    this.chart.dispatchAction({
      type: 'takeGlobalCursor',
      key: 'brush',
      brushOption: {
        brushType: false,
        brushMode: 'single',
      },
    });

    this.chart.dispatchAction({ type: 'enableTip' });
  }

  private unsetMultipleBrush(): void {
    this.chart.dispatchAction({
      type: 'takeGlobalCursor',
      key: 'brush',
      brushOption: {
        brushType: this.brushType,
        brushMode: 'single',
      },
    });
  }

  private setBrush(brushType: BrushType): void {
    this.clearBrush();

    this.chart.dispatchAction({ type: 'disableTip' });

    this.chart.dispatchAction({
      type: 'takeGlobalCursor',
      key: 'brush',
      brushOption: {
        brushType: brushType,
        brushMode: 'single',
      },
    });
  }

  private clearBrush(): void {
    this.chart.dispatchAction({
      type: 'axisAreaSelect',
      intervals: [],
    });
    this.chart.dispatchAction({
      type: 'brush',
      command: 'clear',
      areas: [],
    });
  }

  private setMultipleBrush(): void {
    this.clearBrush();

    this.chart.dispatchAction({
      type: 'takeGlobalCursor',
      key: 'brush',
      brushOption: {
        brushType: this.brushType,
        brushMode: 'multiple',
      },
    });
  }

  private toggleSelectZoom(): void {
    // this method use private properties libs/bi/src/lib/echart/echarts.min.js
    // we can't migrate this method
  }
}
