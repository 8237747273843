import { Component, ElementRef, Injector } from '@angular/core';

import * as _ from 'lodash';

import { UIMapOption, UIOption, UIPosition } from '@selfai-platform/bi-domain';

import { BaseOptionComponent } from '../base-option.component';

@Component({
    selector: 'map-legend-option',
    templateUrl: './map-legend-option.component.html',
    standalone: false
})
export class MapLegendOptionComponent extends BaseOptionComponent {
  public legendPositionList: Object[] = [
    {
      name: this.translateService.instant('msg.page.ui.legend.legend.position.right.bottom'),
      value: UIPosition.RIGHT_BOTTOM,
    },
    {
      name: this.translateService.instant('msg.page.ui.legend.legend.position.right.top'),
      value: UIPosition.RIGHT_TOP,
    },
    {
      name: this.translateService.instant('msg.page.ui.legend.legend.position.left.bottom'),
      value: UIPosition.LEFT_BOTTOM,
    },
    { name: this.translateService.instant('msg.page.ui.legend.legend.position.left.top'), value: UIPosition.LEFT_TOP },
  ];

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public changeLegendPos(legend: any): void {
    this.uiOption.legend.pos = legend['value'];

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { legend: this.uiOption.legend });

    this.update();
  }

  public toggleLegend(): void {
    this.uiOption.legend.showName = !this.uiOption.legend.showName;

    this.uiOption = <UIMapOption>_.extend({}, this.uiOption, { legend: this.uiOption.legend });

    this.update();
  }

  public getLegendPosIndex(): number {
    const pos = this.uiOption.legend.pos;

    let index = 0;

    if (!pos) return 0;

    _.each(this.legendPositionList, (item, idx) => {
      if (item['value'] === pos) {
        index = idx;
        return;
      }
    });

    return index;
  }
}
