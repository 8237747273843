<p-confirmPopup></p-confirmPopup>

<selfai-platform-data-list-view
  [actions]="actions$ | async"
  [groupActions]="groupActions$ | async"
  [columns]="columns"
  [showEntityIcon]="true"
  [emptyMessage]="'msg.workspace-list.table-empty' | translate"
  [dataIsLazy]="true"
  (renameItem)="saveName($event)"
  (toggleFavorite)="toggleFavorite($event)"
>
  <ng-template selfaiPlatformDataListViewTemplateName="gridItemInfo" let-item>
    <p class="text-lg font-bold info-color text-center mb-2">
      <ng-container *ngIf="item.countWorkbooks; else noBooks">
        {{ item.countWorkbooks }}
        {{ item.countWorkbooks | pluralTranslateKey: pluralKeys | translate }}
      </ng-container>

      <ng-template #noBooks>
        {{ 'msg.space.ui.set-permission-schema.no-workbooks' | translate }}
      </ng-template>
    </p>

    <p class="text-base info-color text-center mb-1">
      {{ 'msg.workspace-list.table-header.created' | translate }}:
      {{ item.createdTime | date: 'dd.MM.yyyy HH:mm' }}
    </p>

    <p class="text-base info-color text-center mb-1">
      {{ 'msg.workspace-list.table-header.updated' | translate }}:
      {{ item.modifiedTime | date: 'dd.MM.yyyy HH:mm' }}
    </p>

    <p class="text-base info-color text-center mb-1">
      {{ 'msg.workspace-list.table-header.author' | translate }}:
      {{ item.createdBy.fullName || item.createdBy.username }}
    </p>
  </ng-template>

  <ng-template selfaiPlatformDataListViewTemplateName="gridItemPreviewSkeleton">
    <p-skeleton class="mb-2" width="100%" height="1.5rem"></p-skeleton>
    <p-skeleton class="mb-1" width="100%" height="1.5rem"></p-skeleton>
    <p-skeleton class="mb-1" width="100%" height="1.5rem"></p-skeleton>
    <p-skeleton class="mb-1" width="100%" height="1.5rem"></p-skeleton>
    <p-skeleton class="mb-1" width="100%" height="1.5rem"></p-skeleton>
  </ng-template>
</selfai-platform-data-list-view>
