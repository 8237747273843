import { FormControl } from '@angular/forms';
import { WorkflowInfo } from '../worflow';

export enum EWorkflowSelectionMode {
  WORKFLOW_LIST = 'workflow-list',
  CUSTOM_EXPRESSION = 'custom-expression',
}

export enum EWorkflowRunnerParamToRun {
  UUID = 'uuid',
  NAME = 'name',
}

export interface WorkflowRunnerData {
  workflowId: string | null;
  workflowName: string | null;
  stopFlowBeforeRun: boolean;
  sparkCluster: WorkflowRunnerClusterConfiguration | null;
  parameters: WorkflowRunnerAdditionalParam[];
}

export interface WorkflowRunnerFormGroup {
  workflow: FormControl<WorkflowInfo | null>;
  workflowParamToRun: FormControl<EWorkflowRunnerParamToRun>;
  workflowExpression: FormControl<string | null>;
  workflowSelectionMode: FormControl<EWorkflowSelectionMode>;
  workflowId: FormControl<string | null>;
  workflowName: FormControl<string | null>;
  stopFlowBeforeRun: FormControl<boolean | null>;
  clusterConfig: FormControl<ESparkClusterConfig>
}

export interface AdditionalParamFormGroup {
  id: FormControl<string>;
  name?: FormControl<string | null>;
  query?: FormControl<string | null>;
  notEmpty?: FormControl<boolean | null>;
  notEmptyClone?: FormControl<boolean | null>;
  isArray?: FormControl<boolean | null>;
  isArrayClone?: FormControl<boolean | null>;
}

export interface WorkflowRunnerAdditionalParam {
  id: string;
  name: string;
  query: string;
  notEmpty: boolean;
  isArray: boolean;
}

export interface WorkflowRunnerLegacyData {
  'Workflow Id'?: string;
  'Workflow Name'?: string;
  'Stop flow before run': boolean;
  'Parameters': WorkflowRunnerAdditionalLegacyParamData[];
  'Spark cluster configuration': WorkflowRunnerSparkClusterConfiguration['Spark cluster configuration'];
}

export interface WorkflowRunnerAdditionalLegacyParamData {
  'Parameter name': string;
  'Query': string;
  'Non empty': boolean;
  'Is array': boolean;
}

export interface WorkflowRunnerClusterType {
  'Cluster type': ClusterTypeStandalone | ClusterTypeLocal | ClusterTypeYarn;
}

export interface ClusterTypeStandalone {
  Standalone: Record<string, never>;
}

export interface ClusterTypeLocal {
  Local: Record<string, never>;
}

export interface ClusterTypeYarn {
  Yarn: Record<string, never>;
}

export interface WorkflowRunnerSparkClusterConfiguration {
  'Spark cluster configuration':
    SparkClusterDefaultConfiguration |
    SparkClusterOverrideConfiguration;
}

export interface SparkClusterDefaultConfiguration {
  Default: Record<string, never>
}

export interface SparkClusterOverrideConfiguration {
  Override: WorkflowRunnerSparkClusterOverrideConfigurationLegacyData
}

export enum ESparkClusterConfig {
  Default = 'Default',
  Override = 'Override'
}

export enum ESparkCLusterType {
  Standalone = 'standalone',
  Local = 'local',
  Yarn = 'yarn',
}

export enum EJobType {
  Client = 'client',
  Cluster = 'cluster',
  ExternalApi = 'external-api',
}

export enum EBusType {
  MQ = 'mq',
  NONE = 'none',
}

export interface WorkflowRunnerSparkClusterOverrideConfigurationLegacyData {
  'Additional application parameters': string;
  'Driver hostname': string;
  'Cluster type': WorkflowRunnerClusterType['Cluster type'];
  'Master URI': string;
  'Driver memory': string;
  'Executor memory': string;
  'Executor cores': string;
  'Number of executors': string;
  'Total executor cores': string;
}

export interface WorkflowRunnerClusterConfiguration {
  clusterType?: ESparkCLusterType | string;
  driverMemory: string;
  executorCores: number;
  executorMemory: string;
  hadoopUser?: null;
  id?: string;
  isDefault?: boolean;
  isEditable?: boolean;
  name?: string;
  numExecutors: number;
  params: string;
  totalExecutorCores: number;
  uri: string;
  userIP: string;
  busType?: string;
  isInteractive?: boolean;
  isStandalone?: boolean;
  jobType?: string;
  uriApi?: string;
  workflowDir?: string;
}
