import { ChangeDetectionStrategy, Component, forwardRef, HostListener, Input } from '@angular/core';
import { CheckboxControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'bi-shared-settings-switch',
    templateUrl: './switch.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UiSettingsSwitchComponent),
            multi: true,
        },
    ],
    standalone: false
})
export class UiSettingsSwitchComponent extends CheckboxControlValueAccessor {
  @Input() label: string;
  @Input() value?: string;

  checked = false;

  @HostListener('change', ['$event'])
  onChangeEvent(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.checked = input.checked;
    this.onChange(input.checked ? this.value : undefined);
  }

  @HostListener('blur')
  onBlurEvent(): void {
    this.onTouched();
  }

  override writeValue(value: string): void {
    this.checked = typeof value !== 'undefined' && value === this.value;
  }

  disabled = false;
}
