import { Injectable } from '@angular/core';
import { FavoriteByDomainApiService } from '@selfai-platform/bi-api';
import { Observable } from 'rxjs';
import { FavoriteDomain } from '../models';

@Injectable({ providedIn: 'root' })
export class FavoriteDomainService {
  constructor(private favoriteByDomainApiService: FavoriteByDomainApiService) {}

  addToFavorite(domain: FavoriteDomain, id: string): Observable<void> {
    return this.favoriteByDomainApiService.addToFavorite(domain, id);
  }

  removeFromFavorite(domain: FavoriteDomain, id: string): Observable<void> {
    return this.favoriteByDomainApiService.removeFromFavorite(domain, id);
  }
}
