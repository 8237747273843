import { Injectable } from '@angular/core';
import { WorkspaceApiToDomainService } from '@selfai-platform/bi-domain';
import { Observable, forkJoin, map, of, switchMap } from 'rxjs';
import { IRoleGroup, IWorkspaceRole, WorkspaceWithNormalOwner } from './workspaces-roles-manager.interfaces';

@Injectable({
  providedIn: 'root',
})
export class WorkspacesRolesManagerService {
  constructor(private readonly workspaceService: WorkspaceApiToDomainService) {}

  loadWorkspace(workspaceId: string): Observable<WorkspaceWithNormalOwner> {
    return this.workspaceService.getPublicWorkspaceForListWithPermission(
      workspaceId,
    ) as Observable<WorkspaceWithNormalOwner>;
  }

  loadRoles(workspaceId: string): Observable<IWorkspaceRole[]> {
    return this.workspaceService
      .getWorkspaceRoles({
        'roleGroup.name': workspaceId,
      })
      .pipe(
        map((rolesWrapper) => {
          return rolesWrapper._embedded.workspaceRoles.map(
            (role: { name: string; id: number; permissions: string[] }) => ({
              id: role.id,
              name: role.name,
              permissions: role.permissions,
            }),
          );
        }),
      );
  }

  loadMembers(workspaceId: string): Observable<any> {
    return this.workspaceService.getMembers(workspaceId).pipe(
      map((membersWrapper) => {
        return membersWrapper?._embedded?.members || [];
      }),
    );
  }

  deleteRoleGroup(roleGroup: IRoleGroup) {
    const { name, id } = roleGroup;

    return forkJoin([this.removeRoleFromGroup(name), this.removeGroupRole(id)]);
  }

  updateMembers(workspaceId: string, members: any[]) {
    return this.workspaceService
      .deleteWorkspaceMembers(workspaceId)
      .pipe(switchMap(() => this.saveMembers(workspaceId, members)));
  }

  updateWorkspace(workspaceId: string, form: any) {
    const { name, publicType, description } = form;

    return this.workspaceService.updateWorkspaceById(workspaceId, {
      name,
      publicType,
      description,
    });
  }

  createWorkspace(workspace: any) {
    return this.workspaceService.createWorkspace(workspace);
  }

  createRoleGroup(workspaceId: string, roles: any[]) {
    return this.workspaceService.getRoleGroups().pipe(
      map((roleGroups) => {
        return roleGroups._embedded.workspaceRoleGroups;
      }),
      switchMap((workspaceRoleGroups: any[]) => {
        const roleGroup = workspaceRoleGroups.find((roleGroup) => roleGroup.name === workspaceId);

        return !roleGroup ? this.createGroupRole(workspaceId) : of(roleGroup._links.self.href);
      }),
      switchMap((roleGroupLink) => this.removeRoleFromGroup(workspaceId).pipe(map(() => roleGroupLink))),
      switchMap((roleGroupLink) => this.addRoleToGroup(roleGroupLink, roles)),
    );
  }

  private removeRoleFromGroup(roleGroup: string) {
    return this.workspaceService.removeRoleFromGroup(roleGroup);
  }

  private removeGroupRole(roleGroupId: number) {
    return this.workspaceService.deleteWorkspaceRoleGroup(roleGroupId);
  }

  private saveMembers(workspaceId: string, members: any[]) {
    const keys = Object.keys(members);

    const result: any[] = [];

    keys.forEach((key: any) => {
      members[key].forEach((member: string) => {
        result.push({
          op: 'ADD',
          memberId: member,
          role: key,
          memberType: 'USER',
        });
      });
    });

    return this.workspaceService.setWorkspaceMembers(workspaceId, result);
  }

  private addRoleToGroup(roleGroupLink: string, roles: any[]) {
    return forkJoin(
      roles.map((role: any) =>
        this.workspaceService.createRole({
          name: role.name,
          permissions: role.permissions,
          roleGroup: roleGroupLink,
        }),
      ),
    );
  }

  private createGroupRole(workspaceId: string) {
    return this.workspaceService.createWorkspaceRoleGroup(workspaceId).pipe(
      map((group) => {
        const id = group._links.self.href;

        return id;
      }),
    );
  }
}
