import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CubeError, CubeState } from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { DialogService, provideDialogService } from '@selfai-platform/shell';
import { takeUntil } from 'rxjs';
import { ErrorDialogComponent } from '../../dialogs/error-dialog/error-dialog.component';

@Component({
    selector: 'selfai-platform-error-button',
    templateUrl: './error-button.component.html',
    styleUrls: ['./error-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DestroyService, ...provideDialogService()],
    standalone: false
})
export class ErrorButtonComponent {
  @Input() state!: CubeState;

  constructor(
    private readonly destroy$: DestroyService,
    private readonly dialogService: DialogService<undefined, CubeError>,
  ) {}

  openErrorDialog(): void {
    this.dialogService
      .showDialog(ErrorDialogComponent, {
        header: 'Error',
        width: '75%',
        data: this.state.error,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }
}
