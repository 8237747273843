import { ElementRef, OnChanges, SimpleChanges, Input, EventEmitter, Output, Component, Injector } from '@angular/core';

import * as _ from 'lodash';
import { Subject, Subscription, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { AbstractComponent } from '../../../../common/component/abstract.component';
import { RangeSliderResult } from '../../value/range-slider-result';

declare let $;

@Component({
    selector: 'range-slider-component',
    template: `
    <input type="text" value="" />
  `,
    standalone: false
})
export class RangeSliderComponent extends AbstractComponent implements OnChanges {
  private currentElement: ElementRef;

  private inputElem: any;

  private initialized = false;

  private fromPercent: number;

  private fromValue: number;

  private toPercent: number;

  private toValue: number;

  private changeFromValueSubject$ = new Subject<number>();

  private changeFromValueSubjectSubscription: Subscription;

  @Input() min: any;

  @Input() max: any;

  @Input()
  public from: any;

  @Input()
  public to: any;

  @Input()
  public disable: any;

  @Input()
  public type: any;

  @Input()
  public step: any;

  @Input()
  public minInterval: any;

  @Input()
  public maxInterval: any;

  @Input()
  public dragInterval: any;

  @Input()
  public values: any;

  @Input()
  public fromFixed: any;

  @Input()
  public fromMin: any;

  @Input()
  public fromMax: any;

  @Input()
  public fromShadow: any;

  @Input()
  public toFixed: any;

  @Input()
  public toMin: any;

  @Input()
  public toMax: any;

  @Input()
  public toShadow: any;

  @Input()
  public prettifyEnabled: any;

  @Input()
  public prettifySeparator: any;

  @Input()
  public forceEdges: any;

  @Input()
  public keyboard: any;

  @Input()
  public keyboardStep: any;

  @Input()
  public grid: any;

  @Input()
  public gridMargin: any;

  @Input()
  public gridNum: any;

  @Input()
  public gridSnap: any;

  @Input()
  public hideMinMax: any;

  @Input()
  public hideFromTo: any;

  @Input()
  public prefix: any;

  @Input()
  public postfix: any;

  @Input()
  public maxPostfix: any;

  @Input()
  public decorateBoth: any;

  @Input()
  public valuesSeparator: any;

  @Input()
  public inputValuesSeparator: any;

  @Input()
  public currentIndex: number;

  @Input()
  public foreFromText: string;

  @Input()
  public foreToText: string;

  @Input()
  public prettify: Function;

  @Output()
  public onStart: EventEmitter<RangeSliderResult> = new EventEmitter<RangeSliderResult>();

  @Output()
  public onChange: EventEmitter<RangeSliderResult> = new EventEmitter<RangeSliderResult>();

  @Output()
  public onFinish: EventEmitter<RangeSliderResult> = new EventEmitter<RangeSliderResult>();

  @Output()
  public onUpdate: EventEmitter<RangeSliderResult> = new EventEmitter<RangeSliderResult>();

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);

    this.currentElement = elementRef;

    this.changeFromValueSubjectSubscription = this.changeFromValueSubject$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((value) => of<number>(value)),
      )
      .subscribe(() => {
        this.onChange.emit(this.buildCallback());
      });
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.inputElem = this.currentElement.nativeElement.getElementsByTagName('input')[0];

    this.initSlider();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.changeFromValueSubjectSubscription) {
      this.changeFromValueSubjectSubscription.unsubscribe();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.initialized) {
      const update = {};

      for (const propName in changes) {
        update[propName] = changes[propName].currentValue;
      }

      $(this.inputElem).data('ionRangeSlider').update(update);
    }
  }

  public update(data): void {
    $(this.inputElem).data('ionRangeSlider').update(data);
  }

  public restore(): void {
    $(this.inputElem).data('ionRangeSlider').restore();
  }

  public destroy(): void {
    $(this.inputElem).data('ionRangeSlider').destroy();
  }

  private initSlider(): void {
    const scope = this;

    this.initialized = true;

    (<any>$(this.inputElem)).ionRangeSlider({
      min: scope.min,
      max: scope.max,
      from: scope.from,
      to: scope.to,
      disable: this.toBoolean(scope.disable),

      type: scope.type,
      step: scope.step,
      min_interval: scope.minInterval,
      max_interval: scope.maxInterval,
      drag_interval: scope.dragInterval,
      values: scope.values,
      from_fixed: this.toBoolean(scope.fromFixed),
      from_min: scope.fromMin,
      from_max: scope.fromMax,
      from_shadow: this.toBoolean(scope.fromShadow),
      to_fixed: this.toBoolean(scope.toFixed),
      to_min: scope.toMin,
      to_max: scope.toMax,
      to_shadow: this.toBoolean(scope.toShadow),
      prettify_enabled: this.toBoolean(scope.prettifyEnabled),
      prettify_separator: scope.prettifySeparator,
      force_edges: this.toBoolean(scope.forceEdges),
      keyboard: this.toBoolean(scope.keyboard),
      keyboard_step: scope.keyboardStep,
      grid: this.toBoolean(scope.grid),
      grid_margin: this.toBoolean(scope.gridMargin),
      grid_num: scope.gridNum,
      grid_snap: this.toBoolean(scope.gridSnap),
      hide_min_max: this.toBoolean(scope.hideMinMax),
      hide_from_to: this.toBoolean(scope.hideFromTo),
      prefix: scope.prefix,
      postfix: scope.postfix,
      max_postfix: scope.maxPostfix,
      decorate_both: this.toBoolean(scope.decorateBoth),
      values_separator: scope.valuesSeparator,
      input_values_separator: scope.inputValuesSeparator,

      prettify: scope.prettify,

      onStart() {
        scope.onStart.emit(scope.buildCallback());
      },
      onChange(v) {
        scope.updateInternalValues(v);

        scope.changeFromValueSubject$.next(v.from);
      },
      onFinish() {
        scope.onFinish.emit(scope.buildCallback());
      },
      onUpdate() {
        scope.onUpdate.emit(scope.buildCallback());
      },
    });

    if (!_.isEmpty(this.foreFromText)) this.$element.find('.irs-min').text(this.foreFromText + ' ' + this.from);
    if (!_.isEmpty(this.foreToText)) this.$element.find('.irs-max').text(this.foreToText + ' ' + this.to);
  }

  private updateInternalValues(data: RangeSliderResult): void {
    this.min = data.min;
    this.max = data.max;
    this.from = data.from;
    this.fromPercent = data.fromPercent;
    this.fromValue = data.fromValue;
    this.to = data.to;
    this.toPercent = data.toPercent;
    this.toValue = data.toValue;
  }

  private buildCallback(): RangeSliderResult {
    const callback = new RangeSliderResult();
    callback.min = this.min;
    callback.max = this.max;
    callback.from = this.from;
    callback.fromPercent = this.fromPercent;
    callback.fromValue = this.fromValue;
    callback.to = this.to;
    callback.toPercent = this.toPercent;
    callback.toValue = this.toValue;
    callback.currentElement = this.$element;
    callback.currentIndex = this.currentIndex;
    return callback;
  }

  private toBoolean(value): boolean {
    if (value && typeof value === 'string') {
      return value.toLowerCase() !== 'false';
    } else {
      return value;
    }
  }
}
