import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  CurrentWorkspaceDomainService,
  Dashboard,
  DashboardDomainService,
  DashboardListItem,
  THUMBNAIL_IMAGE_DEFAULT,
  WorkbookDashboardListBreadcrumbService,
  WorkbookDashboardListDomainService,
  WorkbookDomainService,
  WorkbookImportDomainService,
  Workspace,
} from '@selfai-platform/bi-domain';
import {
  BiEntityPermission,
  DestroyService,
  LocalStorageKey,
  PREFIX_SETTINGS_LOCAL_STORAGE_KEY,
  UrlPageParamsService,
  providePageParams,
} from '@selfai-platform/shared';
import {
  BI_ROOT_ROUTE,
  BreadcrumbsMenuService,
  GroupAction,
  SelectedItemsService,
  TableColumn,
  ToolbarAction,
  UI_SETTINGS_VIEW_PAGE_DEFAULT_SIZE,
  convertMessageToHtml,
  provideDataListView,
} from '@selfai-platform/shell';
import { ConfirmationService } from 'primeng/api';
import { Observable, catchError, forkJoin, map, mergeMap, of, switchMap, take, takeUntil, tap } from 'rxjs';
import { DashboardActionsForItemService } from './dashboard-actions-for-item.service';
import { WorkbookDashboardDataListViewService } from './workbook-dashboard-data-list-view.service';

@Component({
    selector: 'selfai-platform-workbook-dashboard-list',
    templateUrl: './dashboard-list.component.html',
    styleUrls: ['./dashboard-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        UrlPageParamsService,
        CurrentWorkspaceDomainService,
        WorkbookImportDomainService,
        DestroyService,
        ...providePageParams({
            pageSize: UI_SETTINGS_VIEW_PAGE_DEFAULT_SIZE,
            pageNumber: 1,
            sortField: 'modified',
            sortOrder: 'desc',
        }),
        {
            provide: PREFIX_SETTINGS_LOCAL_STORAGE_KEY,
            useValue: LocalStorageKey.WORKBOOK_DASHBOARD_LIST_PREFIX_SETTINGS,
        },
        ...provideDataListView(WorkbookDashboardDataListViewService, DashboardActionsForItemService),
    ],
    standalone: false
})
export class WorkbookDashboardListComponent implements OnInit {
  thumbnailImgDefault = THUMBNAIL_IMAGE_DEFAULT;
  actions$: Observable<ToolbarAction[]>;
  groupActions$: Observable<GroupAction[]>;
  columns: TableColumn<DashboardListItem>[] = this.workbookDashboardDataListViewService.getColumns();

  private workbookId$ = this.workbookDashboardDataListViewService.workbookId$;
  private workspace$ = this.workbookDashboardDataListViewService.workspace$;

  constructor(
    private readonly workbookDashboardDataListViewService: WorkbookDashboardDataListViewService,
    private readonly workbookDashboardListDomainService: WorkbookDashboardListDomainService,
    private readonly workbookDashboardListBreadcrumbService: WorkbookDashboardListBreadcrumbService,
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    private readonly router: Router,
    private readonly selectedItemsService: SelectedItemsService<DashboardListItem>,
    private readonly destroy$: DestroyService,
    private readonly translate: TranslateService,
    private readonly workbookDomainService: WorkbookDomainService,
    private readonly dashboardDomainService: DashboardDomainService,
    private readonly confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.actions$ = this.workbookId$.pipe(
      map((workbookId) => [
        {
          icon: 'pi pi-plus-circle',
          labelTranslate: 'msg.comm.ui.create.board',
          buttonClass: 'p-button-success',
          action: () => this.createDashboard(workbookId),
        },
      ]),
    );

    this.groupActions$ = this.getGroupActions();

    this.workbookId$
      .pipe(
        switchMap((workbookId) => this.workbookDomainService.loadWorkbook(workbookId)),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.workbookId$
      .pipe(
        switchMap((workbookId) => {
          return this.workbookDashboardListBreadcrumbService.getBreadcrumbsMenu(workbookId);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((items) => {
        this.breadcrumbsMenuService.setBreadcrumbsMenu(items);
      });
  }

  saveName(editingDashboard: Dashboard): void {
    this.workbookId$
      .pipe(
        take(1),
        switchMap((workbookId) =>
          this.workbookDashboardListDomainService.updateOne(workbookId, editingDashboard.id as string, {
            name: editingDashboard.name,
          }),
        ),
        take(1),
      )
      .subscribe();
  }

  toggleFavorite({ itemId, isFavorite }: { itemId: string; isFavorite: boolean }) {
    this.workbookId$
      .pipe(
        take(1),
        switchMap((workbookId) => {
          if (isFavorite) {
            return this.workbookDashboardListDomainService.addToFavorite(workbookId, itemId);
          } else {
            return this.workbookDashboardListDomainService.removeFromFavorite(workbookId, itemId);
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private getGroupActions(): Observable<GroupAction[]> {
    return this.workspace$.pipe(
      map((workspace) => {
        const actions: GroupAction[] = [];

        if (this.hasPermissionForEntity(workspace, BiEntityPermission.WORKBOOK_CREATE)) {
          actions.push({
            icon: 'pi pi-clone',
            labelTranslate: 'msg.workbook-list.table-actions.duplicate-selected',
            action: () => this.duplicateSelected(),
          });
        }

        if (this.hasPermissionForEntity(workspace, BiEntityPermission.WORKBOOK_DELETE)) {
          actions.push({
            icon: 'pi pi-trash',
            buttonClass: 'p-button-danger',
            labelTranslate: 'msg.workbook-list.table-actions.remove-selected',
            action: (eventTarget) => this.removeSelected(eventTarget),
          });
        }

        return actions;
      }),
    );
  }

  private hasPermissionForEntity(entity: Workspace, permission: BiEntityPermission): boolean {
    return entity.permissions?.includes(permission);
  }

  private createDashboard(workbookId: string): void {
    this.router.navigate(['/', BI_ROOT_ROUTE, 'workbook', workbookId], { queryParams: { createDashboard: true } });
  }

  private duplicateSelected(): void {
    this.selectedItemsService
      .getSelectedItemIds()
      .pipe(
        mergeMap((selectedItemIds) =>
          selectedItemIds.map((dashboardId) => this.dashboardDomainService.copyDashboard(dashboardId)),
        ),
      )
      .pipe(
        tap(() => this.workbookDashboardDataListViewService.triggerReloadData()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private removeSelected(eventTarget: EventTarget): void {
    this.confirmationService.confirm({
      target: eventTarget,
      message: convertMessageToHtml(this.translate.instant('msg.workbook-dashboard-list.delete-selected.confirmation')),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.selectedItemsService
          .getSelectedItemIds()
          .pipe(
            mergeMap((selectedItemIds) =>
              forkJoin(
                selectedItemIds.map((dashboardId) =>
                  this.dashboardDomainService.removeToTrash(dashboardId).pipe(catchError(() => of(null))),
                ),
              ),
            ),
            take(1),
            tap(() => this.workbookDashboardDataListViewService.triggerReloadData()),
            takeUntil(this.destroy$),
          )
          .subscribe(() => {
            this.selectedItemsService.resetSelectedItems();
          });
      },
    });
  }
}
