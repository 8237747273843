import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DataListViewComponent, DataListViewTemplateNameDirective } from '@selfai-platform/shell';
import { BlockUIModule } from 'primeng/blockui';
import { SkeletonModule } from 'primeng/skeleton';
import { TooltipModule } from 'primeng/tooltip';
import { WorkbookListComponent } from './workbook-list.component';

@NgModule({
  imports: [
    CommonModule,
    DataListViewComponent,
    DataListViewTemplateNameDirective,
    SkeletonModule,
    TooltipModule,
    TranslateModule,
    BlockUIModule,
    RouterModule.forChild([
      {
        path: '',
        component: WorkbookListComponent,
      },
    ]),
  ],
  declarations: [WorkbookListComponent],
  exports: [WorkbookListComponent],
})
export class WorkbookListComponentModule {}
