import { Component, ElementRef, Injector, Input, ViewChild } from '@angular/core';

import each from 'lodash/each';
import eq from 'lodash/eq';
import extend from 'lodash/extend';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import {
  DashboardField as AbstractField,
  ChartType,
  Dashboard,
  DashboardDomainService,
  DatasourceField as Field,
  FieldRole,
  GranularityType,
  LabelSecondaryIndicatorMarkType,
  LabelSecondaryIndicatorPeriod,
  LabelSecondaryIndicatorType,
  LogicalType,
  PageWidget,
  Pivot,
  ShelveFieldType,
  UILabelAnnotation,
  UILabelChart,
  UILabelChartSeries,
  UILabelIcon,
  UILabelSecondaryIndicator,
  UIOption,
  createTimestampField,
} from '@selfai-platform/bi-domain';
import { DestroyService } from '@selfai-platform/shared';

import { SelectComponent } from '../../common/component/select/select.component';

import { BaseOptionComponent } from './base-option.component';

@Component({
    selector: 'secondary-indicator-option',
    templateUrl: './secondary-indicator.component.html',
    providers: [DestroyService],
    standalone: false
})
export class SecondaryIndicatorComponent extends BaseOptionComponent {
  @ViewChild('targetListComp', { static: false })
  private targetListComp: SelectComponent;

  @ViewChild('indicatorListComp', { static: false })
  private indicatorListComp: SelectComponent;

  @ViewChild('periodListComp')
  private periodListComp: SelectComponent;

  public pivot: Pivot;
  public pivotTemp: Pivot;

  public targetList: Object[] = [{ name: this.translateService.instant('msg.comm.ui.list.all'), value: '' }];
  public target: Object = this.targetList[0];

  public indicatorList: Object[] = [
    { name: this.translateService.instant('msg.page.common.kpi.indocator.standard.to'), value: 'STANDARD' },
    // Need fix period in fututre
    // { name: this.translateService.instant('msg.page.common.kpi.indocator.period'), value: 'PERIOD' },
  ];

  public periodList: Object[] = [
    { name: this.translateService.instant('msg.page.common.kpi.indocator.period.year'), value: 'YEAR' },

    { name: this.translateService.instant('msg.page.common.kpi.indocator.month'), value: 'MONTH' },
    { name: this.translateService.instant('msg.page.common.kpi.indocator.day'), value: 'DAY' },
    { name: this.translateService.instant('msg.page.common.kpi.indocator.hour'), value: 'HOUR' },
  ];

  public standardValueTemp = '';
  public standardValue: number;

  public isPeriod = false;

  public timeField: AbstractField;

  @Input()
  dashboard: Dashboard;

  @Input()
  public widget: PageWidget;

  @Input('uiOption')
  public set setUiOption(uiOption: UIOption) {
    this.uiOption = uiOption;

    if (isUndefined(this.pivot)) {
      this.setPivot = this.pivotTemp;
    }
  }

  @Input('pivot')
  public set setPivot(pivot: Pivot) {
    if (isUndefined(pivot)) {
      return;
    }

    if (isUndefined(this.uiOption)) {
      this.pivotTemp = pivot;
      return;
    }

    this.pivot = pivot;
    this.waitUntilDashboardLoaded(() => {
      this.onPivotUpdated();
    });
  }

  constructor(
    protected elementRef: ElementRef,
    protected injector: Injector,
    private readonly destroy$: DestroyService,
    private readonly dashboardDomainService: DashboardDomainService,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public isTargetAll(): boolean {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    let indicatorType = '';
    let rangeUnit = '';
    let targetValue: number = undefined;
    let mark = '';
    let isAll = true;
    each(option.secondaryIndicators, (series) => {
      const labelSeries: UILabelSecondaryIndicator = <UILabelSecondaryIndicator>series;
      if (!isUndefined(labelSeries.show)) {
        const seriesIndicatorType = String(labelSeries.indicatorType);
        if (indicatorType == '') {
          indicatorType = seriesIndicatorType;
        } else if (indicatorType != seriesIndicatorType) {
          isAll = false;
        }

        const seriesRangeUnit = String(labelSeries.rangeUnit);
        if (isUndefined(rangeUnit) || rangeUnit === '') {
          rangeUnit = seriesRangeUnit;
        } else if (
          eq(seriesIndicatorType, LabelSecondaryIndicatorType.PERIOD) &&
          isUndefined(seriesRangeUnit) &&
          rangeUnit != seriesRangeUnit
        ) {
          isAll = false;
        }

        const seriesTargetValue: number = labelSeries.targetValue;
        if (isUndefined(targetValue) || targetValue == 0) {
          targetValue = seriesTargetValue;
        } else if (
          eq(seriesIndicatorType, LabelSecondaryIndicatorType.STANDARD) &&
          isUndefined(seriesTargetValue) &&
          targetValue != seriesTargetValue
        ) {
          isAll = false;
        }

        const seriesMark = String(labelSeries.mark);
        if (mark == '') {
          mark = seriesMark;
        } else if (mark != seriesMark) {
          isAll = false;
        }
      }
    });
    return isAll;
  }

  public changeTargetUse(): void {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    const show = !option.secondaryIndicators[0].show;
    each(option.secondaryIndicators, (series) => {
      series.show = show;
      if (show) {
        this.changeDetect.detectChanges();
        const indicatorType: Object = this.indicatorListComp.selectedItem;
        if (indicatorType) {
          series.indicatorType = indicatorType['value'];
        } else {
          series.indicatorType = this.indicatorList[0]['value'];
          this.indicatorListComp.selected(this.indicatorList[0]);
        }
      }
    });

    if (show) {
      this.targetListComp.selected(this.targetList[0]);
    } else {
      this.uiOption = <UIOption>extend({}, this.uiOption, { secondaryIndicators: option.secondaryIndicators });
      this.update();
    }
  }

  public getIndicatorTypeIndex(): number {
    const labelOption: UILabelChart = <UILabelChart>this.uiOption;
    if (isUndefined(labelOption.secondaryIndicators) || labelOption.secondaryIndicators.length == 0) {
      return 0;
    } else {
      const secondaryIndicators: UILabelSecondaryIndicator = labelOption.secondaryIndicators[0];
      if (eq(secondaryIndicators.indicatorType, LabelSecondaryIndicatorType.PERIOD)) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  public changeTarget(target: Object): void {
    this.target = target;
    this.changeDetect.detectChanges();
    if (this.target['value'] == '') {
      const option: UILabelChart = <UILabelChart>this.uiOption;

      this.indicatorListComp.selected(
        eq(option.secondaryIndicators[0].indicatorType, LabelSecondaryIndicatorType.PERIOD)
          ? this.indicatorList[1]
          : this.indicatorList[0],
      );

      this.standardValueTemp = isUndefined(option.secondaryIndicators[0].targetValue)
        ? ''
        : String(option.secondaryIndicators[0].targetValue);
      this.standardValue = isUndefined(option.secondaryIndicators[0].targetValue)
        ? undefined
        : option.secondaryIndicators[0].targetValue;
      each(option.secondaryIndicators, (series) => {
        series.targetValue = this.standardValue;
      });

      this.changeMarkType(String(option.secondaryIndicators[0].mark));

      this.changeEmphasized(
        isUndefined(option.secondaryIndicators[0].emphasized) ? false : option.secondaryIndicators[0].emphasized,
      );
    } else {
      const option: UILabelChart = <UILabelChart>this.uiOption;
      each(option.secondaryIndicators, (series) => {
        this.indicatorListComp.selected(
          eq(series.indicatorType, LabelSecondaryIndicatorType.PERIOD) ? this.indicatorList[1] : this.indicatorList[0],
        );

        if (this.target['value'] == series.seriesName) {
          this.standardValueTemp = isUndefined(series.targetValue) ? '' : String(series.targetValue);
          this.standardValue = isUndefined(series.targetValue) ? undefined : series.targetValue;

          this.changeMarkType(String(series.mark));
        }
      });
    }
  }

  public revokeTargetValue(): void {
    this.standardValueTemp =
      isUndefined(this.standardValue) || this.standardValue == 0 ? '' : String(this.standardValue);
  }

  public changeIndicatorType(indicatorType: LabelSecondaryIndicatorType): void {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    each(option.secondaryIndicators, (series) => {
      if (this.target['value'] == '' || this.target['value'] == series.seriesName) {
        series.indicatorType = indicatorType;
      }
    });

    this.isPeriod = eq(indicatorType, LabelSecondaryIndicatorType.PERIOD);

    if (isUndefined(this.timeField)) {
      this.initTimeFieldSetting();
    }
    this.changeDetect.detectChanges();

    if (eq(indicatorType, LabelSecondaryIndicatorType.PERIOD)) {
      let value = '';
      if (this.target['value'] == '') {
        value = isUndefined(option.secondaryIndicators[0].rangeUnit)
          ? this.periodList[0]['value']
          : option.secondaryIndicators[0].rangeUnit;
      } else {
        each(option.secondaryIndicators, (series) => {
          if (this.target['value'] == series.seriesName) {
            value = isUndefined(option.secondaryIndicators[0].rangeUnit)
              ? this.periodList[0]['value']
              : option.secondaryIndicators[0].rangeUnit;

            value = isUndefined(series.rangeUnit) ? this.periodList[0]['value'] : series.rangeUnit;
          }
        });
      }
      each(this.periodList, (period, index) => {
        if (eq(period['value'], value)) {
          this.periodListComp.selected(period);
        }
      });
    } else {
      this.uiOption = <UIOption>extend({}, this.uiOption, { secondaryIndicators: option.secondaryIndicators });
      this.update();
    }
  }

  public getMarkSelected(markTypeStr: string): boolean {
    const markType: LabelSecondaryIndicatorMarkType = LabelSecondaryIndicatorMarkType[markTypeStr];
    const option: UILabelChart = <UILabelChart>this.uiOption;
    if (this.target['value'] == '') {
      if (
        markType == option.secondaryIndicators[0].mark ||
        (markType == LabelSecondaryIndicatorMarkType.INCREMENTAL && !option.secondaryIndicators[0].mark)
      ) {
        return true;
      }
      return false;
    } else {
      const labelSeries: UILabelSecondaryIndicator[] = <UILabelSecondaryIndicator[]>option.secondaryIndicators;
      for (let num = 0; num < labelSeries.length; num++) {
        if (labelSeries[num].seriesName == this.target['value']) {
          if (
            markType == labelSeries[num].mark ||
            (markType == LabelSecondaryIndicatorMarkType.INCREMENTAL && !labelSeries[num].mark)
          ) {
            return true;
          }
          return false;
        }
      }
    }
  }

  public changeMarkType(markTypeStr: string): void {
    const markType: LabelSecondaryIndicatorMarkType = LabelSecondaryIndicatorMarkType[markTypeStr];
    const option: UILabelChart = <UILabelChart>this.uiOption;
    each(option.secondaryIndicators, (series) => {
      if (this.target['value'] == '' || this.target['value'] == series.seriesName) {
        series.mark = markType;
      }
    });
    this.uiOption = <UIOption>extend({}, this.uiOption, { secondaryIndicators: option.secondaryIndicators });
    this.update();
  }

  public getEmphasizedSelected(): boolean {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    if (isUndefined(option.secondaryIndicators[0])) {
      return false;
    }

    if (this.target['value'] == '') {
      return option.secondaryIndicators[0].emphasized;
    } else {
      const labelSeries: UILabelSecondaryIndicator[] = <UILabelSecondaryIndicator[]>option.secondaryIndicators;
      for (let num = 0; num < labelSeries.length; num++) {
        if (labelSeries[num].seriesName == this.target['value']) {
          return labelSeries[num].emphasized;
        }
      }
    }
  }

  public changeEmphasized(forceEmphasized?: boolean): void {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    const emphasized = isUndefined(forceEmphasized) ? !option.secondaryIndicators[0].emphasized : forceEmphasized;
    each(option.secondaryIndicators, (series) => {
      if (this.target['value'] == '') {
        series.emphasized = emphasized;
      } else if (this.target['value'] == series.seriesName) {
        series.emphasized = !series.emphasized;
      }
    });
    this.uiOption = <UIOption>extend({}, this.uiOption, { secondaryIndicators: option.secondaryIndicators });
    this.update();
  }

  public applyStandardValue(): void {
    if (Number.isNaN(Number(this.standardValueTemp))) {
      this.standardValueTemp = isUndefined(this.standardValue) ? undefined : String(this.standardValue);
      return;
    }
    this.standardValue = isUndefined(this.standardValueTemp) ? undefined : Number(this.standardValueTemp);
    const option: UILabelChart = <UILabelChart>this.uiOption;
    each(option.secondaryIndicators, (series) => {
      if (this.target['value'] == '' || this.target['value'] == series.seriesName) {
        series.targetValue = this.standardValue;
      }
    });
    this.uiOption = <UIOption>extend({}, this.uiOption, { secondaryIndicators: option.secondaryIndicators });
    this.update();
  }

  public isShow(): boolean {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    if (isUndefined(option.secondaryIndicators) || option.secondaryIndicators.length == 0) {
      return false;
    }
    return isUndefined(option.secondaryIndicators[0].show) ? false : option.secondaryIndicators[0].show;
  }

  public changePeriodType(periodType: LabelSecondaryIndicatorPeriod): void {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    each(option.secondaryIndicators, (series) => {
      if (this.target['value'] == '' || this.target['value'] == series.seriesName) {
        series.rangeUnit = periodType;
      }
    });
    this.uiOption = <UIOption>extend({}, this.uiOption, { secondaryIndicators: option.secondaryIndicators });
    this.update();
  }

  public setIsPeriod(): void {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    if (isUndefined(option.secondaryIndicators) || option.secondaryIndicators.length == 0) {
      this.isPeriod = false;
      return;
    }

    const isTargetAll: boolean = this.isTargetAll();
    if (isTargetAll || this.target == this.targetList[0]) {
      this.isPeriod = eq(option.secondaryIndicators[0].indicatorType, LabelSecondaryIndicatorType.PERIOD);
      return;
    } else {
      each(option.secondaryIndicators, (series, index) => {
        if (this.target['value'] == series.seriesName) {
          this.isPeriod = eq(series.indicatorType, LabelSecondaryIndicatorType.PERIOD);
          return;
        }
      });
    }
  }

  private getGranularityScore(granularity: string): number {
    let score = 0;
    switch (granularity) {
      case String(GranularityType.SECOND):
        score = 1;
        break;
      case String(GranularityType.MINUTE):
        score = 2;
        break;
      case String(GranularityType.HOUR):
        score = 3;
        break;
      case String(GranularityType.DAY):
        score = 4;
        break;
      case String(GranularityType.WEEK):
        score = 4;
        break;
      case String(GranularityType.MONTH):
        score = 6;
        break;
      case String(GranularityType.QUARTER):
        score = 6;
        break;
      case String(GranularityType.YEAR):
        score = 8;
        break;
    }
    return score;
  }

  private initTimeFieldSetting(): void {
    const fields: Field[] = this.dashboard.configuration.fields || [];
    const idx: number = findIndex(fields, { role: FieldRole.TIMESTAMP, logicalType: LogicalType.TIMESTAMP });
    if (idx === -1) {
      return;
    }
    const timeField = createTimestampField();
    timeField.name = fields[idx].name;
    timeField.alias = fields[idx].alias;
    timeField.granularity = fields[idx].granularity;
    timeField.format = {
      type: 'time_format',
      format: 'yyyy-MM-dd HH:mm:ss',
      timeZone: 'UTC',
      locale: 'en',
    };
    this.timeField = timeField;

    const granularityScore: number = this.getGranularityScore(String(this.timeField.granularity));
    this.periodList = [];
    if (granularityScore <= 8) {
      this.periodList.push({
        name: this.translateService.instant('msg.page.common.kpi.indocator.period.year'),
        value: 'YEAR',
      });
    }
    if (granularityScore <= 6) {
      this.periodList.push({
        name: this.translateService.instant('msg.page.common.kpi.indocator.period.month'),
        value: 'MONTH',
      });
    }
    if (granularityScore <= 4) {
      this.periodList.push({
        name: this.translateService.instant('msg.page.common.kpi.indocator.period.day'),
        value: 'DAY',
      });
    }
    if (granularityScore <= 3) {
      this.periodList.push({
        name: this.translateService.instant('msg.page.common.kpi.indocator.period.hour'),
        value: 'HOUR',
      });
    }
  }

  private waitUntilDashboardLoaded(callback: () => void): void {
    if (!this.dashboard) {
      setTimeout(() => {
        this.waitUntilDashboardLoaded(callback);
      }, 100);
      return;
    }
    callback();
  }

  private onPivotUpdated() {
    const option: UILabelChart = <UILabelChart>this.uiOption;
    if (this.uiOption.type == ChartType.LABEL && option.series) {
      const isTargetAll: boolean = this.isTargetAll();
      this.targetList = [];
      this.targetList.push({ name: this.translateService.instant('msg.comm.ui.list.all'), value: '' });
      const series: UILabelChartSeries[] = [];
      const icons: UILabelIcon[] = [];
      const annotations: UILabelAnnotation[] = [];
      const secondaryIndicators: UILabelSecondaryIndicator[] = [];

      const setFieldName = (item, shelveFieldType?: ShelveFieldType): string => {
        if (!shelveFieldType || (shelveFieldType && item.type === shelveFieldType)) {
          let fieldName = !isEmpty(item.alias) ? item.alias : item.name;
          if (item['alias'] && item['alias'] !== item.name) {
            fieldName = item['alias'];
          } else {
            const alias: string = item['fieldAlias']
              ? item['fieldAlias']
              : item['logicalName']
              ? item['logicalName']
              : item['name'];
            fieldName = item.aggregationType ? item.aggregationType + `(${alias})` : `${alias}`;
          }
          return fieldName;
        }
      };

      const aggs = this.pivot.aggregations
        .map((aggregation) => {
          return setFieldName(aggregation, ShelveFieldType.MEASURE);
        })
        .filter((item) => {
          return typeof item !== 'undefined';
        });

      for (let num = 0; num < this.pivot.aggregations.length; num++) {
        const field: any = this.pivot.aggregations[num];
        let alias: string = field['alias'] ? field['alias'] : field['fieldAlias'] ? field['fieldAlias'] : field['name'];
        const displayName: any = aggs[num];
        if (field.aggregationType && field.aggregationType != '') {
          alias = field.aggregationType + '(' + alias + ')';
        }

        if (option.series.length <= num) {
          if (num > 0) {
            option.series[num] = {
              name: alias,
              displayName: displayName,
            };
            option.icons[num] = {
              seriesName: alias,
              displayName: displayName,
              show: option.icons[0].show,
              iconType: option.icons[0].iconType,
            };
            option.annotations[num] = {
              seriesName: alias,
              displayName: displayName,
              show: option.annotations[0].show,
              description: option.annotations[0].description,
            };
            option.secondaryIndicators[num] = {
              seriesName: alias,
              displayName: displayName,
              show: option.secondaryIndicators[0].show,
              indicatorType: option.secondaryIndicators[0].indicatorType,
              rangeUnit: option.secondaryIndicators[0].rangeUnit,
              targetValue: option.secondaryIndicators[0].targetValue,
              mark: option.secondaryIndicators[0].mark,
              emphasized: option.secondaryIndicators[0].emphasized,
            };
          } else {
            option.series[num] = {};
            option.icons[num] = {};
            option.annotations[num] = {};
            option.secondaryIndicators[num] = {};
          }
        }

        if (
          isUndefined(option.series[num].name) ||
          isUndefined(option.icons[num].seriesName) ||
          isUndefined(option.annotations[num].seriesName) ||
          isUndefined(option.secondaryIndicators[num].seriesName)
        ) {
          option.series[num].name = alias;
          option.series[num].displayName = displayName;
          option.icons[num].seriesName = alias;
          option.icons[num].displayName = displayName;
          option.annotations[num].seriesName = alias;
          option.annotations[num].displayName = displayName;
          option.secondaryIndicators[num].seriesName = alias;
          option.secondaryIndicators[num].displayName = displayName;
        }

        let isPush = false;
        for (let num2 = 0; num2 < this.pivot.aggregations.length; num2++) {
          if (option.series.length >= num2 + 1 && eq(alias, option.series[num2].name)) {
            isPush = true;
            series.push(option.series[num2]);
          }
          if (option.icons.length >= num2 + 1 && eq(alias, option.icons[num2].seriesName)) {
            icons.push(option.icons[num2]);
          }
          if (option.annotations.length >= num2 + 1 && eq(alias, option.annotations[num2].seriesName)) {
            annotations.push(option.annotations[num2]);
          }
          if (option.secondaryIndicators.length >= num2 + 1 && eq(alias, option.secondaryIndicators[num2].seriesName)) {
            secondaryIndicators.push(option.secondaryIndicators[num2]);
          }
        }

        if (!isPush) {
          option.series[num].name = alias;
          option.series[num].displayName = displayName;
          option.icons[num].seriesName = alias;
          option.icons[num].displayName = displayName;
          option.annotations[num].seriesName = alias;
          option.annotations[num].displayName = displayName;
          option.secondaryIndicators[num].seriesName = alias;
          option.secondaryIndicators[num].displayName = displayName;

          for (let num2 = 0; num2 < this.pivot.aggregations.length; num2++) {
            if (option.series.length >= num2 + 1 && eq(alias, option.series[num2].name)) {
              series.push(option.series[num2]);
            }
            if (option.icons.length >= num2 + 1 && eq(alias, option.icons[num2].seriesName)) {
              icons.push(option.icons[num2]);
            }
            if (option.annotations.length >= num2 + 1 && eq(alias, option.annotations[num2].seriesName)) {
              annotations.push(option.annotations[num2]);
            }
            if (
              option.secondaryIndicators.length >= num2 + 1 &&
              eq(alias, option.secondaryIndicators[num2].seriesName)
            ) {
              secondaryIndicators.push(option.secondaryIndicators[num2]);
            }
          }
        }

        this.targetList.push({
          name: displayName,
          value: alias,
        });
      }

      option.series = series;
      option.icons = icons;
      option.annotations = annotations;
      option.secondaryIndicators = secondaryIndicators;

      this.changeDetect.detectChanges();
      if (!isTargetAll) {
        this.targetListComp.selected(this.targetList[this.targetList.length - 1]);
      } else {
        this.targetListComp.selected(this.targetList[0]);
      }
    }
  }
}
