import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Observable } from 'rxjs';
import { WORKBOOK_PINS_ENTITY_NAME } from '../const';
import { WorkbookState } from '../models';
import { WorkbookApiToDomainService } from './workbook-domain-api.service';

@Injectable({ providedIn: 'root' })
export class WorkbookDataService extends DefaultDataService<WorkbookState> {
  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private readonly workbookApiToDomainService: WorkbookApiToDomainService,
  ) {
    super(WORKBOOK_PINS_ENTITY_NAME, http, httpUrlGenerator);
  }

  override getById(workbookId: string): Observable<WorkbookState> {
    return this.workbookApiToDomainService.loadWorkbook(workbookId);
  }
}
