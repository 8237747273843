/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { UIOption } from '@selfai-platform/bi-domain';
import { EChartsOption } from 'echarts';
import { Observable } from 'rxjs';
import { ChartOptionsService } from './chart-options.service';

export class MockChartOptionsService implements ChartOptionsService {
  uiOptions$: Observable<UIOption>;
  chartOptions$: Observable<EChartsOption>;

  initEChartOptions(): EChartsOption {
    return {} as EChartsOption;
  }

  setUiOptions(uiOptions: UIOption): void {}

  getUiOptions(): UIOption {
    return {} as UIOption;
  }

  setEChartOptions(chartOptions: EChartsOption): void {}

  getEChartOptions(): EChartsOption {
    return {} as EChartsOption;
  }
}
