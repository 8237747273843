import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { WORKBOOK_LIST_ENTITY_NAME } from '../const';
import { WorkbookListState } from '../models';

@Injectable({ providedIn: 'root' })
export class WorkbookListStore extends EntityCollectionServiceBase<WorkbookListState> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super(WORKBOOK_LIST_ENTITY_NAME, serviceElementsFactory);
  }
}
