import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GUEST_WORKSPACE_ID, UserProfile, WorkspaceApiToDomainService } from '@selfai-platform/bi-domain';
import { DestroyService } from '@selfai-platform/shared';
import { debounceTime, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { IWorkspaceRole } from '../workspaces-roles-manager.interfaces';

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

@Component({
    selector: 'selfai-platform-workspaces-roles-manager-setting',
    templateUrl: './workspaces-roles-manager-setting.component.html',
    styleUrls: ['./workspaces-roles-manager-setting.component.scss', '../workspaces-roles-manager-styles.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DestroyService],
    standalone: false
})
export class WorkspacesRolesManagerSettingComponent {
  @Input() form: FormGroup;
  @Input() roles: IWorkspaceRole[];
  @Input() workspaceId: string;

  @Output() addRole = new EventEmitter<boolean>();

  public filteredOwners: Observable<UserProfile[]>;
  public guestWorkspaceId = GUEST_WORKSPACE_ID;

  publicType = [
    { name: this.translateService.instant('msg.workspacesEdit.public'), code: 'SHARED' },
    { name: this.translateService.instant('msg.workspacesEdit.private'), code: 'PRIVATE' },
  ];

  constructor(
    private readonly workspaceService: WorkspaceApiToDomainService,
    private readonly translateService: TranslateService,
  ) {}

  public filterOwners(event: AutoCompleteCompleteEvent): void {
    this.filteredOwners = of(event.query).pipe(
      debounceTime(300),
      switchMap((filter: string) => this.workspaceService.getUsers({ nameContains: filter})),
      map((usersWrapper) => usersWrapper._embedded?.users || []),
      map((users) => {
        const selectedOwners = this.form.get('owners').value;

        return users
          .filter((user: any) => !selectedOwners.find((owner: any) => owner.username === user.username))
          .map((user: any) => {
            if (!user.fullName) {
              user.fullName = user.username;
            }

            return user;
          });
      }),
      shareReplay(1),
    );
  }
}
