<div class="recycle-bin-table">
  <p-table
    styleClass="p-datatable-sm"
    paginatorStyleClass="prime-table-custom__paginator"
    [value]="recycledBin$ | async"
    [selectionPageOnly]="true"
    [loading]="loading"
    [showLoader]="false"
    [(selection)]="selectedElement"
  >
    <ng-template pTemplate="loadingbody">
      <tr *ngFor="let item of [].constructor(12)" tabindex="-1">
        <td [style.height.px]="35" *ngFor="let _ of [].constructor(tableConfig.length + 1)">
          <p-skeleton class="w-full"></p-skeleton>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td [colSpan]="tableConfig.length">{{ 'msg.board.filter.ui.search-all.nodata' | translate }}</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="caption">
      <div class="flex justify-content-between" [formGroup]="form">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>

          <input
            formControlName="search"
            style="width: 300px"
            pAutoFocus
            [autofocus]="true"
            pInputText
            type="text"
            placeholder="{{ 'msg.workbook-list.search.input-placeholder' | translate }}"
          />
        </span>

        <div class="recycle-bin-actions">
          <ng-container *ngIf="selectedElement.length">
            <button
              pButton
              type="button"
              [title]="'recycle.ui.restore' | translate"
              class="p-button"
              (click)="recover()"
              [pTooltip]="'recycle.ui.restore' | translate"
              appearance="icon"
              icon="pi pi-refresh"
              tooltipposition="top"
            ></button>
            <button
              *ngIf="isAdmin"
              pButton
              type="button"
              class="p-button p-button-danger"
              (click)="removePermanently()"
              [pTooltip]="'recycle.ui.delete' | translate"
              icon="pi pi-trash"
              appearance="icon"
              tooltipposition="top"
            ></button>
          </ng-container>
          <p-dropdown [options]="pageSizes" formControlName="size" (onChange)="loading = true"></p-dropdown>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>

        <th *ngFor="let item of tableConfig" [style.width]="item.width">
          {{ item.label }}
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-product>
      <tr *ngIf="!loading">
        <td><p-tableCheckbox [value]="product"></p-tableCheckbox></td>
        <td>{{ product.name }}</td>
        <td>{{ 'recycle.ui.type.' + product.domain | translate }}</td>
        <td>{{ product.createdTime | date: 'dd.MM.yyyy HH:mm' }}</td>
        <td>{{ product.createdBy }}</td>
        <td>{{ product.modifiedTime | date: 'dd.MM.yyyy HH:mm' }}</td>
        <td>{{ product.modifiedBy }}</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="footer">
      <tr>
        <td class="p-0" [colSpan]="tableConfig.length + 1">
          <selfai-platform-data-list-view-pagination
            *ngIf="page$ | async as page"
            [pageNumber]="page.number"
            [pageSize]="page.size"
            [totalRecords]="page.totalElements"
            (pageChange)="onPageChange($event.pageNumber)"
          ></selfai-platform-data-list-view-pagination>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>
<p-toast></p-toast>
