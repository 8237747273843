import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CreateDataColumnType } from '@selfai-platform/pipeline-common';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { CreateDataFrameComponentService } from '../create-data-frame-component.service';

@Component({
    selector: 'selfai-platform-create-data',
    imports: [
        CommonModule,
        InputTextModule,
        InputSwitchModule,
        FormsModule,
        TableModule,
        TranslateModule,
        ButtonModule,
        TooltipModule,
        MessageModule,
    ],
    templateUrl: './create-data.component.html',
    styleUrl: './create-data.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateDataComponent {
  items: Record<string, string | number | boolean>[] = [];

  get columns(): { name: string; type: 'string' | 'number' | 'boolean' }[] {
    return this.createDataFrameComponentService.columns.map((column) => ({
      name: column.columnName,
      type: this.mapColumnType(column.columnType),
    }));
  }

  @Input({ required: true })
  set dataItems(value: Record<string, string | number | boolean>[]) {
    this.items = value;
  }
  @Output() dataItemsChange = new EventEmitter<Record<string, string | number | boolean>[]>();

  private readonly createDataFrameComponentService = inject(CreateDataFrameComponentService);

  onChange(index: number, key: string, value: string | number | boolean): void {
    this.items.forEach((item, i) => {
      if (i === index) {
        item[key] = value;
      }
    });
    this.dataItemsChange.emit(this.items);
  }

  removeItem(index: number): void {
    this.items = this.items.filter((_, i) => i !== index);
    this.dataItemsChange.emit(this.items);
  }

  copyItem(index: number): void {
    this.items = [...this.items, this.items[index]];
    this.dataItemsChange.emit(this.items);
  }

  addItem(): void {
    this.items = [...this.items, {}];
    this.dataItemsChange.emit(this.items);
  }

  mapColumnType(columnType: CreateDataColumnType | null): 'string' | 'number' | 'boolean' {
    switch (columnType) {
      case CreateDataColumnType.Integer:
      case CreateDataColumnType.Float:
      case CreateDataColumnType.BigInteger:
      case CreateDataColumnType.Double:
      case CreateDataColumnType.Timestamp:
        return 'number';
      case CreateDataColumnType.Boolean:
        return 'boolean';
      default:
        return 'string';
    }
  }
}
