import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Update } from '@ngrx/entity';
import { WorkspaceDetailViewModel } from '@selfai-platform/bi-api';
import { omit } from 'lodash';
import { map, Observable } from 'rxjs';
import { WORKSPACE_LIST_ENTITY_NAME } from '../constants';
import { WorkspaceListItem } from '../models/workspace.model';
import { workspaceListItemApiNormalizer } from '../normalizers';
import { WorkspaceApiToDomainService } from './workspace-domain-api.service';

@Injectable({ providedIn: 'root' })
export class WorkspaceListDataService extends DefaultDataService<WorkspaceListItem> {
  constructor(
    private readonly workspaceApiToDomainService: WorkspaceApiToDomainService,
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
  ) {
    super(WORKSPACE_LIST_ENTITY_NAME, http, httpUrlGenerator);
  }

  override update(update: Update<WorkspaceListItem>): Observable<WorkspaceListItem> {
    const workspace: Partial<WorkspaceDetailViewModel> = this.normalizeWorkspaceForUpdateApi(update.changes);

    return this.workspaceApiToDomainService
      .updateWorkspaceById(update.changes.id, workspace)
      .pipe(map(workspaceListItemApiNormalizer));
  }

  private normalizeWorkspaceForUpdateApi(changes: Partial<WorkspaceListItem>): Partial<WorkspaceDetailViewModel> {
    return omit(changes, ['createdBy']);
  }
}
