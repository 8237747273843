import { Component, ElementRef, EventEmitter, Injector, OnInit, Output } from '@angular/core';

import {
  BoardDataSource,
  BoardDataSourceRelation,
  BoardGlobalOptions,
  ConnectionType,
  Dashboard,
  JoinMapping,
  WorkbookActionsService,
  WorkbookPinsDomainService,
  createBoardGlobalOptions,
  createDashboard,
  setDataSourceAndRelations,
} from '@selfai-platform/bi-domain';

import { AbstractPopupComponent } from '../../../common/component/abstract-popup.component';
import { CommonUtil } from '../../../common/util/common.util';
import { StringUtil } from '../../../common/util/string.util';
import { DashboardApiLegacyService } from '../../service/dashboard-api.service';

@Component({
    selector: 'create-board-complete',
    templateUrl: './create-board-complete.component.html',
    standalone: false
})
export class CreateBoardCompleteComponent extends AbstractPopupComponent implements OnInit {
  @Output() completeCreateDashboard = new EventEmitter<void>();

  private _workbookId: string;

  private _dataSources: BoardDataSource[];
  private _relations: BoardDataSourceRelation[];

  public dashboard: Dashboard;

  public isShow = false;

  public workbookName: string;

  public isInvalidName = false;
  public errMsgName = '';

  public isInvalidDesc = false;
  public errMsgDesc = '';

  constructor(
    protected elementRef: ElementRef,
    protected injector: Injector,
    private dashboardService: DashboardApiLegacyService,
    private workbookActionsService: WorkbookActionsService,
    private readonly workbookPinsDomainService: WorkbookPinsDomainService,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.useUnloadConfirm = true;
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public openComp(
    workbookId: string,
    workbookName: string,
    dataSources: BoardDataSource[],
    relations: BoardDataSourceRelation[],
  ) {
    this._workbookId = workbookId;
    this._dataSources = dataSources;
    this._relations = relations;
    this.dashboard = createDashboard();
    this.workbookName = workbookName;
    this.isShow = true;
  }

  public closeComp(isForceClose: boolean = false) {
    if (isForceClose) {
      this.isShow = false;
      this.close();
    } else {
      this.unloadConfirmSvc.confirm().subscribe((isClose) => {
        if (isClose) {
          this.isShow = false;
          this.close();
        }
      });
    }
  }

  public complete() {
    if (this._verifyNameAndDesc()) {
      this.pageLoaderService.show();

      const options: BoardGlobalOptions = createBoardGlobalOptions();

      this.dashboard = setDataSourceAndRelations(this.dashboard, this._dataSources, this._relations);

      const linkedDs: BoardDataSource = this._dataSources.find(
        (item) => ConnectionType.LINK.toString() === item.connType,
      );
      if (linkedDs) {
        this.dashboard.temporaryId = linkedDs['temporaryId'];
      }

      this.dashboardService
        .createDashboard(this._workbookId, this.dashboard, options, (info) => {
          const dsIds: string[] = this._dataSources.reduce((acc: string[], currVal: BoardDataSource) => {
            acc.push(currVal.id);
            if (currVal.type === 'mapping') {
              currVal.joins.forEach((join: JoinMapping) => {
                acc.push(join.id);
                join.join && acc.push(join.join.id);
              });
            }
            return acc;
          }, []);
        })

        .then((board: Dashboard) => {
          this.workbookPinsDomainService
            .addDashboardToPins(this._workbookId, { dashboardId: board.id, name: board.name })
            .subscribe();
          this.alertPrimeService.success(
            `'${this.dashboard.name}' ` + this.translateService.instant('msg.board.alert.create.success'),
          );
          this.pageLoaderService.hide();
          this.completeCreateDashboard.emit();
          this.workbookActionsService.goToDashboardView(this._workbookId, board.id);
        })
        .catch((err) => this.commonExceptionHandler(err));
    }
  }

  public getDatasourceNames(): string {
    return this._dataSources.reduce((acc: string, currDs: BoardDataSource, currIdx: number) => {
      let result = currDs.name;
      if (currDs.joins && currDs.joins.length > 0) {
        result += ' / ' + currDs.joins.map((ds) => ds.name).join(', ');
      }
      0 < currIdx && (result = '<br>' + result);
      return acc + result;
    }, '');
  }

  private _verifyNameAndDesc() {
    this.dashboard.name = this.dashboard.name ? this.dashboard.name.trim() : '';
    if (StringUtil.isEmpty(this.dashboard.name)) {
      this.isInvalidName = true;
      this.errMsgName = this.translateService.instant('msg.alert.edit.name.empty');
      return false;
    }

    if (CommonUtil.getByte(this.dashboard.name) > 150) {
      this.isInvalidName = true;
      this.errMsgName = this.translateService.instant('msg.alert.edit.name.len');
      return false;
    }

    if (this.dashboard.description != null && CommonUtil.getByte(this.dashboard.description.trim()) > 450) {
      this.isInvalidDesc = true;
      this.errMsgDesc = this.translateService.instant('msg.alert.edit.description.len');
      return false;
    }
    return true;
  }
}
