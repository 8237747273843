import { Component, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { FontSize, Operator, TextAlign, TotalValueStyle, UIFontStyle, UIPosition } from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../common/component/abstract.component';

@Component({
    selector: 'calc-option-slider',
    templateUrl: './calc-option-slider.component.html',
    standalone: false
})
export class CalculatedOptionSliderComponent extends AbstractComponent implements OnInit, OnDestroy {
  public totalValueStyle: TotalValueStyle;

  @Input('totalValueStyle')
  public set setTotalValueStyle(totalValueStyle: TotalValueStyle) {
    this.totalValueStyle = totalValueStyle;

    if (totalValueStyle) {
      this.operatorDefaultIdx = this.operatorList.findIndex(
        (item) => item['value'] === totalValueStyle.aggregationType,
      );
      this.hAlignDefaultIdx = this.hAlignList.findIndex((item) => item['value'] === totalValueStyle.hAlign);
      -1 === this.hAlignDefaultIdx && (this.hAlignDefaultIdx = 0);
    }
  }

  @Output() public onChanged = new EventEmitter();

  public operatorList: Object[] = [
    { name: this.translateService.instant('msg.page.calc.label.operator.sum'), value: Operator.SUM },
    { name: this.translateService.instant('msg.page.calc.label.operator.average'), value: Operator.AVERAGE },
    { name: this.translateService.instant('msg.page.calc.label.operator.max'), value: Operator.MAX },
    { name: this.translateService.instant('msg.page.calc.label.operator.min'), value: Operator.MIN },
    { name: this.translateService.instant('msg.page.calc.label.operator.count'), value: Operator.COUNT },
  ];
  public operatorDefaultIdx = 0;

  public hAlignList: Object[] = [
    { name: this.translateService.instant('msg.page.chart.datalabel.text.align.default'), value: TextAlign.DEFAULT },
    { name: this.translateService.instant('msg.page.chart.datalabel.text.align.left'), value: TextAlign.LEFT },
    { name: this.translateService.instant('msg.page.chart.datalabel.text.align.center'), value: TextAlign.CENTER },
    { name: this.translateService.instant('msg.page.chart.datalabel.text.align.right'), value: TextAlign.RIGHT },
  ];
  public hAlignDefaultIdx = 0;

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public changeLabel(label: string): void {
    this.totalValueStyle.label = label;
    this.onChanged.emit(this.totalValueStyle);
  }

  public changeOperator(data: Object): void {
    this.totalValueStyle.aggregationType = data['value'];
    this.onChanged.emit(this.totalValueStyle);
  }

  public changeHAlign(data: Object): void {
    this.totalValueStyle.hAlign = data['value'];
    this.onChanged.emit(this.totalValueStyle);
  }

  public changeValign(vAlign: string): void {
    this.totalValueStyle.vAlign = UIPosition[vAlign];
    this.onChanged.emit(this.totalValueStyle);
  }

  public changeFontSize(fontSize: string): void {
    this.totalValueStyle.fontSize = FontSize[fontSize];
    this.onChanged.emit(this.totalValueStyle);
  }

  public changeFontColor(fontColor: any): void {
    this.totalValueStyle.fontColor = fontColor;
    this.onChanged.emit(this.totalValueStyle);
  }

  public changeFontBackground(backgroundColor: any): void {
    this.totalValueStyle.backgroundColor = backgroundColor;
    this.onChanged.emit(this.totalValueStyle);
  }

  public changeFontStyle(fontStyle: string): void {
    if (-1 == this.totalValueStyle.fontStyles.indexOf(UIFontStyle[fontStyle])) {
      this.totalValueStyle.fontStyles.push(UIFontStyle[fontStyle]);
    } else {
      this.totalValueStyle.fontStyles.splice(this.totalValueStyle.fontStyles.indexOf(UIFontStyle[fontStyle]), 1);
    }

    this.onChanged.emit(this.totalValueStyle);
  }

  public isFontStyle(fontStyle: string): boolean {
    if (this.totalValueStyle && this.totalValueStyle.fontStyles.indexOf(UIFontStyle[fontStyle]) != -1) {
      return true;
    } else {
      return false;
    }
  }
}
