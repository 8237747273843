<div class="clear_link"></div>
<div class="button_group flex flex-row align-items-center">
  <p-button 
    styleClass="p-button-link" 
    (click)="cancel.emit()" 
    [disabled]="isSaving">
    {{ 'msg.workspacesEdit.cancel' | translate }}
  </p-button>
  <p-button
    [label]="'msg.workspacesEdit.apply' | translate"
    (click)="apply.emit()"
    [disabled]="isSaving || disabled"
  ></p-button>
  <p-button
    [label]="'msg.workspacesEdit.saveAndExit' | translate"
    (click)="saveAndExit.emit()"
    [disabled]="isSaving || disabled"
  ></p-button>

  <ng-container *ngIf="isSaving">
    <i class="pi pi-spin pi-spinner"></i>
  </ng-container>
</div>
