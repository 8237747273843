import { ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  DataType,
  JsonToColumnItem,
  JsonToColumnItemFormGroup,
  JsonToColumnTransformationDataForSave,
  JsonToColumnTransformationFormGroup,
  WorkflowJsonToColumnTransformationData,
} from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { Table } from 'primeng/table';
import { filter, take, takeUntil } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { DialogHelperService } from '../../../../dialog';
import { normalizeToLegacyDataJsonToColumnTransormation } from '../../../converters';
import { SaveConfirmationService, SelectionStoreService } from '../../../services';
import { CubeDialogManagementService } from '../../../services/cube-dialog-management.service';
import { AbstractCubeDialogFormWithTableComponent } from '../../abstract-cube-dialog-form-with-table.component';
import { DialogHeaderService } from '../../dialog-header/dialog-header.service';
import { JsonToColumnTransformationComponentService } from './json-to-column-transformation-component.service';

@Component({
    selector: 'selfai-platform-json-to-column-transformation',
    templateUrl: './json-to-column-transformation.component.html',
    styleUrls: ['./json-to-column-transformation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        DestroyService,
        DialogHelperService,
        JsonToColumnTransformationComponentService,
        SelectionStoreService,
        SaveConfirmationService,
        DialogHeaderService,
    ],
    standalone: false
})
export class JsonToColumnTransformationComponent extends AbstractCubeDialogFormWithTableComponent implements OnInit {
  override itemsMap = new Map<string, FormGroup<JsonToColumnItemFormGroup>>();
  override form: FormGroup<JsonToColumnTransformationFormGroup> =
    this.jsonToColumnTransformationComponentService.getFormGroup();
  dataTypeItems = Object.values(DataType);

  @ViewChild('tableRef', { static: false, read: Table }) tableRef!: Table;

  override initialItem: JsonToColumnItem = {
    id: '',
    dataType: '' as DataType,
    jsonPath: '',
    columnName: null,
  };

  get dataForWorkflow(): WorkflowJsonToColumnTransformationData {
    const { dateFormat, dateTimeFormat, justTypeTransformation, sourceColumn } = this.form
      .value as JsonToColumnTransformationDataForSave;

    return normalizeToLegacyDataJsonToColumnTransormation({
      dateFormat,
      dateTimeFormat,
      justTypeTransformation,
      sourceColumn,
      items: this.items.map(({ value }: FormGroup<JsonToColumnItemFormGroup>) => ({
        ...value,
        columnName: value.columnName as string,
        dataType: value.dataType as DataType,
        jsonPath: value.jsonPath as string,
      })),
    });
  }

  constructor(
    private readonly jsonToColumnTransformationComponentService: JsonToColumnTransformationComponentService,
    injector: Injector,
    private readonly dialogHeaderService: DialogHeaderService,
    private readonly cubeDialogManagementService: CubeDialogManagementService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.jsonToColumnTransformationComponentService
      .getItemsFormGroups()
      .pipe(take(1), filter(Boolean), takeUntil(this.destroy$))
      .subscribe((items) => {
        items.forEach((formGroup) => {
          this.itemsMap.set(formGroup.controls.id.value, formGroup);
        });
        this.markFormAsInitialized();
      });
    this.dialogHeaderService.initCustomHeaderComponent(
      this.nodeId,
      'Json to column transformation',
      this.closeDialog.bind(this),
      this.onClickSave.bind(this),
    );
    setTimeout(() => {
      this.cubeDialogManagementService.setFocus(this.nodeId);
    });
  }

  filter(event: Event): void {
    this.tableRef.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  override copyItem(id: string): void {
    const data = this.itemsMap.get(id)?.value;
    if (data) {
      const id = uuidv4();
      const item: JsonToColumnItem = { ...data, id } as JsonToColumnItem;
      const form = this.mapItemToFormGroup(item);
      this.itemsMap.set(id, form);
    }
  }

  override addItem(): void {
    const id = uuidv4();
    const item = { ...this.initialItem, id };
    const form = this.mapItemToFormGroup(item);
    this.itemsMap.set(id, form);
  }

  mapItemToFormGroup(item: JsonToColumnItem): FormGroup<JsonToColumnItemFormGroup> {
    return this.jsonToColumnTransformationComponentService.mapItemToFormGroup(item);
  }
}
