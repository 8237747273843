import { Component, ElementRef, Injector, Input, OnDestroy, OnInit } from '@angular/core';

import { AbstractComponent } from '../../common/component/abstract.component';

@Component({
    selector: 'error-widget',
    templateUrl: './error-widget.component.html',
    styles: ['.ddp-box-data-none { overflow-y: auto; }'],
    standalone: false
})
export class ErrorWidgetComponent extends AbstractComponent implements OnInit, OnDestroy {
  @Input()
  public isShow = false;

  @Input()
  public iconClass: string;

  @Input()
  public widgetName: string;

  @Input()
  public errorInfo: { show?: boolean; code?: string; details?: string };

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }
}
